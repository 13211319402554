import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Messages extends Component {
  componentDidMount() {
    this.getData();
    this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],

     lastSeen: '',

     language: 'he',

     type: '',

     messages: []
   }
  }


  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getMessages = async () => {
    axios.get(API + 'manager/messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messages: response.data.data.messages
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  deleteMessage = (id) => {
    axios.delete(API + 'manager/message?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages()
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  unfreezeMessage = (id) => {
    axios.put(API + 'manager/message/freeze?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages()
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  freezeMessage = (id) => {
    axios.put(API + 'manager/message/unfreeze?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages()
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.messageSystem}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <Link
          to={'/message/add'}
          style={{textDecoration: 'none'}}>
            <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
              <ForumIcon style={{color: 'white'}} />שידור הודעה חדשה
            </Button>
            <br /><br />
        </Link>
        <center>
        {this.state.messages.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.emptymessagesstring1}
          <br/>
          {strings.emptymessagesstring2}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.delete}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.pause}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.broadcastat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.message}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.messages.map((message, i) => {
                  return (
                    <TableRow key={i+'_employee'}>
                      <TableCell align="right">
                        <Button variant={'outlined'} style={{color: 'red'}} onClick={(e) => this.deleteMessage(message._id)}>מחק הודעה</Button>
                      </TableCell>
                      <TableCell align="right">
                        {message.frozen ? (
                          <Button variant={'outlined'} style={{color: 'red'}} onClick={(e) => this.freezeMessage(message._id)}>הקפא שידור הודעה</Button>
                        ) : (
                          <Button variant={'outlined'} style={{color: 'green'}} onClick={(e) => this.unfreezeMessage(message._id)}>המשך שידור הודעה</Button>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(message.createdAt).getDate() + '/' + parseInt(new Date(message.createdAt).getMonth() + 1) + '/' + new Date(message.createdAt).getFullYear()}
                      </TableCell>
                      <TableCell align="right">
                        {(message.type === 'specific' && message.teacherId !== '') && ('הודעה ספציפית למורה' + ' ('+message.teacherName+')')}
                        {(message.type === 'specific' && message.teacherId === '') && ('הודעה ספציפית לבית ספר')}
                        {(message.type !== 'specific') && ('הודעה כללית')}
                      </TableCell>
                      <TableCell align="right">
                        {message.frozen ? (
                          <p style={{fontWeight: 'bold', color: 'red'}}>{strings.frozen}</p>
                        ) : (
                          <p style={{fontWeight: 'bold', color: 'green'}}>{strings.active}</p>
                        )}
                      </TableCell>
                      <TableCell align="right">{message.message}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default Messages;
