import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import DescriptionIcon from '@material-ui/icons/Description';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import {
  Link
} from "react-router-dom";

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ClerkGenerateInvoice extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     users: [],
     user: {},
     cashRegister: '',
     userId: '',
     lastSeen: '',
     language: 'he',
     money: 0,
     payments: [],
     paymentsOriginal: [],
     old_payments: [],
     old_paymentsOriginal: [],
     invoiceReady: false,
     link: '',
     whatsapp: '',
     search_query: ''
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/get-invoiceables', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          payments: response.data.data.payments,
          paymentsOriginal: response.data.data.payments,
          old_payments: response.data.data.old,
          old_paymentsOriginal: response.data.data.old
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת רשימת העובדים')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getAmount = (amount) => {
    let money = parseFloat(amount).toFixed(2);
    if (money === null || money === undefined || isNaN(money)) return parseFloat(0.00);

    return money;
  }

  generateInvoice = (id, whatsapp) => {
    this.setState({invoiceReady: false, link: '', whatsapp: ''});
    axios.post(API + 'clerk/generate-invoice', {
      id: id
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        window.scrollTo(0, 0);
        let whatsapp_ = 'https://wa.me/972'+whatsapp+'?text='+encodeURI('החשבונית שלך מוכנה וזמינה בקישור: \n' + response.data.data.link);
        this.setState({invoiceReady: true, link: response.data.data.link, whatsapp: whatsapp_});
        this.getData();
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה ביצירת חשבונית חדשה')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  search = (string) => {
    this.setState({search_query: string}, () => {
      let payments = this.state.old_paymentsOriginal;
      let output = [];

      payments.forEach((item, i) => {
        if (
          string.includes(item.student.firstName) ||
          item.student.firstName.includes(string) ||
          string.includes(item.student.lastName) ||
          item.student.lastName.includes(string) ||
          string.includes(item.teacher.firstName) ||
          item.teacher.firstName.includes(string) ||
          string.includes(item.teacher.lastName) ||
          item.teacher.lastName.includes(string)
        ) {
          output.push(item);
        }
      });

      // if (output.length > 0) {
        this.setState({old_payments: output});
      // } else {
      //   this.setState({old_payments: this.state.old_paymentsOriginal})
      // }
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              {this.state.invoiceReady && (
                <React.Fragment>
                  <center>
                    <p>{strings.invoiceready}</p>

                    <IconButton onClick={(e) => window.open(this.state.link, '_blank')}>
                      <GetAppIcon style={{width: 32, height: 32 }} />
                      <a href={this.state.link} target="_blank">{strings.todownloadreceipt}</a>
                    </IconButton>
                    <p>{strings.orH}</p>
                    <Button onClick={(e) => window.open(this.state.link, '_blank')} style={{backgroundColor: '#25D366'}}>
                      <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                      <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceipt}</a>
                    </Button>
                  </center>
                </React.Fragment>
              )}

              <h2 style={{textAlign: 'right'}}>{strings.generateinvoices}</h2>

              <center>

                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.generateinvoice}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.callstudent}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.callteacher}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.payments.map((payment, i) => {
                        if (payment.payment.type !== 'ptor') {
                          let createdAtStringify = new Date(payment.payment.createdAt);
                          createdAtStringify = createdAtStringify.getDate() + '/' + (createdAtStringify.getMonth() + 1) + '/' + createdAtStringify.getFullYear();

                          return (
                            <TableRow key={i+'_payment'}>
                              <TableCell align="right">
                                <IconButton onClick={(e) => this.generateInvoice(payment.payment._id, payment.student.phoneNumber)}>
                                  <DescriptionIcon style={{width: 32, height: 32}} />
                                </IconButton>
                              </TableCell>
                              <TableCell align="right">₪{this.getAmount(payment.payment.amountWithVAT)}</TableCell>
                              <TableCell align="right"><a href={'tel:'+payment.student.phoneNumber}>{payment.student.phoneNumber}</a></TableCell>
                              <TableCell align="right"><a href={'tel:'+payment.teacher.phoneNumber}>{payment.teacher.phoneNumber}</a></TableCell>
                              <TableCell align="right">{payment.student.firstName + ' ' + payment.student.lastName}</TableCell>
                              <TableCell align="right">{payment.teacher.firstName + ' ' + payment.teacher.lastName}</TableCell>
                              <TableCell align="right">{createdAtStringify}</TableCell>
                            </TableRow>
                          )
                        }
                      })}

                    </TableBody>
                  </Table>
                </TableContainer>


              </center>
              <br />
              <h2 style={{textAlign: 'right'}}>{strings.oldinvoices}</h2>

              <center>
              <h4>חפשו רשומה על פי שם התלמיד או שם המורה</h4>
              <TextField
                value={this.state.search_query}
                onChange={(e) => this.search(e.target.value)}
                placeholder={'...הקלידו כאן'}
              />
              </center>
              <br />

              <center>
              {this.state.old_payments.length === 0 ? (
                <h4 style={{direction: 'rtl', color: 'grey'}}>
                {strings.nooldinvoices1}
                <PersonAddIcon style={{width: 32, height: 32}}/><br/>
                 {strings.nooldinvoices2}
                </h4>
              ) : (
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {/*<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.generateinvoice}</TableCell>*/}
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{'העתק לינק לחשבונית'}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.callstudent}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.callteacher}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.old_payments.map((payment, i) => {
                        if (!payment.payment.isPtor) {
                          let createdAtStringify = new Date(payment.payment.createdAt);
                          createdAtStringify = createdAtStringify.getDate() + '/' + (createdAtStringify.getMonth() + 1) + '/' + createdAtStringify.getFullYear();


                          return (
                            <TableRow key={i+'_payment'}>
                              {/*
                                <TableCell align="right">
                                  <IconButton>
                                    <DescriptionIcon style={{width: 32, height: 32}} />
                                  </IconButton>
                                </TableCell>
                                */}
                              {payment.payment.isPtor ? (
                                <TableCell align="right"> - </TableCell>
                              ) : (
                                <TableCell align="right"><Button onClick={(e) => this.copyToClipboard(payment.payment.isPtor ? ' -' : (payment.payment.type === 'tranzila' ? 'https://sivan-school.com/pdfs/'+ payment.payment._id +'.pdf' : 'https://sivan-school.com/pdfs/invoice_'+ payment.payment._id +'.pdf'))}>העתקה</Button></TableCell>
                              )}
                              <TableCell align="right">₪{parseFloat(payment.payment.amountWithVAT).toFixed(2)}</TableCell>
                              <TableCell align="right"><a href={'tel:'+payment.student.phoneNumber}>{payment.student.phoneNumber}</a></TableCell>
                              <TableCell align="right"><a href={'tel:'+payment.teacher.phoneNumber}>{payment.teacher.phoneNumber}</a></TableCell>
                              <TableCell align="right">{payment.student.firstName + ' ' + payment.student.lastName}</TableCell>
                              <TableCell align="right">{payment.teacher.firstName + ' ' + payment.teacher.lastName}</TableCell>
                              <TableCell align="right">{createdAtStringify}</TableCell>
                            </TableRow>
                          )
                        }
                      })}

                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              </center>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkGenerateInvoice;
