import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ManagerPullMoney extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     users: [],
     user: {},
     cashRegister: '',
     userId: '',
     lastSeen: '',
     language: 'he',
     money: ''
   }
  }

  getData = async () => {
    axios.get(API + 'manager/users-viable-for-money-pull', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let users = response.data.data.users;
        users.sort(function(a,b){
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        this.setState({
          users: users,
          cashRegister: response.data.data.cashRegister
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת המידע מהשרת ת.23141')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  transferMoney = () => {
    // if (parseInt(this.state.user.cashRegister) === parseInt(this.state.money)) {
      let payload = {
        money: this.state.money
      }

      axios.put(API + 'manager/money-pull?id='+this.state.userId, payload, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          this.setState({userId: '', money: 0});
          //alert(strings.moneytransfersuccessmessage);
          this.getData();
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בהעברת הכסף');
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    // } else {
    //   this.setState({money: 0})
    //   alert('הסכום אינו תואם לסכום בקופה')
    // }
  }

  setUser = (id) => {
    this.setState({userId: id}, () => {
      let users = this.state.users;
      let user = {}
      users.forEach((item, i) => {
        if (item._id.toString() === id) user = item;
      });

      this.setState({user: user}, () => {console.log(this.state.user)});
    })
  }

  setMoney = (money, limit) => {
    if (money === '' || money === 0) {
      this.setState({money: ''})
    } else {
      const digits_only = string => [...string].every(c => '0123456789'.includes(c));

      if (digits_only(money)) {
        money = parseFloat(money);
        limit = parseFloat(limit);

        if (money <= limit) {
          this.setState({money: money})
        }
      }
    }

  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.pullmoney}</h2>

              <p>{strings.mycashregister}: ₪{this.state.cashRegister}</p>
              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseteacher}</p>
              <Select
                value={this.state.userId}
                onChange={(e) => this.setUser(e.target.value)}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                {this.state.users.map((index, i) => {
                  return (
                    <MenuItem value={index._id.toString()} style={{direction: 'rtl', textAlign: 'right'}}>{index.firstName + ' ' + index.lastName + ' (₪'+parseFloat(index.cashRegister).toFixed(2)+')'}</MenuItem>
                  );
                })}
              </Select>

              {this.state.userId !== '' && (
                <React.Fragment>
                  <p>{strings.firstname}: {this.state.user.firstName}</p>
                  <p>{strings.lastname}: {this.state.user.lastName}</p>
                  <p>{strings.cashregister}: ₪{parseFloat(this.state.user.cashRegister).toFixed(2)}</p>

                  <p><b><u>:{strings.pullmoney}</u></b></p>
                  <TextField
                    value={this.state.money}
                    onChange={(e) => this.setMoney(e.target.value, this.state.user.cashRegister)}
                    style={{
                      textAlign: 'right',
                      direction: 'rtl'
                    }}
                  />
                  <br /><br />
                  <Button onClick={(e) => this.transferMoney()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                    {strings.transfermoney}
                  </Button>
                </React.Fragment>
              )}
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ManagerPullMoney;
