import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class AddObligation extends Component {
  componentDidMount() {
    this.getTeachers();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     dateOpen: false,
     date: new Date(Date.now()),
     teachers: [],
     teacherFound: {},
     teacherById: {},
     selectedTeachers: [],
     selectedTeachersAll: [],
   }
  }


  getTeachers = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
          let teacherById = {};
        this.setState({teachers: response.data.data.employees}, () => {
          let selectedTeachersAll = [];
          this.state.teachers.forEach((item, i) => {
            teacherById[item._id.toString()] = item;
            selectedTeachersAll.push(item._id.toString());
          });
          this.setState({teacherById: teacherById, selectedTeachersAll: selectedTeachersAll})
          if (window.location.href.includes('?teacher=')) {
            let id = window.location.href.split('?teacher=')[1];

            this.state.teachers.forEach((item, i) => {
              console.log(item._id.toString().trim(), '-', id.trim());
              if (item._id.toString().trim() === id.trim() || item._id.toString().trim().includes(id)) {
                this.setState({teacherId: id, teacherFound: item});
              }
            });
          }
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getData = async () => {
    axios.get(API + 'obligations', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          table: response.data.table,
          header: response.data.header,
          employee: response.data.employee
        }, () => {
          const table = response.data.table;
          let rows = [];
          Object.keys(table).forEach((item, i) => {
            let value = table[item];
            if (value.length > this.state.rowsNum) this.setState({rowsNum: value.length});
            for (let xr = 0; xr < value.length; xr++){
              if (!rows.includes(value[xr].name)) rows.push(value[xr].name);
            }
          });
          this.setState({rows: rows})
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  create = () => {
    const selectedTeachers = this.state.selectedTeachers;
    if (selectedTeachers && selectedTeachers.length >= 1) {
      let count = 0;
      selectedTeachers.forEach((item, i) => {
        let payload = {
          userId: item,
          description: this.state.description,
          date: this.state.date,
          amount: parseFloat(this.state.amount),
          payedWithCashRegister: this.state.payedWithCashRegister
        };

        return axios.post('https://api.sivan-school.com/v1/obligations', payload, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        }).then(res => {
          console.log(res)
          if (res.data.status === "ok") {
            count += 1;
            if (count === selectedTeachers.length - 1) {
              alert('ההתחייבויות נוצרו בהצלחה');
              this.setState({
                userId: '',
                description: '',
                date: new Date(Date.now()),
                amount: '0',
                payedWithCashRegister: false,
                selectedTeachers: []
              });
            }
          } else {
            alert('שגיאה ביצירת ההתחייבות, אנא נסו בשנית');
          }
        }).catch(err => {
          console.log(err)
          alert('שגיאה ביצירת ההתחייבות, אנא נסו בשנית');
        })
      });
    } else {
      alert('יש לבחור לפחות מורה אחד')
    }
  }

  addSelectedTeacher = (id) => {
    let selectedTeachers = this.state.selectedTeachers;
    if (!this.state.selectedTeachers.includes(id)) {
      selectedTeachers.push(id);
      this.setState({selectedTeachers: selectedTeachers});
    }
  }

  removeFromSelected = (id) => {
    let selectedTeachers = this.state.selectedTeachers;
    const index = selectedTeachers.indexOf(id);
    if (index !== -1) {
      selectedTeachers.splice(index, 1);
    }
    this.setState({selectedTeachers: selectedTeachers})
  }

  selectAll = () => {
    const a = window.confirm('האם אתם בטוחים שברצונכם לסמן את כל המורים?');
    if (a) {
      this.setState({selectedTeachers: this.state.selectedTeachersAll})
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>הוספת התחייבות כספית חדשה</h1>
              <Button color={'primary'} variant={'contained'} onClick={(e) => window.location.href='/financial-obligations'}>לחזרה לפורטל הראשי</Button>

            </center>
          </Grid>

          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '80%'}}>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> הוסף מורה</p>
                {this.state.teacherFound['_id'] !== undefined ? (
                  <p style={{textAlign: 'right'}}>{this.state.teacherFound['firstName'] + ' ' +this.state.teacherFound['lastName']}</p>
                ) : (
                  <Select
                    value={this.state.teacherId}
                    onChange={(e) => this.addSelectedTeacher(e.target.value)}
                    default={this.state.teacherId}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    {this.state.teachers.map((index, i) => {
                      if (index.frozen !== true) {
                        return (
                          <MenuItem value={index._id.toString()} style={{direction: 'rtl', textAlign: 'right'}}>{index.firstName + ' ' + index.lastName}</MenuItem>
                        );
                      }
                    })}
                  </Select>
                )}
                <br />
                <br />
                <Button variant={'contained'} color={'primary'} onClick={(e) => this.selectAll()}>בחירת כל המורים</Button>

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> המורים אליהם תשלח התחייבות</p>
                {this.state.selectedTeachers ? (
                  <>
                    {this.state.selectedTeachers.map((teacher) => {
                      return (
                        <>
                        <td>
                        <button onClick={(e) => this.removeFromSelected(teacher)}>הסרה</button>
                        &nbsp;
                          {this.state.teacherById[teacher].firstName + ' ' + this.state.teacherById[teacher].lastName}

                          <br />
                        </td>
                        <br />
                        </>
                      )
                    })}
                  </>
                ) : (
                  <p style={{color: 'red', fontWeight: 'bold'}}>לא נבחרו מורים</p>
                )}

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> תיאור ההתחייבות</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.description}
                  onChange={(e) => this.setState({description: e.target.value})}
                  required
                />

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סכום בש"ח (מספרים בלבד)</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.amount}
                  onChange={(e) => this.setState({amount: e.target.value})}
                  required
                />

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> תאריך</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      value={this.state.date}
                      open={this.state.dateOpen}
                      onClick={(e) => this.setState({dateOpen: true})}
                      onClose={(e) => this.setState({dateOpen: false})}
                      onChange={(date) => this.setState({dateOpen: date})}
                      style={{
                        width: '100%',
                        textAlign: 'right',
                        direction: 'rtl'
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

                <p style={{textAlign: 'right', direction: 'rtl', marginBottom: 0}}>האם לשלם על ידי קופת (העמלות) המורה?</p>
                <div style={{textAlign: 'right', marginTop: 0, marginBottom: 0}}>
                  <Checkbox
                    checked={this.state.payedWithCashRegister}
                    onChange={(e) => this.setState({payedWithCashRegister: this.state.payedWithCashRegister ? false : true})}
                  />
                </div>
                <p style={{textAlign: 'right', direction: 'rtl', marginTop: 0, marginBottom: 0}}>שימו לב - יחייב את כל הסכום</p>

                <Button color={'primary'} variant={'contained'} onClick={(e) => this.create()}>יצירת התחייבות חדשה</Button>
              </div>
            </center>
          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default AddObligation;
