import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class SmallCashRegister extends Component {
  componentDidMount() {
    this.getData();
    this.getPermission();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     cashregister: {
       amount: 0
     },
     records: [],
     lastSeen: '',
     language: 'he',
     newRecordRender: false,
     date: new Date(Date.now()),
     dateDialog: false,
     suppliers: [],
     invoiceType: 'receipttaxinvoice',
     supplier: '',
     paymentType: 'changing',
     category: '',
     amount: 0,
     recordsOriginal: [],
     monthSelected: false,
     daySelected: false,
     elseSelected: false,
     elseDateDialog: false,
     elseDate: new Date(Date.now()),
     permissions: {
       print: false,
       view: false,
       in: false,
       out: false,
       edit: false,
       edit24: false,
       remove: false
     },
     fromDateDoc: new Date(Date.now()),
     fromDateDocDialog: false,
     toDateDoc: new Date(Date.now()),
     toDateDocDialog: false,
     link: '',
     whatsapp: '',
     email: '',
     recordId: '',
     source: '',
     sourceSelection: 'smallcashregister',
     user: {
       isGizbar: false,
       isManager: false,
       type: ''
     },
     pullMoney: false,
     totalIn: '0',
     totalOut: '0'
   }
  }


  getData = async () => {
    axios.get(API + 'smallcashregister', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          cashregister: response.data.data.cashregister,
          records: response.data.data.records,
          recordsOriginal: response.data.data.records,
          suppliers: response.data.data.suppliers,
          permissions: response.data.data.permissions
        }, () => {
          console.log(this.state.permissions);
          if (this.state.suppliers.length >= 1) this.setState({supplier: this.state.suppliers[0]._id})
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getPermission = async () => {
    axios.get(API + 'validate', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      this.setState({user: response.data.data});
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  saveRecord = async () => { // TODO: add picture
    let invoiceType = this.state.invoiceType;
    let amount = parseFloat(this.state.amount);
    let note = this.state.note;
    // if (invoiceType === 'invoice' || invoiceType === 'receipttaxinvoice') amount = amount * 1.17;
    axios.post(API + 'smallcashregister-out', {
      date: this.state.date,
      time: this.state.time,
      invoiceType: this.state.invoiceType,
      invoiceNumber: '',
      supplier: this.state.supplier,
      paymentType: this.state.paymentType,
      category: this.state.category,
      source: this.state.sourceSelection,
      amount: amount,
      note: note
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          date: new Date(Date.now()),
          time: '',
          invoiceType: 'receipt',
          supplier: this.state.suppliers[0]._id,
          category: '',
          source: '',
          sourceSelection: 'smallcashregister',
          amount: 0,
          newRecordRender: false
        }, () => {
          this.getData();
          this.setState({newRecordRender: false});
          this.resetState();
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  editRecord = async () => { // TODO: add picture
    let invoiceType = this.state.invoiceType;
    let amount = parseFloat(this.state.amount);
    let note = this.state.note;
    // if (invoiceType === 'invoice' || invoiceType === 'receipttaxinvoice') amount = amount * 1.17;
    axios.put(API + 'smallcashregister-edit?id='+this.state.recordId, {
      date: this.state.date,
      time: this.state.time,
      invoiceType: this.state.invoiceType,
      invoiceNumber: '',
      supplier: this.state.supplier,
      paymentType: this.state.paymentType,
      category: this.state.category,
      source: this.state.sourceSelection,
      amount: amount,
      note: note
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          date: new Date(Date.now()),
          time: '',
          invoiceType: 'receipt',
          supplier: this.state.suppliers[0]._id,
          category: '',
          amount: 0,
          source: '',
          sourceSelection: 'smallcashregister',
          newRecordRender: false
        }, () => {
          this.resetState();
          this.setState({editRecordRender: false});
          this.getData();
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  deleteRecord = async (record) => { // TODO: add picture
    let id = record._id;
    // if (invoiceType === 'invoice' || invoiceType === 'receipttaxinvoice') amount = amount * 1.17;
    axios.delete(API + 'smallcashregister-remove?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.resetState();
        this.setState({editRecordRender: false});
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  isDate = (dateStr) => {
    return !isNaN(new Date(dateStr).getDate());
  }

  pad = (n) => {return n<10 ? '0'+n : n}

  filterToday = () => {
    let date = new Date(Date.now());
    date = new Date(date).getFullYear() + '-' + new Date(date).getMonth() + '-' + new Date(date).getDate();
    const records = this.state.recordsOriginal;
    let output = [];

    records.forEach((item, i) => {
      let d = new Date(item.date).getFullYear() + '-' + new Date(item.date).getMonth() + '-' + new Date(item.date).getDate();

      if (d === date) output.push(item);
    });


    this.setState({records: output, monthSelected: false, daySelected: true, elseSelected: false});
  }
  filterByDate = (date) => {
    date = new Date(date).getFullYear() + '-' + new Date(date).getMonth() + '-' + new Date(date).getDate();
    const records = this.state.recordsOriginal;
    let output = [];

    records.forEach((item, i) => {
      let d = new Date(item.date).getFullYear() + '-' + new Date(item.date).getMonth() + '-' + new Date(item.date).getDate();

      if (d === date) output.push(item);
    });


    this.setState({records: output, monthSelected: false, daySelected: false, elseSelected: true});
  }

  filterByMonth = (month) => {
    const records = this.state.recordsOriginal;
    let output = [];

    records.forEach((item, i) => {
      function pad(n) {return n<10 ? '0'+n : n}
      let d = new Date(item.date).getMonth() + 1;

      if (pad(d.toString()) === pad(month.toString()) && new Date(item.date).getFullYear() === new Date(Date.now()).getFullYear()) output.push(item);
    });


    this.setState({records: output, monthSelected: true, daySelected: false, elseSelected: false});
  }

  generateDocument = () => {
    let fromDateDoc = this.state.fromDateDoc;
    let toDateDoc = this.state.toDateDoc;

    axios.post(API + 'smallcashregister-document', {
      fromDate: fromDateDoc,
      toDate: toDateDoc
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          link: response.data.data.link,
          whatsapp: response.data.data.whatsapp,
          email: response.data.data.email,
          downloadRender: true
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  modifyPayType = (payment) => {
    if (payment === 'changing') {
      return 'משתנה';
    } else if (payment === 'monthly') {
      return 'חודשי רציף'
    } else if (payment === 'two-monthly') {
      return 'דו חודשי רציף';
    } else if (payment === 'yearly'){
      return 'שנתי';
    } else {
      return '-';
    }
  }

  igniteEdition = (record) => {
    window.scroll(0,0);
    console.log(record);
    this.setState({
      date: record.date,
      time: record.time,
      paymentType: record.paymentType,
      supplier: record.supplier,
      invoiceType: record.invoiceType,
      sourceSelection: record.source,
      amount: record.amount,
      recordId: record._id,
      note: record.note
    }, () => {
      this.setState({editRecordRender: true})
    })
  }

  resetState = () => {
    this.setState({
      date: new Date(Date.now()),
      time: '',
      paymentType: 'yearly',
      invoiceType: 'receipttaxinvoice',
      supplier: this.state.suppliers[0]._id,
      amount: 0,
      recordId: '',
      note: ''
    });
  }


  translateKeySource = (source) => {
    const keys_ = {
      "bank": "העברה בנקאית",
      "application": "אפליקציה",
      "cash": "מזומן",
      "creditcard": "אשראי",
      "cheque": "שיק",
      "standing-order": "העברת קבע"
    };
    if (keys_.hasOwnProperty(source)) {
      return keys_[source];
    } else {
      return "אחר"
    }
  }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          {this.state.downloadRender && (
            <React.Fragment>
              <Grid item xs={12}>
                <center>
                <a href='/'>{strings.backtohomepage}</a>

                  <p style={{fontSize: '1.5em'}}>{'הורדת מסמך הוצאות הקופה'}</p>

                  <IconButton onClick={(e) => window.open(this.state.link, '_blank')}>
                    <GetAppIcon style={{width: 32, height: 32 }} />
                    <a href={this.state.link} target="_blank">הורדת המסמך</a>
                  </IconButton>
                  <p>{strings.orH}</p>
                  <Button onClick={(e) => window.open(this.state.whatsapp, '_blank')} style={{backgroundColor: '#25D366'}}>
                    <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                    <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלחו מסמך בווטצאפ</a>
                  </Button>
                  <br />
                  <Button onClick={(e) => window.open('mailto:'+this.state.email+'?subject=הוצאות קופה קטנה&body=היי, מצרף כאן את הוצאות הקופה הקטנה '+this.state.link, '_blank')} color={'primary'} variant={'contained'}>
                    <MailOutlineIcon style={{width: 32, height: 32, color: 'white'}}/>
                    &nbsp;
                    <a href={'mailto:'+this.state.email+'?subject=הוצאות קופה קטנה&body=היי, מצרף כאן את הוצאות הקופה הקטנה '+this.state.link} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלח את החשבונית באימייל בלחיצת כפתור</a>
                  </Button>
                  <br /><br />
                  <Button onClick={(e) => window.open('https://mail.google.com/mail/?view=cm&fs=1&to='+this.state.email+'&su=הוצאות קופה קטנה&body=היי, מצרף כאן את הוצאות הקופה הקטנה '+this.state.link, '_blank')} style={{backgroundColor: '#D44638'}} variant={'contained'}>
                    <MailOutlineIcon style={{width: 32, height: 32, color: 'white'}}/>
                    &nbsp;
                    <a href={'https://mail.google.com/mail/?view=cm&fs=1&to='+this.state.email+'&su=הוצאות קופה קטנה&body=היי, מצרף כאן את הוצאות הקופה הקטנה '+this.state.link} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלח את החשבונית באימייל בלחיצת כפתור</a>
                  </Button>
                  <br />

                </center>
              </Grid>
            </React.Fragment>
          )}

          {this.state.newRecordRender && (
            <Grid item xs={12}>
              <Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({newRecordRender: false}, () => { this.resetState() })}>סגירה וביטול</Button>
              <h2><u>רישום תשלום חדש לספק</u></h2>
              <center>
                <div style={{direction: 'rtl', textAlign: 'right', maxWidth: '50%'}}>
                  <p>תאריך:</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={this.state.date}
                    selecte={this.state.date}
                    onChange={(value) => this.setState({date: value})}
                    open={this.state.dateDialog}
                    onClick={(e) => this.setState({dateDialog: true})}
                    onClose={(e) => this.setState({dateDialog: false})}
                    disableFuture={false}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{
                      width: '100%'
                    }}
                    locale='he'
                  />
                  </MuiPickersUtilsProvider>
                  <br />
                  <p>שעה:</p>
                  <TextField
                    value={this.state.time}
                    onChange={(e) => this.setState({time: e.target.value})}
                    placeholder={'10:15'}
                    style={{width: '100%'}}
                  />
                  <br />
                  <p>סוג חשבונית:</p>
                  <Select style={{width: '100%'}} value={this.state.invoiceType} onChange={(e) => this.setState({invoiceType: e.target.value})}>
                    <MenuItem value={'receipt'}>קבלה</MenuItem>
                    <MenuItem value={'invoice'}>חשבונית</MenuItem>
                    <MenuItem value={'receipttaxinvoice'}>חשבונית מס קבלה</MenuItem>
                  </Select>
                  <br />
                  <p>בחר ספק מהרשימה</p>
                  <Select style={{width: '100%'}} value={this.state.supplier} onChange={(e) => this.setState({supplier: e.target.value})}>
                    {this.state.suppliers.map((supplier) => {
                      return (
                        <MenuItem value={supplier._id}>{supplier.name}</MenuItem>
                      )
                    })}
                  </Select>
                  <br />
                  <p>תשלום באמצעות:</p>
                  <Select style={{width: '100%'}} value={this.state.sourceSelection} onChange={(e) => this.setState({sourceSelection: e.target.value})}>
                    <MenuItem value={'smallcashregister'}>קופה קטנה</MenuItem>
                    <MenuItem value={'cash'}>מזומן</MenuItem>
                    <MenuItem value={'cheque'}>שיק</MenuItem>
                    <MenuItem value={'creditcard'}>אשראי</MenuItem>
                    <MenuItem value={'bank'}>העברה בנקאית</MenuItem>
                    <MenuItem value={'application'}>אפליקציה</MenuItem>
                    <MenuItem value={'standing-order'}>הוראת קבע</MenuItem>
                    {/* <MenuItem value={'other'}>אחר</MenuItem> */}
                  </Select>
                  {this.state.sourceSelection === 'other' && (
                    <TextField
                      value={this.state.source}
                      onChange={(e) => this.setState({source: e.target.value})}
                      placeholder={'תשלום באמצעות..'}
                      style={{width: '100%'}}
                    />
                  )}
                  <br />
                  <p>צורת תשלום:</p>
                  <Select style={{width: '100%'}} value={this.state.paymentType} onChange={(e) => this.setState({paymentType: e.target.value})}>
                    <MenuItem value={'changing'}>משתנה</MenuItem>
                    <MenuItem value={'monthly'}>חודשי רציף</MenuItem>
                    <MenuItem value={'two-monthly'}>דו חודשי רציף</MenuItem>
                    <MenuItem value={'yearly'}>שנתי</MenuItem>
                  </Select>
                  <br />
                  <p>סכום (כולל מע"מ):</p>
                  <Input
                    type={'number'}
                    value={this.state.amount}
                    onChange={(e) => this.setState({amount: e.target.value})}
                    style={{width: '100%'}}
                  />

                  <br />
                  <p>הערה:</p>
                  <TextField
                    value={this.state.note}
                    onChange={(e) => this.setState({note: e.target.value})}
                    placeholder={'הערה..'}
                    style={{width: '100%', direction: 'rtl'}}
                  />
                  <br />
                  <br />
                  <Button color={'primary'} variant={'contained'} onClick={(e) => this.saveRecord()}>שמירה</Button>
                </div>
              </center>
            </Grid>
          )}

          {this.state.editRecordRender && (
            <Grid item xs={12}>
              <Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({editRecordRender: false}, () => { this.resetState() } )}>סגירה וביטול</Button>
              <h2><u>עריכת רשומה</u></h2>
              <center>
                <div style={{direction: 'rtl', textAlign: 'right', maxWidth: '50%'}}>
                  <p>תאריך:</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={this.state.date}
                    selecte={this.state.date}
                    onChange={(value) => this.setState({date: value})}
                    open={this.state.dateDialog}
                    onClick={(e) => this.setState({dateDialog: true})}
                    onClose={(e) => this.setState({dateDialog: false})}
                    disableFuture={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{
                      width: '100%'
                    }}
                    locale='he'
                  />
                  </MuiPickersUtilsProvider>
                  <br />
                  <p>שעה:</p>
                  <TextField
                    value={this.state.time}
                    onChange={(e) => this.setState({time: e.target.value})}
                    placeholder={'10:15'}
                    style={{width: '100%'}}
                  />
                  <br />
                  <p>סוג חשבונית:</p>
                  <Select style={{width: '100%'}} value={this.state.invoiceType} onChange={(e) => this.setState({invoiceType: e.target.value})}>
                    <MenuItem value={'receipt'}>קבלה</MenuItem>
                    <MenuItem value={'invoice'}>חשבונית</MenuItem>
                    <MenuItem value={'receipttaxinvoice'}>חשבונית מס קבלה</MenuItem>
                  </Select>
                  <br />
                  <p>בחר ספק מהרשימה</p>
                  <Select style={{width: '100%'}} value={this.state.supplier} onChange={(e) => this.setState({supplier: e.target.value})}>
                    {this.state.suppliers.map((supplier) => {
                      return (
                        <MenuItem value={supplier._id}>{supplier.name}</MenuItem>
                      )
                    })}
                  </Select>
                  <br />
                  <p>תשלום באמצעות:</p>
                  <Select style={{width: '100%'}} value={this.state.sourceSelection} onChange={(e) => this.setState({sourceSelection: e.target.value})}>
                    <MenuItem value={'smallcashregister'}>קופה קטנה</MenuItem>
                    <MenuItem value={'cash'}>מוזמן</MenuItem>
                    <MenuItem value={'cheque'}>שיק</MenuItem>
                    <MenuItem value={'creditcard'}>אשראי</MenuItem>
                    <MenuItem value={'bank'}>העברה בנקאית</MenuItem>
                    <MenuItem value={'application'}>אפליקציה</MenuItem>
                    <MenuItem value={'standing-order'}>הוראת קבע</MenuItem>
                    {/* <MenuItem value={'other'}>אחר</MenuItem> */}
                  </Select>
                  {this.state.sourceSelection === 'other' && (
                    <TextField
                      value={this.state.source}
                      onChange={(e) => this.setState({source: e.target.value})}
                      placeholder={'תשלום באמצעות..'}
                      style={{width: '100%'}}
                    />
                  )}
                  <br />
                  <p>צורת תשלום:</p>
                  <Select style={{width: '100%'}} value={this.state.paymentType} onChange={(e) => this.setState({paymentType: e.target.value})}>
                    <MenuItem value={'changing'}>משתנה</MenuItem>
                    <MenuItem value={'monthly'}>חודשי רציף</MenuItem>
                    <MenuItem value={'two-monthly'}>דו חודשי רציף</MenuItem>
                    <MenuItem value={'yearly'}>שנתי</MenuItem>
                  </Select>
                  <br />
                  <p>סכום (כולל מע"מ):</p>
                  <Input
                    type={'number'}
                    value={this.state.amount}
                    onChange={(e) => this.setState({amount: e.target.value})}
                    style={{width: '100%'}}
                  />
                  <br />
                  <p>הערה:</p>
                  <TextField
                    value={this.state.note}
                    onChange={(e) => this.setState({note: e.target.value})}
                    placeholder={'הערה..'}
                    style={{width: '100%', direction: 'rtl'}}
                  />
                  <br />
                  <br />
                  <Button color={'primary'} variant={'contained'} onClick={(e) => this.editRecord()}>שמירה</Button>
                </div>
              </center>
            </Grid>
          )}

          <Grid item xs={12}>
            {/*{(!this.state.newRecordRender && this.state.permissions.in) && (<Link to='/small-cashregister-in'><Button color={'primary'} variant={'contained'}>העברת כסף לקופה</Button></Link>)} &nbsp;*/}

            {(!this.state.newRecordRender && this.state.permissions.out) && (<Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({newRecordRender: true})}>רישום תשלום חדש לספק</Button>)}
            &nbsp;
            {(this.state.user.isManager || this.state.user.isGizbar || this.state.user.type === 'clerk') && (<Button color={'primary'} variant={'contained'} onClick={(e) => window.location.href='/small-cashregister-pull'}>משיכת כספים לקופה</Button>)}
            &nbsp;
            {(this.state.user.isManager || this.state.user.isGizbar || this.state.user.type === 'clerk') && (<Button color={'primary'} variant={'contained'} onClick={(e) => window.location.href='/small-cashregister-push'}>משיכת כספים למשתמש</Button>)}
            &nbsp;

            <br />
            <br />
            {(!this.state.newRecordRender && this.state.permissions.print) && (
              <center><div style={{maxWidth: '30%', border: '1px solid black', direction: 'rtl', padding: '2%'}}>
                <p>מתאריך:</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  value={this.state.fromDateDoc}
                  selecte={this.state.fromDateDoc}
                  onChange={(value) => this.setState({fromDateDoc: value})}
                  open={this.state.fromDateDocDialog}
                  onClick={(e) => this.setState({fromDateDocDialog: true})}
                  onClose={(e) => this.setState({fromDateDocDialog: false})}
                  disableFuture={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    width: '100%'
                  }}
                  locale='he'
                />
                </MuiPickersUtilsProvider>
                <p>עד תאריך:</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  value={this.state.toDateDoc}
                  selecte={this.state.toDateDoc}
                  onChange={(value) => this.setState({toDateDoc: value})}
                  open={this.state.toDateDocDialog}
                  onClick={(e) => this.setState({toDateDocDialog: true})}
                  onClose={(e) => this.setState({toDateDocDialog: false})}
                  disableFuture={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    width: '100%'
                  }}
                  locale='he'
                />
                </MuiPickersUtilsProvider>
                <Button color={'default'} variant={'contained'} style={{backgroundColor: 'orange', color: 'white'}} onClick={(e) => this.generateDocument()}>הפקת מסמך הוצאות</Button>
              </div></center>
            )}
            <h1>קופה קטנה</h1>
            <h2>₪{this.state.cashregister.amount}</h2>
          </Grid>

          <Grid item xs={12}>
            <h1>רשומות הקופה</h1>
            {this.state.permissions.view ? (
              <>
              {this.state.records.length === 0 && (<h4>אין לקופה רשומות עדיין</h4>)}
              {this.state.records.length >= 1 && (
                <>
                  <Button color={'primary'} variant={this.state.daySelected ? 'contained' : 'outlined'} onClick={(e) => this.filterToday(new Date(Date.now()).getMonth() + 1)}>היום</Button>
                  <Button color={'primary'} variant={this.state.monthSelected ? 'contained' : 'outlined'} onClick={(e) => this.filterByMonth(new Date(Date.now()).getMonth() + 1)}>חודש</Button>
                  <Button color={'primary'} variant={this.state.elseSelected ? 'contained' : 'outlined'} onClick={(e) => this.setState({elseSelected: true, monthSelected: false, daySelected: false, records: this.state.recordsOriginal})}>אחר</Button>

                  <br />
                  {this.state.elseSelected && (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        value={this.state.elseDate}
                        selecte={this.state.elseDate}
                        onChange={(value) => this.setState({elseDate: value}, () => { this.filterByDate(value) })}
                        open={this.state.elseDateDialog}
                        onClick={(e) => this.setState({elseDateDialog: true})}
                        onClose={(e) => this.setState({elseDateDialog: false})}
                        disableFuture={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        style={{
                          width: '100%'
                        }}
                        locale='he'
                      />
                      </MuiPickersUtilsProvider>
                      <Button color={'primary'} variant={'contaiend'} onClick={(e) => this.filterByDate(this.state.elseDate)}></Button>
                    </>
                  )}
                  <br />
                </>
              )}
              <center>
                <div>
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {this.state.permissions.remove && (<TableCell align="right" style={{fontWeight: 'bold'}}>מחיקה</TableCell>)}
                        {/* {this.state.permissions.edit && (<TableCell align="right" style={{fontWeight: 'bold'}}>עריכה</TableCell>)} */}
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סכום</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>הערה</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>תשלום באמצעות</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סוג תשלום</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>קטגוריה</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>ספק</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סוג חשבונית</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>שעה</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>פקיד מזין</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.records.reverse().map((record, i) => {
                        return (
                          <TableRow key={i+'_record'}>
                            {this.state.permissions.remove && (
                              <>
                              {(new Date(record.date).getDate() + '-' + new Date(record.date).getMonth() + '-' + new Date(record.date).getFullYear() === new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getFullYear()) ? (
                                <TableCell align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.deleteRecord(record)}>מחיקה</Button></TableCell>
                              ) : (
                                <>
                                  {this.state.permissions.manager ? (
                                    <TableCell align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.deleteRecord(record)}>מחיקה</Button></TableCell>
                                  ) : (
                                    <TableCell align="right"><Button variant={'contained'} onClick={(e) => alert('אין אפשרות למחוק רשומה שלא רשומה על התאריך של היום')}>מחיקה</Button></TableCell>
                                  )}
                                </>
                              )}</>)}
                              {/* {this.state.permissions.edit && (
                                <>
                                {(new Date(record.date).getDate() + '-' + new Date(record.date).getMonth() + '-' + new Date(record.date).getFullYear() === new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getFullYear()) ? (
                                  <TableCell align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.igniteEdition(record)}>עריכה</Button></TableCell>
                                ) : (
                                  <>
                                    {this.state.permissions.manager ? (
                                      <TableCell align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.igniteEdition(record)}>עריכה</Button></TableCell>
                                    ) : (
                                      <TableCell align="right"><Button variant={'contained'} onClick={(e) => alert('אין אפשרות לערוך רשומה שלא רשומה על התאריך של היום')}>עריכה</Button></TableCell>
                                    )}
                                  </>
                                )}</>)} */}
                            <TableCell align="right">{record.amount}</TableCell>
                            <TableCell align="right">{record.note || "-"}</TableCell>
                            <TableCell align="right">{record.source === 'smallcashregister' ? 'קופה קטנה' : this.translateKeySource(record.source)}</TableCell>
                            <TableCell align="right">{this.modifyPayType(record.paymentType)}</TableCell>
                            <TableCell align="right">{record.category}</TableCell>
                            <TableCell align="right">{record.supplier === undefined ? 'הפקדה' : record.supplier.name}</TableCell>
                            <TableCell align="right">{record.invoiceType === 'receipt' ? 'קבלה' : (record.invoiceType === 'invoice' ? 'חשבונית' : 'חשבונית מס קבלה')}</TableCell>
                            <TableCell align="right">{this.isDate(record.time) ? this.pad(new Date(record.time).getHours()) + ':' + this.pad(new Date(record.time).getMinutes()) : record.time}</TableCell>
                            <TableCell align="right">{new Date(record.date).getDate() + '/' + parseInt(new Date(record.date).getMonth() + 1) + '/' + new Date(record.date).getFullYear()}</TableCell>
                            <TableCell align="right">{record.userId}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                </div>
              </center>
              </>
            ) : (
              <p style={{color: 'red'}}>אין לך הרשאות להצגת רשומות הקופה</p>
            )}


          </Grid>

          <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
        </Grid>
      </div>
    )
  }
}

export default SmallCashRegister;
