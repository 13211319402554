import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import TimelineIcon from '@material-ui/icons/Timeline';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class TeacherDashboard extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentsOriginal: [],
     studentChosen: {},
     didStudentChosen: false,
     lastSeen: '',
     search_query: ''
   }
  }

  getData = (id) => {
    axios.get(API + 'manager/students-invoices', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      // console.log(response);
      if (response.data.status === "ok") {
        this.setState({students: response.data.output, studentsOriginal: response.data.output});
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  searchStudentLive = async (value) => {
    const students = this.state.studentsOriginal;
    const query = value;
    this.setState({search_query: value});
    let output = [];
    students.forEach((item, i) => {
      if (
        item.firstName.includes(query) ||
        item.lastName.includes(query) ||
        item.idNumber.includes(query) ||
        item.phoneNumber.includes(query)
      ) output.push(item);
    });
    this.setState({students: output});
  }

  getInvoices = (student) => {
    this.setState({studentChosen: student, didStudentChosen: true});
  }

  sendWhatsapp = (phoneNumber, link) => {
    let msg = `
    תלמיד יקר, \n
    החשבונית שלך זמינה כאן: \n
    ` + link;
    window.open('https://wa.me/+972'+phoneNumber+'?text='+encodeURI(msg), '_blank');
  }

  paymentType = (type) => {
    let types = {
      'cash': 'מזומן',
      'bankTransfer': 'העברה בנקאית',
      'tranzila': 'סליקה אשראי',
      'cheque': 'שיק',
      'barter': 'ברטר',
      'application': 'אפליקציה'
    };
    return types[type] === undefined ? 'אחר' : types[type];
  }

  modifyDate = (date) => {
    date = new Date(date);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  paymentDescription = (description) => {
    let descriptions = {
      'signupFee': 'דמי תשלום',
      'insideTestFee': 'דמי מבחן פנימי'
    }
    return descriptions[description] === undefined ? description : descriptions[description];
  }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>חשבוניות התלמידים</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <React.Fragment>

            <center>
              <h4>{strings.searchstudentbynm}</h4>

              <Button variant={'contained'} onClick={(e) => this.setState({search_query: ''}, () => {this.getData()})}>איפוס</Button>
              &nbsp;&nbsp;&nbsp;
              <TextField
                value={this.state.search_query}
                onChange={(e) => this.searchStudentLive(e.target.value)}
                style={{
                  textAlign: 'right',
                  direction: 'rtl'
                }}
              />
              <br />
              <br />
            </center>
          {this.state.didStudentChosen && (
            <>
              <Button variant={'contained'} color={'secondary'} onClick={(e) => this.setState({studentChosen: {}, didStudentChosen: false})}>סגירה</Button>
              <h3>את\ה צופה בחשבוניות התלמיד: {this.state.studentChosen.firstName + ' ' + this.state.studentChosen.lastName + ' ('+this.state.studentChosen.idNumber+')'}</h3>
              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>לחץ לשליחה לתלמיד בווטצאפ</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>לינק</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>סכום כולל מע"מ</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תיאור</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך הפקה</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>אמצעי תשלום</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.studentChosen.payments.reverse().map((payment, i) => {
                      return (
                        <TableRow key={i+'_student'}>
                          <TableCell align="right">
                            <Button onClick={() => this.sendWhatsapp(this.state.studentChosen.phoneNumber, "https://sivan-school.com/pdfs/"+payment._id.toString()+".pdf")}>שליחה בווטצאפ</Button>
                          </TableCell>
                          <TableCell align="right"><a target="_blank" href={"https://sivan-school.com/pdfs/"+payment._id.toString()+".pdf"}>לינק לחשבונית</a></TableCell>
                          <TableCell align="right">{payment.amountWithVAT}</TableCell>
                          <TableCell align="right">{this.paymentDescription(payment.description)}</TableCell>
                          <TableCell align="right">{this.modifyDate(payment.createdAt)}</TableCell>
                          <TableCell align="right">{this.paymentType(payment.type)}</TableCell>
                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
              <br /><br />
            </>
          )}

          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'לצפייה בחשבוניות התלמיד'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.students.reverse().map((student, i) => {
                  if (student.payments && student.payments.length >= 1) {
                    return (
                      <TableRow key={i+'_student'}>
                        <TableCell align="right">
                          <Button onClick={(e) => this.getInvoices(student)}>צפייה</Button>
                        </TableCell>
                        <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                      </TableRow>
                    )
                  }
                })}

              </TableBody>
            </Table>
          </TableContainer>
          </React.Fragment>


        </center>
        </Grid>

        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default TeacherDashboard;
