import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class MessagesBoard extends Component {
  componentDidMount () {
    this.getStore();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     store: {},
     slogan: '',
     lastSeen: '',
     language: 'he',
     updated: false
   }
  }

  getStore = async () => {
    axios.get(API + 'store/me', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          store: response.data.data.store,
          slogan: response.data.data.store.slogan,
        });
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  updateSlogan = async () => {
    axios.put(API + 'store/slogan?slogan='+this.state.slogan, {
      slogan: this.state.slogan
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({updated: true})
        this.getStore();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{'החנות שלי'}</h2>
              </div>
            </center>
          </Grid>

          <Grid item xs={12}>
            <p style={{direction: 'rtl'}}>הסלוגן שלי: {this.state.store.slogan}</p>

            {this.state.updated && (<p style={{color: 'green'}}>הסלוגן עודכן בהצלחה</p>)}
            <p style={{direction: 'rtl'}}>ערוך סלוגן:</p>

            <Button variant={'contained'} color={'primary'} onClick={(e) => this.updateSlogan()}>שמור</Button>
            &nbsp;
            <TextField
              value={this.state.slogan}
              onChange={(e) => this.setState({slogan: e.target.value})}
              placeholder={'הסלוגן שלי'}
              style={{
                width: '30%'
              }}
            />

          </Grid>
        </Grid>
      </div>
    )
  }
}

export default MessagesBoard;
