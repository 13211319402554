import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';

import axios from 'axios';
const cookies = new Cookies();
const strings = new LocalizedStrings(i18n);

class KupotMorim extends Component {
  componentDidMount() {
    this.getData();
    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     data: [],
     teachers: [],
     teachersOriginal: [],
     addPayment: false,
     addPaymentTeacherId: '',
     amount: 0,
     teacherChosen: {},
     teacherChosenWatch: {},
     payments: [],
     watchPaymentsActive: false,
     watchList: [],

     orderName: 'up',
     showWithZero: false,
     depositAccounts: [],
     display: 'all',
     teachersCommissions: {}
   }
  }

  addPaymentActive = (teacher) => {
    let id = teacher._id;
    let amount = teacher.cashRegisterCommission;
    this.setState({
      addPayment: true,
      addPaymentTeacherId: id,
      amount: amount,
      teacherChosen: teacher
    }, () => {
      window.scroll(0,0)
    });
  }

  getData = async () => {
    axios.get('https://api.sivan-school.com/v1/kupot-morim', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({depositAccounts: response.data.deposit_accounts, data: response.data.data, teachers: response.data.teachers, teachersOriginal: response.data.teachers,  payments: response.data.payments}, () => {
          this.getCommission()
        });
      } else {
        alert('שגיאה בקבלת המידע');
      }
    })
    .catch(error => {
      console.log(error);
      alert('שגיאה בשרת');
    });
  }

  getDataDates = async () => {
    axios.get('https://api.sivan-school.com/v1/kupot-morim-dates?fromDate='+(this.state.fromDate).toString()+'&toDate='+(this.state.toDate).toString(), {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({depositAccounts: response.data.deposit_accounts, data: response.data.data, teachers: response.data.teachers, teachersOriginal: response.data.teachers,  payments: response.data.payments}, () => {
          this.getCommission()
        });
      } else {
        alert('שגיאה בקבלת המידע');
      }
    })
    .catch(error => {
      console.log(error);
      alert('שגיאה בשרת');
    });
  }

  add = async () => {
    axios.post('https://api.sivan-school.com/v1/kupot-morim/payment', {
      teacherId: this.state.addPaymentTeacherId,
      amount: this.state.amount,
      type: this.state.type,
      bankNumber: this.state.bankNumber,
      branchNumber: this.state.branchNumber,
      bankAccountNumber: this.state.bankAccountNumber,
      dateOfPayment: this.state.dateOfPayment,
      description: this.state.description,
      applicationType: this.state.applicationType,
      applicationNumber: this.state.applicationNumber,
      chequeNum: this.state.chequeNum,
      expdate: this.state.expdate,
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        alert('הרשומה נוספה בהצלחה');
        this.getData();
        this.setState({
          addPayment: false,
          addPaymentTeacherId: '',
          amount: 0,
          teacherChosen: {},
          watchPaymentsActive: false
        });
      } else {
        alert('שגיאה בקבלת המידע');
      }
    })
    .catch(error => {
      console.log(error);
      alert('שגיאה בשרת');
    });
  }

  watchPayments = (teacher) => {
    console.log('teacher:', teacher._id.toString());
    let t = [];
    this.state.payments.forEach((item, i) => {
      if (item.teacherId === teacher._id.toString()) {
        t.push(item);
      }
    });
    this.setState({
      watchList: t.reverse(),
      watchPaymentsActive: true,
      teacherChosenWatch: teacher
    }, () => {
        window.scroll(0,0)
    });
  }

  getCommission = () => {
    this.state.teachers.forEach((teacher) => {
      let t = {};
      this.state.payments.forEach((item, i) => {
        if (t[item.teacherId] === undefined) t[item.teacherId] = 0;
        t[item.teacherId] = t[item.teacherId] + parseFloat(item.amountWithVAT)
      });
      this.setState({
        teachersCommissions: t
      })
    });


  }

  modifyPaymentType = (payment) => {
    if (payment.type === 'application') {
      if (payment.applicationType === 'bit') return 'ביט';
      else if (payment.applicationType === 'paybox') return 'פייבוקס';
      else if (payment.applicationType === 'pepper') return 'פפר';
      else return 'אפליקציה';
    } else {
      if (payment.type === 'cash') return 'מזומן';
      else if (payment.type === 'cheque') return 'שיק';
      else if (payment.type === 'tranzila') return 'סליקה';
      else if (payment.type === 'bankTransfer') return 'העברה בנקאית';
      else if (payment.type === 'insidetestcommission') return 'עמלת מבחן פנימי';
      else {
        if (payment.isPtor) {
          return 'פטור'
        } else {
          return 'אחר';
        }
      }
    }
  }


  modifyDate = (date) => {
    date = new Date(date);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  search = (query) => {
    this.setState({query: query}, () => {
      const teachers = this.state.teachersOriginal;
      let t_ = [];
      teachers.forEach((item, i) => {
        let fullName = item.firstName + ' ' + item.lastName;
        let phoneNumber = item.phoneNumber;
        let idNumber = item.idNumber;
        if (
          idNumber.includes(query) ||
          phoneNumber.includes(query) ||
          fullName.includes(query)
        ) t_.push(item);
      });
      this.setState({teachers: t_});
    });
  }

  resetSearch = () => {
    this.setState({
      query: '',
      teachers: this.state.teachersOriginal
    })
  }

  orderByName = () => {
    if (this.state.orderName === "up") {
      // down
      const teachers = this.state.teachers;
      teachers.sort((a, b) => b.idNumber.localeCompare(a.idNumber))
      this.setState({teachers: teachers,orderName: 'down'})
    } else {
      // up
      const teachers = this.state.teachers;
      teachers.sort((a, b) => a.idNumber.localeCompare(b.idNumber))
      this.setState({teachers: teachers, orderName: 'up'})
    }
  }

  chooseDepositAccount = (account) => {
    account = account.split(';');
    let bank = account[0].toString().trim();
    let branch = account[1];
    let accountNumber = account[2];
    this.setState({deposit_account: account, bankNumber: bank, branchNumber: branch, bankAccountNumber: accountNumber});
  }

  showAll = () => {
    this.getData();
    this.setState({display: 'all'});
  }

  filterDates = () => {
    this.getDataDates();
    this.setState({display: 'between_dates'})
  }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>טבלת קופות מורים</h2>

              <div>
                <p>חיפוש על פי שם המורה / מספר ת.ז או מספר טלפון</p>
                <Button onClick={(e) => this.setState({query: ''})} color={'primary'} variant={'contained'}>איפוס</Button>
                &nbsp;
                &nbsp;
                <TextField
                  value={this.state.query}
                  onChange={(e) => this.search(e.target.value)}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right'
                  }}
                />
                <br />
                <br />
                <div style={{border: '1px solid black', width: '30%', paddingTop: '1%', paddingBottom: '1%'}}>
                  <Button onClick={(e) => this.setState({display: 'between_dates'})} color={this.state.display !== 'all' ? 'secondary' : 'primary'}  variant={'contained'}>חיפוש בין תאריכים</Button>
                  &nbsp;&nbsp;
                  <Button onClick={(e) => this.showAll()} color={this.state.display === 'all' ? 'secondary' : 'primary'} variant={'contained'}>הצג הכל</Button>

                  {this.state.display === 'between_dates' && (
                    <>
                      <br />
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <div>
                          <p>עד תאריך</p>
                          <TextField
                            value={this.state.fromDate}
                            onChange={(e) => this.setState({fromDate: e.target.value})}
                            type='date'
                            format='dd/mm/yyyy'
                          />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div>
                          <p>מתאריך</p>
                          <TextField
                            value={this.state.toDate}
                            onChange={(e) => this.setState({toDate: e.target.value})}
                            type='date'
                            format='dd/mm/yyyy'
                          />
                        </div>
                      </div>
                      <Button onClick={(e) => this.filterDates()} color={this.state.display !== 'all' ? 'secondary' : 'primary'}  variant={'contained'}>חיפוש</Button>
                    </>
                  )}
                </div>
                <br />
                <br />
                <Button onClick={(e) => this.orderByName()} color={'primary'} variant={'contained'}>{this.state.orderName === "up" ? "↑" : "↓"} מיון על פי מספר ת.ז</Button>
                &nbsp;&nbsp;&nbsp;
                {this.state.showWithZero ? (
                  <Button onClick={(e) => this.setState({showWithZero: false})} color={'primary'} variant={'contained'}>הצג ללא יתרות 0</Button>
                ) : (
                  <Button onClick={(e) => this.setState({showWithZero: true})} color={'primary'} variant={'contained'}>הצג עם יתרות 0</Button>
                )}

                <br />
                <br />
              </div>

              {this.state.watchPaymentsActive && (
                <center>
                  <Card style={{maxWidth: '70%'}}>
                    <div style={{maxWidth: '70%'}}>
                      <p style={{textDecoration: 'underline', fontSize: '1.2em'}}>התשלומים של {this.state.teacherChosenWatch.firstName + ' ' + this.state.teacherChosenWatch.lastName}</p>

                      <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                              <TableCell align="right" style={{fontWeight: 'bold'}}>סכום</TableCell>
                              <TableCell align="right" style={{fontWeight: 'bold'}}>צורת תשלום</TableCell>
                              <TableCell align="right" style={{fontWeight: 'bold'}}>תלמיד</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.watchList.map((payment, i) => {
                              if (!payment.refunded) {
                                return (
                                  <TableRow key={i.toString()+'_payment'}>
                                    <TableCell align="center">{this.modifyDate(payment.createdAt)}</TableCell>
                                    <TableCell align="center" style={{direction:'rtl'}}>{payment.amountWithVAT} ש״ח</TableCell>
                                    <TableCell align="center">{this.modifyPaymentType(payment)}</TableCell>
                                    <TableCell align="center">{payment.info === undefined ? '-' : payment.info}</TableCell>
                                  </TableRow>
                                )
                              }
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <br />
                      <br />
                      <br />
                    </div>
                  </Card>
                  <br />
                  <br />
                  <br />
                </center>
              )}

              {this.state.addPayment && (
                <center>
                <Card style={{maxWidth: '50%'}}>
                <div style={{maxWidth: '50%'}}>
                  <p style={{textDecoration: 'underline', fontSize: '1.2em'}}>הוספת תשלום ל{this.state.teacherChosen.firstName + ' ' + this.state.teacherChosen.lastName}</p>
                  <p style={{}}>סכום</p>
                  <Input
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  value={this.state.amount} onChange={(e) => this.setState({amount: e.target.value})}/>
                  <p style={{}}>דרך תשלום</p>
                  <Select
                    value={this.state.type}
                    onChange={(e) => this.setState({type: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cash'}>{strings.cash}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cheque'}>{strings.cheque}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bankTransfer'}>{strings.bankTransfer}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'application'}>{strings.application}</MenuItem>
                  </Select>
                  {this.state.type === "application" && (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseapplication}</p>
                      <Select
                        value={this.state.applicationType}
                        onChange={(e) => this.setState({applicationType: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bit'}>{strings.bit}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'paybox'}>{strings.paybox}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'pepper'}>{strings.pepper}</MenuItem>
                      </Select>
                    </React.Fragment>
                  )}

                  {(this.state.type === 'bankTransfer' || this.state.type === 'cheque') && (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}>בחר מחשבון הפקדה מוגדר (לא חובה)</p>
                      <Select
                        value={this.state.deposit_account}
                        onChange={(e) => this.chooseDepositAccount(e.target.value)}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        {this.state.depositAccounts.map((item) => {
                          if (item.bank !== '') {
                            return (
                              <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={item.bank+';'+item.branch+';'+item.account}>{item.bank} | {item.branch} | {item.account}</MenuItem>
                            );
                          }
                        })}
                      </Select>

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                      <Select
                        value={this.state.bankNumber}
                        onChange={(e) => this.setState({bankNumber: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                      </Select>

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                      <Input
                        value={this.state.branchNumber}
                        onChange={(e) => this.setState({branchNumber: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                      <Input
                        value={this.state.bankAccountNumber}
                        onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />

                      {this.state.type === 'cheque' && (
                        <>
                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> מספר שיק</p>
                        <Input
                          value={this.state.chequeNum}
                          onChange={(e) => this.setState({chequeNum: e.target.value})}
                          type='number'
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        />
                        </>
                      )}
                    </React.Fragment>
                  )}
                  <br />
                  <Button onClick={(e) => this.add()} color={'primary'} variant={'contained'} style={{marginTop: '2%', marginBottom: '2%'}}>אישור</Button>
                  <br />
                </div>
                </Card>
                <br /><br />
                </center>
              )}

              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>יתרת קופת עמלות</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>מספר טלפון</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>ת.ז</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>שם מלא</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.teachers.map((teacher, i) => {
                      console.log(teacher.firstName + ' ' + teacher.lastName);
                      if (this.state.showWithZero) {
                        return (
                          <TableRow key={i+'_payment'}>
                            <TableCell align="right"><Button onClick={(e) => this.watchPayments(teacher)} color={'primary'} variant={'contained'}>צפייה ברשומות תשלום</Button></TableCell>
                            <TableCell align="right"><Button onClick={(e) => this.addPaymentActive(teacher)} color={'primary'} variant={'contained'}>הוספת תשלום</Button></TableCell>
                            <TableCell align="right">ש״ח {teacher.cashRegisterCommission}</TableCell>
                            <TableCell align="right">{teacher.phoneNumber}</TableCell>
                            <TableCell align="right">{teacher.idNumber}</TableCell>
                            <TableCell align="right">{teacher.firstName + ' ' + teacher.lastName}</TableCell>
                          </TableRow>
                        );
                      } else {
                        // try {
                          // if (parseFloat(this.state.data[teacher._id.toString()]['cashRegisterCommission']) >= 1) {
                            return (
                              <TableRow key={i+'_payment'}>
                                <TableCell align="right"><Button onClick={(e) => this.watchPayments(teacher)} color={'primary'} variant={'contained'}>צפייה ברשומות תשלום</Button></TableCell>
                                <TableCell align="right"><Button onClick={(e) => this.addPaymentActive(teacher)} color={'primary'} variant={'contained'}>הוספת תשלום</Button></TableCell>
                                <TableCell align="right">ש״ח {teacher.cashRegisterCommission}</TableCell>
                                {/*<TableCell align="right">ש״ח {this.state.teachersCommissions[teacher._id.toString()] === undefined ? (this.state.data[teacher._id.toString()] === undefined || this.state.data[teacher._id.toString()]['cashRegisterCommission'] === undefined || this.state.data[teacher._id.toString()]['cashRegisterCommission'] === '' || this.state.data[teacher._id.toString()]['cashRegisterCommission'] === null) ? 0 : this.state.data[teacher._id.toString()]['cashRegisterCommission'] : this.state.teachersCommissions[teacher._id.toString()]}</TableCell>*/}
                                <TableCell align="right">{teacher.phoneNumber}</TableCell>
                                <TableCell align="right">{teacher.idNumber}</TableCell>
                                <TableCell align="right">{teacher.firstName + ' ' + teacher.lastName}</TableCell>
                              </TableRow>
                            );
                          // }
                      }
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default KupotMorim;
