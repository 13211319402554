import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import {
  isMobile
} from "react-device-detect";
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();
const SITE = 'https://sivan-school.com/';

class ClerkSendOutSidePaymentLink extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     studentId: '',
     teacherId: '',

     teachers: [],
     student: {},

     lastSeen: '',
     language: 'he',

     shareLinkView: 'signupFee'
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/all-my-teachers', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers: response.data.data.teachers,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.sendoutsidepaymentlinkTItle}</h2>

              {/*<p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseteacher}</p>
              <Select
                value={this.state.teacherId}
                onChange={(e) => this.setState({teacherId: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                {this.state.teachers.map((index, i) => {
                  return (
                    <MenuItem value={index._id} style={{direction: 'rtl', textAlign: 'right'}}>{index.firstName + ' ' + index.lastName}</MenuItem>
                  );
                })}
              </Select>*/}

              {/*<ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                <Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>
                <Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>
              </ButtonGroup>*/}

              {/*<IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacherId+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
              </IconButton

              --------

              <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacherId+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
              </IconButton>>*/}
              <React.Fragment>
                <Button variant={'contained'} color={'primary'}>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teachers[0]._id)}>
                    <LinkIcon style={{color: 'white', width: 20, height: 20}}/> &nbsp; <span style={{color: 'white', fontSize: '.65em'}}>{strings.getexternalstudentlink}</span>
                  </IconButton>
                </Button>
              </React.Fragment>

            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkSendOutSidePaymentLink;
