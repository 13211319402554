import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import SelectSearch from 'react-select-search';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class AddInsideTestStudent extends Component {
  componentDidMount () {
    this.getData();
    this.getProffesionals();
    this.getProfile();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',
     teacherId: '',
     teachers: [],

     testDate: new Date(Date.now()),
     testTime: new Date(Date.now()),

     withoutDates: false,
     displayPermissionsNumberTesting: false
   }
  }

  getData = async () => {
    axios.get(API + 'teacher/students-available-to-inside-test', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen,
          students: response.data.data.students,
        }, () => {
          let s_ = [];
          this.state.students.forEach((item, i) => {
            if (item.paidInsideTestFee === 'yes' || item.paidInsideTestFee === 'ptor') {
              s_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
            }
          });

          this.setState({s_: s_});
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getProffesionals = async () => {
    axios.get(API + 'teacher/get-professionals', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers: response.data.data.teachers,
        }, () => {
          let t_ = [];
          this.state.teachers.forEach((item, i) => {
            t_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
          });

          this.setState({t_: t_});
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  setTest = async () => {
    let payload = {
      'studentId': this.state.studentId,
      'teacherId': this.state.teacherId,
    }

    if (!this.state.withoutDates) {
      payload['time'] = this.state.testTime;
      payload['date'] = this.state.testDate;
    }

    axios.post(API + 'teacher/set-insidetest-student', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        alert('המבחן נקבע בהצלחה');
        window.location.href = '/';
      } else {
        alert('שגיאה בקביעת המבחן הפנימי');
      }
    })
    .catch(error => {
      console.log(error);
      alert('שגיאה בקביעת המבחן הפנימי');
    });
  }

  setWithoutDates = () => {
    if (this.state.withoutDates) {
      this.setState({
        withoutDates: false
      });
    } else {
      this.setState({
        withoutDates: true
      });
    }
  }

  setStudent = (studentId) => {
    this.setState({studentId: studentId}, () => {
      axios.get(API + 'teacher/student/payment-info?id='+this.state.studentId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        console.log({
          canSignup: response.data.data.can_signup,
          canInsideTest: response.data.data.can_inside_test,
        });
        this.setState({
          canSignup: response.data.data.can_signup,
          canInsideTest: response.data.data.can_inside_test,
        });
      }).catch(err => {
        alert(strings.errorPleaseTryAgain);
      });
    })
  }

  getProfile = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
        });
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
              <center>
              <div style={{maxWidth: '90%'}}>
              {this.state.displayPermissionsNumberTesting && (
                <p>הרשאה 10\9\7</p>
              )}
                <h2 style={{textAlign: 'right'}}>{strings.setInsideTest}</h2>



                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseteacher}</p>
                <SelectSearch
                  onChange={(value) => this.setState({teacherId: value}, () => {
                    console.log('teacherId:', this.state.teacherId)
                  })}
                  options={this.state.t_}
                  search={true}
                  value={this.state.teacherId}
                  name="teacherId"
                  placeholder="שם המורה.."
                />


                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosestudent}</p>
                <SelectSearch
                  onChange={(value) => this.setStudent(value)}
                  options={this.state.s_}
                  search={true}
                  value={this.state.studentId}
                  name="studentId"
                  placeholder="שם התלמיד.."
                />


                {!this.state.withoutDates && (
                  <React.Fragment>
                  <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateoftest}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={strings.opencalendarhere}
                        format="dd/MM/yyyy"
                        value={this.state.testDate}
                        onChange={(date) => this.setState({testDate: date})}
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          direction: 'rtl'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateoftesttime}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          ampm={false}
                          label={strings.opencalendarheretime}
                          value={this.state.testTime}
                          onChange={(date) => this.setState({testTime: date})}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          style={{
                            width: '100%',
                            textAlign: 'right',
                            direction: 'rtl'
                          }}
                        />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  </React.Fragment>
                )}

                <Checkbox
                  value={this.state.withoutDates}
                  onChange={(e) => this.setWithoutDates()}
                /> {strings.setWithoutdates}
                <br /><br />

                {(this.state.studentId !== '' && this.state.teacherId !== '') ? (
                  <React.Fragment>
                  {/*{!this.state.canInsideTest ? (
                    <p>{strings.cantsetinsideteststudent}</p>
                  ) : (
                    <Button onClick={(e) => this.setTest()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                      {strings.create}
                    </Button>
                  )}*/}
                  <Button onClick={(e) => this.setTest()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                    {strings.create}
                  </Button>
                  </React.Fragment>
                ) : (
                  <Button variant="contained" style={{marginTop: '2%', fontSize: '1.2em', width: '30%', backgroundColor: '#717171', color: 'white'}}>
                    {strings.create}
                  </Button>
                )}


              </div>
              </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddInsideTestStudent;
