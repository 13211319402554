import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ReportHoursClerk extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     table: {},
     employee: {
       name: '',
       reportHoursAllowMoveBetweenDates: false,
       reportHoursAllowMoveBetweenDatesMonths: []
     },
     lineToEdit: null,
     endInputDialog: false,
     startInputDialog: false,
     nextMonth: 1,
     previousMonth: 1,
     month: '0' + (new Date(Date.now()).getMonth() + 1).toString(),
     reportHoursAllowMoveBetweenDates: false,
     reportHoursAllowMoveBetweenDatesMonths: []
   }
  }



      modifyTime = (date) => {
        if (date === "Invalid Date" || date === "" || date === null) {
          return '-'
        } else {
          let d = new Date(date);
          return String(d.getHours()).padStart(2, "0") + ':' + String(d.getMinutes()).padStart(2, "0");
        }

      }




  getData = async () => {
    this.getNextMonth();
    this.getLastMonth();

    const url = window.location.href;
    const id = url.split('?id=')[1];
    axios.get(API + 'clerk/my-report?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          table: response.data.table,
          employee: response.data.employee,
          reportHoursAllowMoveBetweenDates: response.data.employee.reportHoursAllowMoveBetweenDates,
          reportHoursAllowMoveBetweenDatesMonths: response.data.employee.reportHoursAllowMoveBetweenDatesMonths
        })
        console.log(response.data.employee.reportHoursAllowMoveBetweenDatesMonths);
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getDataByMonth = async (month) => {
    this.setState({month: month}, () => {
      this.getNextMonth();
      this.getLastMonth();
    });
    axios.get(API + 'clerk/my-report-by-month?month='+month, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          table: response.data.table,
          employee: response.data.employee,
          reportHoursAllowMoveBetweenDates: response.data.employee.reportHoursAllowMoveBetweenDates,
          reportHoursAllowMoveBetweenDatesMonths: response.data.employee.reportHoursAllowMoveBetweenDatesMonths
        })

      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  updateReport = (key) => {
    console.log({
      date: key,
      fromHour: this.state.startInput,
      toHour: this.state.endInput,
      note: '',
      isWorkingDay: '',
      vacationCause: ''
    });
    axios.put(API + 'clerk/my-report', {
      date: key,
      fromHour: this.state.startInput,
      toHour: this.state.endInput,
      note: '',
      isWorkingDay: '',
      vacationCause: ''
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getDataByMonth(this.state.month);
        this.setState({lineToEdit: null})
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }
  removeRow = (key) => {
    const url = window.location.href;
    const id = url.split('?id=')[1];
    axios.delete(API + 'clerk/my-report?id='+id+'&date='+key, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
        this.setState({lineToEdit: null})
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }
  setVacationDay = (key) => {
    let a = window.confirm('האם אתם בטוחים שברצונכם להפוך יום זה ליום חופש בתאריך: ' + key + ' ?');
    if (a) {
      const url = window.location.href;
      const id = url.split('?id=')[1];
      axios.put(API + 'clerk/my-report/make-vacation-day?id='+id+'&date='+key, {}, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
          this.setState({lineToEdit: null})
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    }

  }
  cancelRow = (key) => {
    this.setState({lineToEdit: null});
  }

  getNextMonth = () => {
    const month_now = parseInt(this.state.month);
    let month = month_now + 1;
    if (month_now === 12) month = 1;
    this.setState({nextMonth: '0' + month.toString()});
  }

  getLastMonth = () => {
    const month_now = parseInt(this.state.month);
    let month = month_now - 1;
    if (month_now === 1) month = 12;
    this.setState({previousMonth: '0' + month.toString()});
  }

  monthToString = (month) => {
    const months = {
      1: 'ינואר',
      2: 'פברואר',
      3: 'מרץ',
      4: 'אפריל',
      5: 'מאי',
      6: 'יוני',
      7: 'יולי',
      8: 'אוגוסט',
      9: 'ספטמבר',
      10: 'אוקטובר',
      11: 'נובמבר',
      12: 'דצמבר'
    }
    return months[parseInt(month)];
  }

  fixMonth = (month) => {
    if (month === '010') return '10';
    if (month === '011') return '11';
    if (month === '012') return '12';
    return month;
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>הדו"ח החודשי שלי</h1>
              <br />
            </center>
          </Grid>

          <Grid item xs={12}>
            <center>
            <h3>בחרו חודש לעריכה</h3>

            <div style={{direction: 'rtl'}}>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('01') ? alert('חודש זה נעול') : this.setState({month: '01'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('01') ? '' : 'primary'} variant={'contained'}>ינואר</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('02') ? alert('חודש זה נעול') : this.setState({month: '02'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('02') ? '' : 'primary'} variant={'contained'}>פברואר</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('03') ? alert('חודש זה נעול') : this.setState({month: '03'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('03') ? '' : 'primary'} variant={'contained'}>מרץ</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('04') ? alert('חודש זה נעול') : this.setState({month: '04'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('04') ? '' : 'primary'} variant={'contained'}>אפריל</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('05') ? alert('חודש זה נעול') : this.setState({month: '05'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('05') ? '' : 'primary'} variant={'contained'}>מאי</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('06') ? alert('חודש זה נעול') : this.setState({month: '06'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('06') ? '' : 'primary'} variant={'contained'}>יוני</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('07') ? alert('חודש זה נעול') : this.setState({month: '07'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('07') ? '' : 'primary'} variant={'contained'}>יולי</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('08') ? alert('חודש זה נעול') : this.setState({month: '08'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('08') ? '' : 'primary'} variant={'contained'}>אוגוסט</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('09') ? alert('חודש זה נעול') : this.setState({month: '09'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('09') ? '' : 'primary'} variant={'contained'}>ספטמבר</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('10') ? alert('חודש זה נעול') : this.setState({month: '10'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('10') ? '' : 'primary'} variant={'contained'}>אוקטובר</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('11') ? alert('חודש זה נעול') : this.setState({month: '11'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('11') ? '' : 'primary'} variant={'contained'}>נובמבר</Button>
            <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.state.reportHoursAllowMoveBetweenDatesMonths.includes('12') ? alert('חודש זה נעול') : this.setState({month: '12'}, () => { this.getDataByMonth(this.state.month) })} color={this.state.reportHoursAllowMoveBetweenDatesMonths.includes('12') ? '' : 'primary'} variant={'contained'}>דצמבר</Button>
            </div>

            <br />

            <p>אתם צופים בחודש בחודש {this.monthToString(this.state.month)}</p>
            <br />
            <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>עדכן בשם העובד</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>הערה</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שעות עבודה</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>יציאה</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>כניסה</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>יום</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(this.state.table).map((key, i) => {
                  if (parseInt(key.split('/')[1]) === parseInt(this.state.month) && parseInt(key.split('/')[2]) === parseInt(new Date(Date.now()).getFullYear())) {
                    console.log(key);
                    let value = this.state.table[key];

                    console.log(value);
                    let reportExists = value.report !== null;
                    let isDayOff = (value.dayOff === undefined || value.dayOff === null || value.dayOff === 'undefined') ? true : value.dayOff;
                    let dayOff = isDayOff === false ? 'לא עובד/ת היום' : '';
                    let holiday = value.holiday === null ? '' : value.holiday;
                    const weekDays = {
                      '1': 'ראשון',
                      '2': 'שני',
                      '3': 'שלישי',
                      '4': 'רביעי',
                      '5': 'חמישי',
                      '6': 'שישי',
                      '7': 'שבת'
                    };
                    let hours = 0;
                    try {
                      const dateOneObj = new Date(value.report.start);
                      const dateTwoObj = new Date(value.report.end);
                      const milliseconds = Math.abs(dateTwoObj - dateOneObj);
                      hours = milliseconds / 36e5;
                    } catch (err) {
                      hours = 0;
                    }
                    return (
                      <TableRow style={{backgroundColor: isDayOff ? '' : '#ffcccb', color: isDayOff ? '' : 'white'}}>
                        {isDayOff ? (
                          <TableCell align="right">
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Button onClick={(e) => (this.state.lineToEdit !== i ? this.setState({
                              lineToEdit: i,
                              startInput: reportExists ? value.report.start : '',
                              endInput: reportExists ? value.report.end : ''
                            }) : this.updateReport(key))} variant={'contained'} color={this.state.lineToEdit !== i ? 'primary' : 'secondary'}>
                              {this.state.lineToEdit === i ? 'עדכן שורה' : 'ערוך'}</Button>
                              &nbsp;

                            {this.state.lineToEdit === i ? (
                              <> &nbsp;&nbsp;
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Button onClick={(e) => this.removeRow(key)} variant={'contained'} style={{backgroundColor: 'red', color: 'white'}}>מחק</Button>
                                &nbsp;&nbsp;
                                <Button onClick={(e) => this.cancelRow(key)} variant={'contained'} style={{backgroundColor: 'orange', color: 'white'}}>בטל</Button>
                              </div>
                              </>
                            ) : (
                              <Button onClick={(e) => this.setVacationDay(key)} variant={'contained'} color={this.state.lineToEdit !== i ? 'primary' : 'secondary'}>הגדר כיום חופש</Button>
                            )}
                            </div>
                          </TableCell>
                        ) : (
                          <>
                            <TableCell align="right"></TableCell>
                          </>
                        )}

                        <TableCell align="right">{dayOff} {(dayOff !== '' && holiday !== '') && ('|')} {holiday}</TableCell>
                        <TableCell align="right">{(hours === 0 || hours === null || hours === undefined || hours === '' || hours === 'NaN' || isNaN(hours)) ? '-' : parseFloat(hours).toFixed(1)}</TableCell>
                        {this.state.lineToEdit === i ? (
                          <>
                          <TableCell align="right">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                               <Grid container justify="space-around">
                                 <KeyboardTimePicker
                                   margin="normal"
                                   id="time-picker"
                                   value={this.state.endInput}
                                   onChange={(value) => this.setState({endInput: value})}
                                   KeyboardButtonProps={{
                                     'aria-label': 'change time',
                                   }}
                                   ampm={false}
                                   open={this.state.endInputDialog}
                                   onClick={(e) => this.setState({endInputDialog: true})}
                                   onClose={(e) => this.setState({endInputDialog: false})}

                                 />
                               </Grid>
                             </MuiPickersUtilsProvider>
                          </TableCell>
                          <TableCell align="right">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                               <Grid container justify="space-around">
                                 <KeyboardTimePicker
                                   margin="normal"
                                   id="time-picker"
                                   value={this.state.startInput}
                                   onChange={(value) => this.setState({startInput: value})}
                                   KeyboardButtonProps={{
                                     'aria-label': 'change time',
                                   }}
                                   ampm={false}
                                   open={this.state.startInputDialog}
                                   onClick={(e) => this.setState({startInputDialog: true})}
                                   onClose={(e) => this.setState({startInputDialog: false})}

                                 />
                               </Grid>
                             </MuiPickersUtilsProvider>
                          </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right">{reportExists ? this.modifyTime(value.report.end) : 'לא עודכן'}</TableCell>
                            <TableCell align="right">{reportExists ? this.modifyTime(value.report.start) : 'לא עודכן'}</TableCell>
                          </>
                        )}
                        <TableCell align="right">{key}</TableCell>
                        <TableCell align="right">{weekDays[(new Date(key.split('/')[1] + '-' + key.split('/')[0] + '-' + key.split('/')[2]).getDay() + 1).toString()]}</TableCell>
                      </TableRow>
                    );
                  }
                })}
                </TableBody>
              </Table>
              </TableContainer>
            </center>
          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ReportHoursClerk;
