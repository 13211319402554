import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class MessagesBoard extends Component {
  componentDidMount () {
    this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     messages: [],
     lastSeen: '',
     language: 'he',
   }
  }

  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messages: response.data.data.messages
        });
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  read = async (id) => {
    axios.put(API + 'message/read?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      this.getMessages();
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.mymessages}</h2>
              </div>
            </center>
          </Grid>

          {this.state.messages.reverse().map((message, i) => {
            return (
              <Grid item xs={12}>
                <Card style={{textAlign: 'right', direction: 'rtl'}}>
                  <div style={{marginRight: '5%', maxWidth: '50%'}}>
                  <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{message.message}</p>
                  <p style={{fontSize: '0.9em'}}>{strings.messagereceivedat + '' + new Date(message.createdAt).getDate() + '/' + parseInt(new Date(message.createdAt).getMonth() + 1) + '/' + new Date(message.createdAt).getFullYear()}</p>
                  <Button color={'secondary'} bariant={'contained'} onClick={(e) => this.read(message._id)}>סמן כקראתי</Button>
                  </div>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default MessagesBoard;
