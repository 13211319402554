import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PrintIcon from '@material-ui/icons/Print';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ClerkDiscoverPayments extends Component {
  componentDidMount() {
    this.getData();
    this.getPayments();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],

     lastSeen: '',
     language: 'he',

     payments: [],
     paymentsOriginal: [],
     view: '',
     dateToSearch: new Date(Date.now()),
     paymentsDay: [],
     paymentsMonth: [],
     type: '',
     total: 0,
     dateToSearchFrom: new Date(Date.now()),
     dateToSearchTo: new Date(Date.now()),
   }
  }



  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeView = (view) => {
    this.setState({view: view}, () => {
      if (view === '') {
        this.setState({payments: this.state.paymentsOriginal}, () => {
          this.calcTotal(this.state.payments);
        });
      } else if (view === 'other') {
        this.setState({payments: this.state.paymentsOriginal}, () => {
          this.calcTotal(this.state.payments);
        });
      } else {
        if (view === 'day') {
          this.setState({payments: this.state.paymentsDay}, () => {
            this.calcTotal(this.state.payments);
          });
        } else {
          this.setState({payments: this.state.paymentsMonth}, () => {
            this.calcTotal(this.state.payments);
          });
        }
      }
    })
  }

  getPayments = async () => {
    axios.get(API + 'clerk/payments/discover', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          payments: response.data.data.payments,
          paymentsOriginal: response.data.data.payments,
        }, () => {
          this.splitPayments();
          this.calcTotal(this.state.payments);
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }
  modifyDate = (date) => {
    let d = new Date(date);
    let month = d.getMonth() + 1;
    month = month.toString();
    if (month.length === 1) month = '0'+month;
    let day = d.getDay().toString();
    if (day.length === 1) day = '0'+day;

    return day + '/' + month + '/' + d.getFullYear();
  }

  changeType = (type) => {
    this.setState({type: type, searchByDate: null, view: ''}, () => {
      if (type === '') {
        this.getPayments();
      } else {
        axios.get(API + 'clerk/payments/discover?type='+type, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              payments: response.data.data.payments,
              paymentsOriginal: response.data.data.payments,
            }, () => {
              this.splitPayments();
              this.calcTotal(this.state.payments);
            })
          } else {
            alert(strings.errorPleaseTryAgain);
          }
        })
        .catch(error => {
          alert(strings.errorPleaseTryAgain);
        });
      }
    });
  }

  calcTotal = (payments, refunded = false) => {
    console.log(payments.length);
    console.log('---');
    let total = 0;
    let refund = 0;
    payments.forEach((item, i) => {
      if (item.refunded) refund = refund + parseFloat(item.amountWithVAT)
      else total = total + parseFloat(item.amountWithVAT)
    });
    this.setState({total: total});
  }

  splitPayments = () => {
    let payments = this.state.payments;

    let paymentsDay = [];
    let paymentsMonth = [];
    let today = new Date(Date.now());
    today = this.modifyDate(today);
    let month = new Date(Date.now()).getMonth();

    this.state.payments.map((payment) => {
      let payment_date_d = this.modifyDate(payment.createdAt);
      let payment_data_month = new Date(payment.createdAt).getMonth();

      if (payment_date_d === today) {
        paymentsDay.push(payment);
      }

      if (payment_data_month === month) {
        paymentsMonth.push(payment);
      }
    });

    this.setState({
      paymentsDay: paymentsDay,
      paymentsMonth: paymentsMonth,
    });
  }


  getDatesRange = (startDate, stopDate) => {
    const ONE_DAY = 24*3600*1000;
    var days= [];
    var currentDate = new Date(startDate);
    while (currentDate <= stopDate) {
        days.push(new Date (currentDate));
        currentDate = currentDate - 1 + 1 + ONE_DAY;
    }
    let days_ = [];
    days.forEach((item, i) => {
      days_.push(item.getDate() + '/' + item.getMonth() + '/' + item.getFullYear())
    });

    return days_;
  }

  searchByDate = () => {
    let from = this.state.dateToSearchFrom;
    from = new Date(from);
    let to = this.state.dateToSearchTo;
    to = new Date(to);

    let from_ = from.getDate() + '/' + from.getMonth() + '/' + from.getFullYear()
    let to_ = to.getDate() + '/' + to.getMonth() + '/' + to.getFullYear()

    let daysBetween = this.getDatesRange(from, to);

    let output = [];
    this.state.payments.map((payment) => {
      let payment_date_d = new Date(payment.createdAt);

      payment_date_d = payment_date_d.getDate() + '/' + payment_date_d.getMonth() + '/' + payment_date_d.getFullYear();

      if (payment_date_d === from_ || payment_date_d === to_ || daysBetween.includes(payment_date_d)) output.push(payment);
    });
    console.log(output);
    this.setState({payments: output}, () => {
      this.calcTotal(this.state.payments)
    });
  }

  modifyPaymentType = (payment) => {
    if (payment.type === 'application') {
      if (payment.applicationType === 'bit') return 'ביט';
      else if (payment.applicationType === 'paybox') return 'פייבוקס';
      else if (payment.applicationType === 'pepper') return 'פפר';
      else return 'אפליקציה';
    } else {
      if (payment.type === 'cash') return 'מזומן';
      else if (payment.type === 'cheque') return 'שיק';
      else if (payment.type === 'tranzila') return 'סליקה';
      else if (payment.type === 'bankTransfer') return 'העברה בנקאית';
      else {
        if (payment.isPtor) {
          return 'פטור'
        } else {
          return 'אחר';
        }
      }
    }
  }

  modifyDetails = (payment) => {
    if (payment.description === 'insideTestFee') {
      return 'תשלום מבחן פנימי';
    } else {
      return window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text');
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header type={'logged'} lastSeen={this.state.lastSeen}/>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.discoverPayments}</h2>
          </center>

          <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
            <Button onClick={(e) => this.changeType('cash')} style={{color: this.state.type === 'cash' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'cash' ? '#3f51b5' : 'transparent'}}>מזומן</Button>
            <Button onClick={(e) => this.changeType('bankTransfer')} style={{color: this.state.type === 'bankTransfer' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'bankTransfer' ? '#3f51b5' : 'transparent'}}>העברה בנקאית</Button>
            <Button onClick={(e) => this.changeType('cheque')} style={{color: this.state.type === 'cheque' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'cheque' ? '#3f51b5' : 'transparent'}}>שיק</Button>
            <Button onClick={(e) => this.changeType('tranzila')} style={{color: this.state.type === 'tranzila' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'tranzila' ? '#3f51b5' : 'transparent'}}>סליקה</Button>
            <Button onClick={(e) => this.changeType('bit')} style={{color: this.state.type === 'bit' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'bit' ? '#3f51b5' : 'transparent', direction: 'rtl'}}>ביט (אפליקציה)</Button>
            <Button onClick={(e) => this.changeType('pepper')} style={{color: this.state.type === 'pepper' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'pepper' ? '#3f51b5' : 'transparent', direction: 'rtl'}}>פפר (אפליקציה)</Button>
            <Button onClick={(e) => this.changeType('paybox')} style={{color: this.state.type === 'paybox' ? 'white' : '#3f51b5', backgroundColor: this.state.type === 'paybox' ? '#3f51b5' : 'transparent', direction: 'rtl'}}>פייבוקס (אפליקציה)</Button>
            <Button onClick={(e) => this.changeType('')} style={{color: this.state.type === '' ? 'white' : '#3f51b5', backgroundColor: this.state.type === '' ? '#3f51b5' : 'transparent', direction: 'rtl'}}>הכל</Button>
          </ButtonGroup>
          <br /><br />

          <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
            <Button onClick={(e) => this.changeView('other')} style={{color: this.state.view === 'other' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'other' ? '#3f51b5' : 'transparent'}}>אחר</Button>
            <Button onClick={(e) => this.changeView('month')} style={{color: this.state.view === 'month' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'month' ? '#3f51b5' : 'transparent'}}>החודש</Button>
            <Button onClick={(e) => this.changeView('day')} style={{color: this.state.view === 'day' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'day' ? '#3f51b5' : 'transparent'}}>היום</Button>
            <Button onClick={(e) => this.changeView('')} style={{color: this.state.view === '' ? 'white' : '#3f51b5', backgroundColor: this.state.view === '' ? '#3f51b5' : 'transparent'}}>הכל</Button>
          </ButtonGroup>

          {this.state.view === 'other' && (
            <React.Fragment>
            <p style={{textAlign: 'center'}}><span style={{color: 'red'}}>*</span> תאריך לחיפוש</p>
            <p>מתאריך</p>
            <TextField
              value={this.state.dateToSearchFrom}
              onChange={(e) => this.setState({dateToSearchFrom: e.target.value})}
              type='date'
              format='dd/mm/yyyy'
              style={{
                direction: 'rtl',
                width: '20%'
              }}
            />
            <p>עד תאריך</p>
            <TextField
              value={this.state.dateToSearchTo}
              onChange={(e) => this.setState({dateToSearchTo: e.target.value})}
              type='date'
              format='dd/mm/yyyy'
              style={{
                direction: 'rtl',
                width: '20%'
              }}
            />
            <br />
            <br />

            <Button variant={'contained'} color={'secondary'} onClick={(e) => this.searchByDate()}>חפש</Button>

            <br /><br />
            </React.Fragment>
          )}

          <h3>סה"כ: ₪{this.state.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
        </Grid>
        <Grid item xs={12}>
        <center>
        <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'הערה'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'פרטים'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teacherName}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'מספר חשבונית'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'מפיק התשלום'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'תלמיד/ה'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.state.payments.reverse().map((payment, i) => {
              if (payment.refunded) {
                return (
                  <TableRow key={i} style={{color: 'red', fontWeight: 'bold'}}>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right"><Button style={{marginRight: 0, marginLeft: 0}} onClick={(e) => window.open('https://sivan-school.com/pdfs/'+payment._id.toString()+'_refund.pdf', '_blank')}><PrintIcon /></Button></TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right"><Button style={{marginRight: 0, marginLeft: 0}}><WhatsAppIcon onClick={(e) => window.open('https://wa.me/+972'+((payment.studentId === '' || payment.studentId === undefined || payment.studentId === null) ? payment.casualClientPhoneNumber : payment.student.phoneNumber)+'?text='+encodeURI('https://sivan-school.com/pdfs/'+payment._id.toString()+'_refund.pdf'), '_blank')}/></Button></TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{new Date(payment.createdAt).getDate() + '/' + parseInt(new Date(payment.createdAt).getMonth() + 1) + '/' + new Date(payment.createdAt).getFullYear()}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{payment.note}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{this.modifyDetails(payment)}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">₪{(payment.amountWithVAT === null ? '0' : payment.amountWithVAT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">זוכה על ידי {this.modifyPaymentType(payment)}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{(payment.teacher === undefined) ? 'לקוח מזדמן' : payment.teacher.firstName + ' ' + payment.teacher.lastName + ' '+payment.teacher.idNumber+''}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{payment.invoiceNumber}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{(payment.issuer === undefined) ? payment.student.firstName + ' ' + payment.student.lastName : payment.issuer.firstName + ' ' + payment.issuer.lastName + '('+this.modifyType(payment.issuer.type)+')'}</TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right">{(payment.studentId === '' || payment.studentId === undefined || payment.studentId === null) ? (payment.casualClientName) :  (payment.student.firstName + ' ' + payment.student.lastName)}</TableCell>
                  </TableRow>
                )
              } else {
                return (
                  <TableRow key={i}>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right"><Button style={{marginRight: 0, marginLeft: 0}}><PrintIcon onClick={(e) => window.open('https://sivan-school.com/pdfs/'+payment._id.toString()+'.pdf', '_blank')}/></Button></TableCell>
                    <TableCell style={{color: 'red', fontWeight: 'bold'}} align="right"><Button style={{marginRight: 0, marginLeft: 0}}><WhatsAppIcon onClick={(e) => window.open('https://wa.me/+972'+((payment.studentId === '' || payment.studentId === undefined || payment.studentId === null) ? payment.casualClientPhoneNumber : payment.student.phoneNumber)+'?text='+encodeURI('https://sivan-school.com/pdfs/'+payment._id.toString()+'.pdf'), '_blank')}/></Button></TableCell>
                    <TableCell align="right">{new Date(payment.createdAt).getDate() + '/' + parseInt(new Date(payment.createdAt).getMonth() + 1) + '/' + new Date(payment.createdAt).getFullYear()}</TableCell>
                    <TableCell align="right">{payment.note}</TableCell>
                    <TableCell align="right">{this.modifyDetails(payment)}</TableCell>
                    <TableCell align="right">₪{(payment.amountWithVAT === null ? '0' : payment.amountWithVAT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</TableCell>
                    <TableCell align="right">{this.modifyPaymentType(payment)}</TableCell>
                    <TableCell align="right">{(payment.teacher === undefined) ? 'לקוח מזדמן' : payment.teacher.firstName + ' ' + payment.teacher.lastName + '('+payment.teacher.idNumber+')'}</TableCell>
                    <TableCell align="right">{payment.invoiceNumber}</TableCell>
                    <TableCell align="right">{(payment.issuer === undefined) ? payment.student.firstName + ' ' + payment.student.lastName : payment.issuer.firstName + ' ' + payment.issuer.lastName + '('+this.modifyType(payment.issuer.type)+')'}</TableCell>
                    <TableCell align="right">{(payment.studentId === '' || payment.studentId === undefined || payment.studentId === null) ? (payment.casualClientName) :  (payment.student.firstName + ' ' + payment.student.lastName)}</TableCell>
                  </TableRow>
                )
              }
            })}
            </TableBody>
          </Table>
        </TableContainer>

        </center>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkDiscoverPayments;
