import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ClerkCashRegisters extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {

     lastSeen: '',
     language: 'he',
     cashregisters: [],
     all_cash: 0,
     all_cheque: 0,
     all_all: 0,
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/cashregisters', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          cashregisters: response.data.data.cashregisters,
        }, () => {
          let all_cash = 0;
          let all_cheque = 0;
          let all_all = 0;

          this.state.cashregisters.forEach((item, i) => {
            all_cash = all_cash + item.cash;
            all_cheque = all_cheque + item.cheque;
            all_all = all_all + item.all;
          });

          this.setState({
            all_cash: all_cash,
            all_cheque: all_cheque,
            all_all: all_all,
          })
        });

      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת רשימת תלמידים')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <p style={{direction: 'rtl', fontSize: '1.2em', marginRight: 5}}>סה״כ ₪{this.state.all_all.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                  <h2 style={{textAlign: 'right'}}>{strings.canSeeTeachersCashRegister}</h2>
                </div>
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {/*<TableCell align="right" style={{fontWeight: 'bold'}}>{'נותר בקופה'}</TableCell>*/}
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{'סה"כ בקופה'}</TableCell>
                        {/*<TableCell align="right" style={{fontWeight: 'bold'}}>{'שיק'}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{'מזומן'}</TableCell>*/}
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{'שם מלא'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cashregisters.map((employee, i) => {
                        return (
                          <TableRow key={i+'_employee'}>
                            {/*<TableCell align="right">₪{employee.cashRegister.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>*/}
                            <TableCell align="right">₪{employee.cashRegister.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            {/*
                              <TableCell align="right">₪{employee.cheque.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                              <TableCell align="right">₪{(parseInt(employee.cashRegister) < parseInt(employee.cash)) ? employee.cashRegister.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : employee.cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>

                              */}
                            <TableCell align="right">{employee.firstName + ' ' + employee.lastName}</TableCell>
                          </TableRow>
                        )
                      })}

                      <TableRow key={'final'}>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>₪{this.state.all_all.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סה"כ</TableCell>
                      </TableRow>


                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkCashRegisters;
