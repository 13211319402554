import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DateFnsUtils from '@date-io/date-fns';
import { CSVLink, CSVDownload } from "react-csv";


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import imageCompression from 'browser-image-compression';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ClerkDepositAccounts extends Component {
    componentDidMount() {
        this.getData();

        if (cookies.get('language') === "hebrew") {
            this.setState({ language: 'he' });
            strings.setLanguage('he');
        } else if (cookies.get('language') === "arabic") {
            this.setState({ language: 'ar' });
            strings.setLanguage('ar');
        } else if (cookies.get('language') === "english") {
            this.setState({ language: 'en' });
            strings.setLanguage('en');
        } else if (cookies.get('language') === "russian") {
            this.setState({ language: 'ru' });
            strings.setLanguage('ru');
        } else {
            this.setState({ language: 'he' });
            strings.setLanguage('he');
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPassed: 0,
            totalFailed: 0,
            totalUnkown: 0,
            totalPassedPrecentage: 0,
            filter: '',
            isFilterOn: false,
            testsShow: { tests: [] },
            filter: 'all',
            dataOriginal: [],
            toDate: '',
            fromDate: '',
            exportedData: [],
            exportedHeaders: [
                { label: "מורה", key: "teacher" },
                { label: "עברו", key: "passed" },
                { label: "נכשלו", key: "failed" },
                { label: "לא ידוע", key: "unknown" },
                { label: "אחוז עוברים", key: "passed_precent" }],
            prettyLink : {
                color: '#fff'
                }                  
        }
    }

    onChange = (e) => {
        this.setState({ file: e.target.files[0] }, () => {
            // console.log(this.fileSizeLimit());
            // if (!this.fileSizeLimit()) {
            //   alert('אנא בחרו תמונה מתחת ל2 מגה בייט');
            // }
        });
    }

    getData = async () => {
        axios.get(API + 'manager/inside-tests-statistics', {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
            .then(response => {
                console.log(response);
                if (response.data.status === "ok") {
                    this.setState({ data: response.data.statistics, dataOriginal: response.data.statistics }, () => {
                        const data = this.state.data;
                        let dataToExport = [];
                        let totalPassed = 0;
                        let totalFailed = 0;
                        let totalUnkown = 0;
                        let totalPassedPrecentage = 0;
                        let totalTotal = 0;
                        data.forEach((item, i) => {

                            totalTotal += 1;
                            if (item.passed !== undefined && item.passed !== null) totalPassed += item.passed;
                            if (item.failed !== undefined && item.failed !== null) totalFailed += item.failed;
                            if (item.unkown !== undefined && item.unkown !== null) totalUnkown += item.unkown;

                            dataToExport.push({
                                'teacher': item.firstName + ' ' + item.lastName + ' (' + item.idNumber + ')',
                                'passed': item.passed,
                                'failed': item.failed,
                                'unknown': item.unkown,
                                'passed_precent': item.passed_precentage.toFixed(2)
                            });
                        });

                        totalPassedPrecentage = ((totalPassed / (totalPassed + totalFailed + totalUnkown)) * 100).toFixed(2)

                        dataToExport.push({
                            'teacher': 'סך הכל',
                            'passed': totalPassed,
                            'failed': totalFailed,
                            'unknown': totalUnkown,
                            'passed_precent': totalPassedPrecentage
                        });

                        this.setState({ totalPassed: totalPassed, totalFailed: totalFailed, totalUnkown: totalUnkown, totalPassedPrecentage: totalPassedPrecentage, exportedData: dataToExport })
                    });
                }

            })
            .catch(error => {
                console.log(error);
                alert(strings.errorPleaseTryAgain);
            });
    }

    exportTestsexportInternalTests = () => {
        console.log("Starting export...");
        return this.state.data;
    }

    exportInternalTestsFileName = () => {
        return "מבחנים_פנימים.csv"
    }

    showTests = (item) => {
        this.setState({ testsShow: item });
    }

    modifyDate = (date) => {
        let d = new Date(date);
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    }

    modifyStatus = (status) => {
        if (status === 'success') return 'עבר';
        else if (status === 'fail' || status === 'failed') return 'נכשל';
        else return 'לא ידוע';
    }

    filter = (changeTo) => {
        let dataToExport = [];

        if (changeTo === 'all') {
            this.setState({ filter: 'all', data: this.state.dataOriginal }, () => {
                const data = this.state.data;
                let totalPassed = 0;
                let totalFailed = 0;
                let totalUnkown = 0;
                let totalPassedPrecentage = 0;
                let totalTotal = 0;
                data.forEach((item, i) => {

                    totalTotal += 1;
                    if (item.passed !== undefined && item.passed !== null) totalPassed += item.passed;
                    if (item.failed !== undefined && item.failed !== null) totalFailed += item.failed;
                    if (item.unkown !== undefined && item.unkown !== null) totalUnkown += item.unkown;
                });

                totalPassedPrecentage = ((totalPassed / (totalPassed + totalFailed + totalUnkown)) * 100).toFixed(2)

                data.forEach((record, i) => {
                    dataToExport.push({
                        'teacher': record.firstName + ' ' + record.lastName + ' (' + record.idNumber + ')',
                        'passed': record.passed,
                        'failed': record.failed,
                        'unknown': record.unkown,
                        'passed_precent': record.passed_precentage.toFixed(2)
                    });
                });

                dataToExport.push({
                    'teacher': 'סך הכל',
                    'passed': totalPassed,
                    'failed': totalFailed,
                    'unknown': totalUnkown,
                    'passed_precent': totalPassedPrecentage
                });

                this.setState({ totalPassed: totalPassed, totalFailed: totalFailed, totalUnkown: totalUnkown, totalPassedPrecentage: totalPassedPrecentage, exportedData: dataToExport })
            });
        } else {
            const data = this.state.dataOriginal;
            if (changeTo === 'today') {
                let output = [];
                let today = this.modifyDate(new Date(Date.now()));
                data.forEach((record, i) => {
                    let tests = [];
                    let this_passed = 0;
                    let this_failed = 0;
                    let this_unkown = 0;
                    record.tests.forEach((item, i) => {
                        let itemDate = this.modifyDate(item.createdAt);
                        if (itemDate === today) {
                            tests.push(item);
                            if (item.status === "success") this_passed += 1;
                            if (item.status === "fail" || item.status === "failed") this_failed += 1;
                            if (item.status === "unkown") this_unkown += 1;
                        }
                    });
                    let this_passed_precentage = this_passed === 0 ? 0 : parseFloat((this_passed / (this_passed + this_failed + this_unkown)) * 100).toFixed(2);
                    dataToExport.push({
                        'teacher': record.firstName + ' ' + record.lastName + ' (' + record.idNumber + ')',
                        'passed': this_passed,
                        'failed': this_failed,
                        'unknown': this_unkown,
                        'passed_precent': this_passed_precentage
                    });
                    output.push({
                        '_id': record._id,
                        'firstName': record.firstName,
                        'lastName': record.lastName,
                        'idNumber': record.idNumber,
                        'passed': this_passed,
                        'failed': this_failed,
                        'unkown': this_unkown,
                        'tests': tests,
                        'type': record.type,
                        'passed_precentage': this_passed_precentage
                    });
                });
                let totalPassed = 0;
                let totalFailed = 0;
                let totalUnkown = 0;
                let totalPassedPrecentage = 0;
                let totalTotal = 0;
                //console.log(output);
                output.forEach((item, i) => {

                    totalTotal += 1;
                    if (item.passed !== undefined && item.passed !== null) totalPassed += item.passed;
                    if (item.failed !== undefined && item.failed !== null) totalFailed += item.failed;
                    if (item.unkown !== undefined && item.unkown !== null) totalUnkown += item.unkown;
                });


                totalPassedPrecentage = totalPassed === 0 ? 0 : ((totalPassed / (totalPassed + totalFailed + totalUnkown)) * 100).toFixed(2)

                dataToExport.push({
                    'teacher': 'סך הכל',
                    'passed': totalPassed,
                    'failed': totalFailed,
                    'unknown': totalUnkown,
                    'passed_precent': totalPassedPrecentage
                });

                this.setState({ filter: 'today', data: output, totalPassed: totalPassed, totalFailed: totalFailed, totalUnkown: totalUnkown, totalPassedPrecentage: totalPassedPrecentage, exportedData: dataToExport })

            } else if (changeTo === 'month') {
                let output = [];
                let today = this.modifyDate(new Date(Date.now()));
                data.forEach((record, i) => {
                    let tests = [];
                    let this_passed = 0;
                    let this_failed = 0;
                    let this_unkown = 0;
                    record.tests.forEach((item, i) => {
                        let itemDate = this.modifyDate(item.createdAt);
                        if ((new Date(item.createdAt).getMonth() + 1) + '/' + new Date(item.createdAt).getFullYear() === (new Date(Date.now()).getMonth() + 1) + '/' + new Date(Date.now()).getFullYear()) {
                            tests.push(item);
                            if (item.status === "success") this_passed += 1;
                            if (item.status === "fail" || item.status === "failed") this_failed += 1;
                            if (item.status === "unkown") this_unkown += 1;
                        }
                    });
                    let this_passed_precentage = this_passed === 0 ? 0 : parseFloat((this_passed / (this_passed + this_failed + this_unkown)) * 100).toFixed(2);
                    dataToExport.push({
                        'teacher': record.firstName + ' ' + record.lastName + ' (' + record.idNumber + ')',
                        'passed': this_passed,
                        'failed': this_failed,
                        'unknown': this_unkown,
                        'passed_precent': this_passed_precentage
                    });
                    output.push({
                        '_id': record._id,
                        'firstName': record.firstName,
                        'lastName': record.lastName,
                        'idNumber': record.idNumber,
                        'passed': this_passed,
                        'failed': this_failed,
                        'unkown': this_unkown,
                        'tests': tests,
                        'type': record.type,
                        'passed_precentage': this_passed_precentage
                    });
                });
                let totalPassed = 0;
                let totalFailed = 0;
                let totalUnkown = 0;
                let totalPassedPrecentage = 0;
                let totalTotal = 0;
                //console.log(output);
                output.forEach((item, i) => {

                    totalTotal += 1;
                    if (item.passed !== undefined && item.passed !== null) totalPassed += item.passed;
                    if (item.failed !== undefined && item.failed !== null) totalFailed += item.failed;
                    if (item.unkown !== undefined && item.unkown !== null) totalUnkown += item.unkown;
                });


                totalPassedPrecentage = totalPassed === 0 ? 0 : ((totalPassed / (totalPassed + totalFailed + totalUnkown)) * 100).toFixed(2)
                
                dataToExport.push({
                    'teacher': 'סך הכל',
                    'passed': totalPassed,
                    'failed': totalFailed,
                    'unknown': totalUnkown,
                    'passed_precent': totalPassedPrecentage
                });

this.setState({ filter: 'month', data: output, totalPassed: totalPassed, totalFailed: totalFailed, totalUnkown: totalUnkown, totalPassedPrecentage: totalPassedPrecentage, exportedData: dataToExport })
            } else {
                this.setState({ filter: 'other' })
            }
        }
    }

otherSearch = () => {
    let dataToExport = [];

        const fromDate = new Date(this.state.fromDate).setHours(0, 0, 0);
        const toDate = new Date(this.state.toDate).setHours(23, 59, 59);
        const data = this.state.dataOriginal;
        let output = [];
        let today = this.modifyDate(new Date(Date.now()));
        data.forEach((record, i) => {
            let tests = [];
            let this_passed = 0;
            let this_failed = 0;
            let this_unkown = 0;
            record.tests.forEach((item, i) => {
                let itemDate = new Date(item.createdAt);
                if (fromDate <= itemDate && itemDate <= toDate) {
                    tests.push(item);
                    if (item.status === "success") this_passed += 1;
                    if (item.status === "fail" || item.status === "failed") this_failed += 1;
                    if (item.status === "unkown") this_unkown += 1;
                }
            });
            let this_passed_precentage = this_passed === 0 ? 0 : parseFloat((this_passed / (this_passed + this_failed + this_unkown)) * 100).toFixed(2);
            dataToExport.push({
                'teacher': record.firstName + ' ' + record.lastName + ' (' + record.idNumber + ')',
                'passed': this_passed,
                'failed': this_failed,
                'unknown': this_unkown,
                'passed_precent': this_passed_precentage
            });
            output.push({
                '_id': record._id,
                'firstName': record.firstName,
                'lastName': record.lastName,
                'idNumber': record.idNumber,
                'passed': this_passed,
                'failed': this_failed,
                'unkown': this_unkown,
                'tests': tests,
                'type': record.type,
                'passed_precentage': this_passed_precentage
            });
        });
        let totalPassed = 0;
        let totalFailed = 0;
        let totalUnkown = 0;
        let totalPassedPrecentage = 0;
        let totalTotal = 0;
        //console.log(output);
        output.forEach((item, i) => {

            totalTotal += 1;
            if (item.passed !== undefined && item.passed !== null) totalPassed += item.passed;
            if (item.failed !== undefined && item.failed !== null) totalFailed += item.failed;
            if (item.unkown !== undefined && item.unkown !== null) totalUnkown += item.unkown;
        });


        totalPassedPrecentage = totalPassed === 0 ? 0 : ((totalPassed / (totalPassed + totalFailed + totalUnkown)) * 100).toFixed(2);

    dataToExport.push({
        'teacher': 'סך הכל',
        'passed': totalPassed,
        'failed': totalFailed,
        'unknown': totalUnkown,
        'passed_precent': totalPassedPrecentage
    });
    this.setState({ filter: 'other', data: output, totalPassed: totalPassed, totalFailed: totalFailed, totalUnkown: totalUnkown, totalPassedPrecentage: totalPassedPrecentage, exportedData: dataToExport })
    }

    render() {
        return (
            <div className="App">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Header type={'logged'} lastSeen={this.state.lastSeen} />
                    </Grid>

                    <Grid item xs={12}>
                        <h1>סטטיסטיקה למבחנים פנימיים</h1>
                        <br />
                        <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                            <Button onClick={(e) => this.filter('other')} style={{ color: this.state.filter === 'other' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'other' ? '#3f51b5' : 'transparent' }}>אחר</Button>
                            <Button onClick={(e) => this.filter('month')} style={{ color: this.state.filter === 'month' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'month' ? '#3f51b5' : 'transparent' }}>החודש</Button>
                            <Button onClick={(e) => this.filter('today')} style={{ color: this.state.filter === 'today' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'today' ? '#3f51b5' : 'transparent' }}>היום</Button>
                            <Button onClick={(e) => this.filter('all')} style={{ color: this.state.filter === 'all' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'all' ? '#3f51b5' : 'transparent' }}>הכל</Button>
                        </ButtonGroup>
                        <br />
                        {this.state.filter === 'other' && (
                            <>
                                <center>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '7%' }}>
                                        <div style={{ marginRight: '1%', marginLeft: '1%', marginTop: '2%' }}>
                                            <Button variant={'contained'} color={'primary'} onClick={(e) => this.otherSearch()}>חפש</Button>
                                        </div>

                                        <div style={{ marginRight: '1%', marginLeft: '1%' }}>
                                            <p>עד תאריך</p>
                                            <TextField
                                                value={this.state.toDate}
                                                onChange={(e) => this.setState({ toDate: e.target.value })}
                                                type='date'
                                                format='dd/mm/yyyy'
                                            />
                                        </div>

                                        <div style={{ marginRight: '1%', marginLeft: '1%' }}>
                                            <p>מתאריך</p>
                                            <TextField
                                                value={this.state.fromDate}
                                                onChange={(e) => this.setState({ fromDate: e.target.value })}
                                                type='date'
                                                format='dd/mm/yyyy'
                                            />
                                        </div>
                                    </div>
                                    <br />
                                </center>
                            </>
                        )}
                    </Grid>

                    {this.state.testsShow.tests.length >= 1 && (
                        <Grid item xs={12}>
                            <h3>מציג את המבחנים הפנימיים של: {this.state.testsShow.firstName + ' ' + this.state.testsShow.lastName}</h3>
                            <Button onClick={(e) => this.setState({ testsShow: { tests: [] } })} variant={'contained'} color={'secondary'}>סגירה</Button>
                            <TableContainer component={Paper} style={{ maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)' }} className="tableFixHeader">
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>תאריך</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>סטטוס</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>תלמיד</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.testsShow.tests.map((item, i) => {
                                            console.log(item);
                                            return (
                                                <TableRow key={i + '_student'}>
                                                    <TableCell align="right">{this.modifyDate(item.createdAt)}</TableCell>
                                                    <TableCell align="right">{this.modifyStatus(item.status) + ' ' + ((item.status === "fail" || item.status === "failed") ? ' - ' + item.notes : '')}</TableCell>
                                                    <TableCell align="right">{item.student.firstName + ' ' + item.student.lastName + ' (' + item.student.idNumber + ')'}</TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <center>
                            <TableContainer component={Paper} style={{ maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)' }} className="tableFixHeader">
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>צפייה בכל המבחנים הפנימיים</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>אחוז עוברים</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>לא ידוע</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>נכשלו</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>עברו</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>מורה</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.data.map((item, i) => {
                                            console.log(item);
                                            return (
                                                <TableRow key={i + '_student'}>
                                                    <TableCell align="right"><Button onClick={() => this.showTests(item)} variant={'contained'} color={'primary'}>לצפייה</Button></TableCell>
                                                    <TableCell align="right">{item.passed_precentage === null ? '0%' : parseFloat(item.passed_precentage).toFixed(2) + '%'}</TableCell>
                                                    <TableCell align="right">{item.unkown === undefined ? 0 : item.unkown}</TableCell>
                                                    <TableCell align="right">{item.failed === undefined ? 0 : item.failed}</TableCell>
                                                    <TableCell align="right">{item.passed === undefined ? 0 : item.passed}</TableCell>
                                                    <TableCell align="right">{item.firstName + ' ' + item.lastName + ' (' + item.idNumber + ')'}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableRow key={'_student'}>
                                        <TableCell align="right">
                                            <Button variant={'contained'} color={'primary'}>
                                            <CSVLink
                                                style={this.state.prettyLink}
                                                data={this.state.exportedData}
                                                filename={"סטטיסטיקת מבחנים פנימיים.csv"}
                                                headers={this.state.exportedHeaders}
                                            target="_blank">יצא לאקסל</CSVLink></Button></TableCell>
                                        <TableCell align="right">{this.state.totalPassedPrecentage + '%'}</TableCell>
                                        <TableCell align="right">{this.state.totalUnkown}</TableCell>
                                        <TableCell align="right">{this.state.totalFailed}</TableCell>
                                        <TableCell align="right">{this.state.totalPassed}</TableCell>
                                        <TableCell align="right">סה"כ</TableCell>
                                    </TableRow>
                                </Table>
                            </TableContainer>
                        </center>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ClerkDepositAccounts;
