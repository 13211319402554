import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class SmallCashRegisterIn extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     cashregister: {
       amount: 0
     },
     records: [],
     lastSeen: '',
     language: 'he',
     newRecordRender: false,
     date: new Date(Date.now()),
     dateDialog: false,
     suppliers: [],
     invoiceType: 'receipt',
     supplier: '',
     category: '',
     amount: 0,
     cashregister: 0,
     note: ''
   }
  }


  getData = async () => {
    axios.get(API + 'user', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          cashregister: response.data.data.user.cashRegister,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  saveRecord = async () => { // TODO: add picture
    let amount = parseFloat(this.state.amount);
    let note = this.state.note;
    axios.post(API + 'smallcashregister-in', {
      amount: amount,
      note: note
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        alert('בוצעה העברה בהצלחה! הסכום המעודכן כעת מופיע, להעברה נוספת המשיכו - אחרת חזרו לעמוד הבית');
        window.location.reload();
        this.setState({amount: 0});
        this.getData();
      } else {
        if (response.data.error === "no money left") {
          alert('אין מספיק כסף ביתרתך לביצוע פעולה זו')
        } else {
          alert(strings.errorPleaseTryAgain);
        }

      }
    })
    .catch(error => {

        alert(strings.errorPleaseTryAgain);

    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <h1>העברת כסף לקופה הקטנה</h1>
            <h2>הקופה שלי: ₪{this.state.cashregister}</h2>
            <center>
              <div style={{maxWidth: '50%'}}>
                <p>סכום:</p>
                <Input
                  type={'number'}
                  value={this.state.amount}
                  onChange={(e) => this.setState({amount: e.target.value})}
                  style={{width: '100%'}}
                />
                <p>הערה:</p>
                <Input
                  type={'text'}
                  value={this.state.note}
                  onChange={(e) => this.setState({note: e.target.value})}
                  style={{width: '100%', direction: 'rtl'}}
                />
                <br />
                <br />
                <Button color={'primary'} variant={'contained'} onClick={(e) => this.saveRecord()}>שמירה</Button>
              </div>
            </center>

          </Grid>

          <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
        </Grid>
      </div>
    )
  }
}

export default SmallCashRegisterIn;
