import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import axios from 'axios';
import {Helmet} from "react-helmet";


import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SelfSignup extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     firstName: '',
     lastName: '',
     idNumber: '',
     gender: 'male',
     birthday: new Date(Date.now()),
     email: '',
     phoneNumber: '',
     licenseType: 'A1',

     teacher: '',
     teacherName: '',
     message: '',

     saved: false,
     language: 'he',

     allowedDrivingLicenseTypes: [],
     age: 15,
     allowedDrivingLicenseTypesAge: [],
     alreadyExists: false,
     idCorrect: true
   }
  }

  getAge = (date) => {
    this.setState({birthday: date}, () => {
      var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      this.setState({age: age, allowedDrivingLicenseTypesAge: []}, () => {
        let allowedDrivingLicenseTypesAge = [];
        if (age <= 15) {
          allowedDrivingLicenseTypesAge.push('1')
          allowedDrivingLicenseTypesAge.push('D3')
          allowedDrivingLicenseTypesAge.push('D2')
        } else if (age <= 16) {
          allowedDrivingLicenseTypesAge.push('1')
          allowedDrivingLicenseTypesAge.push('D3')
          allowedDrivingLicenseTypesAge.push('D2')
          allowedDrivingLicenseTypesAge.push('B_automate')
          allowedDrivingLicenseTypesAge.push('B_manual')
          allowedDrivingLicenseTypesAge.push('A2')
        } else if (age <= 17) {
          allowedDrivingLicenseTypesAge.push('1')
          allowedDrivingLicenseTypesAge.push('D3')
          allowedDrivingLicenseTypesAge.push('D2')
          allowedDrivingLicenseTypesAge.push('B_automate')
          allowedDrivingLicenseTypesAge.push('B_manual')
          allowedDrivingLicenseTypesAge.push('A2')
          allowedDrivingLicenseTypesAge.push('C1')
        } else if (age >= 18) {
          allowedDrivingLicenseTypesAge.push('1')
          allowedDrivingLicenseTypesAge.push('D3')
          allowedDrivingLicenseTypesAge.push('D2')
          allowedDrivingLicenseTypesAge.push('B_automate')
          allowedDrivingLicenseTypesAge.push('B_manual')
          allowedDrivingLicenseTypesAge.push('A2')
          allowedDrivingLicenseTypesAge.push('C1')
          allowedDrivingLicenseTypesAge.push('C')
          allowedDrivingLicenseTypesAge.push('C+E')
          allowedDrivingLicenseTypesAge.push('C_E')
          allowedDrivingLicenseTypesAge.push('CE')
          allowedDrivingLicenseTypesAge.push('D')
          allowedDrivingLicenseTypesAge.push('D1')
          allowedDrivingLicenseTypesAge.push('A')
          allowedDrivingLicenseTypesAge.push('A1')
        } else {

        }

        this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge});
      });
    });
  }

  getData = async () => {
    // if (cookies.get('saved') === true || cookies.get('saved') === "true") {
    //   this.setState({saved: true});
    // } else {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({teacher: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        axios.get(API + 'student/signup?id='+this.state.teacher)
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              teacherName: response.data.data.teacherName,
              message: response.data.data.specialMessage,
              allowedDrivingLicenseTypes: response.data.data.allowedDrivingLicenseTypes
            });
          } else {
            alert(strings.errorPleaseTryAgain);
          }
        })
        .catch(error => {
          console.log(error);
          alert(strings.errorPleaseTryAgain);
        });
      });
    // }
  }

  checkIdNumber = (idNumber) => {
    this.setState({idNumber: idNumber}, () => {
      axios.get(API + 'student/idnumber?id='+this.state.teacher+'&idn='+idNumber).then(response => {
        this.setState({
          alreadyExists: response.data.studentExists,
        });
      }).catch(err => {
        this.setState({
          alreadyExists: false
        });
      });
    });

    if (idNumber.length >= 9) {
      this.setState({idCorrect: this.validateID(idNumber)});
    }
  }



  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10;
  }

  validate = () => {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let idNumber = this.state.idNumber;
    let birthday = this.state.birthday;
    let phoneNumber = this.state.phoneNumber;

    if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
      alert(strings.mustFillAllFields);
      return false;
    }

    if (phoneNumber.length !== 10 || !this.validatePhoneNumber(phoneNumber)) {
      alert(strings.phoneNotGood);
      return false
    }

    if (!this.validateID(idNumber)) {
      alert(strings.idNumberNotGood);
      return false;
    }

    return true;
  }

  createUser = () => {
    if (this.validate()) {
      if (this.state.age >= 15 && this.state.age <= 80) {
        if (this.state.allowedDrivingLicenseTypes.length === 1 && this.state.allowedDrivingLicenseTypesAge.includes(this.state.allowedDrivingLicenseTypes[0])) {
          this.setState({licenseType: this.state.allowedDrivingLicenseTypes[0]})
        }

        if (this.validate()) {
          let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            idNumber: this.state.idNumber,
            gender: this.state.gender,
            birthday: this.state.birthday,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            licenseType: this.state.licenseType,
            id: this.state.teacher
          }

          if (this.state.allowedDrivingLicenseTypes.length <= 1) {
            payload['licenseType'] = this.state.allowedDrivingLicenseTypes[0];
          }

          axios.post(API + 'student/signup', payload)
          .then(response => {
            if (response.data.status === "ok") {
              // alert(strings.messsageThankYouContactShortly);
              // cookies.set('saved', true, { path: '/' });
              this.setState({saved: true});
              // window.location.reload();
            } else {
              if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                alert(strings.messageDearStudentAlreadyExists + ' ' + response.data.teacherName + '. אנא צרו איתו קשר');
              } else {
                alert(strings.errorPleaseTryAgain);
              }
            }
          })
          .catch(error => {
            alert(strings.errorPleaseTryAgain);
          });
        }
      } else {
        if (this.state.age <= 15) alert(strings.minimalageerror)
        if (this.state.age >= 80) alert('התאריך שהזנתם אינו חוקי!')
      }
    } else {
        alert('שגיאה, אנו וודאו שהת.ז ומספר הטלפון תקינים ושמילאתם את כל שדות החובה');
    }
  }

  render() {
    return (
      <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>תלמיד יקר, זהו לינק הרשמה</title>
            <meta name="description" content="אנא הרשם בעזרת לינק זה" />
        </Helmet>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'not_logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            {this.state.saved ? (
              <div>
                {(this.state.message !== '') ? (
                  <h4 style={{textAlign: 'center', color: 'green', direction: 'rtl'}}>
                    {this.state.message}
                  </h4>

                ) : (
                  <h2 style={{textAlign: 'center', color: 'green', direction: 'rtl'}}>{strings.selfsignupthankyou}</h2>
                )}

                <br />
                <div style={{border: '1px solid black', width: '50%'}}>
                  <div>
                    <p style={{tetDecoration: 'rtl'}}>אנו מזמינים אתכם להכנס לאתר המכירות - קניות של קמע</p>
                    <p style={{tetDecoration: 'rtl'}}><a href='https://sivan-school.com/board' target='_blank'>לכניסה לחצו כאן</a></p>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right', direction: 'rtl'}}>{strings.selfsigndearstudent}</h2>
                <h3 style={{textAlign: 'right', direction: 'rtl'}}>{strings.noticefillstudentinfo}</h3>
                <h4 style={{textAlign: 'right', color: '#717171', direction: 'rtl'}}>
                {strings.selfsignattheend}
                {this.state.teacherName}.
                </h4>

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.idnumber}</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.idNumber}
                  onChange={(e) => this.checkIdNumber(e.target.value)}
                />

                {(!this.state.alreadyExists && this.state.idCorrect) ? (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.firstname}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.firstName}
                    onChange={(e) => this.setState({firstName: e.target.value})}
                    required
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.lastname}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.lastName}
                    onChange={(e) => this.setState({lastName: e.target.value})}
                    required
                  />

                  <p style={{textAlign: 'right'}}>{strings.email}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.email}
                    onChange={(e) => this.setState({email: e.target.value})}
                    required
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.phonenumber}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.phoneNumber}
                    onChange={(e) => this.setState({phoneNumber: e.target.value})}
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.birthday}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label=""
                        format="dd/MM/yyyy"
                        value={this.state.birthday}
                        onChange={(date) => this.getAge(date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        style={{
                          width: '100%',
                          textAlign: 'right'
                        }}
                      />
                  </MuiPickersUtilsProvider>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.gender}</p>
                  <Select
                    value={this.state.gender}
                    onChange={(e) => this.setState({gender: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    <MenuItem value={'male'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.male}</MenuItem>
                    <MenuItem value={'female'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.female}</MenuItem>
                    <MenuItem value={'other'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.othergender}</MenuItem>
                  </Select>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.licensetype}</p>
                  <React.Fragment>
                    <React.Fragment>
                    {this.state.allowedDrivingLicenseTypesAge.length === 0 && (
                      <p style={{textAlign: 'right', color: 'red'}}>אין רשיונות זמינים לגיל התלמיד</p>
                    )}
                    </React.Fragment>
                    {this.state.allowedDrivingLicenseTypesAge.length >= 1 && (
                      <React.Fragment>
                      {this.state.allowedDrivingLicenseTypes.length >= 2 ? (
                        <Select
                          value={this.state.licenseType}
                          onChange={(e) => this.setState({licenseType: e.target.value})}
                          style={{
                            direction: 'rtl',
                            width: '100%'
                          }}
                        >
                          {(this.state.allowedDrivingLicenseTypesAge.includes('A1') && this.state.allowedDrivingLicenseTypes.includes('A1')) && (<MenuItem value={'A1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA1}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('A2') && this.state.allowedDrivingLicenseTypes.includes('A2')) && (<MenuItem value={'A2'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA2}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('A') && this.state.allowedDrivingLicenseTypes.includes('A')) && (<MenuItem value={'A'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('B_manual') && this.state.allowedDrivingLicenseTypes.includes('B_manual')) && (<MenuItem value={'B_manual'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('B_automate') && this.state.allowedDrivingLicenseTypes.includes('B_automate')) && (<MenuItem value={'B_automate'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB_}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('C') && this.state.allowedDrivingLicenseTypes.includes('C')) && (<MenuItem value={'C'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('C1') && this.state.allowedDrivingLicenseTypes.includes('C1')) && (<MenuItem value={'C1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC1}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('C+E') && this.state.allowedDrivingLicenseTypes.includes('C+E')) && (<MenuItem value={'C+E'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeCA}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.allowedDrivingLicenseTypes.includes('D')) && (<MenuItem value={'D'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD}</MenuItem>)}
                          {(this.state.allowedDrivingLicenseTypesAge.includes('D1') && this.state.allowedDrivingLicenseTypes.includes('D1')) && (<MenuItem value={'D1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD1}</MenuItem>)}

                        </Select>
                      ) : (
                        <React.Fragment>
                          {this.state.allowedDrivingLicenseTypes.includes('A1') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA1}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('A2') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA2}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('A') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('B_manual') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('B_automate') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB_}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('C') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('C1') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC1}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('C+E') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeCA}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('D') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD}</p>)}
                          {this.state.allowedDrivingLicenseTypes.includes('D1') && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD1}</p>)}
                        </React.Fragment>
                      )}
                      </React.Fragment>
                    )}
                  </React.Fragment>



                  <br /><br />

                  {(this.state.message !== '') && (
                    <h4 style={{textAlign: 'right', direction: 'rtl'}}>
                      {this.state.message}
                    </h4>

                  )}
                  {isMobile ? (
                    <Button onClick={(e) => this.createUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.4em', width: '90%'}}>
                      {strings.approve}
                    </Button>
                  ) : (
                    <Button onClick={(e) => this.createUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                      {strings.approve}
                    </Button>
                  )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                      {this.state.alreadyExists && (<p style={{direction: 'rtl'}}>נראה שאת\ה כבר קיימ\ת במערכת, אנא צרו קשר עם המורה שלכם, {this.state.teacherName}, לפרטים נוספים</p>)}
                      {!this.state.idCorrect && (<p style={{direction: 'rtl'}}>מספר ת.ז שגוי, אנא בדוק אותו</p>)}
                  </React.Fragment>
                )}



              </div>
            )}
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SelfSignup;
