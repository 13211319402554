import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import {
  isMobile
} from "react-device-detect";
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class InsideTest extends Component {
  componentDidMount () {
    this.getData();
    this.getTest();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     firstName: '',
     lastName: '',
     idNumber: '',
     gender: 'male',
     birthday: '01-01-1995',
     email: '',
     phoneNumber: '',
     licenseType: 'A1',
     status: 'active',
     paidSignupFee: '',
     paidInsideTestFee: '',
     paidInsideTestFee: '',
     canSignWithBrosh: false,
     broshDate: '',
     broshClerk: '',

     lastSeen: '',
     language: 'he',
     insidetestId: '',
     status: 'unkown',
      notes: '',
      drivingStart: '',
      drivingEnd: '',
      wheelControl: '',
      clutchControl: '',
      gearSwitch: '',
      drivingStartUp: '',
      breakUsage: '',
      signaling: '',
      targetStop: '',
      carSecure: '',
      indicatorUsage: '',
      safetyAccessories: '',
      ridingBackwardHeavy: '',
      allowingHarnessingTow: '',
      mirrorUsage: '',
      rightSideDriving: '',
      laneUsage: '',
      nodesCloser: '',
      rightTurn: '',
      leftTurn: '',
      uTurn: '',
      roadSignsUsage: '',
      narrowRoad: '',
      ridingBackwardParking: '',
      deadEnd: '',
      sledMeterUsage: '',
      tachographUsage: '',
      frontBackExtenstionBus: '',
      dropOffPickupPassengers: '',
      taxiDrive: '',
      caution: '',
      drivingSpeedSlowFast: '',
      drivingSpeed: '',
      keepingSpace: '',
      bypassCars: '',
      damageOnRoadHandling: '',
      damageOnUnpavedJunction: '',
      damageOnTransformedJunction: '',
      trafficLightJunction: '',
      complaianceWithSigns: '',
      pedestrianHandling: '',
      nonUrbanRoadDriving: '',
      wetRoadDriving: '',
      suddenDangerHandling: '',
      marginHandling: '',
      railRoadHandling: '',
      lightsUsage: '',
      curvyPathDriving: '',
      mountainRoadDriving: '',
      naturalForcesCurves: '',

      printed: false,
      link: '',
      linkStudent: '',
      linkTeacher: '',
      student: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        idNumber: ''
      },
      teacher: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      displayPermissionsNumberTesting: false
   }
  }

  getData = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen,
          displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        // alert('שגיאה בקבלת הפרופיל')
      }
    })
    .catch(error => {
      console.log(error);
      // alert(strings.errorPleaseTryAgain);
    });
  }



  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10;
  }

  validate = () => {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let idNumber = this.state.idNumber;
    let birthday = this.state.birthday;
    let phoneNumber = this.state.phoneNumber;

    if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
      alert(strings.mustFillAllFields);
      return false;
    }

    if (phoneNumber.length !== 10 || !this.validatePhoneNumber(phoneNumber)) {
      alert(strings.phoneNotGood);
      return false
    }

    if (!this.validateID(idNumber)) {
      alert(strings.idNumberNotGood);
      return false;
    }

    return true;
  }

  getTest = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({insidetestId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'inside-test?id='+this.state.insidetestId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response.data.data.inside_test.status);
        if (response.data.status === "ok") {
          this.setState({
            status: response.data.data.inside_test.status,
            notes: response.data.data.inside_test.notes,
            drivingStart: response.data.data.inside_test.drivingStart,
            drivingEnd: response.data.data.inside_test.drivingEnd,
            wheelControl: response.data.data.inside_test.wheelControl,
            clutchControl: response.data.data.inside_test.clutchControl,
            gearSwitch: response.data.data.inside_test.gearSwitch,
            drivingStartUp: response.data.data.inside_test.drivingStartUp,
            breakUsage: response.data.data.inside_test.breakUsage,
            signaling: response.data.data.inside_test.signaling,
            targetStop: response.data.data.inside_test.targetStop,
            carSecure: response.data.data.inside_test.carSecure,
            indicatorUsage: response.data.data.inside_test.indicatorUsage,
            safetyAccessories: response.data.data.inside_test.safetyAccessories,
            ridingBackwardHeavy: response.data.data.inside_test.ridingBackwardHeavy,
            allowingHarnessingTow: response.data.data.inside_test.allowingHarnessingTow,
            mirrorUsage: response.data.data.inside_test.mirrorUsage,
            rightSideDriving: response.data.data.inside_test.rightSideDriving,
            laneUsage: response.data.data.inside_test.laneUsage,
            nodesCloser: response.data.data.inside_test.nodesCloser,
            rightTurn: response.data.data.inside_test.rightTurn,
            leftTurn: response.data.data.inside_test.leftTurn,
            uTurn: response.data.data.inside_test.uTurn,
            roadSignsUsage: response.data.data.inside_test.roadSignsUsage,
            narrowRoad: response.data.data.inside_test.narrowRoad,
            ridingBackwardParking: response.data.data.inside_test.ridingBackwardParking,
            deadEnd: response.data.data.inside_test.deadEnd,
            sledMeterUsage: response.data.data.inside_test.sledMeterUsage,
            tachographUsage: response.data.data.inside_test.tachographUsage,
            frontBackExtenstionBus: response.data.data.inside_test.frontBackExtenstionBus,
            dropOffPickupPassengers: response.data.data.inside_test.dropOffPickupPassengers,
            taxiDrive: response.data.data.inside_test.taxiDrive,
            caution: response.data.data.inside_test.caution,
            drivingSpeedSlowFast: response.data.data.inside_test.drivingSpeedSlowFast,
            drivingSpeed: response.data.data.inside_test.drivingSpeed,
            keepingSpace: response.data.data.inside_test.keepingSpace,
            bypassCars: response.data.data.inside_test.bypassCars,
            damageOnRoadHandling: response.data.data.inside_test.damageOnRoadHandling,
            damageOnUnpavedJunction: response.data.data.inside_test.damageOnUnpavedJunction,
            damageOnTransformedJunction: response.data.data.inside_test.damageOnTransformedJunction,
            trafficLightJunction: response.data.data.inside_test.trafficLightJunction,
            complaianceWithSigns: response.data.data.inside_test.complaianceWithSigns,
            pedestrianHandling: response.data.data.inside_test.pedestrianHandling,
            nonUrbanRoadDriving: response.data.data.inside_test.nonUrbanRoadDriving,
            wetRoadDriving: response.data.data.inside_test.wetRoadDriving,
            suddenDangerHandling: response.data.data.inside_test.suddenDangerHandling,
            marginHandling: response.data.data.inside_test.marginHandling,
            railRoadHandling: response.data.data.inside_test.railRoadHandling,
            lightsUsage: response.data.data.inside_test.lightsUsage,
            curvyPathDriving: response.data.data.inside_test.curvyPathDriving,
            mountainRoadDriving: response.data.data.inside_test.mountainRoadDriving,
            naturalForcesCurves: response.data.data.inside_test.naturalForcesCurves,

            student: response.data.data.student,
            teacher: response.data.data.teacher,
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת המבחן')
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  printTest = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({insidetestId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'print-inside-test?id='+this.state.insidetestId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            printed: true,
            link: response.data.data.link,
            linkStudent: response.data.data.linkStudent,
            linkTeacher: response.data.data.linkTeacher,
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בהדפסת המבחן')
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  updateTest = () => {
      let payload = {
         status: this.state.status,
         notes: this.state.notes,
         drivingStart: this.state.drivingStart,
         drivingEnd: this.state.drivingEnd,
         wheelControl: this.state.wheelControl,
         clutchControl: this.state.clutchControl,
         gearSwitch: this.state.gearSwitch,
         drivingStartUp: this.state.drivingStartUp,
         breakUsage: this.state.breakUsage,
         signaling: this.state.signaling,
         targetStop: this.state.targetStop,
         carSecure: this.state.carSecure,
         indicatorUsage: this.state.indicatorUsage,
         safetyAccessories: this.state.safetyAccessories,
         ridingBackwardHeavy: this.state.ridingBackwardHeavy,
         allowingHarnessingTow: this.state.allowingHarnessingTow,
         mirrorUsage: this.state.mirrorUsage,
         rightSideDriving: this.state.rightSideDriving,
         laneUsage: this.state.laneUsage,
         nodesCloser: this.state.nodesCloser,
         rightTurn: this.state.rightTurn,
         leftTurn: this.state.leftTurn,
         uTurn: this.state.uTurn,
         roadSignsUsage: this.state.roadSignsUsage,
         narrowRoad: this.state.narrowRoad,
         ridingBackwardParking: this.state.ridingBackwardParking,
         deadEnd: this.state.deadEnd,
         sledMeterUsage: this.state.sledMeterUsage,
         tachographUsage: this.state.tachographUsage,
         frontBackExtenstionBus: this.state.frontBackExtenstionBus,
         dropOffPickupPassengers: this.state.dropOffPickupPassengers,
         taxiDrive: this.state.taxiDrive,
         caution: this.state.caution,
         drivingSpeedSlowFast: this.state.drivingSpeedSlowFast,
         drivingSpeed: this.state.drivingSpeed,
         keepingSpace: this.state.keepingSpace,
         bypassCars: this.state.bypassCars,
         damageOnRoadHandling: this.state.damageOnRoadHandling,
         damageOnUnpavedJunction: this.state.damageOnUnpavedJunction,
         damageOnTransformedJunction: this.state.damageOnTransformedJunction,
         trafficLightJunction: this.state.trafficLightJunction,
         complaianceWithSigns: this.state.complaianceWithSigns,
         pedestrianHandling: this.state.pedestrianHandling,
         nonUrbanRoadDriving: this.state.nonUrbanRoadDriving,
         wetRoadDriving: this.state.wetRoadDriving,
         suddenDangerHandling: this.state.suddenDangerHandling,
         marginHandling: this.state.marginHandling,
         railRoadHandling: this.state.railRoadHandling,
         lightsUsage: this.state.lightsUsage,
         curvyPathDriving: this.state.curvyPathDriving,
         mountainRoadDriving: this.state.mountainRoadDriving,
         naturalForcesCurves: this.state.naturalForcesCurves,
      }

      axios.put(API + 'inside-test?id='+this.state.insidetestId, payload, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert(strings.insidetestupdatedSuccessMessage);
          // window.location.href='/';
          this.getTest();
        } else {
          if (response.data.errorCode === 111 || response.data.errorCode === '111') {
            alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
          } else {
            // alert(strings.errorPleaseTryAgain);
            alert('שגיאה בעדכון המבחן הפנימי')
          }
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });

  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>

              {this.state.displayPermissionsNumberTesting && (
                <p>הרשאה 22</p>
              )}

              {this.state.printed && (
                <React.Fragment>
                <h4 style={{color: 'green'}}>!המסמך הופק בהצלחה</h4>
                <Button onClick={(e) => window.open(this.state.linkStudent, '_blank')} style={{backgroundColor: '#25D366'}}>
                  <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                  <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceiptInsideTest}</a>
                </Button>
                <br /><br />
                <Button onClick={(e) => window.open(this.state.linkTeacher, '_blank')} style={{backgroundColor: '#25D366'}}>
                  <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                  <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceiptteacherInsideTest}</a>
                </Button>
                <br /><br />
                </React.Fragment>
              )}
              <Button onClick={(e) => this.updateTest()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
              <br />
              {isMobile ? (
                <Button onClick={(e) => this.printTest()} variant="contained" color="secondary" style={{marginTop: '2%', fontSize: '1.2em', width: '70%'}}>
                  {strings.createDocument}
                </Button>
              ) : (
                <Button onClick={(e) => this.printTest()} variant="contained" color="secondary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                  {strings.createDocument}
                </Button>
              )}


              <h2 style={{textAlign: 'right'}}>{strings.insidetest_title}</h2>

              <h4 style={{textAlign: 'right'}}>שם התלמיד: {this.state.student.firstName + ' ' + this.state.student.lastName + ', ת.ז: ' + this.state.student.idNumber + ', טלפון: '} <a style={{textDecoration: 'none', color: 'black'}} href={"tel:"+this.state.student.phoneNumber}>{this.state.student.phoneNumber}</a></h4>
              <h4 style={{textAlign: 'right'}}>שם המורה: {this.state.teacher.firstName + ' ' + this.state.teacher.lastName + ', טלפון: '} <a style={{textDecoration: 'none', color: 'black'}} href={"tel:"+this.state.teacher.phoneNumber}>{this.state.teacher.phoneNumber}</a></h4>

              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({status: 'unkown'})} style={{color: this.state.status === 'unkown' ? 'white' : '#3f51b5', backgroundColor: this.state.status === 'unkown' ? '#3f51b5' : 'transparent'}}>לא ידוע</Button>
                  <Button onClick={(e) => this.setState({status: 'success'})} style={{color: this.state.status === 'success' ? 'white' : '#3f51b5', backgroundColor: this.state.status === 'success' ? '#3f51b5' : 'transparent'}}>עבר</Button>
                  <Button onClick={(e) => this.setState({status: 'fail'})} style={{color: this.state.status === 'fail' ? 'white' : '#3f51b5', backgroundColor: this.state.status === 'fail' ? '#3f51b5' : 'transparent'}}>נכשל</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>האם עבר טסט?</p>
              </div>

              <p>הערות הבוחן</p>
              <TextField
                onChange={(e) => this.setState({notes: e.target.value})}
                value={this.state.notes}
                multiline
                style={{
                  width: '70%',
                  direction: 'rtl',
                  textAlign: 'right'
                }}
              />

              <h1>שליטה ברכב</h1>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({drivingStart: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStart === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStart === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingStart: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStart === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStart === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingStart: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStart === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStart === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingStart: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStart === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStart === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התחלת נסיעה/התנעה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({drivingEnd: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingEnd === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingEnd === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingEnd: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingEnd === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingEnd === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingEnd: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingEnd === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingEnd === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingEnd: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingEnd === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingEnd === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>סיום נסיעה ואבטחת הרכב</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({wheelControl: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wheelControl === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.wheelControl === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({wheelControl: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wheelControl === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.wheelControl === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({wheelControl: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wheelControl === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.wheelControl === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({wheelControl: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wheelControl === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.wheelControl === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שליטה בהגה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({clutchControl: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.clutchControl === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.clutchControl === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({clutchControl: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.clutchControl === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.clutchControl === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({clutchControl: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.clutchControl === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.clutchControl === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({clutchControl: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.clutchControl === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.clutchControl === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שימוש במצמד/גלישה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({gearSwitch: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.gearSwitch === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.gearSwitch === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({gearSwitch: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.gearSwitch === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.gearSwitch === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({gearSwitch: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.gearSwitch === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.gearSwitch === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({gearSwitch: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.gearSwitch === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.gearSwitch === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>החלפת הילוכים במועד</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({drivingStartUp: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStartUp === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStartUp === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingStartUp: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStartUp === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStartUp === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingStartUp: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStartUp === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStartUp === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingStartUp: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingStartUp === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingStartUp === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התחלת נסיעה במעלה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({breakUsageHandLeg: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsageHandLeg === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsageHandLeg === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({breakUsageHandLeg: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsageHandLeg === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsageHandLeg === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({breakUsageHandLeg: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsageHandLeg === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsageHandLeg === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({breakUsageHandLeg: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsageHandLeg === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsageHandLeg === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שימוש בבלם יד/רגל</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({signaling: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.signaling === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.signaling === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({signaling: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.signaling === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.signaling === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({signaling: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.signaling === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.signaling === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({signaling: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.signaling === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.signaling === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>איתות</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({targetStop: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.targetStop === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.targetStop === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({targetStop: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.targetStop === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.targetStop === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({targetStop: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.targetStop === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.targetStop === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({targetStop: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.targetStop === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.targetStop === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>עצירת מטרה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({carSecure: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.carSecure === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.carSecure === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({carSecure: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.carSecure === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.carSecure === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({carSecure: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.carSecure === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.carSecure === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({carSecure: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.carSecure === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.carSecure === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>אבטחת הרכב בסיום הנסיעה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({breakUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({breakUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({breakUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({breakUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.breakUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.breakUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שימוש בבלמי האטה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({indicatorUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.indicatorUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.indicatorUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({indicatorUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.indicatorUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.indicatorUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({indicatorUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.indicatorUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.indicatorUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({indicatorUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.indicatorUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.indicatorUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התייחסות למחוונים ואמצעי אזהרה</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({safetyAccessories: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.safetyAccessories === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.safetyAccessories === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({safetyAccessories: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.safetyAccessories === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.safetyAccessories === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({safetyAccessories: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.safetyAccessories === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.safetyAccessories === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({safetyAccessories: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.safetyAccessories === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.safetyAccessories === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>אביזרי בטיחות</p>
              </div>
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({ridingBackwardHeavy: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardHeavy === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardHeavy === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({ridingBackwardHeavy: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardHeavy === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardHeavy === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({ridingBackwardHeavy: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardHeavy === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardHeavy === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({ridingBackwardHeavy: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardHeavy === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardHeavy === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נסיעה לאחור בזוית בתומך/באוטובוס/כבד</p>
              </div>
                <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({allowingHarnessingTow: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.allowingHarnessingTow === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.allowingHarnessingTow === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({allowingHarnessingTow: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.allowingHarnessingTow === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.allowingHarnessingTow === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({allowingHarnessingTow: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.allowingHarnessingTow === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.allowingHarnessingTow === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({allowingHarnessingTow: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.allowingHarnessingTow === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.allowingHarnessingTow === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התרת ורתימת גרור</p>
              </div>

              <h1>הדרך</h1>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({mirrorUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mirrorUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.mirrorUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({mirrorUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mirrorUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.mirrorUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({mirrorUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mirrorUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.mirrorUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({mirrorUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mirrorUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.mirrorUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>הסתכלות/שימוש במראות</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({rightSideDriving: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightSideDriving === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.rightSideDriving === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({rightSideDriving: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightSideDriving === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.rightSideDriving === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({rightSideDriving: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightSideDriving === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.rightSideDriving === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({rightSideDriving: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightSideDriving === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.rightSideDriving === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נסיעה בימין הכביש</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({laneUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.laneUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.laneUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({laneUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.laneUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.laneUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({laneUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.laneUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.laneUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({laneUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.laneUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.laneUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>משמעת נתיבים</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({nodesCloser: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nodesCloser === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.nodesCloser === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({nodesCloser: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nodesCloser === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.nodesCloser === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({nodesCloser: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nodesCloser === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.nodesCloser === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({nodesCloser: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nodesCloser === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.nodesCloser === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התקרבות לצמתים/מיקום/חציה</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({rightTurn: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightTurn === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.rightTurn === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({rightTurn: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightTurn === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.rightTurn === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({rightTurn: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightTurn === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.rightTurn === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({rightTurn: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.rightTurn === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.rightTurn === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>פניות ימינה</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({leftTurn: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.leftTurn === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.leftTurn === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({leftTurn: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.leftTurn === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.leftTurn === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({leftTurn: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.leftTurn === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.leftTurn === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({leftTurn: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.leftTurn === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.leftTurn === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>פניות שמאלה</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({uTurn: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.uTurn === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.uTurn === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({uTurn: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.uTurn === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.uTurn === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({uTurn: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.uTurn === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.uTurn === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({uTurn: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.uTurn === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.uTurn === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>פניות פרסה</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({roadSignsUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.roadSignsUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.roadSignsUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({roadSignsUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.roadSignsUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.roadSignsUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({roadSignsUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.roadSignsUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.roadSignsUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({roadSignsUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.roadSignsUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.roadSignsUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התייחסות לתואי הכביש</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({narrowRoad: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.narrowRoad === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.narrowRoad === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({narrowRoad: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.narrowRoad === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.narrowRoad === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({narrowRoad: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.narrowRoad === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.narrowRoad === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({narrowRoad: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.narrowRoad === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.narrowRoad === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>דרך צרה/אומדני רוחב</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({ridingBackwardParking: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardParking === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardParking === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({ridingBackwardParking: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardParking === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardParking === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({ridingBackwardParking: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardParking === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardParking === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({ridingBackwardParking: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.ridingBackwardParking === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.ridingBackwardParking === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נסיעה לאחור/חניה בניצב/מקביל</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({deadEnd: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.deadEnd === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.deadEnd === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({deadEnd: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.deadEnd === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.deadEnd === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({deadEnd: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.deadEnd === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.deadEnd === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({deadEnd: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.deadEnd === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.deadEnd === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>כניסה ויציאה מדרך ללא מוצא</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({sledMeterUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.sledMeterUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.sledMeterUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({sledMeterUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.sledMeterUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.sledMeterUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({sledMeterUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.sledMeterUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.sledMeterUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({sledMeterUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.sledMeterUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.sledMeterUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שימוש במד סלד ברכב</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({tachographUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.tachographUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.tachographUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({tachographUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.tachographUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.tachographUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({tachographUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.tachographUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.tachographUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({tachographUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.tachographUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.tachographUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שימוש בטכוגרף</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({frontBackExtenstionBus: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.frontBackExtenstionBus === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.frontBackExtenstionBus === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({frontBackExtenstionBus: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.frontBackExtenstionBus === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.frontBackExtenstionBus === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({frontBackExtenstionBus: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.frontBackExtenstionBus === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.frontBackExtenstionBus === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({frontBackExtenstionBus: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.frontBackExtenstionBus === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.frontBackExtenstionBus === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התייחסות לשלוחה קדמית ואחורית באוטובוס</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({dropOffPickupPassengers: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.dropOffPickupPassengers === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.dropOffPickupPassengers === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({dropOffPickupPassengers: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.dropOffPickupPassengers === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.dropOffPickupPassengers === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({dropOffPickupPassengers: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.dropOffPickupPassengers === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.dropOffPickupPassengers === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({dropOffPickupPassengers: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.dropOffPickupPassengers === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.dropOffPickupPassengers === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>הורדת והעלאת נוסעים/תלמידים</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({taxiDrive: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.taxiDrive === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.taxiDrive === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({taxiDrive: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.taxiDrive === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.taxiDrive === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({taxiDrive: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.taxiDrive === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.taxiDrive === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({taxiDrive: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.taxiDrive === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.taxiDrive === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>בקיאות ומיומנות בנהיגה במונית</p>
              </div><br />

              <h1>התנועה</h1>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({caution: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.caution === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.caution === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({caution: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.caution === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.caution === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({caution: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.caution === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.caution === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({caution: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.caution === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.caution === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>זהירות</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({drivingSpeedSlowFast: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeedSlowFast === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeedSlowFast === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingSpeedSlowFast: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeedSlowFast === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeedSlowFast === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingSpeedSlowFast: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeedSlowFast === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeedSlowFast === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingSpeedSlowFast: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeedSlowFast === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeedSlowFast === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>קצב נסיעה איטי/מהיר</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({drivingSpeed: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeed === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeed === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingSpeed: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeed === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeed === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingSpeed: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeed === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeed === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({drivingSpeed: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.drivingSpeed === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.drivingSpeed === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>מהירות הנסיעה</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({keepingSpace: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.keepingSpace === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.keepingSpace === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({keepingSpace: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.keepingSpace === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.keepingSpace === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({keepingSpace: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.keepingSpace === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.keepingSpace === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({keepingSpace: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.keepingSpace === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.keepingSpace === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>שמירת רווח</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({bypassCars: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.bypassCars === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.bypassCars === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({bypassCars: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.bypassCars === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.bypassCars === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({bypassCars: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.bypassCars === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.bypassCars === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({bypassCars: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.bypassCars === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.bypassCars === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>עקיפות/רכב נעקף</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({damageOnRoadHandling: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnRoadHandling === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnRoadHandling === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnRoadHandling: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnRoadHandling === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnRoadHandling === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnRoadHandling: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnRoadHandling === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnRoadHandling === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnRoadHandling: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnRoadHandling === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnRoadHandling === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>מזק בדרך</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({damageOnUnpavedJunction: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnUnpavedJunction === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnUnpavedJunction === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnUnpavedJunction: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnUnpavedJunction === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnUnpavedJunction === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnUnpavedJunction: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnUnpavedJunction === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnUnpavedJunction === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnUnpavedJunction: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnUnpavedJunction === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnUnpavedJunction === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>מזק בצומת לא מתומרר</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({damageOnTransformedJunction: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnTransformedJunction === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnTransformedJunction === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnTransformedJunction: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnTransformedJunction === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnTransformedJunction === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnTransformedJunction: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnTransformedJunction === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnTransformedJunction === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({damageOnTransformedJunction: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.damageOnTransformedJunction === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.damageOnTransformedJunction === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>מזק בצומת מתמורר</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({trafficLightJunction: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.trafficLightJunction === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.trafficLightJunction === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({trafficLightJunction: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.trafficLightJunction === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.trafficLightJunction === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({trafficLightJunction: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.trafficLightJunction === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.trafficLightJunction === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({trafficLightJunction: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.trafficLightJunction === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.trafficLightJunction === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>צומת מרומזר</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({complaianceWithSigns: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.complaianceWithSigns === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.complaianceWithSigns === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({complaianceWithSigns: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.complaianceWithSigns === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.complaianceWithSigns === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({complaianceWithSigns: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.complaianceWithSigns === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.complaianceWithSigns === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({complaianceWithSigns: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.complaianceWithSigns === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.complaianceWithSigns === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>תשומת לב/ציות לתמרורים</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({pedestrianHandling: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.pedestrianHandling === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.pedestrianHandling === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({pedestrianHandling: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.pedestrianHandling === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.pedestrianHandling === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({pedestrianHandling: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.pedestrianHandling === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.pedestrianHandling === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({pedestrianHandling: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.pedestrianHandling === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.pedestrianHandling === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התייחסות/ז"ק להולכי רגל</p>
              </div><br />

              <h1>משימות מיוחדות</h1>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({nonUrbanRoadDriving: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nonUrbanRoadDriving === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.nonUrbanRoadDriving === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({nonUrbanRoadDriving: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nonUrbanRoadDriving === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.nonUrbanRoadDriving === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({nonUrbanRoadDriving: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nonUrbanRoadDriving === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.nonUrbanRoadDriving === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({nonUrbanRoadDriving: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.nonUrbanRoadDriving === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.nonUrbanRoadDriving === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נהיגה בדרך שאינה עירונית</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({wetRoadDriving: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wetRoadDriving === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.wetRoadDriving === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({wetRoadDriving: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wetRoadDriving === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.wetRoadDriving === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({wetRoadDriving: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wetRoadDriving === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.wetRoadDriving === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({wetRoadDriving: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.wetRoadDriving === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.wetRoadDriving === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נהיגה בכביש רטוב</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({suddenDangerHandling: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.suddenDangerHandling === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.suddenDangerHandling === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({suddenDangerHandling: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.suddenDangerHandling === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.suddenDangerHandling === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({suddenDangerHandling: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.suddenDangerHandling === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.suddenDangerHandling === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({suddenDangerHandling: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.suddenDangerHandling === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.suddenDangerHandling === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>תגובה לסכנה פתאומית</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({marginHandling: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.marginHandling === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.marginHandling === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({marginHandling: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.marginHandling === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.marginHandling === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({marginHandling: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.marginHandling === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.marginHandling === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({marginHandling: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.marginHandling === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.marginHandling === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>ירידה לשול</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({railRoadHandling: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.railRoadHandling === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.railRoadHandling === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({railRoadHandling: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.railRoadHandling === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.railRoadHandling === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({railRoadHandling: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.railRoadHandling === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.railRoadHandling === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({railRoadHandling: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.railRoadHandling === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.railRoadHandling === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התנהגות במפגשת מסילת ברזל</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({lightsUsage: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.lightsUsage === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.lightsUsage === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({lightsUsage: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.lightsUsage === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.lightsUsage === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({lightsUsage: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.lightsUsage === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.lightsUsage === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({lightsUsage: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.lightsUsage === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.lightsUsage === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>אורות/זמן תאורה</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({curvyPathDriving: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.curvyPathDriving === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.curvyPathDriving === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({curvyPathDriving: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.curvyPathDriving === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.curvyPathDriving === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({curvyPathDriving: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.curvyPathDriving === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.curvyPathDriving === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({curvyPathDriving: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.curvyPathDriving === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.curvyPathDriving === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נהיגה בדרך מפותלת</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({mountainRoadDriving: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mountainRoadDriving === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.mountainRoadDriving === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({mountainRoadDriving: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mountainRoadDriving === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.mountainRoadDriving === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({mountainRoadDriving: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mountainRoadDriving === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.mountainRoadDriving === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({mountainRoadDriving: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.mountainRoadDriving === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.mountainRoadDriving === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>נהיגה בדרך הררית</p>
              </div><br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: isMobile ? '98%' : '50%'}}>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({naturalForcesCurves: 'ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.naturalForcesCurves === 'ok' ? 'white' : '#3f51b5', backgroundColor: this.state.naturalForcesCurves === 'ok' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_ok}</Button>
                  <Button onClick={(e) => this.setState({naturalForcesCurves: 'half_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.naturalForcesCurves === 'half_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.naturalForcesCurves === 'half_ok' ? '#3f51b5' : 'transparent'}}>{strings.half_ok}</Button>
                  <Button onClick={(e) => this.setState({naturalForcesCurves: 'not_ok'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.naturalForcesCurves === 'not_ok' ? 'white' : '#3f51b5', backgroundColor: this.state.naturalForcesCurves === 'not_ok' ? '#3f51b5' : 'transparent'}}>{strings.not_ok}</Button>
                  <Button onClick={(e) => this.setState({naturalForcesCurves: 'bad'})} style={{padding: '5px', fontSize: '0.7em', fontWeight: 'bold', color: this.state.naturalForcesCurves === 'bad' ? 'white' : '#3f51b5', backgroundColor: this.state.naturalForcesCurves === 'bad' ? '#3f51b5' : 'transparent'}}>{strings.inside_test_bad}</Button>
                </ButtonGroup>
                <p style={{direction: 'rtl', textAlign: 'right'}}>התייחסות לכוחות טבע בעקומות</p>
              </div><br />

              {this.state.printed && (
                <React.Fragment>
                <h4 style={{color: 'green'}}>!המסמך הופק בהצלחה</h4>
                <Button onClick={(e) => window.open(this.state.linkStudent, '_blank')} style={{backgroundColor: '#25D366'}}>
                  <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                  <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceiptInsideTest}</a>
                </Button>
                <br /><br />
                <Button onClick={(e) => window.open(this.state.linkTeacher, '_blank')} style={{backgroundColor: '#25D366'}}>
                  <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                  <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceiptteacherInsideTest}</a>
                </Button>
                <br /><br />
                </React.Fragment>
              )}

              <Button onClick={(e) => this.updateTest()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
              <br />
              {isMobile ? (
                <Button onClick={(e) => this.printTest()} variant="contained" color="secondary" style={{marginTop: '2%', fontSize: '1.2em', width: '70%'}}>
                  {strings.createDocument}
                </Button>
              ) : (
                <Button onClick={(e) => this.printTest()} variant="contained" color="secondary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                  {strings.createDocument}
                </Button>
              )}


            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default InsideTest;
