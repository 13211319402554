import React, { Component, Suspense } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SelectSearch from 'react-select-search';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ManagerAddPayment extends Component {
  componentDidMount () {
    this.getManager();
    this.getData();
    this.getPtors();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',
     schoolId: '',


     type: '',
     bankNumber: '',
     branchNumber: '',
     bankAccountNumber: '',
     dateOfPayment: new Date(Date.now()),
     description: '',
     applicationType: '',
     applicationNumber: '',
     chequeNum: '',
     transactionId: '',

     lastSeen: '',
     language: 'he',

     link: '',
     whatsapp: '',
     email: '',

     canSignup: true,
     canInsideTest: true,

     ccno: '',
     expdate: '',
     cvv: '',
     expdatemonth: '',
     expdateyear: '',

     isPtor: false,
     ptorReason: '',
     ptors: [],

     signupPrice: 0,
     insideTestPrice: 0,

     prices: {},
     s_: [],
     displayPermissionsNumberTesting: false,
     haveInsideTests: false,
     haveSignUpFee: false,
     self: '',
     teachers: [],
     ptorGiverSignupUserId:'',
     ptorGiverInsideTestUserId:'',
     studentById: {},

     canStudentPaySignupFee: true,
     canStudentPayInsideTestFee: true,
     idNumberOwner: '',
     loading: false
   }
  }

  getManager = async () => {
      axios.get(API + 'manager', {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log('****:', response);
        if (response.data.status === "ok") {
          this.setState({
            displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
            haveInsideTests: response.data.haveInsideTests,
            haveSignUpFee: response.data.haveSignUpFee,
            self: response.data.self,
            teachers: response.data.data.employees
          }, () => {
            let teachersWithPermission = [];
            this.state.teachers.forEach((item, i) => {
              if (item.canGivePtorSignup || item.canGivePtorInsideTest) teachersWithPermission.push(item);
            });

            this.setState({teachers: teachersWithPermission}, () => {
              this.setState({loading: false});
            });

            if (!this.state.haveInsideTests && !this.state.haveSignUpFee) this.setState({description: 'ptor'});
            if (!this.state.haveInsideTests && this.state.haveSignUpFee) this.setState({description: 'signupFee'});
            if (this.state.haveInsideTests && !this.state.haveSignUpFee) this.setState({description: 'insideTestFee'});
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת רשימת תלמידים')
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
  }

  getData = async () => {
    this.setState({loading: true}, () => {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        axios.get(API + 'manager/minified/students?id='+this.state.schoolId, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              students: response.data.data.students,
              lastSeen: response.data.lastSeen,
              schoolName: response.data.data.schoolName,
              manager: response.data.data.manager,
              signupPrice: response.data.prices.signupPrice,
              insideTestPrice: response.data.prices.insideTestPrice,
            }, () => {
              let students = [];
              let studentsApproval = [];
              let prices = {};
              let s_ = [];
              let studentById = {};

              this.state.students.forEach((item, i) => {
                studentById[item._id.toString()] = item;

                if (item.approved === "false" || !item.approved) studentsApproval.push(item);
                else students.push(item);

                if (prices[item._id] === undefined) {
                  prices[item._id] = item.prices;
                }

                if (item.paidSignupFee === 'no' || item.paidInsideTestFee === 'no' || item.paidSignupFee === null || item.paidInsideTestFee === null || item.paidSignupFee === undefined || item.paidInsideTestFee === undefined) {
                  s_.push({name: item.firstName + ' ' + item.lastName + ' '+item.idNumber+'', value: item._id})
                }
              });

              this.setState({
                students: students,
                studentsApproval: studentsApproval,
                prices: prices,
                s_: s_,
                studentById: studentById
              }, () => {
                console.log('done');
                this.setState({loading: false});

              });
            });
          } else {
            // alert(strings.errorPleaseTryAgain);
            alert('שגיאה בקבלת רשימת תלמידים')
            this.setState({loading: false});
          }
        })
        .catch(error => {
          console.log(error);
          alert(strings.errorPleaseTryAgain);
          this.setState({loading: false});
        });
      });
    });
  }

  getPtors = () => {
    axios.get(API + 'ptors', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {
      this.setState({ptors: response.data.data.ptors});
    }).catch(err => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  setPtorReason = (ptorReason) => {
    this.state.ptors.forEach((item, i) => {
      if (item.reason === ptorReason) {
        this.setState({ptorReason: item.reason, ptorPrecentages: item.ptorPrecentages}, () => {
          console.log('reason:', this.state.ptorReason);
          console.log('ptorPrecentages:', this.state.ptorPrecentages);
          if (this.state.ptorPrecentages !== undefined && this.state.ptorPrecentages > 0 && this.state.ptorPrecentages < 100) {
            this.setState({isPtor: false})
          }
        });
      }
    });

  }

  createPayment = () => {
    let a = window.confirm('אנא אשרו ליצירת רשומת תשלום חדשה');
    if (a) {
      if (!this.state.loading) {
        this.setState({loading: true}, () => {
          if (
            ((this.state.type !== '' && this.state.type !== undefined && this.state.type !== null) || (this.state.ptorReason !== '' && this.state.ptorReason !== undefined && this.state.ptorReason !== null)) &&
            this.state.description !== '' && this.state.description !== undefined && this.state.description !== null &&
            this.state.studentId !== '' && this.state.studentId !== undefined && this.state.studentId !== null
          ) {
            let payload = {
              type: this.state.type,
              bankNumber: this.state.bankNumber,
              branchNumber: this.state.branchNumber,
              bankAccountNumber: this.state.bankAccountNumber,
              dateOfPayment: this.state.dateOfPayment,
              description: this.state.description,
              applicationType: this.state.applicationType,
              applicationNumber: this.state.applicationNumber,
              chequeNum: this.state.chequeNum,
              transactionId: this.state.transactionId,
              expdate: this.state.expdatemonth +''+ this.state.expdateyear,
              ccno: this.state.ccno,
              cvv: this.state.cvv,
              isPtor: this.state.isPtor,
              ptorReason: this.state.ptorReason,
              ptorGiverSignupUserId: this.state.ptorGiverUserId,
              ptorGiverInsideTestUserId: this.state.ptorGiverUserId,
              idNumberOwner: this.state.idNumberOwner
            }

            if (this.state.type === 'tranzila') payload['dateOfPayment'] = new Date(Date.now());

            axios.post(API + 'manager/student/payment?id='+this.state.studentId, payload, {
              headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
            })
            .then(response => {
              if (response.data.status === "ok") {
                this.setState({loading: false});
                //alert(strings.messagePaymentCreatedSuccess);
                this.setState({
                  link: response.data.data.link,
                  whatsapp: response.data.data.whatsapp,
                  email: response.data.data.email,
                })
                if (this.state.isPtor) { window.location.href='/'; }
              } else {
                this.setState({loading: false});
                if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                  alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
                } else if (response.data.errorCode === 1111 || response.data.errorCode === '1111' || response.data.errorCode === 1112 || response.data.errorCode === '1112') {
                  alert('שגיאה בחיוב הכרטיס, אנא נסו בשנית');
                } else if (response.data.errorCode === 6772 || response.data.errorCode === '6772') {
                  alert('אין אפשרות להוציא חשבונית על סכום אפס או סכום שלילי!');
                } else {
                  alert(strings.errorPleaseTryAgain);
                }
              }
            })
            .catch(error => {
              alert(strings.errorPleaseTryAgain);
              this.setState({loading: false});
            });
          } else {
            alert('נתונים חסרים!')
            this.setState({loading: false});
          }
        });
      } else {
        alert('תהליך בריצה, אנא המתינו');
      }
    }
  }

  setStudent = (studentId) => {
    this.setState({studentId: studentId}, () => {
      axios.get(API + 'manager/student/payment-info?id='+this.state.studentId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        console.log(response);
        this.setState({
          canSignup: response.data.data.can_signup,
          canInsideTest: response.data.data.can_inside_test,
        });
      }).catch(err => {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה קבלת המידע')
      });
    })
  }


    handlePtor = () => {
      let isPtor = this.state.isPtor;

      if (isPtor) {
        this.setState({isPtor: false});
      } else {
        this.setState({isPtor: true});
      }
    }

    ptorCheckInvoiceNumber = (reason) => {
      let ptor = {};
      this.state.ptors.forEach((item, i) => {
        if (item.reason === this.state.ptorReason) {
            ptor = item;
        }
      });

      if (ptor.invoiceNumber) {
        return true;
      } else {
        return false;
      }

      return false;
    }

    ptorCheckRequireInvoiceNumber = (reason) => {
      let ptor = {};
      this.state.ptors.forEach((item, i) => {
        if (item.reason === this.state.ptorReason) {
          ptor = item;
        }
      });

      if (ptor.requireInvoiceNumber) {
        return true;
      } else {
        return false;
      }

      return false;
    }

    chooseStudent = (value) => {
      this.setState({studentId: value}, () => {
        let student = this.state.studentById[value];
        console.log(student);
        console.log('student.paidSignupFee:',student.paidSignupFee);
        console.log('student.paidInsideTestFee:',student.paidInsideTestFee);
        if ((student.paidSignupFee === 'yes' || student.paidSignupFee === 'ptor') && student.paidInsideTestFee === 'no') this.setState({description: 'insideTestFee'});
        if ((student.paidInsideTestFee === 'yes' || student.paidInsideTestFee === 'ptor') && student.paidSignupFee === 'no') this.setState({description: 'signupFee'});

        if (student.paidSignupFee === 'no' && student.paidInsideTestFee === 'no') this.setState({description: 'signupFee'});

        if (student.paidSignupFee === "no" && student.paidInsideTestFee === "no") {
          this.setState({
            canStudentPaySignupFee: true,
            canStudentPayInsideTestFee: false,
            signupPrice: student.prices.signupPrice,
            insideTestPrice: student.prices.insideTestPrice,
          }, () => {
            if (!this.state.canStudentPaySignupFee && !this.state.canStudentPayInsideTestFee) {
              alert('לא נותר לתלמיד זה על מה לשלם');
            }
          });
        } else {
          this.setState({
            canStudentPaySignupFee: (student.paidSignupFee === 'yes' || student.paidSignupFee === 'ptor') ? false : true,
            canStudentPayInsideTestFee: (student.paidInsideTestFee === 'yes' || student.paidInsideTestFee === 'ptor') ? false : true,
            signupPrice: student.prices.signupPrice,
            insideTestPrice: student.prices.insideTestPrice,
          }, () => {
            if (!this.state.canStudentPaySignupFee && !this.state.canStudentPayInsideTestFee) {
              alert('לא נותר לתלמיד זה על מה לשלם');
            }
          });
        }

      });
    }

    // getSignupFeeText = () => {
    //   return window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text');
    //
    // }
    getSignupFeeText = () => {
      let ft = window.localStorage.getItem('signupfee_text');
      if (ft === null || ft === undefined || ft === 'NULL' || ft === 'null') {
        return 'תשלום דמי רישום';
      }
      return ft;
    }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          {(this.state.loading) ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item xs={12}>

              {this.state.link === '' ? (

                <center>
                <div style={{maxWidth: '90%'}}>
                  <h2 style={{textAlign: 'right'}}>{strings.addnewstudentpayment}</h2>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosestudent}</p>
                  {/*<Select
                    value={this.state.studentId}
                    onChange={(e) => this.setStudent(e.target.value)}
                    style={{
                      direction: 'rtl',
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    {this.state.students.map((student) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={student._id}>{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</MenuItem>
                      )
                    })}
                  </Select>*/}

                  {this.state.s_ && this.state.s_.length >= 70 && (
                    <p>אם הרשימה ריקה, אנא המתינו מספר שניות ולחצו עליה בשנית</p>
                  )}
                  {this.state.s_ && this.state.s_.length === 0 ? (
                    <CircularProgress />
                  ) : (
                    <Suspense fallback={<div>loading...</div>}>
                      <SelectSearch
                        onChange={(value) => this.chooseStudent(value)}
                        options={this.state.s_}
                        search={true}
                        value={this.state.studentId}
                        name="studentId"
                        placeholder="שם התלמיד.."
                      />
                    </Suspense>
                  )}


                  {/*<p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.payfor}</p>
                  <Select
                    value={this.state.description}
                    onChange={(e) => this.setState({description: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    {this.state.canSignup && (<MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'signupFee'}>{this.getSignupFeeText()} {this.state.prices[this.state.studentId] === undefined ? '' : '₪' + this.state.prices[this.state.studentId].signupPrice }</MenuItem>)}
                    {this.state.canInsideTest && (<MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'insideTestFee'}>{strings.insideTest} {this.state.prices[this.state.studentId] === undefined ? '' : '₪' + this.state.prices[this.state.studentId].insideTestPrice}</MenuItem>)}
                  </Select>*/}

                  {(this.state.haveInsideTests && this.state.haveSignUpFee) && (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.payfor}</p>
                      <Select
                        value={this.state.description}
                        onChange={(e) => this.setState({description: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                      {this.state.canStudentPaySignupFee && (<MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'signupFee'}>{this.getSignupFeeText()} ₪{this.state.signupPrice}</MenuItem>)}
                      {this.state.canStudentPayInsideTestFee && (<MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'insideTestFee'}>{strings.insideTest} ₪{this.state.insideTestPrice}</MenuItem>)}
                      </Select>
                    </React.Fragment>
                  )}
                  {(this.state.haveInsideTests && !this.state.haveSignUpFee) && (
                    <p style={{textAlign: 'right'}}>{strings.payfor}: {strings.insideTest} (₪{this.state.insideTestPrice})</p>
                  )}
                  {(!this.state.haveInsideTests && this.state.haveSignUpFee) && (
                    <p style={{textAlign: 'right'}}>{strings.payfor}: {this.getSignupFeeText()} (₪{this.state.signupPrice})</p>
                  )}
                  {(!this.state.haveInsideTests && !this.state.haveSignUpFee) && (
                    <p style={{textAlign: 'right'}}>{strings.ptor} (₪0)</p>
                  )}


                  <div style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}>
                    <Checkbox
                      onChange={(e) => this.handlePtor()}
                      selected={this.state.isPtor}
                      value={this.state.isPtor}
                    /> {strings.ptorfrompayment}
                  </div>

                  {this.state.ptorPrecentages && (
                    <p>פטור הנחה: {this.state.ptorPrecentages}%</p>
                  )}

                  {this.state.isPtor && (
                    <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.whoGaveThePtor}</p>
                    <Select
                      value={this.state.ptorGiverUserId}
                      onChange={(e) => this.setState({ptorGiverUserId: e.target.value})}
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                    >
                      <MenuItem value={this.state.self} style={{direction: 'rtl', textAlign: 'right'}}>אני</MenuItem>
                      {this.state.teachers.map((teacher) => {
                        return (
                          <MenuItem value={teacher._id} style={{direction: 'rtl', textAlign: 'right'}}>{teacher.firstName + ' ' + teacher.lastName + ' '+teacher.idNumber+''}</MenuItem>
                        )
                      })}
                    </Select>
                    </React.Fragment>
                  )}

                  {!this.state.isPtor ? (
                    <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paywith}</p>
                    <Select
                      value={this.state.type}
                      onChange={(e) => this.setState({type: e.target.value}, () => {
                        if (this.state.type === 'ptor') {
                          this.setState({isPtor: true});
                        }
                      })}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cash'}>{strings.cash}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cheque'}>{strings.cheque}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bankTransfer'}>{strings.bankTransfer}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'application'}>{strings.application}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'tranzila'}>{strings.tranzila}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'barter'}>{strings.barter}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'ptor'}>{strings.ptor}</MenuItem>
                    </Select>

                    {this.state.type === 'tranzila' && (
                      <React.Fragment>
                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.creditcardnumber}</p>
                        <Input
                          value={this.state.ccno}
                          onChange={(e) => this.setState({ccno: e.target.value})}
                          type='number'
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        />

                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.expdatenumber}</p>
                        <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.month}</p>
                        <Select
                          value={this.state.expdatemonth}
                          onChange={(e) => this.setState({expdatemonth: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        >
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>01</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'02'}>02</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'03'}>03</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>04</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'05'}>05</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'06'}>06</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>07</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>08</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>09</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>10</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>11</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>12</MenuItem>
                        </Select>
                        <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.year}</p>
                        <Select
                          value={this.state.expdateyear}
                          onChange={(e) => this.setState({expdateyear: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        >
                          {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear() % 100) + i).map((index) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.toString()}>{index.toString()}</MenuItem>
                      )
                    })}
                        </Select>
                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.cvvnumber}</p>
                        <Input
                          value={this.state.cvv}
                          onChange={(e) => this.setState({cvv: e.target.value})}
                          type='number'
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        />
                        <p style={{textAlign: 'right', direction: 'rtl'}}>{strings.idnumbercardowner} <span style={{color: 'red'}}>*</span></p>
                        <TextField
                          style={{
                            direction: 'rtl',
                            width: '100%'
                          }}
                          value={this.state.idNumberOwner}
                          onChange={(e) => this.setState({idNumberOwner: e.target.value})}
                        />
                      </React.Fragment>
                    )}


                    {this.state.type === "application" && (
                      <React.Fragment>
                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseapplication}</p>
                        <Select
                          value={this.state.applicationType}
                          onChange={(e) => this.setState({applicationType: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        >
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bit'}>{strings.bit}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'paybox'}>{strings.paybox}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'pepper'}>{strings.pepper}</MenuItem>
                        </Select>
                      </React.Fragment>
                    )}

                    {(this.state.type === 'bankTransfer' || this.state.type === 'cheque') && (
                      <React.Fragment>
                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                        <Select
                          value={this.state.bankNumber}
                          onChange={(e) => this.setState({bankNumber: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        >
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                        </Select>

                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                        <Input
                          value={this.state.branchNumber}
                          onChange={(e) => this.setState({branchNumber: e.target.value})}
                          type='number'
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        />

                        <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                        <Input
                          value={this.state.bankAccountNumber}
                          onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                          type='number'
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        />
                      </React.Fragment>
                    )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.ptor_reason}</p>
                      <Select
                        value={this.state.ptorReason}
                        onChange={(e) => this.setPtorReason(e.target.value)}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        {this.state.ptors.map((index) => {
                          return (
                            <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.reason}>{index.reason} {index.ptorPrecentages && ('('+index.ptorPrecentages+'%)')}</MenuItem>
                          );
                        })}
                      </Select>

                      {this.ptorCheckInvoiceNumber(this.state.ptorReason) && (
                        <React.Fragment>
                        <p style={{textAlign: 'right'}}>{this.ptorCheckRequireInvoiceNumber(this.state.ptorReason) && (<span style={{color: 'red'}}>*</span>)} מספר המסמך</p>
                        <TextField
                          value={this.state.invoiceNumber}
                          onChange={(e) => this.setState({invoiceNumber: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '100%'
                          }}
                        />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}


                  {!this.state.canSignup && !this.state.canInsideTest ? (
                    <p>{strings.cantpayanything}</p>
                  ) : (
                    <>
                      {!this.state.loading && (
                        <Button onClick={(e) => this.createPayment()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                          {strings.create}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                </center>
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <center>
                    <a href='/'>{strings.backtohomepage}</a>

                      <p style={{fontSize: '1.5em'}}>{strings.invoicereceiptandpaymentcreatedsuccess}</p>

                      <IconButton onClick={(e) => window.open(this.state.link, '_blank')}>
                        <GetAppIcon style={{width: 32, height: 32 }} />
                        <a href={this.state.link} target="_blank">{strings.todownloadreceipt}</a>
                      </IconButton>
                      <p>{strings.orH}</p>
                      <Button onClick={(e) => window.open(this.state.whatsapp, '_blank')} style={{backgroundColor: '#25D366'}}>
                        <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                        <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceipt}</a>
                      </Button>
                      <br />
                      <Button onClick={(e) => window.open('mailto:'+this.state.email+'?subject=חשבונית לתלמיד&body=היי, מצרף כאן את החשבונית שלך '+this.state.link, '_blank')} color={'primary'} variant={'contained'}>
                        <MailOutlineIcon style={{width: 32, height: 32, color: 'white'}}/>
                        &nbsp;
                        <a href={'mailto:'+this.state.email+'?subject=חשבונית לתלמיד&body=היי, מצרף כאן את החשבונית שלך '+this.state.link} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלח את החשבונית באימייל לתלמיד בלחיצת כפתור</a>
                      </Button>
                      <br /><br />
                      <Button onClick={(e) => window.open('https://mail.google.com/mail/?view=cm&fs=1&to='+this.state.email+'&su=חשבונית לתלמיד&body=היי, מצרף כאן את החשבונית שלך '+this.state.link, '_blank')} style={{backgroundColor: '#D44638'}} variant={'contained'}>
                        <MailOutlineIcon style={{width: 32, height: 32, color: 'white'}}/>
                        &nbsp;
                        <a href={'https://mail.google.com/mail/?view=cm&fs=1&to='+this.state.email+'&su=חשבונית לתלמיד&body=היי, מצרף כאן את החשבונית שלך '+this.state.link} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלח את החשבונית בג'ימייל לתלמיד בלחיצת כפתור</a>
                      </Button>
                      <br />

                    </center>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

export default ManagerAddPayment;
