import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class SmallCashRegisterPull extends Component {
  componentDidMount() {
    this.getData();
    this.getPermission();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     cashregister: {
       amount: 0
     },
     records: [],
     lastSeen: '',
     language: 'he',
     newRecordRender: false,
     date: new Date(Date.now()),
     dateDialog: false,
     suppliers: [],
     invoiceType: 'receipttaxinvoice',
     supplier: '',
     paymentType: 'changing',
     category: '',
     amount: 0,
     recordsOriginal: [],
     monthSelected: false,
     daySelected: false,
     elseSelected: false,
     elseDateDialog: false,
     elseDate: new Date(Date.now()),
     permissions: {
       print: false,
       view: false,
       in: false,
       out: false,
       edit: false,
       edit24: false,
       remove: false
     },
     fromDateDoc: new Date(Date.now()),
     fromDateDocDialog: false,
     toDateDoc: new Date(Date.now()),
     toDateDocDialog: false,
     link: '',
     whatsapp: '',
     email: '',
     recordId: '',
     source: '',
     sourceSelection: 'smallcashregister',
     user: {
       isGizbar: false,
       isManager: false,
       type: ''
     },
     pullMoney: false,
     teachers: [],
     selectedUser: ''
   }
  }


  getData = async () => {
    axios.get(API + 'smallcashregister?showt=yes', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          cashregister: response.data.data.cashregister,
          records: response.data.data.records,
          recordsOriginal: response.data.data.records,
          suppliers: response.data.data.suppliers,
          permissions: response.data.data.permissions,
          teachers: response.data.data.teachers,
          selectedUser: response.data.data.teachers[0]._id.toString() || ''
        }, () => {
          console.log(this.state.permissions);
          if (this.state.suppliers.length >= 1) this.setState({supplier: this.state.suppliers[0]._id})
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getPermission = async () => {
    axios.get(API + 'validate', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      this.setState({user: response.data.data});
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  saveRecord = async () => { // TODO: add picture
    let invoiceType = this.state.invoiceType;
    let amount = parseFloat(this.state.amount);
    let note = this.state.note;
    // if (invoiceType === 'invoice' || invoiceType === 'receipttaxinvoice') amount = amount * 1.17;
    axios.post(API + 'smallcashregister-out', {
      date: this.state.date,
      time: this.state.time,
      invoiceType: this.state.invoiceType,
      invoiceNumber: '',
      supplier: this.state.supplier,
      paymentType: this.state.paymentType,
      category: this.state.category,
      source: this.state.sourceSelection,
      amount: amount,
      note: note
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          date: new Date(Date.now()),
          time: '',
          invoiceType: 'receipt',
          supplier: this.state.suppliers[0]._id,
          category: '',
          source: '',
          sourceSelection: 'smallcashregister',
          amount: 0,
          newRecordRender: false
        }, () => {
          this.getData();
          this.setState({newRecordRender: false});
          this.resetState();
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  editRecord = async () => { // TODO: add picture
    let invoiceType = this.state.invoiceType;
    let amount = parseFloat(this.state.amount);
    let note = this.state.note;
    // if (invoiceType === 'invoice' || invoiceType === 'receipttaxinvoice') amount = amount * 1.17;
    axios.put(API + 'smallcashregister-edit?id='+this.state.recordId, {
      date: this.state.date,
      time: this.state.time,
      invoiceType: this.state.invoiceType,
      invoiceNumber: '',
      supplier: this.state.supplier,
      paymentType: this.state.paymentType,
      category: this.state.category,
      source: this.state.sourceSelection,
      amount: amount,
      note: note
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          date: new Date(Date.now()),
          time: '',
          invoiceType: 'receipt',
          supplier: this.state.suppliers[0]._id,
          category: '',
          amount: 0,
          source: '',
          sourceSelection: 'smallcashregister',
          newRecordRender: false
        }, () => {
          this.resetState();
          this.setState({editRecordRender: false});
          this.getData();
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  deleteRecord = async (record) => { // TODO: add picture
    let id = record._id;
    // if (invoiceType === 'invoice' || invoiceType === 'receipttaxinvoice') amount = amount * 1.17;
    axios.delete(API + 'smallcashregister-remove?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.resetState();
        this.setState({editRecordRender: false});
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  isDate = (dateStr) => {
    return !isNaN(new Date(dateStr).getDate());
  }

  pad = (n) => {return n<10 ? '0'+n : n}

  filterToday = () => {
    let date = new Date(Date.now());
    date = new Date(date).getFullYear() + '-' + new Date(date).getMonth() + '-' + new Date(date).getDate();
    const records = this.state.recordsOriginal;
    let output = [];

    records.forEach((item, i) => {
      let d = new Date(item.date).getFullYear() + '-' + new Date(item.date).getMonth() + '-' + new Date(item.date).getDate();

      if (d === date) output.push(item);
    });


    this.setState({records: output, monthSelected: false, daySelected: true, elseSelected: false});
  }
  filterByDate = (date) => {
    date = new Date(date).getFullYear() + '-' + new Date(date).getMonth() + '-' + new Date(date).getDate();
    const records = this.state.recordsOriginal;
    let output = [];

    records.forEach((item, i) => {
      let d = new Date(item.date).getFullYear() + '-' + new Date(item.date).getMonth() + '-' + new Date(item.date).getDate();

      if (d === date) output.push(item);
    });


    this.setState({records: output, monthSelected: false, daySelected: false, elseSelected: true});
  }

  filterByMonth = (month) => {
    const records = this.state.recordsOriginal;
    let output = [];

    records.forEach((item, i) => {
      function pad(n) {return n<10 ? '0'+n : n}
      let d = new Date(item.date).getMonth() + 1;

      if (pad(d.toString()) === pad(month.toString()) && new Date(item.date).getFullYear() === new Date(Date.now()).getFullYear()) output.push(item);
    });


    this.setState({records: output, monthSelected: true, daySelected: false, elseSelected: false});
  }

  generateDocument = () => {
    let fromDateDoc = this.state.fromDateDoc;
    let toDateDoc = this.state.toDateDoc;

    axios.post(API + 'smallcashregister-document', {
      fromDate: fromDateDoc,
      toDate: toDateDoc
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          link: response.data.data.link,
          whatsapp: response.data.data.whatsapp,
          email: response.data.data.email,
          downloadRender: true
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  modifyPayType = (payment) => {
    if (payment === 'changing') {
      return 'משתנה';
    } else if (payment === 'monthly') {
      return 'חודשי רציף'
    } else if (payment === 'two-monthly') {
      return 'דו חודשי רציף';
    } else if (payment === 'yearly'){
      return 'שנתי';
    } else {
      return '-';
    }
  }

  igniteEdition = (record) => {
    window.scroll(0,0);
    console.log(record);
    this.setState({
      date: record.date,
      time: record.time,
      paymentType: record.paymentType,
      supplier: record.supplier,
      invoiceType: record.invoiceType,
      amount: record.amount,
      recordId: record._id,
      note: record.note
    }, () => {
      this.setState({editRecordRender: true})
    })
  }

  resetState = () => {
    this.setState({
      date: new Date(Date.now()),
      time: '',
      paymentType: 'yearly',
      invoiceType: 'receipttaxinvoice',
      supplier: this.state.suppliers[0]._id,
      amount: 0,
      recordId: '',
      note: ''
    });
  }

  pullMoney = () => {
    const confirm = window.confirm("האם אתם בטוחים שברצונכם למשוך כסף ממשתמש זה?")
    if (confirm) {
      const user = this.state.selectedUser;
      const amount = this.state.amount;

      axios.put(API + 'smallcashregister-pull?id='+user, {
        amount: amount,
      }, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          alert('הכסף נמשך בהצלחה לקופה');
          window.location.reload();
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    }
  }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <br />
            <br />
            <h1>קופה קטנה</h1>
            <h2>₪{this.state.cashregister.amount}</h2>
          </Grid>

          <Grid item xs={12}>
            <h1>משיכת כספים לקופה הקטנה</h1>


            {(this.state.user.isManager || this.state.user.isGizbar || this.state.user.type === 'clerk') ? (
              <div>
                <h2>בחרו ממי למשוך כספים</h2>
                <Select style={{direction: 'rtl', width: 500}} value={this.state.selectedUser} onChange={(e) => this.setState({selectedUser: e.target.value})}>
                  {this.state.teachers.map((teacher) => {
                    return (
                        <MenuItem value={teacher._id.toString()}>{teacher.firstName + ' ' + teacher.lastName + ' ('+teacher.idNumber+')'}</MenuItem>
                    )
                  })}
                </Select>

                <h2>סכום המשיכה</h2>
                <Input
                  type={'number'}
                  value={this.state.amount}
                  onChange={(e) => this.setState({amount: e.target.value})}
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: 500
                  }}
                />

                <br />
                <br />

                <Button variant={'contained'} color={'primary'} onClick={(e) => this.pullMoney()}>אישור</Button>
              </div>
            ) : (
              <div>
                <h2>אין לכם הרשאה לצפות בעמוד זה</h2>
              </div>
            )}


          </Grid>

          <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
        </Grid>
      </div>
    )
  }
}

export default SmallCashRegisterPull;
