import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Suppliers extends Component {
  componentDidMount() {
    this.getData();
    this.getSuppliers();

    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))});

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     suppliers: [],

     lastSeen: '',

     language: 'he',

     type: '',
     schoolId: ''
   }
  }


  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getSuppliers = async () => {
    axios.get(API + 'manager/suppliers', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          suppliers: response.data.data.suppliers
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mysupplierslist}</h2>
          </center>
          <br />
          <Link style={{textDecoration: 'none'}} to={'supplier/add?id='+this.state.schoolId}><Button variant="contained" color="primary">
            {strings.addsupplier}
          </Button></Link>
          <br /><br />
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.suppliers.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.emptysuppliersstring1}
          <PersonAddIcon style={{width: 32, height: 32}}/><br/>
           {strings.emptysuppliersstring2}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.update}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.suppliercanelsesee}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.supplierfee}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.category}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.supplierphone}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.suppliername}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.suppliers.map((supplier, i) => {
                  return (
                    <TableRow key={i+'_employee'}>
                      <TableCell align="right">
                        <Link to={'/school/supplier/edit?id='+supplier._id} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>{strings.update}</Button></Link>
                      </TableCell>
                      <TableCell align="right">{supplier.canElseSee ? strings.yes : strings.no}</TableCell>
                      <TableCell align="right">{supplier.fee}</TableCell>
                      <TableCell align="right">{supplier.category}</TableCell>
                      <TableCell align="right">{supplier.phoneNumber}</TableCell>
                      <TableCell align="right">{supplier.name}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default Suppliers;
