import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ReportsEmployees extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     employees: [],
     pdf: '',
     pdfExported: false,
     exportMonth: new Date(Date.now()).getMonth() + 1
   }
  }


  getData = async () => {
    axios.get(API + 'manager/report/employees', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          employees: response.data.employees
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  updateEmployeeStatus = async (id) => {
    axios.put(API + 'manager/report/modify-employee-status?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      this.getData();
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  generateReport = async (id) => {
    axios.post(API + 'manager/report/generate-pdf?id='+id+'&month='+this.state.exportMonth, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response.data.pdf);
      this.setState({pdf: response.data.pdf, pdfExported: true})
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>דיווחי שעת - עובדים</h1>
            </center>
            {this.state.pdfExported && (
              <center>
                <h3><u>הדו"ח נוצר בהצלחה</u></h3>
                <Button variant={'contained'} color={'primary'} onClick={(e) => window.open('https://sivan-school.com/pdfs/'+this.state.pdf, '_blank')}>להורדה הדו"ח לחץ כאן</Button>
              </center>
            )}
          </Grid>



          <Grid item xs={12}>
            <center>
              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>ייצא דו"ח</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>ניהול הוצאות חודשיות</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>עריכת יומן שבועי</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>צפייה בדיווח השעות</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>האם מחויב בדיווח שעות</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>תעודת זהות</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שם מלא</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.employees.map((employee, i) => {
                    if (employee.type === "clerk") {
                      return (
                        <TableRow key={i+'_deposit'}>
                          <TableCell align="right">
                            <Button variant={'contained'} onClick={(e) => this.generateReport(employee._id)}>ייצוא</Button>
                            &nbsp;
                            &nbsp;
                            <Select onChange={(e) => this.setState({exportMonth: e.target.value})} value={this.state.exportMonth} defaultValue={new Date(Date.now()).getMonth() + 1}>
                              <MenuItem value={1}>{"01/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={2}>{"02/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={3}>{"03/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={4}>{"04/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={5}>{"05/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={6}>{"06/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={7}>{"07/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={8}>{"08/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={9}>{"09/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={10}>{"10/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={11}>{"11/"+new Date(Date.now()).getFullYear()}</MenuItem>
                              <MenuItem value={12}>{"12/"+new Date(Date.now()).getFullYear()}</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell align="right"><Button variant={'contained'} onClick={(e) => window.location.href='/reports/expenses?id='+employee._id.toString()}>ניהול</Button></TableCell>
                          <TableCell align="right"><Button variant={'contained'} onClick={(e) => window.location.href='/reports/weekly-calendar?id='+employee._id.toString()}>עריכה</Button></TableCell>
                          <TableCell align="right"><Button variant={'contained'} onClick={(e) => window.location.href='/reports/report?id='+employee._id.toString()}>צפייה</Button></TableCell>
                          <TableCell align="right"><Button onClick={(e) => this.updateEmployeeStatus(employee._id)} variant={'contained'} color={employee.shouldReportHours ? 'primary' : 'secondary'}>{employee.shouldReportHours === false ? 'לא' : 'כן'}</Button></TableCell>
                          <TableCell align="right">{employee.idNumber}</TableCell>
                          <TableCell align="right">{employee.firstName + ' ' + employee.lastName}</TableCell>
                        </TableRow>
                      )
                    }
                  })}
                </TableBody>
              </Table>
              </TableContainer>
            </center>
          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ReportsEmployees;
