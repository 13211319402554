import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class InsideTestsEasyView extends Component {
  componentDidMount() {
    this.getData();
    this.getMessages();
    this.getClerk();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],

     lastSeen: '',
     clerk: {
       firstName: '',
       lastName: ''
     },
     students: [],
     studentsApproval: [],
     payments: [],
     language: 'he',
     messages: [],
     tickerMove: true,
     showTicker: true,

     payment_string: '',
     signToBroshInsideTest_date: new Date(Date.now()),
     filter: 'day',
     haveInsideTests: false,
     haveSignUpFee: false,
     dateToSearchFrom: new Date(Date.now()),
     dateToSearchTo: new Date(Date.now()),
   }
  }

  getClerk = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          haveInsideTests: response.data.haveInsideTests,
          haveSignUpFee: response.data.haveSignUpFee
        })
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let msgs = response.data.data.messages;
        this.setState({messages: msgs})
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  signToBrosh = (id) => {
    axios.put(API + 'clerk/student/signtobrosh?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  signToBroshInsideTest = (id) => {
    axios.put(API + 'clerk/student/signtobrosh-insidetest?id='+id, {
      date: this.state.signToBroshInsideTest_date
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  getData = async () => {
    axios.get(API + 'clerk/inside-tests-ev', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          clerk: response.data.data.clerk,
          students: response.data.data.students,
          lastSeen: response.data.lastSeen,
        });
      } else {
        alert('שגיאה בקבלת הפרופיל מהשרת')
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      // alert(strings.errorPleaseTryAgain);
      alert('שגיאת מערכת (קוד 1772)')
    });
  }

  approveStudentRequest = (id) => {
    axios.put(API + 'clerk/student/approve?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  removeStudentRequest = (id) => {
    axios.delete(API + 'clerk/student?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }

  searchPayment = () => {
    let payments = this.state.payments;
    let string = this.state.payment_string;
    let output = [];

    payments.forEach((payment) => {
      if (payment.studentName.includes(string)) output.push(payment);
    });

    this.setState({payments: output})
  }

  getDatesRange = (startDate, stopDate) => {
    const ONE_DAY = 24*3600*1000;
    var days= [];
    var currentDate = new Date(startDate);
    while (currentDate <= stopDate) {
        days.push(new Date (currentDate));
        currentDate = currentDate - 1 + 1 + ONE_DAY;
    }
    let days_ = [];
    days.forEach((item, i) => {
      days_.push(item.getDate() + '/' + item.getMonth() + '/' + item.getFullYear())
    });

    return days_;
  }

  searchByDate = () => {
    let from = this.state.dateToSearchFrom;
    from = new Date(from);
    let to = this.state.dateToSearchTo;
    to = new Date(to);

    let from_ = from.getDate() + '/' + from.getMonth() + '/' + from.getFullYear()
    let to_ = to.getDate() + '/' + to.getMonth() + '/' + to.getFullYear()

    let daysBetween = this.getDatesRange(from, to);

    let output = [];
    this.state.students.map((student) => {
      let student_date_d = new Date(student.date);

      student_date_d = student_date_d.getDate() + '/' + student_date_d.getMonth() + '/' + student_date_d.getFullYear();

      if (student_date_d === from_ || student_date_d === to_ || daysBetween.includes(student_date_d)) output.push(student);
    });

    this.setState({students: output});
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>מבחנים פנימיים</h2>
            <br />
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                <Button onClick={(e) => this.setState({filter: 'other'})} style={{padding: '5px', fontSize: '1em', fontWeight: 'bold', color: this.state.filter === 'other' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'other' ? '#3f51b5' : 'transparent'}}>{strings.other}</Button>
                <Button onClick={(e) => this.setState({filter: 'month'})} style={{padding: '5px', fontSize: '1em', fontWeight: 'bold', color: this.state.filter === 'month' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'month' ? '#3f51b5' : 'transparent'}}>{strings.monthly}</Button>
                <Button onClick={(e) => this.setState({filter: 'week'})} style={{padding: '5px', fontSize: '1em', fontWeight: 'bold', color: this.state.filter === 'week' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'week' ? '#3f51b5' : 'transparent'}}>{strings.weekly}</Button>
                <Button onClick={(e) => this.setState({filter: 'day'})} style={{padding: '5px', fontSize: '1em', fontWeight: 'bold', color: this.state.filter === 'day' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'day' ? '#3f51b5' : 'transparent'}}>{strings.daily}</Button>
              </ButtonGroup>
            </div>

            {this.state.filter === 'other' && (
              <React.Fragment>
              <p style={{textAlign: 'center'}}><span style={{color: 'red'}}>*</span> תאריך לחיפוש</p>
              <p>מתאריך</p>
              <TextField
                value={this.state.dateToSearchFrom}
                onChange={(e) => this.setState({dateToSearchFrom: e.target.value})}
                type='date'
                format='dd/mm/yyyy'
                style={{
                  direction: 'rtl',
                  width: '20%'
                }}
              />
              <p>עד תאריך</p>
              <TextField
                value={this.state.dateToSearchTo}
                onChange={(e) => this.setState({dateToSearchTo: e.target.value})}
                type='date'
                format='dd/mm/yyyy'
                style={{
                  direction: 'rtl',
                  width: '20%'
                }}
              />
              <br />
              <br />

              <Button variant={'contained'} color={'secondary'} onClick={(e) => this.searchByDate()}>חפש</Button>

              <br /><br />
              </React.Fragment>
            )}
            <br /><br />
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {this.state.haveInsideTests && (<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.statusBroshInsideTest}</TableCell>)}
                  {this.state.haveSignUpFee && (<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.statusBrosh}</TableCell>)}
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'עבר/לא עבר'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'נבחן בתאריך'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.students.reverse().map((student, i) => {console.log(student);
                  if (this.state.filter === 'day' && student.dateStatus === 'day') {
                    return (
                      <TableRow key={i+'_student'}>
                        {this.state.haveInsideTests && (
                          <TableCell align="right">
                          {
                            student.canSignWithBroshInsideTest ? (
                              student.didSignedWithBroshInsideTest ?
                               ('עודכן שעבר מבחן פנימי בברוש' + ' ('+(student.didSignedWithBroshInsideTestDate === undefined ? 'שגיאה' : (new Date(student.didSignedWithBroshInsideTestDate).getDate() + '/' + parseInt(new Date(student.didSignedWithBroshInsideTestDate).getMonth() + 1) + '/' + new Date(student.didSignedWithBroshInsideTestDate).getFullYear()))+')')
                               : (
                                 <React.Fragment>
                                 <TextField
                                  value={this.state.signToBroshInsideTest_date}
                                  onChange={(e) => this.setState({signToBroshInsideTest_date: e.target.value})}
                                  type="date"
                                  format='dd/mm/yyyy'
                                />
                                 <Button onClick={(e) => this.signToBroshInsideTest(student.student._id)}>לא עודכן עדיין בברוש שעבר מבחן פנימי</Button>
                                 </React.Fragment>
                               )
                            ) : (
                              'לא יכול לעדכן מבחן פנימי בברוש'
                            )
                          }
                          </TableCell>
                        )}
                        {this.state.haveSignUpFee && (
                          <TableCell align="right">
                          {
                            student.canSignWithBrosh ? (
                              student.didSignedWithBrosh ?
                               'נרשם בברוש'
                               : (<Button onClick={(e) => this.signToBrosh(student._id)}>לא נרשם עדיין בברוש</Button>)
                             )
                               : 'לא יכול להרשם בברוש'
                          }
                          </TableCell>
                        )}

                        <TableCell align="right">{student.status === 'success' ? 'עבר' : 'לא עבר'}</TableCell>
                        <TableCell align="right">{new Date(student.date).getDate() + '/' + parseInt(new Date(student.date).getMonth() + 1) + '/' + new Date(student.date).getFullYear()}</TableCell>
                        <TableCell align="right">{student.student.firstName + ' ' + student.student.lastName}</TableCell>
                      </TableRow>
                    )
                  }
                  if (this.state.filter === 'week' && student.dateStatus === 'week') {
                    return (
                      <TableRow key={i+'_student'}>
                        {this.state.haveInsideTests && (
                          <TableCell align="right">
                          {
                            student.canSignWithBroshInsideTest ? (
                              student.didSignedWithBroshInsideTest ?
                               ('עודכן שעבר מבחן פנימי בברוש' + ' ('+(student.didSignedWithBroshInsideTestDate === undefined ? 'שגיאה' : (new Date(student.didSignedWithBroshInsideTestDate).getDate() + '/' + parseInt(new Date(student.didSignedWithBroshInsideTestDate).getMonth() + 1) + '/' + new Date(student.didSignedWithBroshInsideTestDate).getFullYear()))+')')
                               : (
                                 <React.Fragment>
                                 <TextField
                                  value={this.state.signToBroshInsideTest_date}
                                  onChange={(e) => this.setState({signToBroshInsideTest_date: e.target.value})}
                                  type="date"
                                  format='dd/mm/yyyy'
                                />
                                 <Button onClick={(e) => this.signToBroshInsideTest(student.student._id)}>לא עודכן עדיין בברוש שעבר מבחן פנימי</Button>
                                 </React.Fragment>
                               )
                            ) : (
                              'לא יכול לעדכן מבחן פנימי בברוש'
                            )
                          }
                          </TableCell>
                        )}
                        {this.state.haveSignUpFee && (
                          <TableCell align="right">
                          {
                            student.canSignWithBrosh ? (
                              student.didSignedWithBrosh ?
                               'נרשם בברוש'
                               : (<Button onClick={(e) => this.signToBrosh(student._id)}>לא נרשם עדיין בברוש</Button>)
                             )
                               : 'לא יכול להרשם בברוש'
                          }
                          </TableCell>
                        )}

                        <TableCell align="right">{student.status === 'success' ? 'עבר' : 'לא עבר'}</TableCell>
                        <TableCell align="right">{new Date(student.date).getDate() + '/' + parseInt(new Date(student.date).getMonth() + 1) + '/' + new Date(student.date).getFullYear()}</TableCell>
                        <TableCell align="right">{student.student.firstName + ' ' + student.student.lastName}</TableCell>
                      </TableRow>
                    )
                  }
                  if (this.state.filter === 'month' && student.dateStatus === 'month') {
                    return (
                      <TableRow key={i+'_student'}>
                        {this.state.haveInsideTests && (
                          <TableCell align="right">
                          {
                            student.canSignWithBroshInsideTest ? (
                              student.didSignedWithBroshInsideTest ?
                               ('עודכן שעבר מבחן פנימי בברוש' + ' ('+(student.didSignedWithBroshInsideTestDate === undefined ? 'שגיאה' : (new Date(student.didSignedWithBroshInsideTestDate).getDate() + '/' + parseInt(new Date(student.didSignedWithBroshInsideTestDate).getMonth() + 1) + '/' + new Date(student.didSignedWithBroshInsideTestDate).getFullYear()))+')')
                               : (
                                 <React.Fragment>
                                 <TextField
                                  value={this.state.signToBroshInsideTest_date}
                                  onChange={(e) => this.setState({signToBroshInsideTest_date: e.target.value})}
                                  type="date"
                                  format='dd/mm/yyyy'
                                />
                                 <Button onClick={(e) => this.signToBroshInsideTest(student.student._id)}>לא עודכן עדיין בברוש שעבר מבחן פנימי</Button>
                                 </React.Fragment>
                               )
                            ) : (
                              'לא יכול לעדכן מבחן פנימי בברוש'
                            )
                          }
                          </TableCell>
                        )}
                        {this.state.haveSignUpFee && (
                          <TableCell align="right">
                          {
                            student.canSignWithBrosh ? (
                              student.didSignedWithBrosh ?
                               'נרשם בברוש'
                               : (<Button onClick={(e) => this.signToBrosh(student._id)}>לא נרשם עדיין בברוש</Button>)
                             )
                               : 'לא יכול להרשם בברוש'
                          }
                          </TableCell>
                        )}

                        <TableCell align="right">{student.status === 'success' ? 'עבר' : 'לא עבר'}</TableCell>
                        <TableCell align="right">{new Date(student.date).getDate() + '/' + parseInt(new Date(student.date).getMonth() + 1) + '/' + new Date(student.date).getFullYear()}</TableCell>
                        <TableCell align="right">{student.student.firstName + ' ' + student.student.lastName}</TableCell>
                      </TableRow>
                    )
                  }
                  if (this.state.filter === 'other') {
                    return (
                      <TableRow key={i+'_student'}>
                        {this.state.haveInsideTests && (
                          <TableCell align="right">
                          {
                            student.canSignWithBroshInsideTest ? (
                              student.didSignedWithBroshInsideTest ?
                               ('עודכן שעבר מבחן פנימי בברוש' + ' ('+(student.didSignedWithBroshInsideTestDate === undefined ? 'שגיאה' : (new Date(student.didSignedWithBroshInsideTestDate).getDate() + '/' + parseInt(new Date(student.didSignedWithBroshInsideTestDate).getMonth() + 1) + '/' + new Date(student.didSignedWithBroshInsideTestDate).getFullYear()))+')')
                               : (
                                 <React.Fragment>
                                 <TextField
                                  value={this.state.signToBroshInsideTest_date}
                                  onChange={(e) => this.setState({signToBroshInsideTest_date: e.target.value})}
                                  type="date"
                                  format='dd/mm/yyyy'
                                />
                                 <Button onClick={(e) => this.signToBroshInsideTest(student.student._id)}>לא עודכן עדיין בברוש שעבר מבחן פנימי</Button>
                                 </React.Fragment>
                               )
                            ) : (
                              'לא יכול לעדכן מבחן פנימי בברוש'
                            )
                          }
                          </TableCell>
                        )}
                        {this.state.haveSignUpFee && (
                          <TableCell align="right">
                          {
                            student.canSignWithBrosh ? (
                              student.didSignedWithBrosh ?
                               'נרשם בברוש'
                               : (<Button onClick={(e) => this.signToBrosh(student._id)}>לא נרשם עדיין בברוש</Button>)
                             )
                               : 'לא יכול להרשם בברוש'
                          }
                          </TableCell>
                        )}

                        <TableCell align="right">{student.status === 'success' ? 'עבר' : 'לא עבר'}</TableCell>
                        <TableCell align="right">{new Date(student.date).getDate() + '/' + parseInt(new Date(student.date).getMonth() + 1) + '/' + new Date(student.date).getFullYear()}</TableCell>
                        <TableCell align="right">{student.student.firstName + ' ' + student.student.lastName}</TableCell>
                      </TableRow>
                    )
                  }
                })}

              </TableBody>
            </Table>
          </TableContainer>
        

        </center>
        </Grid>


        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default InsideTestsEasyView;
