import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditEmployee extends Component {
  componentDidMount () {
    this.getUser();
    this.getManager();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
      isClerk: false,
      parentSchoolId: 10,

      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      idNumber: '',
      type: '',
      professionalTeacherOfficialNumber: '',
      insideTestPrice: 0,
      insideTestPriceGiven: 0,
      signupPrice: 0,
      signupPriceGiven: 0,
      password: '',
      frozen: false,
      canAddEmployees: false,
      isPartner: false,
      isGizbar: false,
      signupNumberOfFeesPerMonth: 0,
      insideTestNumberOfFeesPerMonth: 0,

      schoolId: '',
      userId: '',
      canAddClerk: false,

      lastSeen: '',
      language: 'he',
      allowedDrivingLicenseTypes: [],
      canSeeCommission: false,

      CPEofficename: '',
      CPEaddress: '',
      CPEpostalcode: '',
      CPEfax: '',

      automaticStudentApproval: false,
      canSeeInsideTests: false,
      canSellItems: false,
      canTransferStudent: false,
      canSetInsideTestsProfessional: false,
      canGivePtorInsideTest: false,
      canGivePtorSignup: false,
      canSetInsideTestsToOthers: false,
      canRemoveInsideTest: false,
      canSetInsideTestsToOthersStudents: false,

      showPermissionsList: false,
      emailTaken: false,

      emailOriginal: '',
      displayPermissionsNumberTesting: false,
      canCollectCash: false,
      canCollectCheque: false,
      canCollectBankTransfer: false,
      canCollectApplication: false,
      canCollectTranzila: false,
      canCollectBarter: false,
      canDeposit: false,
      canWithdrawMoney: false,
      canDiscoverPayments: false,
      haveSignUpFee: false,
      haveInsideTests: false,
      canSeeTeachersCashRegister: false,
      canSeeTeachersIncome: false,
      shouldRecieveMessageOnNewStudent: false,
      canEditInsideTests: false,
      canRefund: false,
      canSeePtorsGiven: false,
      canDoInsideTestsToMyStudents: false,
      insideTestCommission: 0
   }
  }

  getManager = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          haveInsideTests: response.data.haveInsideTests,
          haveSignUpFee: response.data.haveSignUpFee,
        });
      } else {
        alert('שגיאה בקבלת הפרופיל מהשרת')
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      // alert(strings.errorPleaseTryAgain);
      alert('שגיאת מערכת (קוד 1772)')
    });
  }

  handleChange = () => {
    let isClerk = this.state.isClerk ? false : true;
    let type = this.state.type;
    if (isClerk) type = 'clerk';
    else type = 'teacher';

    this.setState({isClerk: isClerk, type: type});
  }

  getUser = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({userId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'manager/user?id='+this.state.userId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            firstName: response.data.data.user.firstName,
            lastName: response.data.data.user.lastName,
            idNumber: response.data.data.user.idNumber,
            email: response.data.data.user.email,
            emailOriginal: response.data.data.user.email,
            phoneNumber: response.data.data.user.phoneNumber,
            type: response.data.data.user.type,
            canAddEmployees: response.data.data.user.canAddEmployees,
            isClerk: response.data.data.user.type === "clerk" ? true : false,
            professionalTeacherOfficialNumber: response.data.data.user.professionalTeacherOfficialNumber,
            insideTestPrice: response.data.data.user.insideTestPrice,
            insideTestPriceGiven: response.data.data.user.insideTestPriceGiven,
            signupPrice: response.data.data.user.signupPrice,
            signupPriceGiven: response.data.data.user.signupPriceGiven,
            frozen: response.data.data.user.frozen,
            isPartner: response.data.data.user.isPartner,
            allowedDrivingLicenseTypes: (response.data.data.user.allowedDrivingLicenseTypes === undefined || response.data.data.user.allowedDrivingLicenseTypes === null) ? [] : response.data.data.user.allowedDrivingLicenseTypes,
            isGizbar: response.data.data.user.isGizbar === undefined ? false : response.data.data.user.isGizbar,
            signupNumberOfFeesPerMonth: response.data.data.user.signupNumberOfFeesPerMonth,
            insideTestNumberOfFeesPerMonth: response.data.data.user.insideTestNumberOfFeesPerMonth,
            canSeeCommission: response.data.data.user.canSeeCommission,
            CPEofficename: response.data.data.user.CPEofficename,
            CPEaddress: response.data.data.user.CPEaddress,
            CPEpostalcode: response.data.data.user.CPEpostalcode,
            CPEfax: response.data.data.user.CPEfax,
            automaticStudentApproval: response.data.data.user.automaticStudentApproval,
            canSeeInsideTests: response.data.data.user.canSeeInsideTests,
            canSellItems: response.data.data.user.canSellItems,
            canTransferStudent: response.data.data.user.canTransferStudent,
            canSetInsideTestsProfessional: response.data.data.user.canSetInsideTestsProfessional,
            canGivePtorSignup: response.data.data.user.canGivePtorSignup,
            canGivePtorInsideTest: response.data.data.user.canGivePtorInsideTest,
            canSetInsideTestsToOthers: response.data.data.user.canSetInsideTestsToOthers,
            canRemoveInsideTest: response.data.data.user.canRemoveInsideTest,
            canSetInsideTestsToOthersStudents: response.data.data.user.canSetInsideTestsToOthersStudents,
            canCollectCash: response.data.data.user.canCollectCash,
            canCollectCheque: response.data.data.user.canCollectCheque,
            canCollectBankTransfer: response.data.data.user.canCollectBankTransfer,
            canCollectApplication: response.data.data.user.canCollectApplication,
            canCollectTranzila: response.data.data.user.canCollectTranzila,
            canDeposit: response.data.data.user.canDeposit,
            canCollectBarter: response.data.data.user.canCollectBarter,
            canWithdrawMoney: response.data.data.user.canWithdrawMoney,
            canDiscoverPayments: response.data.data.user.canDiscoverPayments,
            canEditInsideTests: response.data.data.user.canEditInsideTests,
            insideTestCommission: response.data.data.user.insideTestCommission,
            canRefund: response.data.data.user.canRefund,
            canSeePtorsGiven: response.data.data.user.canSeePtorsGiven,
            canSeeTeachersCashRegister: response.data.data.user.canSeeTeachersCashRegister,
            canSeeTeachersIncome: response.data.data.user.canSeeTeachersIncome,
            shouldRecieveMessageOnNewStudent: response.data.data.user.shouldRecieveMessageOnNewStudent,
            canDoInsideTestsToMyStudents: response.data.data.user.canDoInsideTestsToMyStudents,
            canAddClerk: (response.data.data.user.canAddClerk === undefined || response.data.data.user.canAddClerk === null) ? false : response.data.data.user.canAddClerk,
            lastSeen: response.data.lastSeen,
            displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת מידע על העובד')
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10 || phoneNumber.match(/\d/g).length===9;
  }

  validate = () => {
    // let firstName = this.state.firstName;
    // let lastName = this.state.lastName;
    let idNumber = this.state.idNumber;
    // let birthday = this.state.birthday;
    let phoneNumber = this.state.phoneNumber;
    //
    // if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
    //   alert(strings.mustFillAllFields);
    //   return false;
    // }

    if (!this.validatePhoneNumber(phoneNumber)) {
      alert(strings.phoneNotGood);
      return false
    }

    if (this.state.type !== 'cpe') {
      if (!this.validateID(idNumber)) {
        alert(strings.idNumberNotGood);
        return false;
      }
    }

    return true;
  }

  updateUser = () => {
    if (this.validate() && !this.state.emailTaken) {
      let payload = {
        schoolId: this.state.schoolId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        idNumber: this.state.idNumber,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
        type: this.state.type,
        canAddEmployees: this.state.canAddEmployees,
        professionalTeacherOfficialNumber: this.state.professionalTeacherOfficialNumber,
        insideTestPrice: this.state.insideTestPrice,
        insideTestPriceGiven: this.state.insideTestPriceGiven,
        signupPrice: this.state.signupPrice,
        signupPriceGiven: this.state.signupPriceGiven,
        frozen: this.state.frozen,
        isPartner: this.state.isPartner,
        signupNumberOfFeesPerMonth: this.state.signupNumberOfFeesPerMonth,
        insideTestNumberOfFeesPerMonth: this.state.insideTestNumberOfFeesPerMonth,
        canAddClerk: this.state.canAddClerk,
        allowedDrivingLicenseTypes: this.state.allowedDrivingLicenseTypes,
        isGizbar: this.state.isGizbar,
        canSeeCommission: this.state.canSeeCommission,
        CPEofficename: this.state.CPEofficename,
        CPEaddress: this.state.CPEaddress,
        CPEpostalcode: this.state.CPEpostalcode,
        CPEfax: this.state.CPEfax,
        automaticStudentApproval: this.state.automaticStudentApproval,
        canSeeInsideTests: this.state.canSeeInsideTests,
        canSellItems: this.state.canSellItems,
        canTransferStudent: this.state.canTransferStudent,
        canSetInsideTestsProfessional: this.state.canSetInsideTestsProfessional,
        canGivePtorSignup: this.state.canGivePtorSignup,
        canSetInsideTestsToOthers: this.state.canSetInsideTestsToOthers,
        canGivePtorInsideTest: this.state.canGivePtorInsideTest,
        canRemoveInsideTest: this.state.canRemoveInsideTest,
        canSetInsideTestsToOthersStudents: this.state.canSetInsideTestsToOthersStudents,
        canCollectCash: this.state.canCollectCash,
        insideTestCommission: this.state.insideTestCommission,
        canCollectCheque: this.state.canCollectCheque,
        canCollectBankTransfer: this.state.canCollectBankTransfer,
        canCollectApplication: this.state.canCollectApplication,
        canCollectTranzila: this.state.canCollectTranzila,
        canDeposit: this.state.canDeposit,
        canWithdrawMoney: this.state.canWithdrawMoney,
        canCollectBarter: this.state.canCollectBarter,
        canDiscoverPayments: this.state.canDiscoverPayments,
        canSeeTeachersCashRegister: this.state.canSeeTeachersCashRegister,
        canSeeTeachersIncome: this.state.canSeeTeachersIncome,
        shouldRecieveMessageOnNewStudent: this.state.shouldRecieveMessageOnNewStudent,
        canEditInsideTests: this.state.canEditInsideTests,
        canRefund: this.state.canRefund,
        canSeePtorsGiven: this.state.canSeePtorsGiven,
        canDoInsideTestsToMyStudents: this.state.canDoInsideTestsToMyStudents,
      }

      axios.put(API + 'manager/user?id='+this.state.userId, payload, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          //alert(strings.messageUserUpdatedSuccess);
          window.location.href='/';
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בעריכת העובד')
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    }
  }

  freeze = () => {
    let frozen = this.state.frozen ? false : true;

    this.setState({frozen: frozen});
  }


    addAllowedLicenseType = (value) => {
      let values = this.state.allowedDrivingLicenseTypes;

      if (this.wasIChecked(value)) {
        values.remove(value);
      } else {
        values.push(value);
      }
      console.log(value);
      console.log(values);
      this.setState({allowedDrivingLicenseTypes: values});
    }

    wasIChecked = (value) => {
      let values = this.state.allowedDrivingLicenseTypes;

      return values.includes(value);
    }

    handleCanRefund = () => {
      if (this.state.canRefund) {
        this.setState({canRefund: false});
      } else {
        this.setState({canRefund: true});
      }
    }

    handleChangeCanSeeComission = () => {
      if (this.state.canSeeCommission) {
        this.setState({canSeeCommission: false});
      } else {
        this.setState({canSeeCommission: true});
      }
    }

    handleCanDiscoverPayments = () => {
      if (this.state.canDiscoverPayments) {
        this.setState({canDiscoverPayments: false});
      } else {
        this.setState({canDiscoverPayments: true});
      }
    }

    handleCanSeeInsideTests = () => {
      if (this.state.canSeeInsideTests) {
        this.setState({canSeeInsideTests: false});
      } else {
        this.setState({canSeeInsideTests: true});
      }
    }

    handleCanSellItems = () => {
      if (this.state.canSellItems) {
        this.setState({canSellItems: false});
      } else {
        this.setState({canSellItems: true});
      }
    }

    handleCanTransferStudent = () => {
      if (this.state.canTransferStudent) {
        this.setState({canTransferStudent: false});
      } else {
        this.setState({canTransferStudent: true});
      }
    }

    handleChangeASA = () => {
      if (this.state.automaticStudentApproval) {
        this.setState({automaticStudentApproval: false});
      } else {
        this.setState({automaticStudentApproval: true});
      }
    }

    handleCanSetInsideTestsProfessional = () => {
      if (this.state.canSetInsideTestsProfessional) {
        this.setState({canSetInsideTestsProfessional: false});
      } else {
        this.setState({canSetInsideTestsProfessional: true});
      }
    }

    handleCanEditInsideTests = () => {
      if (this.state.canEditInsideTests) {
        this.setState({canEditInsideTests: false});
      } else {
        this.setState({canEditInsideTests: true});
      }
    }

    handleCanGivePtorSignup = () => {
      if (this.state.canGivePtorSignup) {
        this.setState({canGivePtorSignup: false});
      } else {
        this.setState({canGivePtorSignup: true});
      }
    }
    handleCanGivePtorInsideTest = () => {
      if (this.state.canGivePtorInsideTest) {
        this.setState({canGivePtorInsideTest: false});
      } else {
        this.setState({canGivePtorInsideTest: true});
      }
    }
    handleCanSetInsideTestsToOthers = () => {
      if (this.state.canSetInsideTestsToOthers) {
        this.setState({canSetInsideTestsToOthers: false});
      } else {
        this.setState({canSetInsideTestsToOthers: true});
      }
    }
    handleCanRemoveInsideTest = () => {
      if (this.state.canRemoveInsideTest) {
        this.setState({canRemoveInsideTest: false});
      } else {
        this.setState({canRemoveInsideTest: true});
      }
    }

    handleCanSetInsideTestsToOthersStudents = () => {
      if (this.state.canSetInsideTestsToOthersStudents) {
        this.setState({canSetInsideTestsToOthersStudents: false});
      } else {
        this.setState({canSetInsideTestsToOthersStudents: true});
      }
    }

    handleCanSeeTeachersCashRegister = () => {
      if (this.state.canSeeTeachersCashRegister) {
        this.setState({canSeeTeachersCashRegister: false});
      } else {
        this.setState({canSeeTeachersCashRegister: true});
      }
    }

    handleCanSeeTeachersIncome = () => {
      if (this.state.canSeeTeachersIncome) {
        this.setState({canSeeTeachersIncome: false});
      } else {
        this.setState({canSeeTeachersIncome: true});
      }
    }

    handleShouldRecieveMessageOnNewStudent = () => {
      if (this.state.shouldRecieveMessageOnNewStudent) {
        this.setState({shouldRecieveMessageOnNewStudent: false});
      } else {
        this.setState({shouldRecieveMessageOnNewStudent: true});
      }
    }

    handleCanDeposit = () => {
      if (this.state.canDeposit) {
        this.setState({canDeposit: false});
      } else {
        this.setState({canDeposit: true});
      }
    }


    handleCanSeePtorsGiven = () => {
      if (this.state.canSeePtorsGiven) {
        this.setState({canSeePtorsGiven: false});
      } else {
        this.setState({canSeePtorsGiven: true});
      }
    }


    handleCanCollectCash = () => {
      if (this.state.canCollectCash) {
        this.setState({canCollectCash: false});
      } else {
        this.setState({canCollectCash: true});
      }
    }

    handleCanCollectCheque = () => {
      if (this.state.canCollectCheque) {
        this.setState({canCollectCheque: false});
      } else {
        this.setState({canCollectCheque: true});
      }
    }

    handleCanCollectBankTransfer = () => {
      if (this.state.canCollectBankTransfer) {
        this.setState({canCollectBankTransfer: false});
      } else {
        this.setState({canCollectBankTransfer: true});
      }
    }

    handleCanCollectApplication= () => {
      if (this.state.canCollectApplication) {
        this.setState({canCollectApplication: false});
      } else {
        this.setState({canCollectApplication: true});
      }
    }

    handleCanCollectTranzila= () => {
      if (this.state.canCollectTranzila) {
        this.setState({canCollectTranzila: false});
      } else {
        this.setState({canCollectTranzila: true});
      }
    }

    handleCanCollectBarter= () => {
      if (this.state.canCollectBarter) {
        this.setState({canCollectBarter: false});
      } else {
        this.setState({canCollectBarter: true});
      }
    }

    handleCanWithdrawMoney= () => {
      if (this.state.canWithdrawMoney) {
        this.setState({canWithdrawMoney: false});
      } else {
        this.setState({canWithdrawMoney: true});
      }
    }

    handleCanDoInsideTestsToMyStudents= () => {
      if (this.state.canDoInsideTestsToMyStudents) {
        this.setState({canDoInsideTestsToMyStudents: false});
      } else {
        this.setState({canDoInsideTestsToMyStudents: true});
      }
    }

    checkEmail = async () => {
      if (this.state.email === this.state.emailOriginal) {
        this.setState({emailTaken: false})
      } else {
        axios.get(API + 'check-email?email='+this.state.email, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.taken) {
            this.setState({emailTaken: true});
          } else {
            this.setState({emailTaken: false});
          }
        })
        .catch(error => {
          console.log(error);
          // alert(strings.errorPleaseTryAgain);
        });
      }
    }

    setEmail = (email) => {
      if (email === this.state.emailOriginal) {
        this.setState({emailTaken: false, email: email});
      } else {
        this.setState({email: email}, () => {
          this.checkEmail();
        })
      }
    }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.editexistingemployee}</h2>
              <h4 style={{textAlign: 'right'}}>{this.state.firstName + ' ' + this.state.lastName}</h4>

              <div style={{float: 'right', fontWeight: 'bold'}}>
                <Switch
                   checked={this.state.frozen}
                   onChange={(e) => this.freeze()}
                /> {strings.freezeemployee}
              </div>

              <br /><br /><br /><br />



              {this.state.showPermissionsList ? (
                <React.Fragment>
                <Button onClick={(e) => this.setState({showPermissionsList: false})}  variant={'contained'} color={'primary'}>הסתר רשימת הרשאות</Button>
                <table style={{
                  borderCollpase: 'collapse',
                  direction: 'rtl'
                }}>
                  <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>מספור</th> )}
                    <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>הרשאה</th>
                    <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>אישור/ביטול</th>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>0</th> )}

                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.clerk}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.isClerk}
                      onChange={(e) => this.handleChange()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> </td>
                  </tr>
                  <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>1</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSeeCommission}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canSeeCommission}
                      onChange={(e) => this.handleChangeCanSeeComission()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  {this.state.haveInsideTests && (
                    <tr>
                      {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>2</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSeeInsideTests}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                        checked={this.state.canSeeInsideTests}
                        onChange={(e) => this.handleCanSeeInsideTests()}
                        color={'primary'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></td>
                    </tr>
                  )}

                  <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>3</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSellItems}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canSellItems}
                      onChange={(e) => this.handleCanSellItems()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>4</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canTransferStudent}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canTransferStudent}
                      onChange={(e) => this.handleCanTransferStudent()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>5</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canGivePtorSignup}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canGivePtorSignup}
                      onChange={(e) => this.handleCanGivePtorSignup()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>

                  {this.state.haveInsideTests && (
                    <React.Fragment>
                    <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>6</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canGivePtorInsideTest}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                        checked={this.state.canGivePtorInsideTest}
                        onChange={(e) => this.handleCanGivePtorInsideTest()}
                        color={'primary'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></td>
                    </tr>
                    <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>7</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsToOthers}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                        checked={this.state.canSetInsideTestsToOthers}
                        onChange={(e) => this.handleCanSetInsideTestsToOthers()}
                        color={'primary'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></td>
                    </tr>
                    <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>8</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canRemoveInsideTest}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                        checked={this.state.canRemoveInsideTest}
                        onChange={(e) => this.handleCanRemoveInsideTest()}
                        color={'primary'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></td>
                    </tr>
                    <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>9</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsToOthersStudents}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                        checked={this.state.canSetInsideTestsToOthersStudents}
                        onChange={(e) => this.handleCanSetInsideTestsToOthersStudents()}
                        color={'primary'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></td>
                    </tr>
                    {(this.state.type === 'professional' || this.state.type === 'professionalteacher') ? (
                    <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>10</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsProfessional}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                        checked={this.state.canSetInsideTestsProfessional}
                        onChange={(e) => this.handleCanSetInsideTestsProfessional()}
                        color={'primary'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></td>
                    </tr>
                    ) : (
                      <tr>
                      {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>10</th> )}
                        <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsProfessional}</td>
                        <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>הרשאה רק למנהל מקצועי</td>
                      </tr>
                    )}
                    </React.Fragment>
                  )}




                  {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === 'professionalteacher') ? (
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>11</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.automaticStudentApproval}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.automaticStudentApproval}
                      onChange={(e) => this.handleChangeASA()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  ) : (
                    <tr>
                    {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>11</th> )}
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.automaticStudentApproval}</td>
                      <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>הרשאה רק למורה/מנהל מקצועי</td>
                    </tr>
                  )}
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>12</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectCash}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canCollectCash}
                      onChange={(e) => this.handleCanCollectCash()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>13</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectCheque}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canCollectCheque}
                      onChange={(e) => this.handleCanCollectCheque()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>14</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectBankTransfer}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canCollectBankTransfer}
                      onChange={(e) => this.handleCanCollectBankTransfer()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>15</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectApplication}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canCollectApplication}
                      onChange={(e) => this.handleCanCollectApplication()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>16</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectTranzila}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canCollectTranzila}
                      onChange={(e) => this.handleCanCollectTranzila()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>17</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectBarter}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canCollectBarter}
                      onChange={(e) => this.handleCanCollectBarter()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>18</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canDeposit}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canDeposit}
                      onChange={(e) => this.handleCanDeposit()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>19</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canWithdrawMoney.replace('? לחצו לשינוי', '')}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canWithdrawMoney}
                      onChange={(e) => this.handleCanWithdrawMoney()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>20</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canDiscoverPayments}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canDiscoverPayments}
                      onChange={(e) => this.handleCanDiscoverPayments()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>21</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSeeTeachersCashRegister}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canSeeTeachersCashRegister}
                      onChange={(e) => this.handleCanSeeTeachersCashRegister()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>21</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{'האם יכול לצפות בהכנסות מורים'}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canSeeTeachersIncome}
                      onChange={(e) => this.handleCanSeeTeachersIncome()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>21</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{'הודעה למורה על פתיחת תלמיד חדש'}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.shouldRecieveMessageOnNewStudent}
                      onChange={(e) => this.handleShouldRecieveMessageOnNewStudent()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>22</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canEditInsideTests}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canEditInsideTests}
                      onChange={(e) => this.handleCanEditInsideTests()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>23</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canRefund}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canRefund}
                      onChange={(e) => this.handleCanRefund()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>24</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSeePtorsGiven}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canSeePtorsGiven}
                      onChange={(e) => this.handleCanSeePtorsGiven()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                  <tr>
                  {this.state.displayPermissionsNumberTesting && ( <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>25</th> )}
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canDoInsideTestsToMyStudents}</td>
                    <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}><Checkbox
                      checked={this.state.canDoInsideTestsToMyStudents}
                      onChange={(e) => this.handleCanDoInsideTestsToMyStudents()}
                      color={'primary'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                  </tr>
                </table>
                </React.Fragment>
              ) : (
                <Button onClick={(e) => this.setState({showPermissionsList: true})} variant={'contained'} color={'primary'}>הרחב רשימת הרשאות</Button>
              )}

              {!this.state.isClerk && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.type}</p>
                <Select
                  value={this.state.type}
                  onChange={(e) => this.setState({type: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={'teacher'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.teacher}</MenuItem>
                  <MenuItem value={'professional'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.professional}</MenuItem>
                  <MenuItem value={'professionalteacher'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.professionalteacher}</MenuItem>
                  <MenuItem value={'cpe'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.cpe}</MenuItem>
                </Select>
                </React.Fragment>
              )}

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.firstname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.lastname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                required
              />

              {this.state.type !== 'cpe' && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}>{strings.idnumber}</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.idNumber}
                  onChange={(e) => this.setState({idNumber: e.target.value})}
                />
                </React.Fragment>
              )}
              {this.state.emailTaken && (<p style={{textAlign: 'right', color: 'red'}}>כתובת האימייל תפוסה, אנא בחרו אחת אחרת</p>)}

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.email}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setEmail(e.target.value)}
                required
              />

              <p style={{textAlign: 'right'}}>{strings.phonenumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.canAddClerk}</p>
              <Select
                value={this.state.canAddClerk}
                onChange={(e) => this.setState({canAddClerk: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
              </Select>

            {(!this.state.isClerk && this.state.type !== 'cpe') && (
              <React.Fragment>


                {this.state.type == 'professional' && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}>{strings.professionalnumber}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.professionalTeacherOfficialNumber}
                    onChange={(e) => this.setState({professionalTeacherOfficialNumber: e.target.value})}
                  />
                  </React.Fragment>
                )}

                {this.state.haveInsideTests && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.signuppaymentcoststudent}</p>
                  <Input
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    startAdornment={<span>₪</span>}
                    type='number'
                    value={this.state.insideTestPrice}
                    onChange={(e) => this.setState({insideTestPrice: e.target.value})}
                  />
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.insidetestpaymentcoststudent}</p>
                  <Input
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    startAdornment={<span>₪</span>}
                    type='number'
                    value={this.state.insideTestPriceGiven}
                    onChange={(e) => this.setState({insideTestPriceGiven: e.target.value})}
                  />
                  </React.Fragment>
                )}

                {this.state.haveSignUpFee && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.signuppaymentcostteacher}</p>
                  <Input
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    startAdornment={<span>₪</span>}
                    type='number'
                    value={this.state.signupPrice}
                    onChange={(e) => this.setState({signupPrice: e.target.value})}
                  />
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.insidetestpaymentcostteacher}</p>
                  <Input
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    startAdornment={<span>₪</span>}
                    type='number'
                    value={this.state.signupPriceGiven}
                    onChange={(e) => this.setState({signupPriceGiven: e.target.value})}
                  />
                  </React.Fragment>
                )}


                </React.Fragment>
              )}

              {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === "professionalteacher") && (
                <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.ispartner}</p>
                  <Select
                    value={this.state.isPartner}
                    onChange={(e) => this.setState({isPartner: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                    <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                  </Select>
                </React.Fragment>
              )}

              {this.state.isPartner && (
                <React.Fragment>
                  <p style={{textAlign: 'right'}}>{strings.signuppaymentnumberofigrot}</p>
                  <Input
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    startAdornment={<span>₪</span>}
                    type='number'
                    value={this.state.signupNumberOfFeesPerMonth}
                    onChange={(e) => this.setState({signupNumberOfFeesPerMonth: e.target.value})}
                  />

                  <p style={{textAlign: 'right'}}>{strings.insidetestpaymentnumberofigrot}</p>
                  <Input
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    startAdornment={<span>₪</span>}
                    type='number'
                    value={this.state.insideTestNumberOfFeesPerMonth}
                    onChange={(e) => this.setState({insideTestNumberOfFeesPerMonth: e.target.value})}
                  />
                </React.Fragment>
              )}

              {this.state.isClerk && (
                <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.canAddEmployees}</p>
                  <Select
                    value={this.state.canAddEmployees}
                    onChange={(e) => this.setState({canAddEmployees: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                    <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                  </Select>
                </React.Fragment>
              )}

              <p style={{textAlign: 'right'}}>{strings.password}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='password'
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />

              {(!this.state.isClerk && this.state.type !== 'cpe') && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.isGizbar}</p>
                <Select
                  value={this.state.isGizbar}
                  onChange={(e) => this.setState({isGizbar: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                  <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                </Select>
                </React.Fragment>
              )}




              {(!this.state.isClerk && this.state.type !== 'cpe') && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.allowedlicensetypelearn}</p>

                  <table style={{
                    borderCollpase: 'collapse',
                  }}>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('A')}
                        onChange={(value) => this.addAllowedLicenseType('A')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA}</th>
                    </tr>

                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('A1')}
                        onChange={(value) => this.addAllowedLicenseType('A1')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA1}</th>
                    </tr>

                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('A2')}
                        onChange={(value) => this.addAllowedLicenseType('A2')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA2}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('B_manual')}
                        onChange={(value) => this.addAllowedLicenseType('B_manual')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeB}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('B_automate')}
                        onChange={(value) => this.addAllowedLicenseType('B_automate')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeB_}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('C')}
                        onChange={(value) => this.addAllowedLicenseType('C')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeC}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('C1')}
                        onChange={(value) => this.addAllowedLicenseType('C1')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeC1}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('C+E')}
                        onChange={(value) => this.addAllowedLicenseType('C+E')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeCA}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('D')}
                        onChange={(value) => this.addAllowedLicenseType('D')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeD}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('D1')}
                        onChange={(value) => this.addAllowedLicenseType('D1')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeD1}</th>
                    </tr>
                  </table>

                </React.Fragment>
              )}

              {this.state.type === 'cpe' && (
                <React.Fragment>
                  <p style={{textAlign: 'right'}}>{strings.officename}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.CPEofficename}
                    onChange={(e) => this.setState({CPEofficename: e.target.value})}
                  />
                  <p style={{textAlign: 'right'}}>{strings.fax}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.CPEfax}
                    onChange={(e) => this.setState({CPEfax: e.target.value})}
                  />
                  <p style={{textAlign: 'right'}}>{strings.cpeaddress}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.CPEaddress}
                    onChange={(e) => this.setState({CPEaddress: e.target.value})}
                  />
                  <p style={{textAlign: 'right'}}>{strings.cpepostalcode}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.CPEpostalcode}
                    onChange={(e) => this.setState({CPEpostalcode: e.target.value})}
                  />
                </React.Fragment>
              )}

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> עמלת מבחן פנימי (מספר) - מורה מקצועי בלבד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.insideTestCommission}
                onChange={(e) => this.setState({insideTestCommission: e.target.value})}
                required
              />

              <Button onClick={(e) => this.updateUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditEmployee;
