import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class TeacherDashboard extends Component {
  componentDidMount() {
    this.getData();
    this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],
     payments: [],
     paymentsOriginal: [],

     lastSeen: '',
     teacher: {
       firstName: '',
       lastName: '',
       cashRegisterCommission: 0
     },
     students: [],
     studentsApproval: [],
     language: 'he',
     shareLinkView: 'signupFee',
     search_query: '',

     messages: [],

     tickerMove: true,
     showTicker: true,
     payment_string: '',
     displayPermissionsNumberTesting: false,

     canSeeCommission: false,
     canSeeInsideTests: false,
     canSellItems: false,
     canTransferStudent: false,
     canGivePtorSignup: false,
     canGivePtorInsideTest: false,
     canSetInsideTestsToOthers: false,
     canRemoveInsideTest: false,
     canSetInsideTestsToOthersStudents: false,
     canSetInsideTestsProfessional: false,
     automaticStudentApproval: false,
     canCollectCash: false,
     canCollectCheque: false,
     canCollectBankTransfer: false,
     canCollectApplication: false,
     canCollectTranzila: false,
     canCollectTranzila: false,
     canCollectBarter: false,
     canDeposit: false,
     canWithdrawMoney: false,
     haveInsideTests: false,
     haveSignUpFee: false,
     notFound: false,
     studentsOriginal: [],
     paymentsOriginal: [],
     passedtest_students: [],
     table_load: false,
     haveStore: false,
     page: 1
   }
  }

  getData = async () => {
    this.setState({table_load: true}, () => {
      axios.get(API + 'teacher', {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response.data.data.teacher);
        if (response.data.status === "ok") {
          this.setState({
            teacher: response.data.data.teacher,
            students: response.data.data.students.reverse(),
            payments: response.data.data.payments.reverse(),
            paymentsOriginal: response.data.data.payments,
            lastSeen: response.data.lastSeen,
            displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
            haveInsideTests: response.data.haveInsideTests,
            haveSignUpFee: response.data.haveSignUpFee,
            haveStore: response.data.haveStore,
          }, () => {
            let students = [];
            let studentsApproval = [];
            let passedtest_students = [];

            this.state.students.forEach((item, i) => {
              if (item.approved === "false" || !item.approved) studentsApproval.push(item);
              else  {
                if (item.passedtest) passedtest_students.push(item);
                else students.push(item);
              }
            });

            this.setState({
              students: students,
              passedtest_students: passedtest_students,
              studentsOriginal: students,
              studentsApproval: studentsApproval,
              canSeeCommission: this.state.teacher.canSeeCommission,
              canSeeInsideTests: this.state.teacher.canSeeInsideTests,
              canSellItems: this.state.teacher.canSellItems,
              canTransferStudent: this.state.teacher.canTransferStudent,
              canGivePtorSignup: this.state.teacher.canGivePtorSignup,
              canGivePtorInsideTest: this.state.teacher.canGivePtorInsideTest,
              canSetInsideTestsToOthers: this.state.teacher.canSetInsideTestsToOthers,
              canRemoveInsideTest: this.state.teacher.canRemoveInsideTest,
              canSetInsideTestsToOthersStudents: this.state.teacher.canSetInsideTestsToOthersStudents,
              canSetInsideTestsProfessional: this.state.teacher.canSetInsideTestsProfessional,
              automaticStudentApproval: this.state.teacher.automaticStudentApproval,
              canCollectCash: this.state.teacher.canCollectCash,
              canCollectCheque: this.state.teacher.canCollectCheque,
              canCollectBankTransfer: this.state.teacher.canCollectBankTransfer,
              canCollectApplication: this.state.teacher.canCollectApplication,
              canCollectTranzila: this.state.teacher.canCollectTranzila,
              canCollectTranzila: this.state.teacher.canCollectTranzila,
              canCollectBarter: this.state.teacher.canCollectBarter,
              canDeposit: this.state.teacher.canDeposit,
              canWithdrawMoney: this.state.teacher.canWithdrawMoney,
            }, () => {
              this.setState({table_load: false})
            });
          });
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });

  }

  searchLive = async (string) => {
    this.setState({search_query: string}, () => {
      let students = this.state.studentsOriginal;
      let output = [];
      students.forEach((item, i) => {
        if (
          item.firstName.includes(string) ||
          string.includes(item.firstName) ||
          item.lastName.includes(string) ||
          string.includes(item.lastName) ||
          item.idNumber.includes(string) ||
          string.includes(item.idNumber)
        ) {
          output.push(item);
        }
      });

      // if (output.length > 0) {
        this.setState({students: output});
      // } else {
      //   this.setState({students: this.state.studentsOriginal});
      // }
    });
  }

  approveStudentRequest = (id) => {
    axios.put(API + 'teacher/student/approve?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  searchPaymentLive = (string) => {
    this.setState({payment_string: string}, () => {
      let payments = this.state.paymentsOriginal;
      let output = [];

      payments.forEach((item, i) => {
        if (
          string.includes(item.studentName) ||
          item.studentName.includes(string) ||
          string.includes(item.amountWithVAT) ||
          item.amountWithVAT.toString().includes(string)
        ) {
          output.push(item);
        }
      });

      // if (output.length > 0) {
        this.setState({payments: output, page: 1})
      // } else {
      //   this.setState({payments: this.state.paymentsOriginal});
      // }
    });
  }

  removeStudentRequest = (id) => {
    axios.delete(API + 'teacher/student?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }


  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let msgs = response.data.data.messages;
        this.setState({messages: msgs})
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  searchPayment = () => {
    let payments = this.state.payments;
    let string = this.state.payment_string;
    let output = [];

    payments.forEach((payment) => {
      if (payment.studentName.includes(string)) output.push(payment);
    });

    // if (output.length > 0) {
      this.setState({payments: output});
    // } else {
    //   this.setState({notFound: true});
    // }
  }

  displayPermissionsNumberTestingDashboard = () => {
    let displayPermissionsNumberTesting = this.state.displayPermissionsNumberTesting;

    if (displayPermissionsNumberTesting) {
      return (
        <Grid item xs={12}>
          <center>
          <React.Fragment>
          <table style={{
            borderCollpase: 'collapse',
            direction: 'rtl'
          }}>
            <tr>
              <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>הרשאה</th>
              <th style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>סטטוס</th>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSeeCommission}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canSeeCommission ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSeeInsideTests}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canSeeInsideTests ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSellItems}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canSellItems? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canTransferStudent}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canTransferStudent ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canGivePtorSignup}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canGivePtorSignup ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canGivePtorInsideTest}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canGivePtorInsideTest? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsToOthers}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canSetInsideTestsToOthers? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canRemoveInsideTest}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canRemoveInsideTest? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsToOthersStudents}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canSetInsideTestsToOthersStudents ? '✔️' : 'X'}</td>
            </tr>
            {(this.state.type === 'professional' || this.state.type === 'professionalteacher') && (
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canSetInsideTestsProfessional}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canSetInsideTestsProfessional ? '✔️' : 'X'}</td>
            </tr>
            )}
            {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === 'professionalteacher') && (
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.automaticStudentApproval}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.automaticStudentApproval ? '✔️' : 'X'}</td>
            </tr>
            )}
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectCash}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectCash ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectCheque}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectCheque ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectBankTransfer}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectBankTransfer ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectApplication}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectApplication ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectTranzila}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectTranzila ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectTranzila}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectTranzila ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canCollectBarter}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canCollectBarter ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canDeposit}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canDeposit ? '✔️' : 'X'}</td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{strings.canWithdrawMoney.replace('? לחצו לשינוי', '')}</td>
              <td style={{border: '1px solid black', padding: 8, textAlign: 'right'}}>{this.state.canWithdrawMoney ? '✔️' : 'X'}</td>
            </tr>
          </table>
          </React.Fragment>
          </center>
        </Grid>
      );
    }
  }




    changePassedExternalTest = (id, status) => {
      if (status) {
        axios.put(API + 'teacher/student-change-passedexternalTest-false?id='+id, {}, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          // console.log(response);
          if (response.data.status === "ok") {
            this.getData();
          } else {
            alert(strings.errorPleaseTryAgain);
          }
        })
        .catch(error => {
          console.log(error);
          alert(strings.errorPleaseTryAgain);
        });
      } else {
        axios.put(API + 'teacher/student-change-passedexternalTest-true?id='+id, {}, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          // console.log(response);
          if (response.data.status === "ok") {
            this.getData();
          } else {
            alert(strings.errorPleaseTryAgain);
          }
        })
        .catch(error => {
          console.log(error);
          alert(strings.errorPleaseTryAgain);
        });
      }

    }

    changeActive = (id) => {
      axios.put(API + 'teacher/student-change-active?id='+id, {}, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        // console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    }


      filter = (type = 'all') => {
        this.setState({filter: type, page: 1}, () => {
          if (type === 'all') {
            let students = this.state.studentsOriginal;
            let output = [];

            students.forEach((item, i) => {
              output.push(item);
            });

            this.setState({students: output})
          } else if (type === 'not_paid_signup') {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if ((item.paidSignupFee === 'yes')) output.push(item);
            });

            this.setState({students: output});
          } else if (type === 'not_paid_insidetest') {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if ((item.paidInsideTestFee === 'no' || item.paidInsideTestFee === '')) output.push(item);
            });

            this.setState({students: output});
          } else if (type === 'not_paid_nothing') {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if (((item.paidSignupFee === 'no' || item.paidSignupFee === '') && (item.paidInsideTestFee === 'no' || item.paidInsideTestFee === ''))) output.push(item);
            });

            this.setState({students: output});
          } else if (type === 'passed_test_search') {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if (item.passedexternalTest) output.push(item);
            });

            this.setState({students: output});
          } else if (type === 'active_search') {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if (!item.active) output.push(item);
            });

            this.setState({students: output});
          } else if (type === 'passed_internal_test') {
            let students = this.state.passedtest_students;
            this.setState({students: students});
          } else if (type === 'not_signed_in_brosh') {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if (!item.didSignedWithBrosh) output.push(item);
            });

            this.setState({students: output});
          } else if (type === "signed_in_brosh_and_not_payed_signup_fee") {
            let students = this.state.studentsOriginal;
            let output = [];
            students.forEach((item, i) => {
              if (!item.didSignedWithBrosh) {
                if ((item.paidSignupFee === 'yes')) output.push(item);
              }
            });

            this.setState({students: output});
          } else {
            let students = this.state.studentsOriginal;
            let output = [];

            students.forEach((item, i) => {
               output.push(item);
            });

            this.setState({students: output})
          }

        });
      }

      showMoreRecords = () => {
        let page = this.state.page;
        const perpage = 5;

        this.setState({page: page + 1});
      }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>

        {this.displayPermissionsNumberTestingDashboard()}

        <Grid item xs={12}>

          <center><br />
          <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <CardContent>
            <div>
            {this.state.showTicker && (
              <div class="ticker-wrap" onClick={(e) => this.setState({showTicker: false})}>
                <div class="ticker" onClick={(e) => this.setState({showTicker: false})}>
                {this.state.messages.map((index, i) => {
                  if (i === 0) {
                    return (
                      <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                        <span onClick={(e) => this.setState({showTicker: false})}> {index.message} </span>
                      </div>
                    )
                  } else {
                    return (
                      <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                        <span onClick={(e) => this.setState({showTicker: false})}>  {index.message} |&nbsp;  </span>
                      </div>
                    )
                  }
                })}
                </div>
              </div>
            )}
            </div>
            {this.state.haveStore && (
              <React.Fragment>
                <Link
                  to={'/store/slogan'}
                  style={{textDecoration: 'none'}}>
                    <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                      ערוך את סלוגן החנות שלך
                    </Button>
                </Link>
              </React.Fragment>
            )}

            {this.state.teacher.canSeeKupotMorim && (
              <>
              <Link
              to={'/commission-table'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    קופות עמלות מורים ותשלומים
                  </Button>
              </Link><br />
              </>
            )}

              <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1.2em'}}>{this.state.teacher.firstName + ' ' + this.state.teacher.lastName} <AssignmentIndIcon style={{width: 32, height: 32}}/></p>
              {this.state.teacher.canSeeCommission && (
                <p style={{fontWeight: 'bold', color: '#32CD32', fontSize: '1.4em'}}>{strings.mycommissioncashregister} ₪{parseFloat(this.state.teacher.cashRegisterCommission).toFixed(2)}</p>
              )}


              {(this.state.teacher.type === 'teacher' || this.state.teacher.type === 'professionalteacher') && (
                <React.Fragment>
                {isMobile ? (
                  <Link to='/student/add' style={{textDecoration: 'none'}}>
                    <IconButton alt="add-teacher">
                      <PersonAddIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                    </IconButton>
                  </Link>
                ) : (
                  <Link to='/student/add' style={{textDecoration: 'none'}}>
                    <IconButton alt="add-teacher">
                      <PersonAddIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                    </IconButton>
                  </Link>
                )}
                <br />

                {this.state.haveSignUpFee && (
                  <React.Fragment>
                  {isMobile ? (
                    <Link style={{textDecoration: 'none'}}>
                      <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'signup?id='+this.state.teacher._id)}>
                        <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                      </IconButton>
                    </Link>
                  ) : (
                    <Link style={{textDecoration: 'none'}}>
                    <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'signup?id='+this.state.teacher._id)}>
                        <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                      </IconButton>
                    </Link>
                  )}
                  </React.Fragment>
                )}
                </React.Fragment>
              )}



              {!isMobile && ( <br /> )}

              {isMobile ? (
                <React.Fragment>
                <hr style={{maxWidth: '90%'}}/>

                <Link to='/payment' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-payment">
                    <AccountBalanceIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                  </IconButton>
                </Link>

                {/*  {(this.state.haveSignUpFee && this.state.haveInsideTests) && (
                  <React.Fragment>
                  <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>

                  <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>
                    <Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>
                    </ButtonGroup>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher.schoolId+'&tid='+this.state.teacher._id+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  </React.Fragment>
                )}

                {(this.state.haveSignUpFee && !this.state.haveInsideTests) && (
                  <React.Fragment>
                  <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                  {/*<ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>
                  </ButtonGroup>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher.schoolId+'&tid='+this.state.teacher._id+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  </React.Fragment>
                )}

                {(!this.state.haveSignUpFee && this.state.haveInsideTests) && (
                  <React.Fragment>
                  <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                  <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>
                  </ButtonGroup>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  </React.Fragment>
                )}*/}

                <React.Fragment>
                <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                    <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                </IconButton>
                </React.Fragment>

                </React.Fragment>
              ) : (
                <div style={{border: '1px solid #717171', maxWidth: '30%'}}>
                <Link to='/payment' style={{textDecoration: 'none'}}>
                <IconButton alt="add-payment">
                    <AccountBalanceIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                  </IconButton>
                </Link>

                {/*  {(this.state.haveSignUpFee && this.state.haveInsideTests) && (
                  <React.Fragment>
                  <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>

                  <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>
                    <Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>
                    </ButtonGroup>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher.schoolId+'&tid='+this.state.teacher._id+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  </React.Fragment>
                )}

                {(this.state.haveSignUpFee && !this.state.haveInsideTests) && (
                  <React.Fragment>
                  <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                  {/*<ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>
                  </ButtonGroup>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher.schoolId+'&tid='+this.state.teacher._id+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  </React.Fragment>
                )}

                {(!this.state.haveSignUpFee && this.state.haveInsideTests) && (
                  <React.Fragment>
                  <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                  <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>
                  </ButtonGroup>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id+'&pf='+(this.state.shareLinkView === 'signupFee' ? 'suf' : 'itf'))}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton>
                  </React.Fragment>
                )}*/}

                <React.Fragment>
                <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                    <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                </IconButton>
                </React.Fragment>
                </div>
              )}

              {this.state.teacher.canGeneratePaymentDocument && (
                <React.Fragment>
                  <Link
                    to={'/payment-document'}
                    style={{textDecoration: 'none'}}>
                      <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                        הפקדת דו"ח תשלומים תקופתי
                      </Button>
                  </Link>
                </React.Fragment>
              )}


              {this.state.haveInsideTests && (
                <React.Fragment>

                {(this.state.teacher.type === 'professional' || this.state.teacher.type === 'professionalteacher') && (
                  <React.Fragment>
                    <br />
                    <Link to='/calendar/tests' style={{textDecoration: 'none'}}><Button variant={'contained'} color='primary'>
                      לוח המבחנים הפנימיים שלי
                    </Button></Link>
                    <br />
                  </React.Fragment>
                )}

                {((this.state.teacher.type === 'professional' || this.state.teacher.type === 'professionalteacher') && this.state.teacher.canSetInsideTestsProfessional) && (
                  <React.Fragment>
                    <br />
                    <Link to='/calendar/student/set-test-self' style={{textDecoration: 'none'}}><Button variant={'contained'} color='primary'>
                      קבע מבחן פנימי
                    </Button></Link>
                    <br />
                  </React.Fragment>
                )}

                {((this.state.teacher.type === 'professional' || this.state.teacher.type === 'professionalteacher') && this.state.teacher.canSetInsideTestsToOthers) && (
                  <React.Fragment>
                    <br />
                    <Link to='/calendar/students/set-test' style={{textDecoration: 'none'}}><Button variant={'contained'} color='primary'>
                      קבע מבחן פנימי למורה/מנהל מקצועי אחר
                    </Button></Link>
                    <br />
                  </React.Fragment>
                )}

                {(this.state.teacher.type === 'teacher') && (
                  <React.Fragment>
                    <br />
                    <Link to='/calendar/students/set-test' style={{textDecoration: 'none'}}><Button variant={'contained'} color='primary'>
                      קבע מבחן פנימי לתלמיד
                    </Button></Link>
                  </React.Fragment>
                )}

                {(this.state.teacher.type === 'teacher' || this.state.teacher.type === 'professionalteacher') && (
                  <React.Fragment>
                    <br /><br />
                    <Link to='/calendar/students' style={{textDecoration: 'none'}}><Button variant={'outlined'} color=''>
                      לוח המבחנים הפנימיים של התלמידים שלי
                    </Button></Link>
                  </React.Fragment>
                )}

                </React.Fragment>
              )}

              {this.state.teacher.isGizbar && (
                <React.Fragment>
                <br /><br />
                <Link
                  to={'/refunds'}
                  style={{textDecoration: 'none'}}>
                    <Button variant='contained' color={'primary'} style={{}}>
                      {strings.refunds}
                    </Button>
                </Link>
                </React.Fragment>
              )}


              {this.state.teacher.smallcashregisterIn && (
                <React.Fragment>
                <br /><br />
                <Link to={'/small-cashregister-in'} style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><DescriptionIcon style={{color: 'white'}} />
                    הפקדת כסף לקופה קטנה
                  </Button>
                </Link>
                <br />
                </React.Fragment>
              )}
              {this.state.teacher.smallcashregisterOut && (
                <React.Fragment>
                <Link to={'/small-cashregister'} style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>
                    <DescriptionIcon style={{color: 'white'}} />
                      רישום תשלום יוצא לקופה קטנה
                    </Button>
                  </Link>
                <br />
                </React.Fragment>
              )}

              {this.state.teacher.canWithdrawMoney && (
                <React.Fragment>
                {this.state.teacher.isGizbar ? (
                  <React.Fragment>
                    <br /><br />
                    <Link to={'/transfer-money'} style={{textDecoration: 'none'}}><Button variant='outlined'><AccountBalanceIcon style={{color: '#404041'}} />{strings.mycashregisterandtransfermoneyasgizbar}</Button></Link>
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <br /><br />
                    <Link to={'/transfer-money-teacher'} style={{textDecoration: 'none'}}><Button variant='outlined'><AccountBalanceIcon style={{color: '#404041'}} />{strings.mycashregisterandtransfermoney}</Button></Link>
                  </React.Fragment>
                )}
                </React.Fragment>
              )}


              {this.state.teacher.isGizbar && (
                <React.Fragment>
                <br />
                <Link to={'/generate-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 210}}><DescriptionIcon style={{color: 'white'}} />{strings.generateinvoices}</Button></Link>

                </React.Fragment>
              )}

              {this.state.teacher.canSeePtorsGiven && (
                <React.Fragment>
                <br />
                <Link to={'/ptors-given'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{'מעקב פטורים'}</Button></Link>
                </React.Fragment>
              )}

              {this.state.teacher.canSeeTeachersCashRegister && (
                <React.Fragment>
                <br />
                <Link to={'/cash-registers'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.canSeeTeachersCashRegister}</Button></Link>
                </React.Fragment>
              )}

              {this.state.teacher.canSellItems && (
                <React.Fragment>
                <br />
                <Link to={'/items'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.manageItems}</Button></Link>
                </React.Fragment>
              )}

              {this.state.teacher.canTransferStudent && (
                <React.Fragment>
                <br />
                <Link to={'/migrate'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.migrateAction}</Button></Link>
                </React.Fragment>
              )}

              {this.state.teacher.canDeposit && (
                <React.Fragment>
                <br />
                <Link to={'/deposit'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.deposit}</Button></Link>
                </React.Fragment>
              )}

              {this.state.teacher.canDiscoverPayments && (
                <React.Fragment>
                <br />
                <Link to={'/payments/discover'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.discoverPayments}</Button></Link>
                </React.Fragment>
              )}

              <br />
              <Link to={'/students-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{'חשבוניות תלמידים'}</Button></Link>
              <br />
            </CardContent>
          </Card>
         </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mystudents}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h4>{strings.searchstudentbynm}</h4>

            <Button variant={'contained'} onClick={(e) => this.setState({search_query: ''}, () => { this.getData() })}>איפוס</Button>
            &nbsp;&nbsp;&nbsp;
            {/*<Button variant={'contained'} color={'primary'} onClick={(e) => this.searchStudent()}>חיפוש</Button>
            &nbsp;&nbsp;&nbsp;*/}
            <TextField
              value={this.state.search_query}
              onChange={(e) => this.searchLive(e.target.value)}
              style={{
                textAlign: 'right',
                direction: 'rtl'
              }}
            />
            <br />
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        <br />
        <center>
          <h4>{'חפש על פי קבוצה'}</h4>
            <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
              <Button onClick={(e) => this.filter('signed_in_brosh_and_not_payed_signup_fee')} style={{color: this.state.filter === 'signed_in_brosh_and_not_payed_signup_fee' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'signed_in_brosh_and_not_payed_signup_fee' ? '#3f51b5' : 'transparent'}}>שילם דמי רישום ולא רשום בברוש</Button>
              {/*              <Button onClick={(e) => this.filter('not_signed_in_brosh')} style={{color: this.state.filter === 'not_signed_in_brosh' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'not_signed_in_brosh' ? '#3f51b5' : 'transparent'}}>לא רשום בברוש</Button>*/}
              <Button onClick={(e) => this.filter('passed_internal_test')} style={{color: this.state.filter === 'passed_internal_test' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'passed_internal_test' ? '#3f51b5' : 'transparent'}}>עבר מ.פ</Button>
              <Button onClick={(e) => this.filter('active_search')} style={{color: this.state.filter === 'active_search' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'active_search' ? '#3f51b5' : 'transparent'}}>הצג לא פעילים</Button>
              <Button onClick={(e) => this.filter('passed_test_search')} style={{color: this.state.filter === 'passed_test_search' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'passed_test_search' ? '#3f51b5' : 'transparent'}}>עבר טסט</Button>
              <Button onClick={(e) => this.filter('all')} style={{color: this.state.filter === 'all' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'all' ? '#3f51b5' : 'transparent'}}>הכל</Button>
            </ButtonGroup>
            <br />
          </center>
          {this.state.table_load ? (
            <>
              <center>
                <CircularProgress />
              </center>
            </>
          ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.edit}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>עבר \ לא עבר טסט</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>פעיל \ לא פעיל</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidinsidetestfee}</TableCell>

                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidsignupfee}</TableCell>

                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.students.slice(0, this.state.page * 5).map((student, i) => {
                  if (this.state.filter === "active_search") {
                    return (
                      <TableRow key={i+'_student'}>
                        <TableCell align="right">
                          <Link to={'/student/update?id='+student._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.teacher.changeStudentPassTest ? (
                            <Button onClick={(e) => this.changePassedExternalTest(student._id, student.passedexternalTest)}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                          ) : (
                            <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.teacher.changeStudentActive ? (
                            <Button onClick={(e) => this.changeActive(student._id)}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                          ) : (
                            <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                          )}
                        </TableCell>
                        <TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor + '('+student.paidInsideTestPtorReason+')' : strings.no)}</TableCell>

                        <TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor + '('+student.paidSignupFeePtorReason+')' : strings.no)}</TableCell>

                        <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                        <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                        <TableCell align="right">{student.phoneNumber}</TableCell>
                        <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                        <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                        <TableCell align="right">{student.firstName + ' ' + student.lastName + ' (' + student.idNumber +')'}</TableCell>
                      </TableRow>
                    )
                  } else {
                    if (student.active) {
                      return (
                        <TableRow key={i+'_student'}>
                          <TableCell align="right">
                            <Link to={'/student/update?id='+student._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                          </TableCell>
                          <TableCell align="right">
                            {this.state.teacher.changeStudentPassTest ? (
                              <Button onClick={(e) => this.changePassedExternalTest(student._id, student.passedexternalTest)}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                            ) : (
                              <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {this.state.teacher.changeStudentActive ? (
                              <Button onClick={(e) => this.changeActive(student._id)}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                            ) : (
                              <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                            )}
                          </TableCell>
                          <TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor + '('+student.paidInsideTestPtorReason+')' : strings.no)}</TableCell>

                          <TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor + '('+student.paidSignupFeePtorReason+')' : strings.no)}</TableCell>

                          <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                          <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                          <TableCell align="right">{student.phoneNumber}</TableCell>
                          <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                          <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                          <TableCell align="right">{student.firstName + ' ' + student.lastName + ' (' + student.idNumber +')'}</TableCell>
                        </TableRow>
                      )
                    }
                  }
                })}

                </TableBody>
                <TableFooter>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                  <div style={{textAlign: 'right'}}>
                    {((this.state.page * 5) > this.state.students.length || (this.state.students.length - (this.state.page * 5)) === 0) ? (
                      <p>ראיתם הכל</p>
                    ) : (
                      <Button style={{width: 400, fontSize: '1.3em', fontWeight: 'bold', marginRight: '-13%'}} color={'primary'} onClick={(e) => this.showMoreRecords()}>לחצו להצגת רשומות נוספות (נשארו להצגה {(this.state.students.length - (this.state.page * 5))})</Button>
                    )}
                  </div></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>

        {this.state.studentsApproval.length >= 1 && (
          <React.Fragment>
          <Grid item xs={12}>
            <br />
            <center>
              <h2>{strings.getexternalstudentlinktitle}</h2>
            </center>
          </Grid>
          <Grid item xs={12}>
          <center>
          {this.state.studentsApproval.length === 0 ? (
            <h4 style={{direction: 'rtl', color: 'grey'}}>
            {strings.nostudentstoapprove1}
            <br/>
            {strings.nostudentstoapprove2}
            </h4>
          ) : (
            <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.delete}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.approve}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.studentsApproval.reverse().map((student, i) => {
                    return (
                      <TableRow key={i+'_student'}>
                        <TableCell align="right">
                          <IconButton alt="edit-user" onClick={(e) => this.removeStudentRequest(student._id)}>
                            <DeleteForeverIcon style={{color: '#404041'}}/>
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton alt="edit-user" onClick={(e) => this.approveStudentRequest(student._id)}>
                            <CheckIcon style={{color: '#404041'}}/>
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">{student.status === 'passed_test' ? strings.passedtest : (student.status === 'active' ? strings.active : strings.inactive)}</TableCell>
                        <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                        <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                        <TableCell align="right">{student.phoneNumber}</TableCell>
                        <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                        <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                        <TableCell align="right">{student.firstName + ' ' + student.lastName + ' (' + student.idNumber +')'}</TableCell>
                        <TableCell align="right">{student.teacherName}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          </center>
          </Grid>
          </React.Fragment>
        )}

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mypayments}</h2>
            <h4 style={{marginTop: 0, marginBottom: 0}}>ה20 האחרונות</h4>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <React.Fragment>
          <Button variant={'contained'} onClick={(e) => this.setState({payment_string: ''}, () => { this.getData() })}>איפוס</Button>
          &nbsp;&nbsp;&nbsp;
          {/*<Button variant={'contained'} color={'primary'} onClick={(e) => this.searchPayment()}>חיפוש</Button>
          &nbsp;&nbsp;&nbsp;*/}
          <TextField
            value={this.state.payment_string}
            onChange={(e) => this.searchPaymentLive(e.target.value)}
            style={{
              textAlign: 'right',
              direction: 'rtl'
            }}
          />
          <br /><br />
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>חשבונית</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paymentType}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidfor}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.dateOfPayment}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.payments.slice(0, 20).map((payment, i) => {
                  if (!payment.isPtor) {
                    return (
                      <TableRow key={i+'_payment'}>
                        {payment.refunded ? (
                          <TableCell align="right"><Button onClick={(e) => window.open('https://sivan-school.com/pdfs/'+payment._id+'_refund.pdf', '_blank')}>צפה בחשבונית</Button></TableCell>
                        ) : (
                          <TableCell align="right"><Button onClick={(e) => window.open('https://sivan-school.com/pdfs/'+payment._id+'.pdf', '_blank')}>צפה בחשבונית</Button></TableCell>
                        )}
                        <TableCell align="right">{this.modifyPaymentType(payment)}</TableCell>
                        <TableCell align="right">{payment.amountWithVAT}</TableCell>
                        <TableCell align="right">{payment.description === 'insideTestFee' ? 'אגרת מבחן פנימי' : 'אגרת רישום'}</TableCell>
                        <TableCell align="right">{new Date(payment.dateOfPayment).getDate() + '/' + (new Date(payment.dateOfPayment).getMonth() + 1) + '/' + new Date(payment.dateOfPayment).getFullYear()}</TableCell>
                        <TableCell align="right">{payment.studentName}</TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          </React.Fragment>


        </center>
        </Grid>
        <Snackbar open={this.state.notFound} autoHideDuration={3000} onClose={() => this.setState({notFound: false})}>
          <Alert onClose={() => this.setState({notFound: false})} severity="warning">
          לא נמצאו רשומות חיפוש, אנא נסו בשנית
          </Alert>
        </Snackbar>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default TeacherDashboard;
