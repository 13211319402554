import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class PermissionsInsidetestSMS extends Component {
  componentDidMount() {
    this.getData();
    this.getTable();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],
     employeesOriginal: [],
     lastSeen: '',
     language: 'he',
     type: '',
     messages: [],
     tickerMove: true,
     showTicker: true,
     string: '',
     haveInsideTests: false,
     canManagerPayToEmployees: false,
     haveSignUpFee: false,
     notFound: false,
     haveStore: false,
     teachers: [],
     professionalteacher: [],
     edit: false,
     teacher: {}
   }
  }

  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let msgs = response.data.data.messages;

        this.setState({messages: msgs})
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          employeesOriginal: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
          haveSignUpFee: response.data.haveSignUpFee,
          haveInsideTests: response.data.haveInsideTests,
          canManagerPayToEmployees: response.data.canManagerPayToEmployees,
          haveStore: response.data.haveStore,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getTable = async () => {
    axios.get(API + 'manager/inside-test-sms-linkage-permissions-table', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers: response.data.data.teachers,
          professionalteacher: response.data.data.professionalteacher,
        }, () => {
          if (this.state.edit) {
            this.state.teachers.map((index) => {
              if (index._id.toString() === this.state.teacher._id.toString()) this.setState({teacher: index});
            })
          }
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }


  search = (string) => {
  let employees = this.state.employeesOriginal;
  let output = [];

    employees.forEach((item, i) => {
      try {
        if (item.firstName.includes(string) || item.lastName.includes(string)) output.push(item);
        else if (item.idNumber.includes(string)) output.push(item);
        else if (item.schoolName.toString().includes(string)) output.push(item);
        else {

        }
      } catch (err) {
        // console.log(err);
      }
    });

    if (output.length > 0) {
      this.setState({employees: output});
    } else {
      this.setState({notFound: true});
    }
  }

  searchLive = (string) => {
    this.setState({string: string}, () => {
      let employees = this.state.employeesOriginal;
      let output = [];

        employees.forEach((item, i) => {
          try {
            if (item.firstName.includes(string) || item.lastName.includes(string)) output.push(item);
            else if (item.idNumber.includes(string)) output.push(item);
            else if (item.schoolName.toString().includes(string)) output.push(item);
            else {

            }
          } catch (err) {
            // console.log(err);
          }
        });

        // if (output.length > 0) {
          this.setState({employees: output});
        // } else {
        //   // this.setState({notFound: true});
        // }
    });
  }

  reset = () => {
    this.setState({employees: this.state.employeesOriginal, string: ''})
  }

  setEdit = (teacher) => {
    this.setState({
      edit: true,
      teacher: teacher,
    });
  }

  closeEdit = () => {
    this.setState({
      teacher: {},
      edit: false
    });
  }

  changeLinkage = (id) => {
    axios.put(API + 'manager/inside-test-sms-linkage?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getTable();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  addRemoveTeacher = (id) => {
    axios.put(API + 'manager/inside-test-sms-linkage-professional-teachers?id='+this.state.teacher._id+'&ptid='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getTable();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  render() {
    return (

      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginRight: '5%', marginTop: '2%'}}>
            <h2 style={{textAlign: 'right'}}>הרשאות שליחת מסרון לאחר תשלום מ.פ</h2>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginRight: '5%', marginTop: '2%', textAlign: 'right', direction: 'rtl'}}>

          {this.state.edit ? (
            <>
              <Button variant="contained" color="primary" onClick={(e) => this.closeEdit()}>סגירת עריכה</Button>
              <h2 style={{textAlign: 'right'}}>עריכת המורים המקצועיים של {this.state.teacher.firstName + ' ' + this.state.teacher.lastName + ' ('+this.state.teacher.idNumber+')'}</h2>
              <TableContainer component={Paper} style={{maxWidth: '85%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>שם המנהל המקצועי</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>ת.ז</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>ברשימה</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.professionalteacher.reverse().map((teacher, i) => {
                      let tta = this.state.teacher.insideTestSMSLinkageAllowedProfessionalTeachers;
                      let ttaP = [];
                      tta.forEach((item, i) => {
                        ttaP.push(item._id.toString());
                      });

                      return (
                        <TableRow key={i+'_tb'}>
                          <TableCell align="right">{teacher.firstName + ' ' + teacher.lastName}</TableCell>
                          <TableCell align="right">{teacher.idNumber}</TableCell>
                          <TableCell align="right">
                            <Checkbox
                              checked={ttaP.includes(teacher._id.toString())}
                              onChange={(e) => this.addRemoveTeacher(teacher._id.toString())}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
            <TableContainer component={Paper} style={{maxWidth: '85%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שם המורה</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>ת.ז</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>מספר טלפון</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>האם קיימת הרשאה</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>מנהלים מקצועיים מוגדרים</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>הגדרת מנהלים מקצועיים</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>הרשאה</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.teachers.reverse().map((teacher, i) => {
                    console.log(teacher);
                    return (
                      <TableRow key={i+'_tb'}>
                        <TableCell align="right">{teacher.firstName + ' ' + teacher.lastName}</TableCell>
                        <TableCell align="right">{teacher.idNumber}</TableCell>
                        <TableCell align="right">{teacher.phoneNumber}</TableCell>
                        <TableCell align="right">{teacher.insideTestSMSLinkage ? 'כן' : 'לא'}</TableCell>
                        <TableCell align="right">{teacher.insideTestSMSLinkageAllowedProfessionalTeachers.length >= 1 ? teacher.insideTestSMSLinkageAllowedProfessionalTeachers.map((item) => { return item.firstName + ' ' + item.lastName + ',' }) : 'לא מוגדרים מנהלים מקצועיים'}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" color="primary" onClick={(e) => this.setEdit(teacher)}>
                            עריכה
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            checked={teacher.insideTestSMSLinkage}
                            onChange={(e) => this.changeLinkage(teacher._id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </>
          )}


          </div>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default PermissionsInsidetestSMS;
