import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ReportsEmployees extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     calendar: {},
     sunday: true,
     monday: true,
     tuesday: true,
     wednesday: true,
     thursday: true,
     friday: true,
     saturday: true,
     employee: {name: ''}
   }
  }


  getData = async () => {
    const url = window.location.href;
    const id = url.split('?id=')[1];
    axios.get(API + 'manager/report/weekly-calendar?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          calendar: response.data.calendar,
          employee: response.data.employee,
          sunday: response.data.calendar.sunday,
          monday: response.data.calendar.monday,
          tuesday: response.data.calendar.tuesday,
          wednesday: response.data.calendar.wednesday,
          thursday: response.data.calendar.thursday,
          friday: response.data.calendar.friday,
          saturday: response.data.calendar.saturday,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  update = async () => {
    const url = window.location.href;
    const id = url.split('?id=')[1];
    axios.put(API + 'manager/report/weekly-calendar?id='+id, {
      sunday: this.state.sunday,
      monday: this.state.monday,
      tuesday: this.state.tuesday,
      wednesday: this.state.wednesday,
      thursday: this.state.thursday,
      friday: this.state.friday,
      saturday: this.state.saturday,
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>יומן שבועי - {this.state.employee.name}</h1>
              <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href='/reports/employees'}>חזרה למסך כל המשתמשים</Button>
              <br />
            </center>
          </Grid>

          <Grid item xs={12}>
            <center>
              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שבת</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שישי</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>חמישי</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>רביעי</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שלישי</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>שני</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>ראשון</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="right"><Button onClick={(e) => this.setState({saturday: this.state.saturday ? false : true}, () => { this.update() })}>{this.state.saturday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                    <TableCell align="right"><Button onClick={(e) => this.setState({friday: this.state.friday ? false : true}, () => { this.update() })}>{this.state.friday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                    <TableCell align="right"><Button onClick={(e) => this.setState({thursday: this.state.thursday ? false : true}, () => { this.update() })}>{this.state.thursday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                    <TableCell align="right"><Button onClick={(e) => this.setState({wednesday: this.state.wednesday ? false : true}, () => { this.update() })}>{this.state.wednesday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                    <TableCell align="right"><Button onClick={(e) => this.setState({tuesday: this.state.tuesday ? false : true}, () => { this.update() })}>{this.state.tuesday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                    <TableCell align="right"><Button onClick={(e) => this.setState({monday: this.state.monday ? false : true}, () => { this.update() })}>{this.state.monday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                    <TableCell align="right"><Button onClick={(e) => this.setState({sunday: this.state.sunday ? false : true}, () => { this.update() })}>{this.state.sunday ? "יום עבודה" : "יום חופש"}</Button></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </TableContainer>
            </center>
          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ReportsEmployees;
