import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class EmployeesList extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     employees: [],
     lastSeen: '',
     clerk: {
       firstName: '',
       lastName: ''
     },
     language: 'he'
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/manageable-employees', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          employees: response.data.data.employees,
          lastSeen: response.data.lastSeen,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>


        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.employeesinmyorg}</h2>
            <Link to={'/employee/add'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>{strings.addnewemployee}</Button></Link>
            <br /><br />
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.employees.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.employeesinmyorgstring1}
          <PersonAddIcon style={{width: 32, height: 32}}/><br/>
           {strings.employeesinmyorgstring2}
           </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.edit}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.idnumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.lastname}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.firstname}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.employees.map((employee, i) => {
                  return (
                    <TableRow key={i+'_student'}>
                      <TableCell align="right">
                        <Link to={'/employee/edit?id='+employee._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                      </TableCell>
                      <TableCell align="right">{new Date(employee.createdAt).getDate() + '/' + parseInt(new Date(employee.createdAt).getMonth() + 1) + '/' + new Date(employee.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{employee.idNumber}</TableCell>
                      <TableCell align="right">{employee.phoneNumber}</TableCell>
                      <TableCell align="right">{employee.lastName}</TableCell>
                      <TableCell align="right">{employee.firstName}</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>{employee.type === 'clerk' ? 'פקיד' : (employee.type === 'teacher' ? 'מורה' : (employee.type === 'professional' ? 'מנהל מקצועי' : 'מורה/מנהל מקצועי'))}</TableCell>
                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>

        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default EmployeesList;
