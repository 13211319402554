import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class DepositAccounts extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     accounts: [],
   }
  }

  getData = async () => {
    axios.get(API + 'manager/depositaccounts', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          accounts: response.data.data.depositaccounts,
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת רשימת החשבונות')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  createDepositAccount = () => {
    let payload = {
      bank: this.state.bankNumber,
      branch: this.state.branchNumber,
      account: this.state.bankAccountNumber
    }

    axios.post(API + 'manager/depositaccount', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({createDepositAccount: false, edit: false, bankNumber: '', branchNumber: '', account: ''})
        this.getData();
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

    editDepositAccount = (id) => {
      axios.put(API + 'manager/depositaccount?id='+this.state.edit_id, {
        bank: this.state.bankNumber,
        branch: this.state.branchNumber,
        account: this.state.bankAccountNumber
      }, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        if (response.data.status === 'ok') {
          this.setState({createDepositAccount: false, edit: false, bankNumber: '', branchNumber: '', account: ''})
          this.getData();
          this.setState({edit: false});
        }
      }).catch(err => {
        alert('שגיאה קבלת המידע')
      });

    }

    editDepositAccount_ = (id, bankNumber, branchNumber, bankAccountNumber) => {
      this.setState({
        edit_id: id,
        edit: true,
        bankNumber: bankNumber,
        branchNumber: branchNumber,
        bankAccountNumber: bankAccountNumber
      })
    }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.depositaccounts}</h2>

                <div>
                {this.state.edit && (
                  <React.Fragment>
                    <Button onClick={(e) => this.setState({edit: false, edit_description: '', edit_makat: '', edit_price: ''})} variant={'contained'} color={'primary'} style={{textAlign:'right'}}>הסתר יצירת פריט</Button>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                    <Select
                      value={this.state.bankNumber}
                      onChange={(e) => this.setState({bankNumber: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                    </Select>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                    <Input
                      value={this.state.branchNumber}
                      onChange={(e) => this.setState({branchNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                    <Input
                      value={this.state.bankAccountNumber}
                      onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <Button onClick={(e) => this.editDepositAccount()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                      {strings.edit}
                    </Button>
                    <br /><br /><br />
                  </React.Fragment>
                )}

                {this.state.createDepostAccountShow ? (
                  <React.Fragment>
                    <Button onClick={(e) => this.setState({createDepostAccountShow: false})} variant={'contained'} color={'primary'} style={{textAlign:'right'}}>הסתר יצירת פריט</Button>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                    <Select
                      value={this.state.bankNumber}
                      onChange={(e) => this.setState({bankNumber: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                    </Select>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                    <Input
                      value={this.state.branchNumber}
                      onChange={(e) => this.setState({branchNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                    <Input
                      value={this.state.bankAccountNumber}
                      onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <Button onClick={(e) => this.createDepositAccount()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                      {strings.create}
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button onClick={(e) => this.setState({createDepostAccountShow: true})} variant={'contained'} color={'primary'} style={{textAlign:'right'}}>צור חשבון חדש</Button>
                )}

                <br /><br />
                <table style={{borderCollapse: 'collapse'}}>
                  <tr style={{border: '1px solid black', padding: 8}}>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.edit}</th>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.amount}</th>

                    <th style={{border: '1px solid black', padding: 8}}>{strings.bankAccountNumber}</th>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.branchNumber}</th>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.bank}</th>
                  </tr>

                  {this.state.accounts.map((index) => {
                    return (
                      <tr style={{border: '1px solid black', padding: 8}}>
                        <th style={{border: '1px solid black', padding: 8}}><Button onClick={(e) => this.editDepositAccount_(index._id, index.bank, index.branch, index.account)}>{strings.edit}</Button></th>
                        <th style={{border: '1px solid black', padding: 8}}>₪{parseFloat(index.amountDeposited).toFixed()}</th>

                        <th style={{border: '1px solid black', padding: 8}}>{index.account}</th>
                        <th style={{border: '1px solid black', padding: 8}}>{index.branch}</th>
                        <th style={{border: '1px solid black', padding: 8}}>{index.bank}</th>
                      </tr>
                    );
                  })}
                </table>
                </div>
              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default DepositAccounts;
