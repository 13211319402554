import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class TeacherViewDashboard extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],
     payments: [],

     lastSeen: '',
     teacher: {
       firstName: '',
       lastName: '',
       cashRegisterCommission: 0
     },
     students: [],
     studentsApproval: [],
     language: 'he',
     shareLinkView: 'signupFee',
     haveSignUpFee: false,
     haveInsideTests: false
   }
  }

  getData = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teacher: response.data.data.teacher,
          students: response.data.data.students,
          payments: response.data.data.payments,
          lastSeen: response.data.lastSeen,
          haveSignUpFee: response.data.haveSignUpFee,
          haveInsideTests: response.data.haveInsideTests
        }, () => {
          let students = [];
          let studentsApproval = [];

          this.state.students.forEach((item, i) => {
            if (item.approved === "false" || !item.approved) studentsApproval.push(item);
            else students.push(item);
          });

          this.setState({
            students: students,
            studentsApproval: studentsApproval
          });
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  approveStudentRequest = (id) => {
    axios.put(API + 'teacher/student/approve?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  removeStudentRequest = (id) => {
    axios.delete(API + 'teacher/student?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }

  searchStudent = async () => {
    if (this.state.search_query !== '') {
      axios.get(API + 'manager/student/search?query='+this.state.search_query, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            students: response.data.data.students,
          }, () => {
            let students = [];
            let studentsApproval = [];

            this.state.students.forEach((item, i) => {
              if (item.approved === "false" || !item.approved) studentsApproval.push(item);
              else students.push(item);
            });

            if (students.length === 0) {
              alert('לא נמצאו תוצאות חיפוש');
              if (this.state.students.length === 0) {
                this.getData();
              }
            }
            else {
              this.setState({
                students: students,
                studentsApproval: studentsApproval
              });
            }
          });
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    } else {
      this.getData();
    }
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <center><br />
          <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <CardContent>
              <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1.2em'}}>{this.state.teacher.firstName + ' ' + this.state.teacher.lastName} <AssignmentIndIcon style={{width: 32, height: 32}}/></p>
              {/*<p style={{fontWeight: 'bold', color: '#32CD32', fontSize: '1.4em'}}>{strings.mycommissioncashregister} ₪{parseFloat(this.state.teacher.cashRegisterCommission).toFixed(2)}</p>*/}

              {(this.state.teacher.type === 'teacher' || this.state.teacher.type === 'professionalteacher') && (
                <React.Fragment>
                {isMobile ? (
                  <Link to={'/student/add?id='+this.state.teacher.schoolId} style={{textDecoration: 'none'}}>
                    <IconButton alt="add-teacher">
                      <PersonAddIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                    </IconButton>
                  </Link>
                ) : (
                  <Link to={'/student/add?id='+this.state.teacher.schoolId} style={{textDecoration: 'none'}}>
                    <IconButton alt="add-teacher">
                      <PersonAddIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                    </IconButton>
                  </Link>
                )}
                <br />
                {isMobile ? (
                  <Link style={{textDecoration: 'none'}}>
                    <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'signup?id='+this.state.teacher._id)}>
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                    </IconButton>
                  </Link>
                ) : (
                  <Link style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'signup?id='+this.state.teacher._id)}>
                      <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                    </IconButton>
                  </Link>
                )}
                </React.Fragment>
              )}



              {!isMobile && ( <br /> )}

              {isMobile ? (
                <React.Fragment>
                <hr style={{maxWidth: '90%'}}/>

                <Link to='/payment' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-payment">
                    <AccountBalanceIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                  </IconButton>
                </Link>
                {/*<p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>
                  <Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>
                </ButtonGroup>*/}
                <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                    <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                </IconButton>
                </React.Fragment>
              ) : (
                <div style={{border: '1px solid #717171', maxWidth: '30%'}}>
                <Link to={'/payment?id='+this.state.teacher.schoolId} style={{textDecoration: 'none'}}>
                <IconButton alt="add-payment">
                    <AccountBalanceIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                  </IconButton>
                </Link>

                {/*<p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  {this.state.haveSignUpFee && (<Button onClick={(e) => this.setState({shareLinkView: 'insideTestFee'})} style={{color: this.state.shareLinkView === 'insideTestFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'insideTestFee' ? '#3f51b5' : 'transparent'}}>אגרת מבחן פנימי</Button>)}
                  {this.state.haveInsideTests && (<Button onClick={(e) => this.setState({shareLinkView: 'signupFee'})} style={{color: this.state.shareLinkView === 'signupFee' ? 'white' : '#3f51b5', backgroundColor: this.state.shareLinkView === 'signupFee' ? '#3f51b5' : 'transparent'}}>אגרת רישום</Button>)}
                </ButtonGroup>*/}
                <IconButton alt="add-teacher" onClick={(e) => this.copyToClipboard(SITE+'payment?id='+this.state.teacher._id)}>
                    <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                </IconButton>
                </div>
              )}

              {(this.state.teacher.type === 'professional' || this.state.teacher.type === 'professionalteacher' && this.state.haveInsideTests) && (
                <React.Fragment>
                  <br />
                  <Link to='/calendar/tests' style={{textDecoration: 'none'}}><Button variant={'contained'} color='primary'>
                    לוח המבחנים הפנימיים שלי
                  </Button></Link>
                </React.Fragment>
              )}
              {(this.state.teacher.type === 'teacher' && this.state.haveInsideTests) && (
                <React.Fragment>
                  <br />
                  <Link to='/calendar/students/set-test' style={{textDecoration: 'none'}}><Button variant={'contained'} color='primary'>
                    קבע מבחן פנימי לתלמיד
                  </Button></Link>
                </React.Fragment>
              )}


              <React.Fragment>
                <br /><br />
                <Link to={'/transfer-money'} style={{textDecoration: 'none'}}><Button variant='outlined'><AccountBalanceIcon style={{color: '#404041'}} />{strings.mycashregisterandtransfermoney}</Button></Link>
              </React.Fragment>


              <React.Fragment>
              <br />
              <Link to={'/generate-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 210}}><DescriptionIcon style={{color: 'white'}} />{strings.generateinvoices}</Button></Link>

              </React.Fragment>

            </CardContent>
          </Card>
         </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mystudents}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <React.Fragment>
          <center>
            <h4>{strings.searchstudentbynm}</h4>

            <Button variant={'contained'} onClick={(e) => this.setState({search_query: ''}, () => {this.getData()})}>איפוס</Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant={'contained'} color={'primary'} onClick={(e) => this.searchStudent()}>חיפוש</Button>
            &nbsp;&nbsp;&nbsp;
            <TextField
              value={this.state.search_query}
              onChange={(e) => this.setState({search_query: e.target.value})}
              style={{
                textAlign: 'right',
                direction: 'rtl'
              }}
            />
            <br />
          </center>
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
          <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.edit}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.students.reverse().map((student, i) => {
                  return (
                    <TableRow key={i+'_student'}>
                      <TableCell align="right">
                        <Link to={'/student/update?id='+student._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                      </TableCell>
                      <TableCell align="right">{student.status === 'passed_test' ? strings.passedtest : (student.status === 'active' ? strings.active : strings.inactive)}</TableCell>
                      <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                      <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                      <TableCell align="right">{student.phoneNumber}</TableCell>
                      <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                      <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
          </React.Fragment>


        </center>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.getexternalstudentlinktitle}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.studentsApproval.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.nostudentstoapprove1}
          <br/>
          {strings.nostudentstoapprove2}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
          <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.delete}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.approve}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.studentsApproval.reverse().map((student, i) => {
                  return (
                    <TableRow key={i+'_student'}>
                      <TableCell align="right">
                        <IconButton alt="edit-user" onClick={(e) => this.removeStudentRequest(student._id)}>
                          <DeleteForeverIcon style={{color: '#404041'}}/>
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton alt="edit-user" onClick={(e) => this.approveStudentRequest(student._id)}>
                          <CheckIcon style={{color: '#404041'}}/>
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">{student.status === 'passed_test' ? strings.passedtest : (student.status === 'active' ? strings.active : strings.inactive)}</TableCell>
                      <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                      <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                      <TableCell align="right">{student.phoneNumber}</TableCell>
                      <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                      <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                      <TableCell align="right">{student.teacherName}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mypayments}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
          <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paymentType}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidfor}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.dateOfPayment}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.payments.reverse().map((payment, i) => {
                  if (!payment.isPtor) {
                    return (
                      <TableRow key={i+'_payment'}>
                        <TableCell align="right">{this.modifyPaymentType(payment)}</TableCell>
                        <TableCell align="right">{payment.amountWithVAT}</TableCell>
                        <TableCell align="right">{(payment.description === 'insideTestFee' || payment.description === 'signupFee') ? (payment.description === 'insideTestFee' ? 'אגרת מבחן פנימי' : 'אגרת רישום') : payment.description}</TableCell>
                        <TableCell align="right">{new Date(payment.dateOfPayment).getDate() + '/' + (new Date(payment.dateOfPayment).getMonth() + 1) + '/' + new Date(payment.dateOfPayment).getFullYear()}</TableCell>
                        <TableCell align="right">{payment.studentName}</TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>


        </center>
        </Grid>

        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default TeacherViewDashboard;
