import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Login extends Component {

  constructor(props) {
   super(props);
   this.state = {
     username: '',
     password: '',
     language: 'he',
     verifyCode: false,
     code: '',
     everyHourAutoLogout: false
   }
  }

  componentDidMount () {
    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  handleTextField = (value, type) => {
    if (type === 'username') this.setState({'username': value});
    else this.setState({'password': value});
  }


  login = async () => {
    if (this.state.username !== '' && this.state.password !== '') {
      axios.post(API + 'login', {
        email: this.state.username,
        password: this.state.password
      })
      .then((response) => {
        console.log(response.data.status);
        console.log(response.data.data.type);
        if (response.data.status === "ok") {
          this.setState({everyHourAutoLogout: response.data.data.everyHourAutoLogout});

          if (response.data.data.type === 'clerk' && response.data.data.requestSMSVerification) {
            this.setState({verificationCode: true});
          } else {
            if (response.data.data.everyHourAutoLogout) {
              var today = new Date();
              today.setHours(today.getHours() + 1);
              cookies.set('token___school', response.data.data.token, { path: '/', expires: new Date(today) });
              window.location.reload();
            } else {
              cookies.set('token___school', response.data.data.token, { path: '/' });
              window.location.reload();
            }

          }
        } else {
          alert('השם או הסיסמה אינם נכונים');
        }
      })
      .catch((err) => {
        console.log(err);
        alert('השם או הסיסמה אינם נכונים');
      });
    } else {
        alert('השם או הסיסמה אינם נכונים');
    }
  }

  verifyCode = async () => {
    if (this.state.username !== '' && this.state.password !== '') {
      axios.post(API + 'verify-code', {
        email: this.state.username,
        code: this.state.code
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          if (this.state.everyHourAutoLogout) {
            var today = new Date();
            today.setHours(today.getHours() + 1);
            cookies.set('token___school', response.data.data.token, { path: '/', expires: new Date(today) });
            window.location.reload();
          } else {
            cookies.set('token___school', response.data.data.token, { path: '/' });
            window.location.reload();
          }
        } else {
          alert('הקוד שגוי');
        }
      })
      .catch(error => {
        console.log(error);
        alert('הקוד שגוי');
      });
    } else {
      alert('הקוד שגוי');
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'login'} />
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2><b>{strings.login}</b></h2>

              {this.state.verificationCode ? (
                <>
                  <p style={{textAlign: 'right', direction: 'rtl'}}>{strings.code}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.code}
                    onChange={(e) => this.setState({code: e.target.value})}
                  />

                  <Button onClick={(e) => this.verifyCode()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1em', width: '20%'}}>
                    {strings.login}
                  </Button>
                </>
              ) : (
                <>
                  <p style={{textAlign: 'right'}}>{strings.username}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.username}
                    onChange={(e) => this.setState({username: e.target.value})}
                  />

                  <p style={{textAlign: 'right'}}>{strings.password}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.password}
                    type="password"
                    onChange={(e) => this.setState({password: e.target.value})}
                  />

                  <Button onClick={(e) => this.login()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1em', width: '20%'}}>
                    {strings.login}
                  </Button>
                </>
              )}

              <br /><br /><br />
              <Button onClick={(e) => window.location.href='/board'} variant="outlined" style={{marginTop: '2%', fontSize: '1em', width: '20%'}}>
                {strings.boardwithoutlogin}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Login;
