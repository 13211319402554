import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import imageCompression from 'browser-image-compression';


const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class TeacherDeposit extends Component {
  componentDidMount () {
    this.getData();
    this.getProfile();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     accounts: [],
     accountId: '',
     deposits: [],
     cashRegister: 0,
     depositsOriginal: [],
     depositsDay: [],
     depositsMonth: [],
     view: '',
     dateToSearch: new Date(Date.now()),
     displayPermissionsNumberTesting: false,
     by: 'cash',
     file: null
   }
  }

  getProfile = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
        });
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  onChange = (e) => {
      this.setState({file:e.target.files[0]}, () => {
        // console.log(this.fileSizeLimit());
        // if (!this.fileSizeLimit()) {
        //   alert('אנא בחרו תמונה מתחת ל2 מגה בייט');
        // }
      });
  }



  getData = async () => {
    axios.get(API + 'teacher/deposit-related-data', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          accounts: response.data.data.depositaccounts,
          deposits: response.data.data.deposits,
          depositsOriginal: response.data.data.deposits,
          cashRegister: response.data.data.cashRegister,
        }, () => {
          try {
            this.setState({accountId: this.state.accounts[0]._id});
          } catch (err) {

          }

          let depositsDay = [];
          let depositsMonth = [];
          let today = new Date(Date.now());
          today = this.modifyDate(today);
          let month = new Date(Date.now()).getMonth();

          this.state.deposits.map((deposit) => {
            let deposit_date_d = this.modifyDate(deposit.createdAt);
            let deposit_data_month = new Date(deposit.createdAt).getMonth();

            if (deposit_date_d === today) {
              depositsDay.push(deposit);
            }

            if (deposit_data_month === month) {
              depositsMonth.push(deposit);
            }
          });

          this.setState({
            depositsDay: depositsDay,
            depositsMonth: depositsMonth
          })
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת רשימת החשבונות')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  createDepositAccount = () => {
    let payload = {
      bank: this.state.bankNumber,
      branch: this.state.branchNumber,
      account: this.state.bankAccountNumber
    }

    axios.post(API + 'teacher/depositaccount', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({createDepositAccount: false, edit: false, bankNumber: '', branchNumber: '', account: ''})
        this.getData();
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

    editDepositAccount = (id) => {
      axios.put(API + 'teacher/depositaccount?id='+this.state.edit_id, {
        bank: this.state.bankNumber,
        branch: this.state.branchNumber,
        account: this.state.bankAccountNumber
      }, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        if (response.data.status === 'ok') {
          this.setState({createDepositAccount: false, edit: false, bankNumber: '', branchNumber: '', account: ''})
          this.getData();
          this.setState({edit: false});
        }
      }).catch(err => {
        alert('שגיאה קבלת המידע')
      });

    }

    editDepositAccount_ = (id, bankNumber, branchNumber, bankAccountNumber) => {
      this.setState({
        edit_id: id,
        edit: true,
        bankNumber: bankNumber,
        branchNumber: branchNumber,
        bankAccountNumber: bankAccountNumber
      })
    }

    deposit = async () => {
      this.setState({loading: true});

      // if (this.state.file === null) {
      //   alert('חובה לבחור תמונה למוצר');
      //   this.setState({loading: false});
      // } else {
      const formData = new FormData();

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${cookies.get('token___school')}`
          }
      };


      if (this.state.file !== null) {
        const compressedFile = await imageCompression(this.state.file, options);
        const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})
        console.log(convertedBlobFile);
        formData.append('file', convertedBlobFile);
      }
      formData.append('amount', this.state.amount);
      formData.append('by', this.state.by);

      axios.post(API + 'teacher/deposit?id='+this.state.accountId, formData, config).then(response => {
        this.setState({amount: 0, file: null, loading: false})

        if (response.data.status === 'ok') {
          this.getData();
        }
      }).catch(err => {
        alert('שגיאה בהעברת המידע')
      });
    }

    modifyDate = (date) => {
      let d = new Date(date);
      let month = d.getMonth() + 1;
      month = month.toString();
      if (month.length === 1) month = '0'+month;
      let day = d.getDay().toString();
      if (day.length === 1) day = '0'+day;

      return day + '/' + month + '/' + d.getFullYear();
    }

    changeView = (view) => {
      this.setState({view: view}, () => {
        if (view === '') {
          this.setState({deposits: this.state.depositsOriginal});
        } else if (view === 'other') {
          this.setState({deposits: this.state.depositsOriginal});
        } else {
          if (view === 'day') {
            this.setState({deposits: this.state.depositsDay});
          } else {
            this.setState({deposits: this.state.depositsMonth});
          }
        }
      })
    }

    searchByDate = () => {
      let date = this.state.dateToSearch;
      date = new Date(date);
      date = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
      console.log(date);
      let output = [];
      this.state.depositsOriginal.map((deposit) => {
        let deposit_date_d = new Date(deposit.createdAt);

        deposit_date_d = deposit_date_d.getDate() + '/' + deposit_date_d.getMonth() + '/' + deposit_date_d.getFullYear();

        if (deposit_date_d === date) output.push(deposit);
      });
      console.log(output);
      this.setState({deposits: output});
    }

    modifyType = (by) => {
      if (by === 'cash') return 'מזומן';
      else if (by === 'cheque') {
        return 'שיק';
      }
      else if (by === 'bankTransfer') {
        return 'העברה בנקאית';
      } else {
        return '-'
      }
    }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
              {this.state.displayPermissionsNumberTesting && (
                <p>הרשאה מספר 18</p>
              )}
                <h2 style={{textAlign: 'right'}}>{strings.deposit}</h2>
                <h2 style={{textAlign: 'right'}}>הסכום הנמצא בחשבונך: {this.state.cashRegister} ש"ח</h2>

                <div>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosedepositaccount}</p>
                  <Select
                    value={this.state.accountId}
                    onChange={(e) => this.setState({accountId: e.target.value})}
                    style={{
                      textAlign: 'right',
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    {this.state.accounts.map((account) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={account._id}>{account.bank + '-' + account.branch + '-' + account.account}</MenuItem>
                      );
                    })}
                  </Select>
                  <br />
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.depositBy}</p>
                  <Select
                    value={this.state.by}
                    onChange={(e) => this.setState({by: e.target.value})}
                    style={{
                      textAlign: 'right',
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'cash'}>{strings.cash}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'cheque'}>{strings.cheque}</MenuItem>
                  </Select>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.amount}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    type='number'
                    value={this.state.amount}
                    onChange={(e) => this.setState({amount: e.target.value})}
                    required
                  />
                  <br />
                  <br />
                  <div class="upload-btn-wrapper" style={{textAlign: 'right'}}>
                    <label for="file-upload" class="custom-file-upload">
                        <i class="fa fa-cloud-upload"></i> לחצו להעלאת צילום אסמכתא
                    </label>
                    <input type="file" id="file-upload" name="myImage" onChange={this.onChange} accept="image/*"/>
                  </div>

                  {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                    <React.Fragment>
                    {console.log(this.state.file.size)}
                    <p style={{color: 'black', fontSize: '1em', direction: 'rtl', marginRight: '5%'}}>הקובץ שנבחר: {this.state.file.name}</p>
                    </React.Fragment>
                  )}
                  <br />
                  <br />
                  <Button onClick={(e) => this.deposit()} variant={'contained'} color={'primary'} style={{width: '30%'}}>להפקדה</Button>
                </div>
                <br/>
                <br/>
                <br/>

                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.changeView('other')} style={{color: this.state.view === 'other' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'other' ? '#3f51b5' : 'transparent'}}>אחר</Button>
                  <Button onClick={(e) => this.changeView('month')} style={{color: this.state.view === 'month' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'month' ? '#3f51b5' : 'transparent'}}>החודש</Button>
                  <Button onClick={(e) => this.changeView('day')} style={{color: this.state.view === 'day' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'day' ? '#3f51b5' : 'transparent'}}>היום</Button>
                  <Button onClick={(e) => this.changeView('')} style={{color: this.state.view === '' ? 'white' : '#3f51b5', backgroundColor: this.state.view === '' ? '#3f51b5' : 'transparent'}}>הכל</Button>
                </ButtonGroup>

                {this.state.view === 'other' && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> תאריך לחיפוש</p>
                  <Button variant={'contained'} color={'secondary'} onClick={(e) => this.searchByDate()}>חפש</Button>
                  &nbsp;&nbsp;&nbsp;
                  <TextField
                    value={this.state.dateToSearch}
                    onChange={(e) => this.setState({dateToSearch: e.target.value})}
                    type='date'
                    format='dd/mm/yyyy'
                    style={{
                      direction: 'rtl',
                      width: '50%'
                    }}
                  />
                  <br /><br />
                  </React.Fragment>
                )}

                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{'אסמכתא'}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.depositBy}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amount}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.account}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{'מפקיד/ה'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.deposits.reverse().map((deposit, i) => {
                        let deposit_date_d = new Date(deposit.createdAt);
                        return (
                          <TableRow key={i+'_employee'}>
                            <TableCell align="right">
                              {deposit_date_d.getDate() + '/' + (deposit_date_d.getMonth() + 1) + '/' + deposit_date_d.getFullYear()}
                            </TableCell>
                            <TableCell align="right" style={{direction: 'rtl'}}>{deposit.image === '' ? '-' : (<a href={'https://sivan-school.com/images/'+deposit.image} target='_blank'><img src={'https://sivan-school.com/images/'+deposit.image} style={{width: 35, height: 35}} /></a>)}</TableCell>
                            <TableCell align="right" style={{direction: 'rtl'}}>{this.modifyType(deposit.by)}</TableCell>
                            <TableCell align="right" style={{direction: 'rtl'}}>{deposit.amount} ש"ח</TableCell>
                            <TableCell align="right">{deposit.account.bank + '-' + deposit.account.branch + '-' + deposit.account.account}</TableCell>
                            <TableCell align="right">{deposit.userName.replace('()', '')}</TableCell>
                          </TableRow>
                        )
                      })}

                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default TeacherDeposit;
