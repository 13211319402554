import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class TeacherSettings extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     password: '',
     lastSeen: '',
     language: 'he',
     allowedDrivingLicenseTypes: [],
   }
  }

  getData = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          allowedDrivingLicenseTypes: response.data.data.teacher.allowedDrivingLicenseTypes,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  changePassword = () => {
    let payload = {
      password: this.state.password,
    }

    axios.put(API + 'teacher/password-update', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.passwordChangedSuccess)
        window.location.href='/';
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בעדכון הסיסמה')
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  updateAvailableLicenses = () => {
    let payload = {
      allowedDrivingLicenseTypes: this.state.allowedDrivingLicenseTypes,
    }

    axios.put(API + 'teacher/licenses', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.passwordChangedSuccess)
        window.location.href='/';
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בעדכון הסיסמה')
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  handleChange = () => {
    let isBranch = this.state.isBranch ? false : true;

    this.setState({isBranch: isBranch});
  }

  addAllowedLicenseType = (value) => {
    let values = this.state.allowedDrivingLicenseTypes;

    if (this.wasIChecked(value)) {
      values.remove(value);
    } else {
      values.push(value);
    }
    console.log(value);
    console.log(values);
    this.setState({allowedDrivingLicenseTypes: values});
  }


      wasIChecked = (value) => {
        let values = this.state.allowedDrivingLicenseTypes;

        return values.includes(value);
      }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>{strings.allowedlicensetypelearn}</h2>

              <table style={{
                borderCollpase: 'collapse',
              }}>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('A')}
                    onChange={(value) => this.addAllowedLicenseType('A')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA}</th>
                </tr>

                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('A1')}
                    onChange={(value) => this.addAllowedLicenseType('A1')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA1}</th>
                </tr>

                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('A2')}
                    onChange={(value) => this.addAllowedLicenseType('A2')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA2}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('B_manual')}
                    onChange={(value) => this.addAllowedLicenseType('B_manual')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeB}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('B_automate')}
                    onChange={(value) => this.addAllowedLicenseType('B_automate')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeB_}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('C')}
                    onChange={(value) => this.addAllowedLicenseType('C')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeC}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('C1')}
                    onChange={(value) => this.addAllowedLicenseType('C1')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeC1}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('C+E')}
                    onChange={(value) => this.addAllowedLicenseType('C+E')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeCA}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('D')}
                    onChange={(value) => this.addAllowedLicenseType('D')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeD}</th>
                </tr>
                <tr>
                  <th style={{border: '1px solid #dddddd'}}><Checkbox
                    checked={this.wasIChecked('D1')}
                    onChange={(value) => this.addAllowedLicenseType('D1')}
                  /></th>
                  <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeD1}</th>
                </tr>
              </table>

              <Button onClick={(e) => this.updateAvailableLicenses()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>

              <h2>{strings.changepwd}</h2>

              <br /><br /><br />
              <p style={{textAlign: 'right'}}>{strings.password}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />

              <Button onClick={(e) => this.changePassword()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default TeacherSettings;
