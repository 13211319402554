import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

class ClerkAddEmployee extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }

  constructor(props) {
   super(props);
   this.state = {
      isClerk: false,

      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      idNumber: '',
      type: 'teacher',
      professionalTeacherOfficialNumber: '',
      insideTestPrice: 0,
      insideTestPriceGiven: 0,
      signupPrice: 0,
      signupPriceGiven: 0,
      password: '',
      canAddEmployees: false,
      isPartner: false,
      signupNumberOfFeesPerMonth: 0,
      insideTestNumberOfFeesPerMonth: 0,
      canAddClerk: false,
      allowedDrivingLicenseTypes: ['A1', 'A2', 'A', 'B_manual', 'B_automate', 'C', 'C1', 'C+E', 'D', 'D1'],

      lastSeen: '',
      language: '',
      canSeeCommission: false,
      automaticStudentApproval: false,

      emailTaken: false
   }
  }

  handleChangeASA = () => {
    if (this.state.automaticStudentApproval) {
      this.setState({automaticStudentApproval: false});
    } else {
      this.setState({automaticStudentApproval: true});
    }
  }

  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10;
  }

  validate = () => {
    // let firstName = this.state.firstName;
    // let lastName = this.state.lastName;
    let idNumber = this.state.idNumber;
    // let birthday = this.state.birthday;
    let phoneNumber = this.state.phoneNumber;
    //
    // if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
    //   alert(strings.mustFillAllFields);
    //   return false;
    // }

    if (phoneNumber.length !== 10 || !this.validatePhoneNumber(phoneNumber)) {
      alert(strings.phoneNotGood);
      return false
    }

    if (!this.validateID(idNumber)) {
      alert(strings.idNumberNotGood);
      return false;
    }

    return true;
  }

  checkEmail = async () => {
    axios.get(API + 'check-email?email='+this.state.email, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.taken) {
        this.setState({emailTaken: true});
      } else {
        this.setState({emailTaken: false});
      }
    })
    .catch(error => {
      console.log(error);
      // alert(strings.errorPleaseTryAgain);
    });
  }

  setEmail = (email) => {
    this.setState({email: email}, () => {
      this.checkEmail();
    })
  }

  getData = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen
        })
      } else {
        // alert(strings.errorPleaseTryAgain);
        console.log('שגיאה בקבלת הפרופיל');
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  handleChange = () => {
    let isClerk = this.state.isClerk ? false : true;

    this.setState({isClerk: isClerk});
  }


  createUser = () => {
    if (this.validate() && !this.state.emailTaken) {
      let payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        idNumber: this.state.idNumber,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
        type: this.state.isClerk ? 'clerk' : this.state.type,
        professionalTeacherOfficialNumber: this.state.professionalTeacherOfficialNumber,
        insideTestPrice: this.state.insideTestPrice,
        insideTestPriceGiven: this.state.insideTestPriceGiven,
        signupPrice: this.state.signupPrice,
        signupPriceGiven: this.state.signupPriceGiven,
        isPartner: this.state.isPartner,
        signupNumberOfFeesPerMonth: this.state.signupNumberOfFeesPerMonth,
        insideTestNumberOfFeesPerMonth: this.state.insideTestNumberOfFeesPerMonth,
        canAddClerk: this.state.canAddClerk,
        isGizbar: this.state.isGizbar,
        canAddEmployees: this.state.canAddEmployees,
        canSeeCommission: this.state.canSeeCommission,
        automaticStudentApproval: this.state.automaticStudentApproval
      }

      axios.post(API + 'clerk/user', payload, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          //alert(strings.messageUserCreatedSuccess);
          window.location.href='/employees';
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה ביצירת עובד חדש');
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    }
  }

  addAllowedLicenseType = (value) => {
    let values = this.state.allowedDrivingLicenseTypes;

    if (this.wasIChecked(value)) {
      values.remove(value);
    } else {
      values.push(value);
    }
    console.log(value);
    console.log(values);
    this.setState({allowedDrivingLicenseTypes: values});
  }

  wasIChecked = (value) => {
    let values = this.state.allowedDrivingLicenseTypes;

    return values.includes(value);
  }

  handleChangeCanSeeComission = () => {
    if (this.state.canSeeCommission) {
      this.setState({canSeeCommission: false});
    } else {
      this.setState({canSeeCommission: true});
    }
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.addnewemployee}</h2>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.isClerk}
                  onChange={(e) => this.handleChange()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {strings.clerk}
              </div>


              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.canSeeCommission}
                  onChange={(e) => this.handleChangeCanSeeComission()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {strings.canSeeCommission}
              </div>

              {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === 'professionalteacher') && (
                <div style={{textAlign: 'right'}}>
                  <Checkbox
                    checked={this.state.automaticStudentApproval}
                    onChange={(e) => this.handleChangeASA()}
                    color={'primary'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  /> {strings.automaticStudentApproval}
                </div>
              )}


              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.firstname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.lastname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>{strings.idnumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.idNumber}
                onChange={(e) => this.setState({idNumber: e.target.value})}
              />

              {this.state.emailTaken && (<p style={{textAlign: 'right', color: 'red'}}>כתובת האימייל תפוסה, אנא בחרו אחת אחרת</p>)}
              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.email}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setEmail(e.target.value)}
                required
              />

              <p style={{textAlign: 'right'}}>{strings.phonenumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

            {!this.state.isClerk && (
              <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.type}</p>
                <Select
                  value={this.state.type}
                  onChange={(e) => this.setState({type: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={'teacher'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.teacher}</MenuItem>
                  <MenuItem value={'professional'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.professional}</MenuItem>
                  <MenuItem value={'professionalteacher'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.professionalteacher}</MenuItem>
                </Select>

                <p style={{textAlign: 'right'}}>{strings.canAddClerk}</p>
                <Select
                  value={this.state.canAddClerk}
                  onChange={(e) => this.setState({canAddClerk: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                  <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                </Select>

                {this.state.type == 'professional' && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}>{strings.professionalnumber}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.professionalTeacherOfficialNumber}
                    onChange={(e) => this.setState({professionalTeacherOfficialNumber: e.target.value})}
                  />
                  </React.Fragment>
                )}

                {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === "professionalteacher") && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.ispartner}</p>
                    <Select
                      value={this.state.isPartner}
                      onChange={(e) => this.setState({isPartner: e.target.value})}
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                    >
                      <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                      <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                    </Select>
                  </React.Fragment>
                )}

                {this.state.isPartner && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}>{strings.signuppaymentnumberofigrot}</p>
                    <Input
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                      startAdornment={<span>₪</span>}
                      type='number'
                      value={this.state.signupNumberOfFeesPerMonth}
                      onChange={(e) => this.setState({signupNumberOfFeesPerMonth: e.target.value})}
                    />

                    <p style={{textAlign: 'right'}}>{strings.insidetestpaymentnumberofigrot}</p>
                    <Input
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                      startAdornment={<span>₪</span>}
                      type='number'
                      value={this.state.insideTestNumberOfFeesPerMonth}
                      onChange={(e) => this.setState({insideTestNumberOfFeesPerMonth: e.target.value})}
                    />
                  </React.Fragment>
                )}

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.signuppaymentcoststudent}</p>
                <Input
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  startAdornment={<span>₪</span>}
                  type='number'
                  value={this.state.insideTestPrice}
                  onChange={(e) => this.setState({insideTestPrice: e.target.value})}
                />
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.insidetestpaymentcoststudent}</p>
                <Input
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  startAdornment={<span>₪</span>}
                  type='number'
                  value={this.state.insideTestPriceGiven}
                  onChange={(e) => this.setState({insideTestPriceGiven: e.target.value})}
                />

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.signuppaymentcostteacher}</p>
                <Input
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  startAdornment={<span>₪</span>}
                  type='number'
                  value={this.state.signupPrice}
                  onChange={(e) => this.setState({signupPrice: e.target.value})}
                />
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.insidetestpaymentcostteacher}</p>
                <Input
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  startAdornment={<span>₪</span>}
                  type='number'
                  value={this.state.signupPriceGiven}
                  onChange={(e) => this.setState({signupPriceGiven: e.target.value})}
                />

                </React.Fragment>
              )}

              {this.state.isClerk && (
                <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.canAddEmployees}</p>
                  <Select
                    value={this.state.canAddEmployees}
                    onChange={(e) => this.setState({canAddEmployees: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                    <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                  </Select>
                </React.Fragment>
              )}

              <p style={{textAlign: 'right'}}>{strings.password}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='password'
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />


              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.isGizbar}</p>
              <Select
                value={this.state.isGizbar}
                onChange={(e) => this.setState({isGizbar: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.allowedlicensetypelearn}</p>


              {!this.state.isClerk && (
                <React.Fragment>
                  <table style={{
                    borderCollpase: 'collapse',
                  }}>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('A')}
                        onChange={(value) => this.addAllowedLicenseType('A')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA}</th>
                    </tr>

                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('A1')}
                        onChange={(value) => this.addAllowedLicenseType('A1')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA1}</th>
                    </tr>

                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('A2')}
                        onChange={(value) => this.addAllowedLicenseType('A2')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeA2}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('B_manual')}
                        onChange={(value) => this.addAllowedLicenseType('B_manual')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeB}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('B_automate')}
                        onChange={(value) => this.addAllowedLicenseType('B_automate')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeB_}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('C')}
                        onChange={(value) => this.addAllowedLicenseType('C')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeC}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('C1')}
                        onChange={(value) => this.addAllowedLicenseType('C1')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeC1}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('C+E')}
                        onChange={(value) => this.addAllowedLicenseType('C+E')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeCA}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('D')}
                        onChange={(value) => this.addAllowedLicenseType('D')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeD}</th>
                    </tr>
                    <tr>
                      <th style={{border: '1px solid #dddddd'}}><Checkbox
                        checked={this.wasIChecked('D1')}
                        onChange={(value) => this.addAllowedLicenseType('D1')}
                      /></th>
                      <th style={{border: '1px solid #dddddd'}}>{strings.licensetypeD1}</th>
                    </tr>
                  </table>

                </React.Fragment>
              )}
              <br /><br />
              <Button onClick={(e) => this.createUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.create}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkAddEmployee;
