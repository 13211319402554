import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {
  Link
} from "react-router-dom";

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import heLocale from '@fullcalendar/core/locales/he';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import PhoneIcon from '@material-ui/icons/Phone';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class TeacherInsideTests extends Component {
  componentDidMount () {
    this.getData();
    this.getProfile();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',


     amountWithVAT: 0,
     type: '',
     bankNumber: '',
     branchNumber: '',
     bankAccountNumber: '',
     dateOfPayment: new Date(Date.now()),
     description: '',
     applicationType: '',
     applicationNumber: '',
     chequeNum: '',
     transactionId: '',

     lastSeen: '',
     language: 'he',

     link: '',
     whatsapp: '',
     calendar: {},
     calendar_ns: {},
     view: 'all',
     pendings: [],
     displayPermissionsNumberTesting: false
   }
  }

  getProfile = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
        });
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  getData = async () => {
    axios.get(API + 'teacher/student-calendar', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({calendar: response.data.data.calendar, pendings: response.data.data.pendings}, () => {
          let output = {};
          let output_ns = {};
          // Object.keys(this.state.calendar).forEach((item) => {
          //   console.log(new Date(Date.now()));
          //   console.log(new Date(this.state.calendar[item][0].date));
          //   if (new Date(Date.now()) <= new Date(this.state.calendar[item][0].date)) {
          //     output[item] = this.state.calendar[item];
          //   }
          // });
          Object.keys(this.state.calendar).forEach((item) => {
            console.log('item:');
            console.log(item);
            if (item === "NaN/NaN") {
              output_ns[item] = this.state.calendar[item];
            } else {
              output[item] = this.state.calendar[item];
            }
          });

          console.log(output);
          this.setState({calendar: output, calendar_ns: output_ns}, () => {
            let calendar = this.state.calendar;

            let success = [];
            let failed = [];
            let unkown = [];

            Object.keys(calendar).forEach((events_) => {
              calendar[events_].forEach((item) => {
                if (item.status === 'success') {
                  if (success[events_] === undefined) success[events_] = [];
                  success[events_].push(item);
                }
                if (item.status === 'fail') {
                  if (failed[events_] === undefined) failed[events_] = [];
                  failed[events_].push(item);
                }
                if (item.status === 'unkown') {
                  if (unkown[events_] === undefined) unkown[events_] = [];
                  unkown[events_].push(item);
                }
              });
            });

            console.log('success:', success);
            console.log('failed:', failed);
            console.log('unkown:', unkown);
            this.setState({
              success: success,
              failed: failed,
              unkown: unkown
            })
          });
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת המבחן פנימי שלי מהשרת')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  generateWeek = () => {
    let today = new Date(Date.now());

  }

  handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  }

  hebDay = (day) => {
    day = new Date(day).getDay() + 1;

    if (day === 1) return 'ראשון';
    else if (day === 2) return 'שני';
    else if (day === 3) return 'שלישי';
    else if (day === 4) return 'רביעי';
    else if (day === 5) return 'חמישי';
    else if (day === 6) return 'שישי';
    else if (day === 7) return 'שבת';
    else return 'ראשון';
  }

  //set-insidetest-time

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
          <center>
            <div style={{maxWidth: '90%'}}>
              {this.state.displayPermissionsNumberTesting && (
                <p>הרשאה מספר 8</p>
              )}

              <h2 style={{textAlign: 'right'}}>{strings.myweeklyAgendamystudents}</h2>
            </div>

            <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
              <Button onClick={(e) => this.setState({view: 'not_set'})} style={{color: this.state.view === 'not_set' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'not_set' ? '#3f51b5' : 'transparent'}}>לא נקבע תאריך</Button>
              <Button onClick={(e) => this.setState({view: 'unkown'})} style={{color: this.state.view === 'unkown' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'unkown' ? '#3f51b5' : 'transparent'}}>לא ידוע</Button>
              <Button onClick={(e) => this.setState({view: 'success'})} style={{color: this.state.view === 'success' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'success' ? '#3f51b5' : 'transparent'}}>עבר</Button>
              <Button onClick={(e) => this.setState({view: 'failed'})} style={{color: this.state.view === 'failed' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'failed' ? '#3f51b5' : 'transparent'}}>נכשל</Button>
              <Button onClick={(e) => this.setState({view: 'all'})} style={{color: this.state.view === 'all' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'all' ? '#3f51b5' : 'transparent'}}>{strings.all}</Button>
            </ButtonGroup>
            <br />
            <br />
             <div>
             {this.state.view === 'not_set' && (
               <React.Fragment>
               {Object.keys(this.state.calendar_ns).map((item) => {
                 return (
                   <Card>
                    <b>לא נקבע תאריך</b>
                    <br />
                    <hr />
                    {this.state.calendar_ns[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי של {ev_.studentName}</p>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'all' && (
               <React.Fragment>
               {Object.keys(this.state.calendar).map((item) => {
                 console.log(this.state.calendar[item][0]);
                 console.log(item)
                 let correctDate = item;
                 try {
                  correctDate = this.state.calendar[item][0].date.split('T')[0].split('-')[2] + '/' + this.state.calendar[item][0].date.split('T')[0].split('-')[1] + '/' + this.state.calendar[item][0].date.split('T')[0].split('-')[0]
                 } catch (err) {}
                 console.log(item, ' <> ', correctDate)
                 return (
                   <Card>
                    <b>{correctDate}, יום {this.hebDay(this.state.calendar[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.calendar[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי של {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {new Date(ev_.time).getHours() + ':' + new Date(ev_.time).getMinutes()}</p>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'success' && (
               <React.Fragment>
               {Object.keys(this.state.success).map((item) => {
                 console.log(this.state.success[item][0]);
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.success[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.success[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי של {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {new Date(ev_.time).getHours() + ':' + new Date(ev_.time).getMinutes()}</p>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'failed' && (
               <React.Fragment>
               {Object.keys(this.state.failed).map((item) => {
                 console.log(this.state.failed[item][0]);
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.failed[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.failed[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי של {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {new Date(ev_.time).getHours() + ':' + new Date(ev_.time).getMinutes()}</p>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'unkown' && (
               <React.Fragment>
               {Object.keys(this.state.unkown).map((item) => {
                 console.log(this.state.unkown[item][0]);
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.unkown[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.unkown[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי של {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {new Date(ev_.time).getHours() + ':' + new Date(ev_.time).getMinutes()}</p>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             </div>
          </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default TeacherInsideTests;
