import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import Ticker from 'react-ticker';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class CPEDashboard extends Component {
  componentDidMount() {
    this.getData();
    this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     teachers_payments: [],
     payments: [],
     cpe: {
       firstName: '',
       lastName: ''
     },
     schools: [],
     language: 'he',
     shareLinkView: 'signupFee',

     fromDatePR: new Date(Date.now()),
     toDatePR: new Date(Date.now()),
     linkPR: '',

     fromDatePR2: new Date(Date.now()),
     toDatePR2: new Date(Date.now()),
     linkPR2: '',

     messages: [],
     accounts: [],
     deposits: [],

     tickerMove: true,
     showTicker: true,
   }
  }

  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let msgs = response.data.data.messages;
        let output = [];
        msgs.forEach((item, i) => {
          output.push(item.message)
        });

        this.setState({messages: output})
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getData = async () => {
    axios.get(API + 'cpe', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers_payments: response.data.data.teachers_payments,
          payments: response.data.data.payments,
          cpe: response.data.data.cpe,
          schools: response.data.data.schools,
          lastSeen: response.data.lastSeen,
          accounts: response.data.data.accounts,
          deposits: response.data.data.deposits,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }


  modifyPaymentTypeTP = (isBankTransfer) => {
    if (isBankTransfer) return strings.bankTransfer;
    else return strings.cheque;

    return strings.cheque;
  }

  generatePaymentReport = () => {
    let fromDate = new Date(this.state.fromDatePR).toString();
    let toDate = new Date(this.state.toDatePR).toString();

    console.log(API + 'cpe/report/invoice?fromDate='+fromDate+'&toDate='+toDate);
    axios.get(API + 'cpe/report/invoice?fromDate='+fromDate+'&toDate='+toDate, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {
      if (response.data.status === "ok") {
        this.setState({linkPR: response.data.link})
      }
    }).catch(err => {
      console.log(err);
    });
  }

  generatePaymentReport2 = () => {
    let fromDate = new Date(this.state.fromDatePR2).toString();
    let toDate = new Date(this.state.toDatePR2).toString();

    axios.get(API + 'cpe/report/payments?fromDate='+fromDate+'&toDate='+toDate, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {

      console.log(response.data);
      if (response.data.status === "ok") {
        this.setState({linkPR2: response.data.link})
      }
    }).catch(err => {
      console.log(err);
    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <center><br />
          <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <CardContent>

            {this.state.showTicker && (
              <div class="ticker-wrap" onClick={(e) => this.setState({showTicker: false})}>
                <div class="ticker" onClick={(e) => this.setState({showTicker: false})}>
                {this.state.messages.map((index, i) => {
                  if (i === 0) {
                    return (
                      <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                        <span onClick={(e) => this.setState({showTicker: false})}> {index.message} </span>
                      </div>
                    )
                  } else {
                    return (
                      <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                        <span onClick={(e) => this.setState({showTicker: false})}>  {index.message} |&nbsp;  </span>
                      </div>
                    )
                  }
                })}
                </div>
              </div>
            )}

              <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1.2em', direction: 'rtl'}}>{this.state.cpe.firstName + ' ' + this.state.cpe.lastName} <AssignmentIndIcon style={{width: 32, height: 32}}/></p>
              <br />
              {this.state.schools.length === 1 ? (
                <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1em', direction: 'rtl'}}>{this.state.schools[0].name}</p>
              ) : (
                <React.Fragment>
                <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1em', direction: 'rtl'}}>בתי הספר שלי:</p>
                {this.state.schools.reverse().map((school, i) => {
                  if (i === this.state.schools.length - 1) {
                    return (
                      <span style={{fontWeight: 'bold', color: '#404041', fontSize: '1em', direction: 'rtl'}}>{school.name}</span>
                    );
                  } else {
                    return (
                      <span style={{fontWeight: 'bold', color: '#404041', fontSize: '1em', direction: 'rtl'}}>{school.name}, </span>
                    );
                  }
                })}
                </React.Fragment>
              )}
            </CardContent>
          </Card>
         </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h3>הפק דו"ח הכנסות</h3>
            <center>

            {this.state.linkPR !== '' && (
              <div>
                <h4 style={{color: 'green'}}>הדו"ח הופק בהצלחה</h4>
                <a href={this.state.linkPR} target='_blank'>להורדה, לחצו כאן</a>
              </div>
            )}

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                <p>עד תאריך</p>
                <TextField
                  value={this.state.toDatePR}
                  onChange={(e) => this.setState({toDatePR: e.target.value})}
                  type='date'
                  format='dd/mm/yyyy'
                />
              </div>

              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                <p>מתאריך</p>
                <TextField
                  value={this.state.fromDatePR}
                  onChange={(e) => this.setState({fromDatePR: e.target.value})}
                  type='date'
                  format='dd/mm/yyyy'
                />
              </div>
            </div>
            </center>
            <br />
            <Button variant={'contained'} color={'primary'} onClick={(e) => this.generatePaymentReport()}>הפק דו"ח</Button>
          </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.paymentsschool}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.payments.slice(0,10).map((payment, i) => {
                  return (
                    <TableRow key={i+'_payment'}>
                      <TableCell align="right">₪{(payment.amountWithVAT === '' || payment.amountWithVAT === null || payment.amountWithVAT === undefined) ? 0 : payment.amountWithVAT}</TableCell>
                      <TableCell align="right">{this.modifyPaymentType(payment)}</TableCell>
                      <TableCell align="right">{new Date(payment.createdAt).getDate() + '/' + parseInt(new Date(payment.createdAt).getMonth() + 1) + '/' + new Date(payment.createdAt).getFullYear()}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>

        <br />

        {this.state.payments.length >= 11 && (
          <div style={{textAlign: 'right', marginRight: '2%'}}>
          <Link to='/payments' style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>
            {strings.paymentsschoolall}
          </Button></Link>
          </div>
        )}
        </center>
        </Grid>


        <Grid item xs={12}>
          <br />
          <center>
            <h3>הפק דו"ח תשלומים</h3>
            <center>

            {this.state.linkPR2 !== '' && (
              <div>
                <h4 style={{color: 'green'}}>הדו"ח הופק בהצלחה</h4>
                <a href={this.state.linkPR2} target='_blank'>להורדה, לחצו כאן</a>
              </div>
            )}

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                <p>עד תאריך</p>
                <TextField
                  value={this.state.toDatePR2}
                  onChange={(e) => this.setState({toDatePR2: e.target.value})}
                  type='date'
                  format='dd/mm/yyyy'
                />
              </div>

              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                <p>מתאריך</p>
                <TextField
                  value={this.state.fromDatePR2}
                  onChange={(e) => this.setState({fromDatePR2: e.target.value})}
                  type='date'
                  format='dd/mm/yyyy'
                />
              </div>
            </div>
            </center>
            <br />
            <Button variant={'contained'} color={'primary'} onClick={(e) => this.generatePaymentReport2()}>הפק דו"ח</Button>
          </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.paymentsschoolteachers}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.teachers_payments.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.nopayments1}
          <PersonAddIcon style={{width: 32, height: 32}}/><br/>
           {strings.nopayments2}
           </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.invoiceDelivered}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.teachers_payments.reverse().slice(0,10).map((payment, i) => {
                return (
                  <TableRow key={i+'_payment'}>
                    <TableCell align="right">{payment.invoiceDelivered ? strings.yes : strings.no}</TableCell>
                    <TableCell align="right">₪{(payment.chequeAmount === '' || payment.chequeAmount === null || payment.chequeAmount === undefined) ? 0 : payment.chequeAmount}</TableCell>
                    <TableCell align="right">{this.modifyPaymentTypeTP(payment.isBankTransfer)}</TableCell>
                    <TableCell align="right">{new Date(payment.createdAt).getDate() + '/' + parseInt(new Date(payment.createdAt).getMonth() + 1) + '/' + new Date(payment.createdAt).getFullYear()}</TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
          </Table>
          </TableContainer>
        )}
        <br />
        {this.state.payments.length >= 11 && (
          <div style={{textAlign: 'right', marginRight: '2%'}}>
          <Link to='/teachers-payments' style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>
            {strings.paymentsschoolall}
          </Button></Link>
          </div>
        )}
        </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.deposits}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.deposits.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {'אין הפקדות - כשיהיו, הן יופיעו כאן'}
           </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.account}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'המפקיד'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amount}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.deposits.reverse().slice(0,10).map((deposit, i) => {
                return (
                  <TableRow key={i+'_deposit'}>
                    <TableCell align="right">{(deposit.account.bank + '-' + deposit.account.branch + '-' + deposit.account.account)}</TableCell>
                    <TableCell align="right">{(deposit.user.firstName + ' ' + deposit.user.lastName)}</TableCell>
                    <TableCell align="right">₪{(deposit.amount)}</TableCell>
                    <TableCell align="right">{new Date(deposit.createdAt).getDate() + '/' + parseInt(new Date(deposit.createdAt).getMonth() + 1) + '/' + new Date(deposit.createdAt).getFullYear()}</TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
          </Table>
          </TableContainer>
        )}
        <br />
        {this.state.payments.length >= 11 && (
          <div style={{textAlign: 'right', marginRight: '2%'}}>
          <Link to='/deposits' style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>
            {'לצפייה בכל רשומות ההפקדות'}
          </Button></Link>
          </div>
        )}
        </center>
        </Grid>


        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default CPEDashboard;
