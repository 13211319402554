import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditSchool extends Component {
  componentDidMount() {
    this.getSchoolFromQuery();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     schoolId: '',
     school: {},
     schoolName: '',
     isBranch: false,
     parentSchoolId: '',

     schools: [],
     children: 0,

     address: '',
     postalCode: '',
     schoolEmail: '',
     officialIdNumber: '',
     mailAddress: '',
     CPAEmail: '',

     invoiceNumber: 0,
     receiptTaxInvoiceNumber: 0,
     receiptNumber: 0,

     lastSeen: '',
     language: 'he'
   }
  }


  getSchoolFromQuery = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'manager/school?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            school: response.data.data.school,
            schoolName: response.data.data.school.name,
            isBranch: response.data.data.school.isBranch,
            parentSchoolId: response.data.data.school.parentId,
            children: response.data.data.children,
            address: response.data.data.school.address,
            postalCode: response.data.data.school.postalCode,
            schoolEmail: response.data.data.school.schoolEmail,
            officialIdNumber: response.data.data.school.officialIdNumber,
            mailAddress: response.data.data.school.mailAddress,
            invoiceNumber: response.data.data.school.invoiceNumber,
            receiptTaxInvoiceNumber: response.data.data.school.receiptTaxInvoiceNumber,
            receiptNumber: response.data.data.school.receiptNumber,
            CPAEmail: response.data.data.school.CPAEmail,
            lastSeen: response.data.lastSeen
          })
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת בית הספר')
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  updateSchool = () => {
    let payload = {
      address: this.state.address,
      postalCode: this.state.postalCode,
      schoolEmail: this.state.schoolEmail,
      mailAddress: this.state.mailAddress,
      CPAEmail: this.state.CPAEmail,
    }

    axios.put(API + 'manager/school?id='+this.state.schoolId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messageSchoolUpdatedSuccess);
        window.location.href='/';
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בעדכון בית הספר')
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  handleChange = () => {
    let isBranch = this.state.isBranch ? false : true;

    this.setState({isBranch: isBranch});
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>{strings.editschool}</h2>

              <br /><br /><br />
              <p style={{textAlign: 'right'}}>{strings.schoolname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.schoolName}
                disabled={true}
              />

              <p style={{textAlign: 'right'}}>{strings.schooladdress}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.address}
                onChange={(e) => this.setState({address: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.postalcode}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.postalCode}
                onChange={(e) => this.setState({postalCode: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.schoolemail}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.schoolEmail}
                onChange={(e) => this.setState({schoolEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.cpaemail}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.CPAEmail}
                onChange={(e) => this.setState({CPAEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.officialidnumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.officialIdNumber}
                disabled={true}
              />

              <Button onClick={(e) => this.updateSchool()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditSchool;
