import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class AddInsideTest extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',
     schoolId: '',
     professionalId: '',
     testDate: new Date(Date.now()),
     testTime: new Date(Date.now()),

     lastSeen: '',
     language: 'he',
     s_: [],
     p_: [],

     link: '',
     whatsapp: '',
     professionals: [],
     withoutDates: false
   }
  }

  setWithoutDates = () => {
    if (this.state.withoutDates) {
      this.setState({
        withoutDates: false
      });
    } else {
      this.setState({
        withoutDates: true
      });
    }
  }

  getData = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'manager/students-professionals-inside-test?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            students: response.data.data.students,
            lastSeen: response.data.lastSeen,
            professionals: response.data.data.professionals,
          }, () => {
            let students = [];
            let studentsApproval = [];

            this.state.students.forEach((item, i) => {
              if (
              (item.paidInsideTestFee === 'yes' || item.paidInsideTestFee === 'ptor')
               && (item.status === 'failed' || item.status === 'not_exists' || item.status === 'unkown')
              ) {
                if (item.approved === "false" || !item.approved) studentsApproval.push(item);
                else students.push(item);
              }
            });

            this.setState({
              students: students,
              studentsApproval: studentsApproval
            }, () => {
              let s_ = [];
              console.log(this.state.students);
              this.state.students.forEach((item, i) => {
                console.log(item);
                s_.push({name: item.firstName + ' ' + item.lastName + ' ' + item.idNumber, value: item._id},)
              });
              let p_ = [];
              this.state.professionals.forEach((item, i) => {
                p_.push({name: item.firstName + ' ' + item.lastName + ' ' + item.idNumber, value: item._id},)
              });

              this.setState({s_: s_, p_: p_});
            });
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת רשימת התלמידים והעובדים')
        }
      })
      .catch(error => {
        console.log(error);
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאת הצבת נתוני מורים ותלמידים, קוד 12311')
      });
    });
  }

  createTest = () => {
    let payload = {
      schoolId: this.state.schoolId,
      professionalId: this.state.professionalId,
      studentId: this.state.studentId,
      testDate: this.state.withoutDates ? '' : this.state.testDate,
      testTime: this.state.withoutDates ? '' : this.state.testTime,
    }

    axios.post(API + 'manager/school/inside-tests/set-test', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messageTestCreatedSuccess);
        window.location.href='/';
      } else {
        if (response.data.errorCode === 111 || response.data.errorCode === '111') {
          alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה ביצירת מבחן פנימי')
        }
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeAmount = (amount) => {
    if (amount > 0) {
      this.setState({amountWithVAT: amount})
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.setInsideTest}</h2>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosestudent}</p>
              <SelectSearch
                onChange={(value) => this.setState({studentId: value})}
                options={this.state.s_}
                search={true}
                value={this.state.studentId}
                name="studentId"
                placeholder="שם התלמיד.."
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseproffesional}</p>
              {/*<Select
                value={this.state.professionalId}
                onChange={(e) => this.setState({professionalId: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%',
                  textAlign: 'right',
                }}
              >
                {this.state.professionals.map((student) => {
                  return (
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={student._id}>{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</MenuItem>
                  )
                })}
              </Select>*/}
              <SelectSearch
                onChange={(value) => this.setState({professionalId: value})}
                options={this.state.p_}
                search={true}
                value={this.state.professionalId}
                name="studentId"
                placeholder="שם המנהל המקצועי.."
              />

              {!this.state.withoutDates && (
                  <React.Fragment>
                  <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateoftest}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={strings.opencalendarhere}
                        format="dd/MM/yyyy"
                        value={this.state.testDate}
                        onChange={(date) => this.setState({testDate: date})}
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          direction: 'rtl'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateoftesttime}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">

                      <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          ampm={false}
                          label={strings.opencalendarheretime}
                          value={this.state.testTime}
                          onChange={(date) => this.setState({testTime: date})}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          style={{
                            width: '100%',
                            textAlign: 'right',
                            direction: 'rtl'
                          }}
                        />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  </React.Fragment>
              )}


              <Checkbox
                value={this.state.withoutDates}
                onChange={(e) => this.setWithoutDates()}
              /> {strings.setWithoutdates}
              <br /><br />
              <Button onClick={(e) => this.createTest()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.create}
              </Button>
            </div>
            </center>

          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddInsideTest;
