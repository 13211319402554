import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';

import axios from 'axios';
const cookies = new Cookies();
const strings = new LocalizedStrings(i18n);

class TeachersIncome extends Component {
  componentDidMount() {
    this.getData();
    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     data: [],
     teachers: [],
     teachersOriginal: [],
     addPayment: false,
     addPaymentTeacherId: '',
     amount: 0,
     teacherChosen: {},
     teacherChosenWatch: {},
     payments: [],
     watchPaymentsActive: false,
     watchList: [],

     orderName: 'up',
     showWithZero: false,
     depositAccounts: [],
     dataOriginal: [],
     payments: [],
     teacherChosne: {},
     sum: 0,
     typeChosne: '',
     dateChosen: '',
     fromDate: new Date(Date.now()),
     toDate: new Date(Date.now()),
   }
  }

  addPaymentActive = (teacher) => {
    let id = teacher._id;
    let amount = teacher.cashRegisterCommission;
    this.setState({
      addPayment: true,
      addPaymentTeacherId: id,
      amount: amount,
      teacherChosen: teacher
    }, () => {
      window.scroll(0,0)
    });
  }

  getData = async () => {
    axios.get('https://api.sivan-school.com/v1/teachers-income', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        const data = response.data.output;
        let teachers = [];
        data.forEach((item, i) => {
          const payments = item.payments;
          let insidetests = [];
          let signup = [];
          let insidetestAmount = 0;
          let signupAmount = 0;
          let totalStudents = [];
          payments.forEach((payment, p_) => {
            if (payment.description === 'signup' || payment.description === 'signupFee') {
              signup.push(payment);
              signupAmount += item.signupPriceGiven;
            } else {
              insidetests.push(payment);
              insidetestAmount += item.insideTestPriceGiven;
            }

            if (!totalStudents.includes(item.studentId)) totalStudents.push(item.studentId);
          });

          teachers.push({
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            idNumber: item.idNumber,
            insideTestPriceGiven: item.insideTestPriceGiven,
            signupPriceGiven: item.signupPriceGiven,
            payments: item.payments,
            insidetests: insidetests,
            signup: signup,
            insidetestAmount: insidetestAmount,
            signupAmount: signupAmount,
            _id: item._id,
            totalStudents: totalStudents.length
          });
        });

        this.setState({data: teachers, dataOriginal: teachers});
      } else {
        alert('שגיאה בקבלת המידע');
      }
    })
    .catch(error => {
      console.log(error);
      alert('שגיאה בשרת');
    });
  }

  modifyPaymentType = (payment) => {
    if (payment.type === 'application') {
      if (payment.applicationType === 'bit') return 'ביט';
      else if (payment.applicationType === 'paybox') return 'פייבוקס';
      else if (payment.applicationType === 'pepper') return 'פפר';
      else return 'אפליקציה';
    } else {
      if (payment.type === 'cash') return 'מזומן';
      else if (payment.type === 'cheque') return 'שיק';
      else if (payment.type === 'tranzila') return 'סליקה';
      else if (payment.type === 'bankTransfer') return 'העברה בנקאית';
      else {
        if (payment.isPtor) {
          return 'פטור'
        } else {
          return 'אחר';
        }
      }
    }
  }


  modifyDate = (date) => {
    date = new Date(date);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  search = (query) => {
    this.setState({query: query}, () => {
      const teachers = this.state.dataOriginal;
      let t_ = [];
      teachers.forEach((item, i) => {
        let fullName = item.firstName + ' ' + item.lastName;
        let phoneNumber = item.phoneNumber;
        let idNumber = item.idNumber;
        if (
          idNumber.includes(query) ||
          phoneNumber.includes(query) ||
          fullName.includes(query)
        ) t_.push(item);
      });
      this.setState({data: t_});
    });
  }

  resetSearch = () => {
    this.setState({
      query: '',
      data: this.state.dataOriginal
    })
  }

  orderByName = () => {
    if (this.state.orderName === "up") {
      // down
      const teachers = this.state.data;
      teachers.sort((a, b) => b.idNumber.localeCompare(a.idNumber))
      this.setState({data: teachers,orderName: 'down'})
    } else {
      // up
      const teachers = this.state.data;
      teachers.sort((a, b) => a.idNumber.localeCompare(b.idNumber))
      this.setState({data: teachers, orderName: 'up'})
    }
  }

  watchPayments = (teacher) => {
    this.setState({
      payments: teacher.payments,
      teacherChosen: teacher
    }, () => {
      let sum = 0;
      this.state.payments.forEach((item, i) => {
        sum += item.description === 'signupFee' ? parseFloat(this.state.teacherChosen.signupPriceGiven) : parseFloat(this.state.teacherChosen.insideTestPriceGiven)
        this.setState({sum: sum});
      });

    });
  }

  filterDates = () => {
    this.setState({loading: true}, () => {
      axios.get('https://api.sivan-school.com/v1/teachers-income', {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          const data = response.data.output;
          let teachers = [];
          data.forEach((item, i) => {
            const payments = item.payments;
            let insidetests = [];
            let signup = [];
            let insidetestAmount = 0;
            let signupAmount = 0;
            let totalStudents = [];
            payments.forEach((payment, p_) => {
              if (this.state.dateChosen === '') {
                if (payment.description === 'signup' || payment.description === 'signupFee') {
                  signup.push(payment);
                  signupAmount += item.signupPriceGiven;
                } else {
                  insidetests.push(payment);
                  insidetestAmount += item.insideTestPriceGiven;
                }

                if (!totalStudents.includes(item.studentId)) totalStudents.push(item.studentId);
              } else if (this.state.dateChosen === 'day') {
                const today = this.modifyDate(Date.now());
                const paymentCreatedAt = this.modifyDate(payment.createdAt);
                if (today === paymentCreatedAt) {
                  if (payment.description === 'signup' || payment.description === 'signupFee') {
                    signup.push(payment);
                    signupAmount += item.signupPriceGiven;
                  } else {
                    insidetests.push(payment);
                    insidetestAmount += item.insideTestPriceGiven;
                  }

                  if (!totalStudents.includes(item.studentId)) totalStudents.push(item.studentId);
                }
              } else if (this.state.dateChosen === 'month') {
                const month = new Date(Date.now()).getMonth() + 1;
                const paymentCreatedAt = new Date(payment.createdAt).getMonth() + 1;
                if (month.toString() === paymentCreatedAt.toString()) {
                  if (payment.description === 'signup' || payment.description === 'signupFee') {
                    signup.push(payment);
                    signupAmount += item.signupPriceGiven;
                  } else {
                    insidetests.push(payment);
                    insidetestAmount += item.insideTestPriceGiven;
                  }

                  if (!totalStudents.includes(item.studentId)) totalStudents.push(item.studentId);
                }
              } else if (this.state.dateChosen === 'range') {
                const fromDate = new Date(this.state.fromDate);
                const toDate = new Date(this.state.toDate);
                const paymentCreatedAt = new Date(payment.createdAt);
                if (paymentCreatedAt >= fromDate && paymentCreatedAt <= toDate) {
                  if (payment.description === 'signup' || payment.description === 'signupFee') {
                    signup.push(payment);
                    signupAmount += item.signupPriceGiven;
                  } else {
                    insidetests.push(payment);
                    insidetestAmount += item.insideTestPriceGiven;
                  }

                  if (!totalStudents.includes(item.studentId)) totalStudents.push(item.studentId);
                }
              } else {
                if (payment.description === 'signup' || payment.description === 'signupFee') {
                  signup.push(payment);
                  signupAmount += item.signupPriceGiven;
                } else {
                  insidetests.push(payment);
                  insidetestAmount += item.insideTestPriceGiven;
                }

                if (!totalStudents.includes(item.studentId)) totalStudents.push(item.studentId);
              }
            });

            teachers.push({
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
              idNumber: item.idNumber,
              insideTestPriceGiven: item.insideTestPriceGiven,
              signupPriceGiven: item.signupPriceGiven,
              payments: item.payments,
              insidetests: insidetests,
              signup: signup,
              insidetestAmount: insidetestAmount,
              signupAmount: signupAmount,
              _id: item._id,
              totalStudents: totalStudents.length
            });
          });

          this.setState({data: teachers, dataOriginal: teachers, loading: false});
        } else {
          alert('שגיאה בקבלת המידע');
          this.setState({loading: false})
        }
      })
      .catch(error => {
        console.log(error);
        alert('שגיאה בשרת');
        this.setState({loading: false})
      });
    });
  }

  // getSignupFeeText = () => {
  //   return window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text');
  // }
  getSignupFeeText = () => {
    let ft = window.localStorage.getItem('signupfee_text');
    if (ft === null || ft === undefined || ft === 'NULL' || ft === 'null') {
      return 'תשלום דמי רישום';
    }
    return ft;
  }
  tableToCSV = (tableSelector) => {
    const table = document.querySelector(tableSelector);
    const rows = Array.from(table.querySelectorAll('tr'));

    const csvRows = rows.map(row => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        return cells.map(cell => `"${cell.innerText.replace(/"/g, '""')}"`).join(',');
    });

    return csvRows.join('\n');
}

downloadCSV = (csvData, filename = 'table.csv') => {
    // Add UTF-8 Byte Order Mark (BOM) for handling non-ASCII characters
    const utf8BOM = '\ufeff';
    const blob = new Blob([utf8BOM + csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

// Example usage
exportData = () => {
  const a = window.confirm('האם את/ה בטוח/ה שברצונך לייצא את המידע לקובץ אקסל?');
  if (a) {
    
      const csvData = this.tableToCSV('.MuiTable-root');
      this.downloadCSV(csvData, 'exported_table.csv');

  }
}
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>טבלת הכנסות מורים</h2>

              <div>
                <p>חיפוש על פי שם המורה / מספר ת.ז או מספר טלפון</p>
                <Button onClick={(e) => this.setState({query: ''})} color={'primary'} variant={'contained'}>איפוס</Button>
                &nbsp;
                &nbsp;
                <TextField
                  value={this.state.query}
                  onChange={(e) => this.search(e.target.value)}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right'
                  }}
                />
                <br />
                <br />
              </div>

              <div>
                <Button onClick={(e) => this.setState({dateChosen: 'day'}, () => this.filterDates())} color={this.state.dateChosen === 'day' ? 'secondary' : 'primary'} variant={'contained'}>ליום</Button>
                &nbsp;&nbsp;
                <Button onClick={(e) => this.setState({dateChosen: 'month'}, () => this.filterDates())} color={this.state.dateChosen === 'month' ? 'secondary' : 'primary'} variant={'contained'}>לחודש</Button>
                &nbsp;&nbsp;
                <Button onClick={(e) => this.setState({dateChosen: 'range'})} color={this.state.dateChosen === 'range' ? 'secondary' : 'primary'} variant={'contained'}>אחר</Button>
                &nbsp;&nbsp;
                <Button onClick={(e) => this.setState({dateChosen: ''}, () => this.getData())} color={this.state.dateChosen === '' ? 'secondary' : 'primary'} variant={'contained'}>הכל</Button>
                &nbsp;&nbsp;
                {this.state.data.length >=1 && (
                  <>
                  <Button onClick={(e) => this.exportData()} color={'default'} variant={'contained'}>ייצא לאקסל</Button>
                  &nbsp;&nbsp;
                  </>
                )}
              </div>
              <br />
              {this.state.dateChosen === 'range' && (
                <>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                  <div style={{marginRight: '1%', marginLeft: '1%'}}>
                    <p>עד תאריך</p>
                    <TextField
                      value={this.state.toDate}
                      onChange={(e) => this.setState({toDate: e.target.value})}
                      type='date'
                      format='dd/mm/yyyy'
                    />
                  </div>

                  <div style={{marginRight: '1%', marginLeft: '1%'}}>
                    <p>מתאריך</p>
                    <TextField
                      value={this.state.fromDate}
                      onChange={(e) => this.setState({fromDate: e.target.value})}
                      type='date'
                      format='dd/mm/yyyy'
                    />
                  </div>
                </div>
                <br />
                <Button onClick={(e) => this.filterDates()} color={'primary'} variant={'contained'}>חיפוש על פי תאריכים</Button>
                <br />
                
                <br />
                </>
              )}

              {this.state.payments.length >= 1 ? (
                <>
                <br />
                <Button onClick={(e) => this.setState({
                  teacherChosen: {},
                  payments: []
                })} color={'primary'} variant={'contained'}>סגירה וחזרה</Button>
                <br />
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תקבול מורה</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>סכום מלא</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>דרך תשלום</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>סוג</TableCell>
                    </TableHead>
                    <TableBody>
                      {this.state.payments.map((payment, i) => {
                        return (
                          <TableRow key={i+'_payment'}>
                            <TableCell align="right">{this.modifyDate(payment.createdAt)}</TableCell>
                            <TableCell align="right">{payment.description === 'signupFee' ? this.state.teacherChosen.signupPriceGiven : this.state.teacherChosen.insideTestPriceGiven}</TableCell>
                            <TableCell align="right">{payment.amountWithVAT}</TableCell>
                            <TableCell align="right">{this.modifyPaymentType(payment.type)}</TableCell>
                            <TableCell align="right">{payment.description === 'signupFee' ? this.getSignupFeeText() : 'דמי מבחן פנימי' }</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow key={'total_payment'}>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right" style={{direction: 'rtl'}}>
                          {this.state.typeChosne === 'all' && (this.state.teacherChosen.insidetestAmount + this.state.teacherChosen.signupAmount)}
                          {this.state.typeChosne === 'insidetests' && (this.state.teacherChosen.insidetestAmount)}
                          {this.state.typeChosne === 'signup' && (this.state.teacherChosen.signupAmount)}
                          &nbsp; ש״ח
                        </TableCell>
                        <TableCell align="right">סה״כ תקבול מורה</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                </>
              ) : (
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סה״כ תלמידים</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סה״כ כסף</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>כמות תלמידים</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>הכנסה ממבחן פנימי</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>כמות תלמידים</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>הכנסה מ{this.getSignupFeeText()}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>מספר טלפון</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>ת.ז</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>שם מלא</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.data.map((teacher, i) => {
                        return (
                          <TableRow key={i+'_payment'}>
                            <TableCell align="right">{teacher.insidetests.length + teacher.signup.length}</TableCell>
                            <TableCell align="right" style={{direction: 'rtl'}}><Button onClick={(e) => this.setState({typeChosne: 'all', teacherChosen: teacher, payments: teacher.signup.concat(teacher.insidetests)})}>{parseFloat(teacher.signupAmount) + parseFloat(teacher.insidetestAmount)} ש״ח</Button></TableCell>
                            <TableCell align="right">{teacher.insidetests.length}</TableCell>
                            <TableCell align="right" style={{direction: 'rtl'}}><Button onClick={(e) => this.setState({typeChosne: 'insidetests', teacherChosen: teacher, payments: teacher.insidetests})}>{teacher.insidetestAmount} ש״ח</Button></TableCell>
                            <TableCell align="right">{teacher.signup.length}</TableCell>
                            <TableCell align="right" style={{direction: 'rtl'}}><Button onClick={(e) => this.setState({typeChosne: 'signup', teacherChosen: teacher, payments: teacher.signup})}>{teacher.signupAmount} ש״ח</Button></TableCell>
                            <TableCell align="right">{teacher.phoneNumber}</TableCell>
                            <TableCell align="right">{teacher.idNumber}</TableCell>
                            <TableCell align="right">{teacher.firstName + ' ' + teacher.lastName}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default TeachersIncome;
