import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {
  Link
} from "react-router-dom";

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import heLocale from '@fullcalendar/core/locales/he';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import PhoneIcon from '@material-ui/icons/Phone';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class MyInsideTests extends Component {
  componentDidMount () {
    this.getData();
    this.getProfile();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',


     amountWithVAT: 0,
     type: '',
     bankNumber: '',
     branchNumber: '',
     bankAccountNumber: '',
     dateOfPayment: new Date(Date.now()),
     description: '',
     applicationType: '',
     applicationNumber: '',
     chequeNum: '',
     transactionId: '',

     lastSeen: '',
     language: 'he',

     link: '',
     whatsapp: '',
     calendar: {},
     calendar_history: {},
     success_history: [],
     failed_history: [],
     unkown_history: [],
     view: 'all',
     pendings: [],
     displayPermissionsNumberTesting: false
   }
  }


  orderByDate = (calendar) => {
    return calendar;
    // try {
    //   let orderedDates = {};
    //   Object.keys(calendar).sort(function(a, b) {
    //     let b_ = b.split('/');
    //     b = b_[1] + '-' + b_[0] + '-' + b_[2];
    //     let a_ = a.split('/');
    //     a = a_[1] + '-' + a_[0] + '-' + a_[2];
    //     return new Date(b) - new Date(a);
    //   }).forEach(function(key) {
    //       orderedDates[key] = calendar[key];
    //   })
    //   return orderedDates;
    // } catch (err) {
    //   return calendar;
    // }
  }

  getProfile = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
        });
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  getData = async () => {
    axios.get(API + 'teacher/calendar', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({calendar: response.data.data.calendar, pendings: response.data.data.pendings}, () => {
          let output = {};
          let output_history = {};
          Object.keys(this.state.calendar).forEach((item) => {
            // console.log(item);
            // console.log(new Date(Date.now()));
            // console.log(new Date(this.state.calendar[item][0].date));
            let date_compare_a = new Date(Date.now())
            let date_compare_b = new Date(this.state.calendar[item][0].date);
            date_compare_a.setHours(0,0,0,0);
            date_compare_b.setHours(0,0,0,0);

            // if (new Date(Date.now()) <= new Date(this.state.calendar[item][0].date)) {
            if (date_compare_a <= date_compare_b) {
              output[item] = this.state.calendar[item];
            } else {
              output_history[item] = this.state.calendar[item];
            }
          });
          // console.log(output);
          this.setState({calendar: output, calendar_history: output_history}, () => {
            let calendar = this.state.calendar;

            let success = [];
            let failed = [];
            let unkown = [];

            Object.keys(calendar).forEach((events_) => {
              // console.log(events_);
              calendar[events_].forEach((item) => {
                // console.log(item);
                if (item.status === 'success') {
                  if (success[events_] === undefined) success[events_] = [];
                  success[events_].push(item);
                }
                if (item.status === 'fail') {
                  if (failed[events_] === undefined) failed[events_] = [];
                  failed[events_].push(item);
                }
                if (item.status === 'unkown') {
                  if (unkown[events_] === undefined) unkown[events_] = [];
                  unkown[events_].push(item);
                }
              });
            });

            let calendar_history = this.state.calendar_history;
            console.log(calendar_history);
            let success_history = [];
            let failed_history = [];
            let unkown_history = [];

            Object.keys(calendar_history).forEach((events_) => {
              // console.log(events_);
              calendar_history[events_].forEach((item) => {
                // console.log(item);
                if (item.status === 'success') {
                  if (success_history[events_] === undefined) success_history[events_] = [];
                  success_history[events_].push(item);
                }
                if (item.status === 'fail') {
                  if (failed_history[events_] === undefined) failed_history[events_] = [];
                  failed_history[events_].push(item);
                }
                if (item.status === 'unkown') {
                  if (unkown_history[events_] === undefined) unkown_history[events_] = [];
                  unkown_history[events_].push(item);
                }
              });
            });

            this.setState({
              success_history: success_history,
              failed_history: failed_history,
              unkown_history: unkown_history,
              success: success,
              failed: failed,
              unkown: unkown,
            })
          });
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת המבחן פנימי שלי מהשרת')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  generateWeek = () => {
    let today = new Date(Date.now());

  }

  handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  }

  hebDay = (day) => {
    day = new Date(day).getDay() + 1;

    if (day === 1) return 'ראשון';
    else if (day === 2) return 'שני';
    else if (day === 3) return 'שלישי';
    else if (day === 4) return 'רביעי';
    else if (day === 5) return 'חמישי';
    else if (day === 6) return 'שישי';
    else if (day === 7) return 'שבת';
    else return 'ראשון';
  }

  //set-insidetest-time

  deleteInsideTest = (id) => {
    axios.delete(API + 'teacher/insidetest-self?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      this.getData();
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }
  pad = (n) => {return n<10 ? '0'+n : n}

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
          <center>
            <div style={{maxWidth: '90%'}}>
            {this.state.displayPermissionsNumberTesting && (
              <p>הרשאה 8</p>
            )}
              <h2 style={{textAlign: 'right'}}>{strings.myweeklyAgenda}</h2>
            </div>

            <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
              <Button onClick={(e) => this.setState({view: 'unkown'})} style={{color: this.state.view === 'unkown' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'unkown' ? '#3f51b5' : 'transparent'}}>לא ידוע</Button>
              <Button onClick={(e) => this.setState({view: 'success'})} style={{color: this.state.view === 'success' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'success' ? '#3f51b5' : 'transparent'}}>עבר</Button>
              <Button onClick={(e) => this.setState({view: 'failed'})} style={{color: this.state.view === 'failed' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'failed' ? '#3f51b5' : 'transparent'}}>נכשל</Button>
              <Button onClick={(e) => this.setState({view: 'all'})} style={{color: this.state.view === 'all' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'all' ? '#3f51b5' : 'transparent'}}>{strings.all}</Button>
              <Button onClick={(e) => this.setState({view: 'history'})} style={{color: this.state.view === 'history' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'history' ? '#3f51b5' : 'transparent'}}>היסטוריה</Button>

            </ButtonGroup>
            <br />
            <br />
             <div>
             {this.state.view === 'all' && (
               <React.Fragment>
               {Object.keys(this.orderByDate(this.state.calendar)).reverse().map((item) => {
                 console.log(this.state.calendar[item][0]);
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.calendar[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.calendar[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                          <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                          {ev_.status === 'unkown' && (
                            <React.Fragment>
                            <br /><br />
                            <Button onClick={(e) => this.deleteInsideTest(ev_._id)} variant="contained" style={{width: '50%', color: 'white', backgroundColor: 'red'}}>אני לא יכול לבצע מבחן פנימי זה</Button>
                            </React.Fragment>
                          )}

                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'success' && (
               <React.Fragment>
               {Object.keys(this.orderByDate(this.state.success)).reverse().map((item) => {
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.success[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.success[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                          <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'failed' && (
               <React.Fragment>
               {Object.keys(this.orderByDate(this.state.failed)).reverse().map((item) => {
                 console.log(this.state.failed[item][0]);
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.failed[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.failed[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                          <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'unkown' && (
               <React.Fragment>
               {Object.keys(this.orderByDate(this.state.unkown)).reverse().map((item) => {
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.unkown[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.unkown[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                          <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                          <br /><br />
                          <Button onClick={(e) => this.deleteInsideTest(ev_._id)} variant="contained" style={{width: '50%', color: 'white', backgroundColor: 'red'}}>אני לא יכול לבצע מבחן פנימי זה</Button>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             {this.state.view === 'history' && (
               <React.Fragment>
               {Object.keys(this.orderByDate(this.state.calendar_history)).reverse().map((item) => {
                 return (
                   <Card>
                    <b>{item}, יום {this.hebDay(this.state.calendar_history[item][0].date)}</b>
                    <br />
                    <hr />
                    {this.state.calendar_history[item].map((ev_) => {
                      return (
                        <React.Fragment>
                        <div style={{pading: 10}}>
                          <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                          <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                          <p></p>
                        </div>
                        <hr />
                        </React.Fragment>
                      )
                    })}
                   </Card>
                 )
               })}
               </React.Fragment>
             )}
             </div>
          </center>
          </Grid>

          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.setDates}</h2>
              </div>

              <div>
                {this.state.pendings.map((item) => {
                  return (
                    <Card>
                      <hr />
                      <div>
                        <p>מבחן פנימי עם התלמיד {item.studentName} 🚗</p>
                        <a href={'tel:'+item.studentPhoneNumber} style={{textDecoration: 'none'}}>
                        <Button variant={'contained'} color={'primary'}>
                          <PhoneIcon style={{width: 20, height: 20}}/>
                          &nbsp;
                          <p>חייג לתלמיד</p>
                        </Button></a>
                        <br /><br />
                        <Link to={'/inside-test-time?id='+item._id} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'secondary'}>
                          לחץ לקביעת זמן המבחן פנימי
                        </Button></Link>
                        <br /><br />
                        <Button onClick={(e) => this.deleteInsideTest(item._id)} variant="contained" style={{width: '50%', color: 'white', backgroundColor: 'red'}}>אני לא יכול לבצע מבחן פנימי זה</Button>

                        <br />
                      </div>
                    </Card>
                  );
                })}
              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default MyInsideTests;
