import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import DescriptionIcon from '@material-ui/icons/Description';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import {
  Link
} from "react-router-dom";

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditCheque extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     chequeId: '',
     teachers: [],
     cheques: [],
     lastSeen: '',
     language: 'he',
     chequeNum: '',
     chequeAmount: 0,
     teacherInvoiceNumber: '',
     teacherInvoiceAmount: 0,
     originalteacherInvoiceNumber: '',
     originalteacherInvoiceAmount: 0,
     invoiceDelivered: false,

     isBankTransfer: false,
     bankAccountNumber: '',
     bankNumber: '',
     bankBranchNumber: ''
   }
  }

  getData = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({chequeId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'clerk/cheque-info?id='+this.state.chequeId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
             chequeNum: response.data.data.cheque.chequeNum,
             chequeAmount: response.data.data.cheque.chequeAmount,
             teacherInvoiceNumber: response.data.data.cheque.teacherInvoiceNumber,
             teacherInvoiceAmount: response.data.data.cheque.teacherInvoiceAmount,
             originalteacherInvoiceNumber: response.data.data.cheque.teacherInvoiceNumber,
             originalteacherInvoiceAmount: response.data.data.cheque.teacherInvoiceAmount,
             invoiceDelivered: response.data.data.cheque.invoiceDelivered,
             isBankTransfer: response.data.data.cheque.isBankTransfer,
             bankNumber: response.data.data.cheque.bankNumber,
             bankAccountNumber: response.data.data.cheque.bankAccountNumber,
             bankBranchNumber: response.data.data.cheque.bankBranchNumber,
             invoiceDelivered: response.data.data.cheque.invoiceDelivered
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בטעינת נתוני הצק')
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    })
  }

  getAmount = (amount) => {
    let money = parseFloat(amount).toFixed(2);
    if (money === null || money === undefined || isNaN(money)) return parseFloat(0.00);

    return money;
  }

  updateCheque = () => {
      let payload = {
        'isBankTransfer': this.state.isBankTransfer,
        'bankNumber': this.state.bankNumber,
        'bankAccountNumber': this.state.bankAccountNumber,
        'bankBranchNumber': this.state.bankBranchNumber,
        'chequeNum': this.state.chequeNum,
        'chequeAmount': this.state.chequeAmount
      }

      if (!this.state.invoiceDelivered) {
        if (this.state.teacherInvoiceNumber !== '') payload['teacherInvoiceNumber'] = this.state.teacherInvoiceNumber;
        if (this.state.teacherInvoiceAmount !== '' && this.state.teacherInvoiceAmount !== 0) payload['teacherInvoiceAmount'] = this.state.teacherInvoiceAmount;
      }
      console.log(payload);
      axios.put(API + 'clerk/cheque?id='+this.state.chequeId, payload, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          //alert('עודכן בהצלחה');
          window.location.href='/cheques';
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert("שגיאה בעדכון הצ'ק")
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
  }


    changeIsBankTransfer = () => {
      let isBankTransfer = this.state.isBankTransfer;

      if (isBankTransfer) {
        this.setState({isBankTransfer: false});
      } else {
        this.setState({isBankTransfer: true});
      }
    }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.writeacheque}</h2>

              <div style={{
                direction: 'rtl',
                width: '100%',
                textAlign: 'right',
              }}>
              <Checkbox
                value={this.state.isBankTransfer}
                onChange={(e) => this.changeIsBankTransfer()}
              /> {strings.isbanktransferc}
              </div>

              {this.state.isBankTransfer ? (
                <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                  <Select
                    value={this.state.bankNumber}
                    onChange={(e) => this.setState({bankNumber: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                  </Select>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                  <Input
                    value={this.state.bankAccountNumber}
                    onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                  <Input
                    value={this.state.bankBranchNumber}
                    onChange={(e) => this.setState({bankBranchNumber: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chequenum}</p>
                <Input
                  value={this.state.chequeNum}
                  onChange={(e) => this.setState({chequeNum: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />


                </React.Fragment>
              )}

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {this.state.isBankTransfer ? strings.banktransferamount : strings.chequeamount}</p>
              <Input
                value={this.state.chequeAmount}
                onChange={(e) => this.setState({chequeAmount: e.target.value})}
                type='number'
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '100%'
                }}
              />

              <p style={{textAlign: 'right'}}>{strings.teacherinvoicenumber}</p>
              {this.state.originalteacherInvoiceNumber !== '' ? (
                <Input
                  value={this.state.teacherInvoiceNumber}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                  disabled={true}
                />
              ) : (
                <Input
                  value={this.state.teacherInvoiceNumber}
                  onChange={(e) => this.setState({teacherInvoiceNumber: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />
              )}

              <p style={{textAlign: 'right'}}>{strings.teacherinvoiceamount}</p>
              {this.state.originalteacherInvoiceNumber !== '' ? (
                <Input
                  value={this.state.teacherInvoiceAmount}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                  disabled={true}
                />
              ) : (
                <Input
                  value={this.state.teacherInvoiceAmount}
                  onChange={(e) => this.setState({teacherInvoiceAmount: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />
              )}

              <br /><br />
              {(this.state.teacherInvoiceNumber !== '') ? (
                <div>
                  {(parseFloat(this.state.teacherInvoiceAmount) !== parseFloat(this.state.chequeAmount)) ? (
                    <div>
                      <p>{strings.chequeamountnotequal}</p>
                      <Button variant={'contained'} color={'grey'}>{strings.create}</Button>
                    </div>
                  ) : (
                    <Button onClick={(e) => this.updateCheque()} variant={'contained'} color={'primary'}>{strings.update}</Button>
                  )}
                </div>
              ) : (
                <Button onClick={(e) => this.updateCheque()} variant={'contained'} color={'primary'}>{strings.update}</Button>
              )}
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditCheque;
