import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import SelectSearch from 'react-select-search';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SMSSetInsideTest extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',
     teacherId: '',
     teachers: [],

     testDate: new Date(Date.now()),
     testTime: new Date(Date.now()),

     withoutDates: false,
     displayPermissionsNumberTesting: false,
     professionalTeachers: [],
     insidetestExists: false,
     studentname: '',
     done: false
   }
  }

  getData = async () => {
    let name = 'stid';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    const id = decodeURIComponent(results[2].replace(/\+/g, ' '));
    axios.get(API + 'teacher/sms-set-inside-test?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          professionalTeachers: response.data.data.professionalteachers,
          insidetestExists: response.data.data.insidetestexists,
          studentname: response.data.data.studentname,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  chooseTeacher = (id) => {
    let name = 'stid';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    const sid = decodeURIComponent(results[2].replace(/\+/g, ' '));
    axios.put(API + 'teacher/sms-set-inside-test-choose?sid='+sid+'&tid='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          done: true
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
              <center>
              <div style={{maxWidth: '90%'}}>
                <h3>בחרו מורה לביצוע מ.פ לתלמיד {this.state.studentname}</h3>

                {this.state.done ? (
                  <h3 style={{color: 'green'}}>מורה נבחר בהצלחה, אתם יכולים לסגור את החלון.</h3>
                ) : (
                  <>
                  {this.state.insidetestExists && (
                    <h3 style={{color: 'red'}}>נראה שקיים כבר מ.פ שלא בוצע לתלמיד, אם תחבר בשנית, מבחן נוסף יווצר</h3>
                  )}
                  {this.state.professionalTeachers.map((teacher, i) => {
                    return (
                      <Card elevation={5}>
                        <div style={{padding: '4%'}}>
                          <h2>{teacher.firstName} {teacher.lastName} ({teacher.idNumber})</h2>
                          <br />
                          <Button onClick={(e) => this.chooseTeacher(teacher._id)} style={{fontSize: '1.2em'}} variant="contained" color="primary">בחר במורה</Button>
                        </div>
                      </Card>
                    )
                  })}
                  </>
                )}
              </div>
              </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SMSSetInsideTest;
