import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import moment from 'moment';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ManagerEditStudent extends Component {
  componentDidMount () {
    this.getData();
    this.getManager();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     firstName: '',
     lastName: '',
     idNumber: '',
     gender: '',
     birthday: '01-01-1995',
     email: '',
     phoneNumber: '',
     licenseType: '',
     status: '',
     paidSignupFee: '',
     paidInsideTestFee: '',
     paidInsideTestFee: '',
     canSignWithBrosh: false,
     canBeChargedTwiceForSignupFee: false,
     broshDate: '',
     broshClerk: '',

     lastSeen: '',
     language: 'he',

     allowedDrivingLicenseTypes: [],
     allowedDrivingLicenseTypesAge: [],
     age: 15,

     canDeleteStudent: false,
     haveInsideTests: false,
     haveSignUpFee: false,
     ptorGiverSignupUserId:'',
     ptorGiverInsideTestUserId:'',
     teachersPtorSignup: [],
     teachersPtorInsideTest: [],
     teachers: [],
     birthdayDialog: false
   }
  }

  getManager = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          haveInsideTests: response.data.haveInsideTests,
          haveSignUpFee: response.data.haveSignUpFee,
          teachers: response.data.data.employees
        }, () => {
          let teachersPtorSignup = [];
          let teachersPtorInsideTest = [];

          this.state.teachers.forEach((item, i) => {
            if (item.canGivePtorSignup) teachersPtorSignup.push(item);
            if (item.canGivePtorInsideTest) teachersPtorInsideTest.push(item);
          });

          this.setState({
            teachersPtorSignup: teachersPtorSignup,
            teachersPtorInsideTest: teachersPtorInsideTest,
          })
        });
      } else {
        alert('שגיאה בקבלת הפרופיל מהשרת')
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      // alert(strings.errorPleaseTryAgain);
      alert('שגיאת מערכת (קוד 1772)')
    });
  }

  getAge = (date) => {
      this.setState({birthday: date}, () => {
        var today = new Date();
        var birthDate = new Date(date);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        console.log(age);
        if (age < 100) {
          this.setState({age: age, allowedDrivingLicenseTypesAge: []}, () => {
            if (age < 15 ) {
              this.setState({allowedDrivingLicenseTypesAge: []});
            } else if (age === 15) {
              let allowedDrivingLicenseTypesAge = [];

              allowedDrivingLicenseTypesAge.push('1')
              allowedDrivingLicenseTypesAge.push('D3')
              allowedDrivingLicenseTypesAge.push('D2')

              console.log(allowedDrivingLicenseTypesAge);
              this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                console.log('::');
                console.log(this.state.allowedDrivingLicenseTypesAge);
              });
            } else if (age <= 16) {
              let allowedDrivingLicenseTypesAge = [];
              allowedDrivingLicenseTypesAge.push('1')
              allowedDrivingLicenseTypesAge.push('D3')
              allowedDrivingLicenseTypesAge.push('D2')
              allowedDrivingLicenseTypesAge.push('B_automate')
              allowedDrivingLicenseTypesAge.push('B_manual')
              allowedDrivingLicenseTypesAge.push('A2')
              console.log(allowedDrivingLicenseTypesAge);
              this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                console.log('::');
                console.log(this.state.allowedDrivingLicenseTypesAge);
              });
            } else if (age <= 17) {
              let allowedDrivingLicenseTypesAge = [];
              allowedDrivingLicenseTypesAge.push('1')
              allowedDrivingLicenseTypesAge.push('D3')
              allowedDrivingLicenseTypesAge.push('D2')
              allowedDrivingLicenseTypesAge.push('B_automate')
              allowedDrivingLicenseTypesAge.push('B_manual')
              allowedDrivingLicenseTypesAge.push('A2')
              allowedDrivingLicenseTypesAge.push('C1')
              console.log(allowedDrivingLicenseTypesAge);
              this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                console.log('::');
                console.log(this.state.allowedDrivingLicenseTypesAge);
              });
            } else if (age >= 18) {
              let allowedDrivingLicenseTypesAge = [];
              allowedDrivingLicenseTypesAge.push('1')
              allowedDrivingLicenseTypesAge.push('D3')
              allowedDrivingLicenseTypesAge.push('D2')
              allowedDrivingLicenseTypesAge.push('B_automate')
              allowedDrivingLicenseTypesAge.push('B_manual')
              allowedDrivingLicenseTypesAge.push('A2')
              allowedDrivingLicenseTypesAge.push('C1')
              allowedDrivingLicenseTypesAge.push('C')
              allowedDrivingLicenseTypesAge.push('C+E')
              allowedDrivingLicenseTypesAge.push('C_E')
              allowedDrivingLicenseTypesAge.push('CE')
              allowedDrivingLicenseTypesAge.push('D')
              allowedDrivingLicenseTypesAge.push('D1')
              allowedDrivingLicenseTypesAge.push('A')
              allowedDrivingLicenseTypesAge.push('A1')
              console.log(allowedDrivingLicenseTypesAge);
              this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                console.log('::');
                console.log(this.state.allowedDrivingLicenseTypesAge);
              });
            } else {
              this.setState({allowedDrivingLicenseTypesAge: []});
            }

          });
        }
      });
  }

  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10;
  }

  validate = () => {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let idNumber = this.state.idNumber;
    let birthday = this.state.birthday;
    let phoneNumber = this.state.phoneNumber;

    if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
      alert(strings.mustFillAllFields);
      return false;
    }

    if (phoneNumber.length !== 10 || !this.validatePhoneNumber(phoneNumber)) {
      alert(strings.phoneNotGood);
      return false
    }

    if (!this.validateID(idNumber)) {
      alert(strings.idNumberNotGood);
      return false;
    }

    if (parseInt(new Date(this.state.birthday).getFullYear()) < 1900 || parseInt(new Date(this.state.birthday).getFullYear()) >= parseInt(new Date(Date.now()).getFullYear())){
      alert('תאריך לידה אינו תיקני')
      return false;
    }

    return true;
  }


  getData = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({studentId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'manager/student?id='+this.state.studentId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            firstName: response.data.data.student.firstName,
            lastName: response.data.data.student.lastName,
            idNumber: response.data.data.student.idNumber,
            gender: response.data.data.student.gender,
            birthday: response.data.data.student.birthday,
            email: response.data.data.student.email,
            phoneNumber: response.data.data.student.phoneNumber,
            licenseType: response.data.data.student.licenseType,
            status: response.data.data.student.status,
            lastSeen: response.data.lastSeen,
            canSignWithBrosh: response.data.data.student.canSignWithBrosh,
            broshDate: response.data.data.student.broshDate,
            broshClerk: response.data.data.student.broshClerk,
            paidSignupFee: response.data.data.student.paidSignupFee,
            paidInsideTestFee: response.data.data.student.paidInsideTestFee,
            canBeChargedTwiceForSignupFee: response.data.data.student.canBeChargedTwiceForSignupFee,
            allowedDrivingLicenseTypes: response.data.data.allowedDrivingLicenseTypes
          }, () => {
            let birthday = this.state.birthday;
            birthday = new Date(birthday);
            this.getAge(birthday);

            let s = response.data.data.student;
            if (!s.paidSignupFee && !s.paidInsideTestFee && !s.paidInsideTestPtor && !s.paidSignupFeePtor) {
              this.setState({canDeleteStudent: true});
            }
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת מידע על התלמיד');
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  updateUser = () => {
    if (this.state.age >= 15 && this.state.age <= 80) {


      if (this.state.allowedDrivingLicenseTypes.length === 1 && this.state.allowedDrivingLicenseTypesAge.includes(this.state.allowedDrivingLicenseTypes[0])) {
        this.setState({licenseType: this.state.allowedDrivingLicenseTypes[0]}, () => {
          if (this.validate()) {
            // let payload = {
            //   firstName: this.state.firstName,
            //   lastName: this.state.lastName,
            //   idNumber: this.state.idNumber,
            //   gender: this.state.gender,
            //   birthday: this.state.birthday,
            //   email: this.state.email,
            //   phoneNumber: this.state.phoneNumber,
            //   licenseType: this.state.licenseType,
            //   status: this.state.status,
            //   canSignWithBrosh: this.state.canSignWithBrosh,
            //   broshDate: this.state.broshDate,
            //   broshClerk: this.state.broshClerk,
            //   paidSignupFee: this.state.paidSignupFee,
            //   paidInsideTestFee: this.state.paidInsideTestFee,
            //   canBeChargedTwiceForSignupFee: this.state.canBeChargedTwiceForSignupFee,
            // }
            let payload = {
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              idNumber: this.state.idNumber,
              gender: this.state.gender,
              birthday: this.state.birthday,
              email: this.state.email,
              phoneNumber: this.state.phoneNumber,
              licenseType: this.state.licenseType,
              status: this.state.status,
              canBeChargedTwiceForSignupFee: this.state.canBeChargedTwiceForSignupFee,
            }

            axios.put(API + 'manager/student?id='+this.state.studentId, payload, {
              headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
            })
            .then(response => {
              if (response.data.status === "ok") {
                //alert(strings.messageStudentUpdateSuccess);
                window.location.href='/students?id='+response.data.data.student.schoolId;
              } else {
                if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                  alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
                } else {
                  // alert(strings.errorPleaseTryAgain);
                  alert('שגיאה בעדכון התלמיד')
                }
              }
            })
            .catch(error => {
              alert(strings.errorPleaseTryAgain);
            });
          }
        })
      } else {
        if (this.validate()) {
          // let payload = {
          //   firstName: this.state.firstName,
          //   lastName: this.state.lastName,
          //   idNumber: this.state.idNumber,
          //   gender: this.state.gender,
          //   birthday: this.state.birthday,
          //   email: this.state.email,
          //   phoneNumber: this.state.phoneNumber,
          //   licenseType: this.state.licenseType,
          //   status: this.state.status,
          //   canSignWithBrosh: this.state.canSignWithBrosh,
          //   broshDate: this.state.broshDate,
          //   broshClerk: this.state.broshClerk,
          //   paidSignupFee: this.state.paidSignupFee,
          //   paidInsideTestFee: this.state.paidInsideTestFee,
          //   canBeChargedTwiceForSignupFee: this.state.canBeChargedTwiceForSignupFee,
          // }
          let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            idNumber: this.state.idNumber,
            gender: this.state.gender,
            birthday: this.state.birthday,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            licenseType: this.state.licenseType,
            status: this.state.status,
            canBeChargedTwiceForSignupFee: this.state.canBeChargedTwiceForSignupFee,
          }

          axios.put(API + 'manager/student?id='+this.state.studentId, payload, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            if (response.data.status === "ok") {
              //alert(strings.messageStudentUpdateSuccess);
              window.location.href='/students?id='+response.data.data.student.schoolId;
            } else {
              if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
              } else {
                // alert(strings.errorPleaseTryAgain);
                alert('שגיאה בעדכון התלמיד')
              }
            }
          })
          .catch(error => {
            alert(strings.errorPleaseTryAgain);
          });
        }
      }

    } else {
      if (this.state.age <= 0) alert('התאריך שהזנתם אינו חוקי!')
      if (this.state.age <= 15 && this.state.age >= 1) alert(strings.minimalageerror)
      if (this.state.age >= 80) alert('התאריך שהזנתם אינו חוקי!');
    }
  }

  removeStudent = () => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק את התלמיד/ה')) {
      axios.delete(API + 'manager/student?id='+this.state.studentId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert(strings.messageStudentDeletedSuccess);
          window.location.href = '/students?id='+response.data.data.student.schoolId;
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה במחיקת התלמיד')
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    } else {
      alert(strings.messageDeletionCancelled);
    }
  }

  showDateValue = () => {
    let date = this.state.birthday;
    console.log(moment(date).format("DD-MM-YYYY"));
    return moment(date).format("DD-MM-YYYY");
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.editstudent}</h2>

              {this.state.canDeleteStudent && (
                <Button onClick={(e) => this.removeStudent()} variant="contained" style={{marginTop: '0%', fontSize: '1em', width: '20%', backgroundColor: 'red', color: 'white', float: 'right'}}>
                    {strings.deletestudent}
                </Button>
              )}

              <br /><br /><br />
              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.firstname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.lastname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>ת.ז</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.idNumber}
                onChange={(e) => this.setState({idNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.email}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>{strings.phonenumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.birthday}</p>
              <p style={{textAlign: 'right'}}><b>{this.showDateValue()}</b></p>
              {/*<TextField
                value={this.state.birthday}
                onChange={(e) => this.getAge(e.target.value)}
                type='date'
                format='dd/mm/yyyy'
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              />*/}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={this.state.birthday}
                selecte={this.state.birthday}
                onChange={(value) => this.getAge(value)}
                open={this.state.birthdayDialog}
                onClick={(e) => this.setState({birthdayDialog: true})}
                onClose={(e) => this.setState({birthdayDialog: false})}
                disableFuture={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{
                  width: '100%'
                }}
                locale='he'
              />
              </MuiPickersUtilsProvider>

              <p style={{textAlign: 'right'}}>{strings.gender}</p>
              <Select
                value={this.state.gender}
                onChange={(e) => this.setState({gender: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={'male'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.male}</MenuItem>
                <MenuItem value={'female'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.female}</MenuItem>
                <MenuItem value={'other'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.othergender}</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.licensetype}</p>
              <React.Fragment>
                {this.state.allowedDrivingLicenseTypesAge.length === 0 && (
                  <p style={{textAlign: 'right', color: 'red'}}>אין רשיונות זמינים לגיל התלמיד</p>
                )}
                {this.state.allowedDrivingLicenseTypesAge.length >= 1 && (
                  <React.Fragment>
                  {this.state.allowedDrivingLicenseTypes.length >= 2 ? (
                    <Select
                      value={this.state.licenseType}
                      onChange={(e) => this.setState({licenseType: e.target.value})}
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                    >
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A1') && this.state.allowedDrivingLicenseTypes.includes('A1')) && (<MenuItem value={'A1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA1}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A2') && this.state.allowedDrivingLicenseTypes.includes('A2')) && (<MenuItem value={'A2'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA2}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A') && this.state.allowedDrivingLicenseTypes.includes('A')) && (<MenuItem value={'A'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_manual') && this.state.allowedDrivingLicenseTypes.includes('B_manual')) && (<MenuItem value={'B_manual'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_automate') && this.state.allowedDrivingLicenseTypes.includes('B_automate')) && (<MenuItem value={'B_automate'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB_}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C') && this.state.allowedDrivingLicenseTypes.includes('C')) && (<MenuItem value={'C'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C1') && this.state.allowedDrivingLicenseTypes.includes('C1')) && (<MenuItem value={'C1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC1}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C+E') && this.state.allowedDrivingLicenseTypes.includes('C+E')) && (<MenuItem value={'C+E'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeCA}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.allowedDrivingLicenseTypes.includes('D')) && (<MenuItem value={'D'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.allowedDrivingLicenseTypes.includes('D1')) && (<MenuItem value={'D1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD1}</MenuItem>)}
                    </Select>
                  ) : (
                    <React.Fragment>
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A1') && this.state.allowedDrivingLicenseTypes.includes('A1')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA1}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A2') && this.state.allowedDrivingLicenseTypes.includes('A2')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA2}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A') && this.state.allowedDrivingLicenseTypes.includes('A')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_automate') && this.state.allowedDrivingLicenseTypes.includes('B_manual')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_manual') && this.state.allowedDrivingLicenseTypes.includes('B_automate')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB_}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C') && this.state.allowedDrivingLicenseTypes.includes('C')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C1') && this.state.allowedDrivingLicenseTypes.includes('C1')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC1}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C+E') && this.state.allowedDrivingLicenseTypes.includes('C+E')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeCA}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.allowedDrivingLicenseTypes.includes('D')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D1') && this.state.allowedDrivingLicenseTypes.includes('D1')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD1}</p>)}
                    </React.Fragment>
                  )}
                  </React.Fragment>
                )}
              </React.Fragment>


              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.studentstatus}</p>
              <Select
                value={this.state.status}
                onChange={(e) => this.setState({status: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={'active'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.active}</MenuItem>
                <MenuItem value={'inactive'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.inactive}</MenuItem>
                <MenuItem value={'passed_test'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.passedtest}</MenuItem>
              </Select>

              {/*<p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.didSignedWithBrosh}</p>
              <Select
                value={this.state.canSignWithBrosh}
                onChange={(e) => this.setState({canSignWithBrosh: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
              </Select>

              {this.state.canSignWithBrosh && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.broshsignupdate}</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.broshDate}
                  onChange={(e) => this.setState({broshDate: e.target.value})}
                />

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.broshclerk}</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.broshClerk}
                  onChange={(e) => this.setState({broshClerk: e.target.value})}
                />
                </React.Fragment>
              )}*/}

              {/*<p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paidsignupfee}</p>
              <Select
                value={this.state.paidSignupFee}
                onChange={(e) => this.setState({paidSignupFee: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={'yes'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                <MenuItem value={'no'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                <MenuItem value={'ptor'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.ptor}</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paidinsidetestfee}</p>
              <Select
                value={this.state.paidInsideTestFee}
                onChange={(e) => this.setState({paidInsideTestFee: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={'yes'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                <MenuItem value={'no'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                <MenuItem value={'ptor'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.ptor}</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.canBeChargedTwiceForSignupFee}</p>
              <Select
                value={this.state.canBeChargedTwiceForSignupFee}
                onChange={(e) => this.setState({canBeChargedTwiceForSignupFee: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                  <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                  <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
              </Select>*/}

              <Button onClick={(e) => this.updateUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ManagerEditStudent;
