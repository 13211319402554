import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ManagerDashboard extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],
     employeesOriginal: [],

     lastSeen: '',

     language: 'he',

     type: '',

     messages: [],

     tickerMove: true,
     showTicker: true,

     string: '',
     haveInsideTests: false,
     canManagerPayToEmployees: false,
     haveSignUpFee: false,
     notFound: false,

     haveStore: false
   }
  }

  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let msgs = response.data.data.messages;

        this.setState({messages: msgs})
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          employeesOriginal: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
          haveSignUpFee: response.data.haveSignUpFee,
          haveInsideTests: response.data.haveInsideTests,
          canManagerPayToEmployees: response.data.canManagerPayToEmployees,
          haveStore: response.data.haveStore,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }


  search = (string) => {
  let employees = this.state.employeesOriginal;
  let output = [];

    employees.forEach((item, i) => {
      try {
        if (item.firstName.includes(string) || item.lastName.includes(string)) output.push(item);
        else if (item.idNumber.includes(string)) output.push(item);
        else if (item.schoolName.toString().includes(string)) output.push(item);
        else {

        }
      } catch (err) {
        // console.log(err);
      }
    });

    if (output.length > 0) {
      this.setState({employees: output});
    } else {
      this.setState({notFound: true});
    }
  }

  searchLive = (string) => {
    this.setState({string: string}, () => {
      let employees = this.state.employeesOriginal;
      let output = [];

        employees.forEach((item, i) => {
          try {
            if (item.firstName.includes(string) || item.lastName.includes(string)) output.push(item);
            else if (item.idNumber.includes(string)) output.push(item);
            else if (item.schoolName.toString().includes(string)) output.push(item);
            else {

            }
          } catch (err) {
            // console.log(err);
          }
        });

        // if (output.length > 0) {
          this.setState({employees: output});
        // } else {
        //   // this.setState({notFound: true});
        // }
    });
  }

  reset = () => {
    this.setState({employees: this.state.employeesOriginal, string: ''})
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
          <div>
          {this.state.showTicker && (
            <div class="ticker-wrap" onClick={(e) => this.setState({showTicker: false})}>
              <div class="ticker" onClick={(e) => this.setState({showTicker: false})}>
              {this.state.messages.map((index, i) => {
                if (i === 0) {
                  return (
                    <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                      <span onClick={(e) => this.setState({showTicker: false})}> {index.message} </span>
                    </div>
                  )
                } else {
                  return (
                    <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                      <span onClick={(e) => this.setState({showTicker: false})}>  {index.message} |&nbsp;  </span>
                    </div>
                  )
                }
              })}
              </div>
            </div>
          )}
          </div>
            {this.state.haveStore && (
              <React.Fragment>
                <Link
                  to={'/store/slogan'}
                  style={{textDecoration: 'none'}}>
                    <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                      ערוך את סלוגן החנות שלך
                    </Button>
                </Link><br />
              </React.Fragment>
            )}

            <Link
            to={'/commission-table'}
              style={{textDecoration: 'none'}}>
                <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                  קופות עמלות מורים ותשלומים
                </Button>
            </Link><br />

            <Link
            to={'/financial-obligations'}
              style={{textDecoration: 'none'}}>
                <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                  פורטל התחייבויות מורים
                </Button>
            </Link><br />

            <Link
            to={'/reports/employees'}
              style={{textDecoration: 'none'}}>
                <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                  ניהול דיווח שעות ויומני עובדים
                </Button>
            </Link><br />

            <Link
              to={'/permissions-active'}
              style={{textDecoration: 'none'}}>
                <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                  הרשאות: ניהול תלמידים פעילים ולא פעילים
                </Button>
            </Link>
            <br />
            <Link
              to={'/permissions-passedtest'}
              style={{textDecoration: 'none'}}>
                <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                  הרשאות: ניהול תלמידים עבר \ לא עבר טסט
                </Button>
            </Link>
            <br />

            <React.Fragment>
              <Link
                to={'/small-cashregister'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    קופה קטנה
                  </Button>
              </Link><br/>
            </React.Fragment>
            <React.Fragment>
              <Link
                to={'/teachers-income'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    צפייה בהכנסות מורים
                  </Button>
              </Link><br/>
            </React.Fragment>
            <React.Fragment>
              <Link
                to={'/small-cashregister-permissions'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    ניהול הרשאות: קופה קטנה
                  </Button>
              </Link><br/>
            </React.Fragment>

            <React.Fragment>
              <Link
                to={'/permissions-insidetest-sms'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    טבלת הרשאות: שליחת מסרון לקביעת מ.פ
                  </Button>
              </Link><br />
            </React.Fragment>

            <React.Fragment>
              <Link
                to={'/inside-tests-statistics'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    סטטיסטיקות מבחנים פנימיים
                  </Button>
              </Link>
            </React.Fragment>

            <br />

            <React.Fragment>
              <Link
                to={'/payment-document'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    הפק דוח תשלומים תקופתי
                  </Button>
              </Link>
            </React.Fragment>

            <br />
            <React.Fragment>
              <Link
                to={'/refunds'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    {strings.refunds}
                  </Button>
              </Link>
              <br />
              {/*<Link
                to={'/settings-money-transfer'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    <SettingsIcon style={{color: 'white'}} />{strings.moneyTransferSettings}
                  </Button>
              </Link>
              <br />*/}
              <Link
                to={'/messages'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    <ForumIcon style={{color: 'white'}} />{strings.messageSystem}
                  </Button>
              </Link>
              <br /><br />
            </React.Fragment>

            {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === 'professionalteacher') && (
              <React.Fragment>
                <Link to={'/view/teacher'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}><CommuteIcon style={{color: 'white'}} />{strings.teacherportal}</Button></Link>
                <br /><br />
              </React.Fragment>
            )}
            {(this.state.type === 'clerk') && (
              <React.Fragment>
                <Link to={'/view/clerk'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 270}}><WorkIcon style={{color: 'white'}} />{strings.clerkportal}</Button></Link>
                <br /><br />
              </React.Fragment>
            )}

            {/*  <Link to={'/transfer-money'} style={{textDecoration: 'none'}}><Button variant='outlined'><AccountBalanceIcon style={{color: '#404041'}} />{strings.mycashregisterandtransfermoney}</Button></Link>*/}
            <br />
            <Link to={'/generate-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 210}}><DescriptionIcon style={{color: 'white'}} />{strings.generateinvoices}</Button></Link>
            <br />
            {this.state.canManagerPayToEmployees && (
              <React.Fragment>
              <Link to={'/cheques'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><DescriptionIcon style={{color: 'white'}} />{strings.writeacheque}</Button></Link>
              <br />
              </React.Fragment>
            )}
            <Link to={'/manager/items'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><ShoppingCartIcon style={{color: 'white'}} />{strings.manageItems}</Button></Link>
            <br />
            <Link to={'/deposit/accounts'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{strings.depositaccounts}</Button></Link>
            <br />
            <Link to={'/deposit'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{strings.deposit}</Button></Link>
            <br />
            <Link to={'/payments/discover'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{strings.discoverPayments}</Button></Link>
            <br />
            <Link to={'/cash-registers'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{'צפה בקופות המורים'}</Button></Link>
            <br />
            <Link to={'/ptors-given'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{'מעקב פטורים'}</Button></Link>
            <br />
            <Link to={'/students-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{'חשבוניות תלמידים'}</Button></Link>
            <br />
          </Grid>

          <Grid item xs={12}>
            <center>
                <h2>{this.state.schools.length === 0 ? strings.myschool : strings.myschools }</h2>
            </center>
          </Grid>

          {this.state.schools.length === 0 ? (
            <React.Fragment>
              {isMobile ? (
                <React.Fragment>
                <Grid item xs={12}>
                  <center>
                  <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                    <CardContent>
                      <Chip label={strings.main} variant="outlined" style={{float: 'right', marginRight: '1%', backgroundColor: '#e2c573', fontWeight: 'bold'}}/>
                      <p style={{fontWeight: 'bold', color: '#404041', marginLeft: '25%'}}>{this.state.main.name} <SchoolIcon style={{width: 32, height: 32}}/></p>
                      <Link to={'/school/update?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="edit-school"><EditIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <Link to={'/employee/add?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="add-teacher"><PersonAddIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <IconButton alt="add-teacher"><AssignmentIcon style={{color: '#404041'}}/></IconButton>
                      <br /><br />
                      <Link to={'/students?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.studentsmanagement}</Button></Link>
                      <br /><br />
                      <Link to={'/school/special-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessage}</Button></Link>
                      <br /><br />
                      <Link to={'/school/payment-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePayment}</Button></Link>
                      <br /><br />

                      {this.state.haveSignUpFee && (
                        <React.Fragment>
                        <Link to={'/school/post-signup-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostSignup}</Button></Link>
                        <br /><br />
                        </React.Fragment>
                      )}


                      {this.state.haveInsideTests && (
                        <React.Fragment>
                        <Link to={'/school/post-insidetest-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostInsideTest}</Button></Link>
                        <br /><br />
                        <Link to={'/school/inside-test?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} />{strings.setInsideTest}</Button></Link>
                        <br /><br />
                        <Link to={'/school/inside-tests?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} /> &nbsp; {strings.insidetestlist}</Button></Link>
                        <br /><br />
                        </React.Fragment>
                      )}


                      <Link to={'/school/suppliers?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><ShoppingCartIcon style={{color: 'white'}} /> &nbsp; {strings.supplierslist}</Button></Link>

                    </CardContent>
                  </Card>
                 </center>
                </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <center>
                  <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                    <CardContent>
                      <Chip label={strings.main} variant="outlined" style={{float: 'right', marginRight: '1%', backgroundColor: '#e2c573', fontWeight: 'bold'}}/>
                      <p style={{fontWeight: 'bold', color: '#404041', marginLeft: '25%'}}>{this.state.main.name} <SchoolIcon style={{width: 32, height: 32}}/></p>
                      <Link to={'/school/update?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="edit-school"><EditIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <Link to={'/employee/add?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="add-teacher"><PersonAddIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <IconButton alt="add-teacher"><AssignmentIcon style={{color: '#404041'}}/></IconButton>
                      <br /><br />
                      <Link to={'/students?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.studentsmanagement}</Button></Link>
                      <br /><br />
                      <Link to={'/school/special-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessage}</Button></Link>
                      <br /><br />
                      <Link to={'/school/payment-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePayment}</Button></Link>
                      <br /><br />

                      {this.state.haveSignUpFee && (
                        <React.Fragment>
                        <Link to={'/school/post-signup-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostSignup}</Button></Link>
                        <br /><br />
                        </React.Fragment>
                      )}


                      {this.state.haveInsideTests && (
                        <React.Fragment>
                        <Link to={'/school/post-insidetest-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostInsideTest}</Button></Link>
                        <br /><br />
                        <Link to={'/school/inside-test?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} /> &nbsp; {strings.setInsideTest}</Button></Link>
                        <br /><br />
                        <Link to={'/school/inside-tests?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} /> &nbsp; {strings.insidetestlist}</Button></Link>
                        <br /><br />
                        </React.Fragment>
                      )}

                      <Link to={'/school/suppliers?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><ShoppingCartIcon style={{color: 'white'}} /> &nbsp; {strings.supplierslist}</Button></Link>

                    </CardContent>
                  </Card>
                 </center>
                </Grid>
                <Grid item xs={4}></Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
            {isMobile ? (
              <React.Fragment>
              <Grid item xs={12}>
                <center>
                <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <CardContent>
                    <Chip label={strings.main} variant="outlined" style={{float: 'right', marginRight: '1%', backgroundColor: '#e2c573', fontWeight: 'bold'}}/>
                    <p style={{fontWeight: 'bold', color: '#404041', marginLeft: '25%'}}>{this.state.main.name} <SchoolIcon style={{width: 32, height: 32}}/></p>
                    <Link to={'/school/update?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="edit-school"><EditIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <Link to={'/employee/add?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="add-teacher"><PersonAddIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <IconButton alt="add-teacher"><AssignmentIcon style={{color: '#404041'}}/></IconButton>
                    <br /><br />
                    <Link to={'/students?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.studentsmanagement}</Button></Link>
                    <br /><br />
                    <Link to={'/school/special-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessage}</Button></Link>
                    <br /><br />
                    <Link to={'/school/payment-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePayment}</Button></Link>
                    <br /><br />

                    {this.state.haveSignUpFee && (
                      <React.Fragment>
                      <Link to={'/school/post-signup-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostSignup}</Button></Link>
                      <br /><br />
                      </React.Fragment>
                    )}

                    {this.state.haveInsideTests && (
                      <React.Fragment>
                      <Link to={'/school/post-insidetest-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostInsideTest}</Button></Link>
                      <br /><br />
                      <Link to={'/school/inside-test?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} />{strings.setInsideTest}</Button></Link>
                      <br /><br />
                      <Link to={'/school/inside-tests?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} /> &nbsp; {strings.insidetestlist}</Button></Link>
                      <br /><br />
                      </React.Fragment>
                    )}

                    <Link to={'/school/suppliers?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><ShoppingCartIcon style={{color: 'white'}} /> &nbsp; {strings.supplierslist}</Button></Link>

                  </CardContent>
                </Card>
               </center>
              </Grid>
              {this.state.schools.reverse().map((school) => {
                return (
                  <Grid item xs={12}>
                    <center>
                    <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                      <CardContent>
                        <p style={{fontWeight: 'bold', color: '#404041'}}>{school.name} <SchoolIcon style={{width: 32, height: 32}}/></p>
                        <Link to={'/school/update?id='+school._id} style={{textDecoration: 'none'}}><IconButton alt="edit-school"><EditIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <Link to={'/employee/add?id='+school._id} style={{textDecoration: 'none'}}><IconButton alt="add-teacher"><PersonAddIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <IconButton alt="add-teacher"><AssignmentIcon style={{color: '#404041'}}/></IconButton>
                        <br /><br />
                        <Link to={'/students?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.studentsmanagement}</Button></Link>
                        <br /><br />
                        <Link to={'/school/special-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessage}</Button></Link>
                        <br /><br />
                        <Link to={'/school/payment-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePayment}</Button></Link>
                        <br /><br />

                        {this.state.haveSignUpFee && (
                          <React.Fragment>
                          <Link to={'/school/post-signup-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostSignup}</Button></Link>
                          <br /><br />
                          </React.Fragment>
                        )}

                        {this.state.haveInsideTests && (
                          <React.Fragment>
                          <Link to={'/school/post-insidetest-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostInsideTest}</Button></Link>
                          <br /><br />
                          <Link to={'/school/inside-test?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} /> &nbsp; {strings.setInsideTest}</Button></Link>
                          <br /><br />
                          <Link to={'/school/inside-tests?id='+school._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} /> &nbsp; {strings.insidetestlist}</Button></Link>
                          <br /><br />
                          </React.Fragment>
                        )}

                        <Link to={'/school/suppliers?id='+school._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><ShoppingCartIcon style={{color: 'white'}} /> &nbsp; {strings.supplierslist}</Button></Link>

                      </CardContent>
                    </Card>
                   </center>
                  </Grid>
                )
              })}
              </React.Fragment>
            ) : (
              <React.Fragment>
              <Grid item xs={4}>
                <center>
                <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <CardContent>
                    <Chip label={strings.main} variant="outlined" style={{float: 'right', marginRight: '1%', backgroundColor: '#e2c573', fontWeight: 'bold'}}/>
                    <p style={{fontWeight: 'bold', color: '#404041', marginLeft: '25%'}}>{this.state.main.name} <SchoolIcon style={{width: 32, height: 32}}/></p>
                    <Link to={'/school/update?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="edit-school"><EditIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <Link to={'/employee/add?id='+this.state.main._id} style={{textDecoration: 'none'}}><IconButton alt="add-teacher"><PersonAddIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <IconButton alt="add-teacher"><AssignmentIcon style={{color: '#404041'}}/></IconButton>
                    <br /><br />
                    <Link to={'/students?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.studentsmanagement}</Button></Link>
                    <br /><br />
                    <Link to={'/school/special-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessage}</Button></Link>
                    <br /><br />
                    <Link to={'/school/payment-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePayment}</Button></Link>
                    <br /><br />

                    {this.state.haveSignUpFee && (
                      <React.Fragment>
                      <Link to={'/school/post-signup-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostSignup}</Button></Link>
                      <br /><br />
                      </React.Fragment>
                    )}

                    {this.state.haveInsideTests && (
                      <React.Fragment>
                      <Link to={'/school/post-insidetest-message?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostInsideTest}</Button></Link>
                      <br /><br />
                      <Link to={'/school/inside-test?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} />{strings.setInsideTest}</Button></Link>
                      <br /><br />
                      <Link to={'/school/inside-tests?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} /> &nbsp; {strings.insidetestlist}</Button></Link>
                      <br /><br />
                      </React.Fragment>
                    )}

                    <Link to={'/school/suppliers?id='+this.state.main._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><ShoppingCartIcon style={{color: 'white'}} /> &nbsp; {strings.supplierslist}</Button></Link>

                  </CardContent>
                </Card>
               </center>
              </Grid>
              {this.state.schools.reverse().map((school) => {
                return (
                  <Grid item xs={4}>
                    <center>
                    <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                      <CardContent>
                        <p style={{fontWeight: 'bold', color: '#404041'}}>{school.name} <SchoolIcon style={{width: 32, height: 32}}/></p>
                        <Link to={'/school/update?id='+school._id} style={{textDecoration: 'none'}}><IconButton alt="edit-school"><EditIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <Link to={'/employee/add?id='+school._id} style={{textDecoration: 'none'}}><IconButton alt="add-teacher"><PersonAddIcon style={{color: '#404041'}}/></IconButton></Link> &nbsp; <IconButton alt="add-teacher"><AssignmentIcon style={{color: '#404041'}}/></IconButton>
                        <br /><br />
                        <Link to={'/students?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.studentsmanagement}</Button></Link>
                        <br /><br />
                        <Link to={'/school/special-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessage}</Button></Link>
                        <br /><br />
                        <Link to={'/school/payment-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePayment}</Button></Link>
                        <br /><br />

                        {this.state.haveSignUpFee && (
                          <React.Fragment>
                          <Link to={'/school/post-signup-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostSignup}</Button></Link>
                          <br /><br />
                          </React.Fragment>
                        )}

                        {this.state.haveInsideTests && (
                          <React.Fragment>
                          <Link to={'/school/post-insidetest-message?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'>{strings.addStudentMessagePaymentPostInsideTest}</Button></Link>
                          <br /><br />
                          <Link to={'/school/inside-test?id='+school._id} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} />{strings.setInsideTest}</Button></Link>
                          <br /><br />
                          <Link to={'/school/inside-tests?id='+school._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} /> &nbsp; {strings.insidetestlist}</Button></Link>
                          <br /><br />
                          </React.Fragment>
                        )}

                        <Link to={'/school/suppliers?id='+school._id} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><ShoppingCartIcon style={{color: 'white'}} /> &nbsp; {strings.supplierslist}</Button></Link>

                      </CardContent>
                    </Card>
                   </center>
                  </Grid>
                )
              })}
              </React.Fragment>
            )}
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.myemployees}</h2>
          </center>

          <Button color={'secondary'} variant={'contained'} onClick={(e) => this.reset()}>איפוס</Button>
          &nbsp;&nbsp;
          {/*<Button color={'primary'} variant={'contained'} onClick={(e) => this.search(this.state.string)}>חיפוש</Button>*/}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            value={this.state.string}
            onChange={(e) => this.searchLive(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.employees.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.emptyemployeesstring1}
          <PersonAddIcon style={{width: 32, height: 32}}/><br/>
           {strings.emptyemployeesstring2}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.edit}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.school}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.position}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.employees.reverse().map((employee, i) => {
                  return (
                    <TableRow key={i+'_employee'}>
                      <TableCell align="right">
                        {employee.frozen ? (
                          <p style={{fontWeight: 'bold', color: 'red'}}>{strings.frozen}</p>
                        ) : (
                          <p style={{fontWeight: 'bold', color: 'green'}}>{strings.active}</p>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Link to={'/employee/update?id='+employee._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                      </TableCell>
                      <TableCell align="right">{employee.schoolName}</TableCell>
                      <TableCell align="right">{new Date(employee.createdAt).getDate() + '/' + parseInt(new Date(employee.createdAt).getMonth() + 1) + '/' + new Date(employee.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{this.modifyType(employee.type)}</TableCell>
                      <TableCell align="right">{employee.firstName + ' ' + employee.lastName}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>
        <Snackbar open={this.state.notFound} autoHideDuration={3000} onClose={() => this.setState({notFound: false})}>
          <Alert onClose={() => this.setState({notFound: false})} severity="warning">
          לא נמצאו רשומות חיפוש, אנא נסו בשנית
          </Alert>
        </Snackbar>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ManagerDashboard;
