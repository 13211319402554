import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ManageObligation extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     dateOpen: false,
     date: new Date(Date.now()),
     openEdit: false,
     obligation: {
       'description': '',
       'teacher': {
         'firstName': '',
         'lastName': ''
       },
       'amountLeft': ''
     }
   }
  }

  getData = async () => {
    let link = window.location.href;
    let id = link.split('?id=')[1];
    axios.get(API + 'obligation?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          obligation: response.data.obligation,
          payments: response.data.payments,
        }, () => {
          const obligation = this.state.obligation;
          this.setState({
            description: obligation.description,
            amount: obligation.amount,
            date: new Date(obligation.date)
          })
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  edit = () => {
    let link = window.location.href;
    let id = link.split('?id=')[1];

    let payload = {
      description: this.state.description,
      date: this.state.date,
      amount: parseFloat(this.state.amount)
    };

    return axios.put('https://api.sivan-school.com/v1/obligation?id='+id, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(res => {
      console.log(res)
      if (res.data.status === "ok") {
        alert('ההתחייבות עודכנה בהצלחה')
        window.location.reload();
      } else {
        alert('שגיאה בעדכון ההתחייבות, אנא נסו בשנית');
      }
    }).catch(err => {
      console.log(err)
      alert('שגיאה בעדכון ההתחייבות, אנא נסו בשנית');
    })
  }

  addPayment = () => {
    let link = window.location.href;
    let id = link.split('?id=')[1];

    let payload = {
      type: this.state.type,
      bankNumber: this.state.bankNumber,
      branchNumber: this.state.branchNumber,
      bankAccountNumber: this.state.bankAccountNumber,
      dateOfPayment: this.state.dateOfPayment,
      description: this.state.description,
      applicationType: this.state.applicationType,
      applicationNumber: this.state.applicationNumber,
      chequeNum: this.state.chequeNum,
      transactionId: this.state.transactionId,
      expdate: this.state.expdatemonth +''+ this.state.expdateyear,
      ccno: this.state.ccno,
      cvv: this.state.cvv,
      idNumberOwner: this.state.idNumberOwner,
      amount: parseFloat(this.state.amount),
      date: new Date(Date.now()),
      paymentType: this.state.type
    }

    if (this.state.type === 'tranzila') payload['dateOfPayment'] = new Date(Date.now());

    axios.post(API + 'obligation/payment?id='+id, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        alert('הרשומה נוספה בהצלחה');
        window.location.reload();
      } else {
        alert('שגיאה בהוספת רשומת תשלום');
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  modifyPaymentType = (paymentType) => {
    if (paymentType === "bankTransfer") return 'העברה בנקאית';
    else if (paymentType === "cashregister") return 'קופת מורה';
    else if (paymentType === "cash") return 'מזומן';
    else if (paymentType === "cheque") return 'שיק';
    else if (paymentType === "application") return 'אפליקציה';
    else if (paymentType === "tranzila") return 'אשראי';
    else return 'העברה בנקאית';

    return 'העברה בנקאית';
  }

  cancelInvoice = (id) => {
    let c = window.confirm('האם אתם בטוחים שברצונכם לבטל חשבונית זאת? שימו לב שזוהי פעולה לא הפיכה. מספר החשבונית לא ישמר זהה בחשבונית חדשה');
    if (c) {
      axios.delete(API + 'obligation-payment?id='+id, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert('הרשומה בוטלה בהצלחה');
          window.location.reload();
        } else {
          alert('שגיאה בביטול הרשומה');
        }
      })
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>ניהול התחייבות כספית</h1>
              <h3>{this.state.obligation.description} | {this.state.obligation.teacher.firstName + ' ' + this.state.obligation.teacher.lastName}</h3>
              <h3>נותר לתשלום: {this.state.obligation.amountLeft} ש"ח</h3>
              {this.state.openEdit ? (
                <Button variant={'contained'} color={'secondary'} onClick={(e) => this.setState({openEdit: false})}>סגירת עריכת נתוני ההתחייבות</Button>
              ) : (
                <Button variant={'contained'} color={'primary'} onClick={(e) => this.setState({openPayment: false, openEdit: true})}>עריכת נתוני ההתחייבות</Button>
              )}
              &nbsp;&nbsp;&nbsp;
              {this.state.openPayment ? (
                <Button variant={'contained'} color={'secondary'} onClick={(e) => this.setState({openPayment: false})}>סגירת הוספת רשומת תשלום חדשה</Button>
              ) : (
                <Button variant={'contained'} color={'primary'} onClick={(e) => this.setState({openEdit: false, openPayment: true})}>הוספת רשומת תשלום חדשה</Button>
              )}
            </center>
          </Grid>

          <Grid item xs={12}>
            <center>
              {this.state.openPayment && (
                <div style={{maxWidth: '80%'}}>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סכום בש"ח (מספרים בלבד)</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.amount}
                    onChange={(e) => this.setState({amount: e.target.value})}
                    required
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paywith}</p>
                  <Select
                    value={this.state.type}
                    onChange={(e) => this.setState({type: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cashregister'}>באמצעות קופת המורה</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cash'}>{strings.cash}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cheque'}>{strings.cheque}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bankTransfer'}>{strings.bankTransfer}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'application'}>{strings.application}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'tranzila'}>{strings.tranzila}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'barter'}>{strings.barter}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'ptor'}>{strings.ptor}</MenuItem>
                  </Select>

                  {this.state.type === 'tranzila' && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.creditcardnumber}</p>
                    <Input
                      value={this.state.ccno}
                      onChange={(e) => this.setState({ccno: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.expdatenumber}</p>
                    <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.month}</p>
                    <Select
                      value={this.state.expdatemonth}
                      onChange={(e) => this.setState({expdatemonth: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>01</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'02'}>02</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'03'}>03</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>04</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'05'}>05</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'06'}>06</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>07</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>08</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>09</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>10</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>11</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>12</MenuItem>
                    </Select>
                    <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.year}</p>
                    <Select
                      value={this.state.expdateyear}
                      onChange={(e) => this.setState({expdateyear: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear() % 100) + i).map((index) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.toString()}>{index.toString()}</MenuItem>
                      )
                    })}
                    </Select>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.cvvnumber}</p>
                    <Input
                      value={this.state.cvv}
                      onChange={(e) => this.setState({cvv: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                    <p style={{textAlign: 'right', direction: 'rtl'}}>{strings.idnumbercardowner} <span style={{color: 'red'}}>*</span></p>
                    <TextField
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                      value={this.state.idNumberOwner}
                      onChange={(e) => this.setState({idNumberOwner: e.target.value})}
                    />
                  </React.Fragment>
                )}


                {this.state.type === "application" && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseapplication}</p>
                    <Select
                      value={this.state.applicationType}
                      onChange={(e) => this.setState({applicationType: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bit'}>{strings.bit}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'paybox'}>{strings.paybox}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'pepper'}>{strings.pepper}</MenuItem>
                    </Select>
                  </React.Fragment>
                )}

                {(this.state.type === 'bankTransfer' || this.state.type === 'cheque') && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                  <Select
                    value={this.state.bankNumber}
                    onChange={(e) => this.setState({bankNumber: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                  </Select>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                  <Input
                    value={this.state.branchNumber}
                    onChange={(e) => this.setState({branchNumber: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                  <Input
                    value={this.state.bankAccountNumber}
                    onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />
                  </React.Fragment>
                )}


                  <br />
                  <br />
                  <Button color={'primary'} variant={'contained'} onClick={(e) => this.addPayment()}>הוספת רשומת תשלום</Button>
                </div>
              )}


              {this.state.openEdit && (
                <div style={{maxWidth: '80%'}}>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> תיאור ההתחייבות</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.description}
                    onChange={(e) => this.setState({description: e.target.value})}
                    required
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סכום בש"ח (מספרים בלבד)</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.amount}
                    onChange={(e) => this.setState({amount: e.target.value})}
                    required
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> תאריך</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        value={this.state.date}
                        open={this.state.dateOpen}
                        onClick={(e) => this.setState({dateOpen: true})}
                        onClose={(e) => this.setState({dateOpen: false})}
                        onChange={(date) => this.setState({dateOpen: date})}
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          direction: 'rtl'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <br />
                  <Button color={'primary'} variant={'contained'} onClick={(e) => this.edit()}>עריכת ההתחייבות</Button>
                </div>
              )}

              <h3><u>תשלומים להתחייבות זאת</u></h3>
              <center>
              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>ביטול החשבונית</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>שליחת חשבונית בווטצאפ</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>חשבונית</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>שולם באמצעות</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>סכום</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.payments && this.state.payments.reverse().map((payment, i) => {
                        return (
                          <TableRow key={i+'_obligation'}>
                            <TableCell align="right">
                              <Button onClick={(e) => this.cancelInvoice(payment._id)} variant={'contained'} style={{color: 'white', backgroundColor: 'red'}}>ביטול חשבונית</Button>
                            </TableCell>
                            <TableCell align="right">
                              <Button onClick={(e) => window.open('https://wa.me/972'+this.state.obligation.teacher.phoneNumber+'?text=https://sivan-school.com/pdfs/ob_'+payment._id.toString()+'.pdf', '_blank')} variant={'contained'} color={'primary'}>שליחה</Button>
                            </TableCell>
                            <TableCell align="right">
                              <Button onClick={(e) => window.open('https://sivan-school.com/pdfs/ob_'+payment._id.toString()+'.pdf', '_blank')} variant={'contained'} color={'primary'}>צפה בחשבונית</Button>
                            </TableCell>
                            <TableCell align="right">{new Date(payment.createdAt).getDate() + '/' + parseInt(new Date(payment.createdAt).getMonth() + 1) + '/' + new Date(payment.createdAt).getFullYear()}</TableCell>
                            <TableCell align="right">{this.modifyPaymentType(payment.paymentType)}</TableCell>
                            <TableCell align="right">{payment.amount}</TableCell>
                          </TableRow>
                        );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              </center>
            </center>
          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ManageObligation;
