import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';
import DeleteIcon from '@material-ui/icons/Delete';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ObligationsDashboard extends Component {
  componentDidMount() {
    this.getTeachers();
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     obligations: [],
     obligationsTeachers: [],
     obligationsPayments: {},
     teachers: [],
     display: '',
     groupObligationByName: {},
     hidePayments: false,
     paymentToRefund: {},
     ccno: '',
     expdate: '',
     cvv: '',
     link: '',
     expdateyear: new Date(Date.now()).getFullYear(),
     expdatemonth: (new Date(Date.now()).getMonth() + 1).toString(),
     teacherChosen: {},
     teacherChosenActive: false,
     sum_left: 0,
     sum: 0,
     sum_between: 0,
     usePaymentLink: false,
     obligationPaymentsRequests: {},
     paidByRequestsByTeacherId: {},
     sumRequests: 0,
     editTeachersPaymentsForLinkOn: false,
     teachersPayload: {},
     signlePaymentLinkOn: "",
     signlePaymentLinkAmount: 0
   }
  }


  getTeachers = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({teachers: response.data.data.employees})
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getData = async () => {
    axios.get(API + 'obligations-full', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        let obligations = response.data.data.obligations;
        let payments = response.data.data.payments;
        let usePaymentLink = false;
        try {
          usePaymentLink = response.data.data.schools.main.usePaymentLink;
          this.setState({usePaymentLink: usePaymentLink})
        } catch (err) {

        }
        console.log('usePaymentLink:', usePaymentLink)
        obligations.forEach((item, i) => {
          if (payments[item._id.toString()] === undefined) {
            payments[item._id.toString()] = [];
          }
        });
        let obligationPaymentsRequestsByTeacher = {};
        let paidByRequestsByTeacherId = {}
        response.data.data.obligationPaymentsRequests.forEach((item) => {
          if (obligationPaymentsRequestsByTeacher[item.teacherId] !== undefined) {
            obligationPaymentsRequestsByTeacher[item.teacherId].push(item);
            paidByRequestsByTeacherId[item.teacherId] += parseInt(item.amountWithVat)
          } else {
            paidByRequestsByTeacherId[item.teacherId] = parseInt(item.amountWithVat)
            obligationPaymentsRequestsByTeacher[item.teacherId] = []
            obligationPaymentsRequestsByTeacher[item.teacherId].push(item);
          }
        })
        console.log(obligationPaymentsRequestsByTeacher)
        this.setState({
          obligations: response.data.data.obligations,
          obligationsTeachers: response.data.data['obligations-teachers-full'],
          obligationsPayments: payments,
          obligationPaymentsRequests: obligationPaymentsRequestsByTeacher,
          paidByRequestsByTeacherId: paidByRequestsByTeacherId
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error)
      alert(strings.errorPleaseTryAgain);
    });
  }


    modifyPaymentType = (paymentType) => {
      if (paymentType === "bankTransfer") return 'העברה בנקאית';
      else if (paymentType === "cashregister") return 'קופת מורה';
      else if (paymentType === "cash") return 'מזומן';
      else if (paymentType === "cheque") return 'שיק';
      else if (paymentType === "application") return 'אפליקציה';
      else if (paymentType === "tranzila") return 'אשראי';
      else return 'העברה בנקאית';

      return 'העברה בנקאית';
    }

    refund = () => {
      let a = window.confirm('האם אתם בטוחים שברצונכם לזכות רשומה זאת?');
      if (a) {
        let payment = this.state.paymentToRefund;
        let expdate = this.state.expdatemonth +''+ this.state.expdateyear;
        axios.post(API + 'obligations-refund?id='+payment._id.toString()+'&type='+payment.paymentType+'&ccno='+this.state.ccno+'&expdate='+expdate+'&mycvv='+this.state.cvv, {}, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          // console.log(response);
          if (response.data.status === "ok") {
            this.setState({link: response.data.link})
            window.scrollTo(0,0)
          } else {
            alert('שגיאה במתן זיכוי');
          }
        }).catch(err => {
          console.log(err);
          alert('שגיאה במתן זיכוי');
        });
      }
    }

      modifyDate = (date) => {
        try {
          date = new Date(date);
          return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        } catch (err) {
          return '';
        }
      }

      chooseTeacher = (teacher) => {
        this.setState({
          teacherChosen: teacher,
          teacherChosenActive: true
        }, () => {
          let total = 0;
          let sum_left = 0;
          let sum = 0;
          let sumRequests = 0;
          this.state.teacherChosen.obligations.forEach((item, i) => {
            total = parseFloat(total) + parseFloat(item.amount);
            sum_left = parseFloat(sum_left) + parseFloat(item.amountLeft);
            sum = parseFloat(sum) + parseFloat(item.amount);
          });
          try {
            this.state.obligationPaymentsRequests[this.state.teacherChosen['teacher']['_id']].forEach((item) => {
              sumRequests += parseInt(item.amountWithVat)  
            }); 
          } catch(err) {

          }
          this.setState({
            sum_left: sum_left,
            sum: sum,
            sumRequests: sumRequests
          });
        });
      }

      removeObligation = (id) => {
        let a = window.confirm('האם אתם בטוחים שברצונכים למחוק התחייבות  זו?');
        if (a) {
          return axios.delete(API + 'obligation?id='+id, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          }).then(obligation => {
            this.getData();
            alert('נמחק בהצלחה');
          }).catch(err => {
            alert('חלה שגיאה במחיקת ההתחייבות');
          });
        }
      }
      sortByDate = (array) => {
        array.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return array;
      }
      copyToClipboard = (text) =>  {
        alert(strings.messageCopiedSuccess);
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);
        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
      }
      sendLinkToPayment = (rowData) => {
        console.log(rowData)
        const teacherPhoneNumber = rowData.teacher.phoneNumber;
        let amountLeft  = rowData.amountLeft;
        try {
          if (this.state.paidByRequestsByTeacherId[rowData.teacher._id] !== null && this.state.paidByRequestsByTeacherId[rowData.teacher._id] !== undefined) {
            amountLeft = amountLeft - parseFloat(this.state.paidByRequestsByTeacherId[rowData.teacher._id])
          }
        } catch (err) {}

        let userInput = prompt("סכום לבקשת תשלום", amountLeft);
        if (userInput != null && parseFloat(userInput) >= 1) {
          
          axios.post(API + 'create-new-obligation-payment-request', {
            'teacherId': rowData.teacher._id.toString(),
            'amountWithVAT': userInput
          }, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            // console.log(response);
            if (response.data.status === "ok") {
              const link = 'https://sivan-school.com/teacher-payments-page?id='+response.data.linkId;
              this.copyToClipboard(link);
              alert("הקישור הועתק בהצלחה למקלדת")
            } else {
            }
          }).catch(err => {
            console.log(err);
          });
        } else {
          if (parseFloat(userInput) <= 1) {
            alert('מספר חייב להיות גדול מ0')
          }
        }
      }

      initiateBulkSendPayment = () => {
        let teachersPayload = {};
        this.state.obligationsTeachers.forEach((tObData) => {
         if (this.state.paidByRequestsByTeacherId && this.state.paidByRequestsByTeacherId[tObData.teacher._id]) {
          if (parseFloat(tObData.amountLeft - parseFloat(this.state.paidByRequestsByTeacherId[tObData.teacher._id])) >= 1) {
            teachersPayload[tObData.teacher._id] = tObData.amountLeft - parseFloat(this.state.paidByRequestsByTeacherId[tObData.teacher._id]);
          }
         } else {
          if (parseFloat(tObData.amountLeft) >= 1) {
            teachersPayload[tObData.teacher._id] = tObData.amountLeft;
          }
         }
        });
        this.setState({'teachersPayload': teachersPayload});
        if (this.state.editTeachersPaymentsForLinkOn) {
          this.setState({editTeachersPaymentsForLinkOn: false});
        } else {
          this.setState({editTeachersPaymentsForLinkOn: true});
          
        }
      }

      changeInputValueBulkPayment = (id, amount) => {
        let teachersPayload = this.state.teachersPayload;
        teachersPayload[id] = amount;
        console.log(id, '<> ', amount)
        this.setState({'teachersPayload': teachersPayload});
      }

      runBulkSendPayment = () => {
        const a = window.confirm('האם את/ה בטוח/ה שברצונך לשלוח בקשת תשלום לכל המורים? יש לוודא שהוזנו כל הסכומים הנכונים ');
        if (a) {
          let teachersPayload = this.state.teachersPayload;
          let teachersPayloadArray = [];
          Object.keys(teachersPayload).forEach((key) => {
            if (parseFloat(teachersPayload[key]) >= 1) {
              teachersPayloadArray.push({
                "teacherId": key,
                "amountWithVAT": parseFloat(teachersPayload[key])
              });
            }
          });
          console.log(teachersPayloadArray);
          //
          axios.post(API + 'create-new-obligation-payment-request-bulk', {
            'teachers': teachersPayloadArray
          }, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            // console.log(response);
            if (response.data.status === "ok") {
              alert('בקשות תשלום נשלחו בהודעות SMS לכלל המורים');
              window.location.reload();
            } else {
            }
          }).catch(err => {
            console.log(err);
          });
          //
        }
      }

      sendSinglePayment = (tObData) => {
        const a = window.confirm('האם את/ה בטוח שברצונך לשלוח בקשת תשלום ל'+tObData.teacher.firstName + ' ' + tObData.teacher.lastName);
        if (a) {
          let teachersPayloadArray = [{
            "teacherId": this.state.signlePaymentLinkOn,
            "amountWithVAT": parseFloat(this.state.signlePaymentLinkAmount)
          }];
          console.log(teachersPayloadArray);
          axios.post(API + 'create-new-obligation-payment-request-bulk', {
            'teachers': teachersPayloadArray
          }, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            // console.log(response);
            if (response.data.status === "ok") {
              alert('בקשה נשלחה למורה: '+tObData.teacher.firstName + ' ' + tObData.teacher.lastName);
              window.location.reload();
            } else {
            }
          }).catch(err => {
            console.log(err);
          });
          //
        }
      }


      igniteSinglePaymentSend = (tObData) => {
        this.setState({signlePaymentLinkOn: tObData.teacher._id})

        if (this.state.paidByRequestsByTeacherId && this.state.paidByRequestsByTeacherId[tObData.teacher._id]) {
          if (parseFloat(tObData.amountLeft - parseFloat(this.state.paidByRequestsByTeacherId[tObData.teacher._id])) >= 1) {
            this.setState({
              signlePaymentLinkAmount: parseFloat(tObData.amountLeft - parseFloat(this.state.paidByRequestsByTeacherId[tObData.teacher._id]))
            }) 
          }
         } else {
          if (parseFloat(tObData.amountLeft) >= 1) {
            this.setState({
              signlePaymentLinkAmount: parseFloat(tObData.amountLeft)
            }) 
          }
         }
      }
      
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Dialog
            open={this.state.refundDialogOpen}
            onClose={() => this.setState({refundDialogOpen: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"לאישור הזיכוי הקלידו את פרטי האשראי לזיכוי"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">

                <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.creditcardnumber}</p>
                  <Input
                    value={this.state.ccno}
                    onChange={(e) => this.setState({ccno: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.expdatenumber}</p>
                  <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.month}</p>
                  <Select
                    value={this.state.expdatemonth}
                    onChange={(e) => this.setState({expdatemonth: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>01</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'02'}>02</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'03'}>03</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>04</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'05'}>05</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'06'}>06</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>07</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>08</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>09</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>10</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>11</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>12</MenuItem>
                  </Select>
                  <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.year}</p>
                  <Select
                    value={this.state.expdateyear}
                    onChange={(e) => this.setState({expdateyear: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear() % 100) + i).map((index) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.toString()}>{index.toString()}</MenuItem>
                      )
                    })}
                  </Select>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.cvvnumber}</p>
                  <Input
                    value={this.state.cvv}
                    onChange={(e) => this.setState({cvv: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />
                </React.Fragment>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({paymentToRefund: {}, refundDialogOpen: false})} color="primary">
                ביטול
              </Button>
              <Button onClick={() => this.refund()} color="primary" autoFocus>
                אישור
              </Button>
            </DialogActions>
          </Dialog>

          {!this.state.teacherChosenActive && (
            <Grid item xs={12}>
              <div style={{textAlign: 'right', paddingRight: '1%'}}>
                {!this.state.usePaymentLink && (
                  <>
                  {this.state.editTeachersPaymentsForLinkOn ? (
                    <><Button variant={'contained'} color={'primary'} style={{'backgroundColor': 'green', 'color': 'white'}} onClick={(e) => this.runBulkSendPayment()}>שליחה</Button> &nbsp;&nbsp;&nbsp;</>
                  ) : (
                    <><Button variant={'contained'} color={'primary'} onClick={(e) => this.initiateBulkSendPayment()}>שליחת לינקים לתשלום לכל המורים</Button> &nbsp;&nbsp;&nbsp;</>
                  )}
                  </>
                )}
                <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href = '/financial-obligations/add'}>הוספת התחייבות חדשה</Button> &nbsp;&nbsp;&nbsp;
                <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href = '/financial-obligations/permissions'}>ניהול הרשאות פורטל</Button>
              </div>
            </Grid>
          )}

          <Grid item xs={12}>
            <center>
              <h1>פורטל התחייבויות המורים</h1>
            </center>
          </Grid>

          {this.state.teacherChosenActive ? (
            <Grid item xs={12}>
              <div style={{textAlign: 'right', paddingRight: '1%'}}>
                <Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({teacherChosen: {}, teacherChosenActive: false, sum: 0, sum_left:0})}>חזור</Button>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{paddingLeft: '2%'}}>
                  <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href = '/financial-obligations/payment?id='+this.state.teacherChosen.teacher._id.toString()}>תשלום</Button> &nbsp;&nbsp;&nbsp;
                  <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href = '/financial-obligations/add'}>התחייבות</Button>

                </div>
                <div style={{fontWeight: 'bold', textAlign: 'right', paddingRight: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <p>{this.state.teacherChosen['teacher']['phoneNumber']} |</p>
                  &nbsp;&nbsp;
                  <p>{this.state.teacherChosen['teacher']['firstName'] + ' ' + this.state.teacherChosen['teacher']['lastName']} |</p>
                  &nbsp;&nbsp;
                  <p>:מורה</p>
                </div>
              </div>
              <center>
              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" ></TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>יתרה</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>זכות</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>חובה</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>סוג</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תיאור</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.sortByDate(this.state.teacherChosen.obligations).map((obligation, i) => {
                      let total = 0;
                      let totalPaid = 0;
                      return (
                        <>
                        <TableRow key={i+'_obligation'}>
                          <TableCell align="right">
                            {obligation.amountLeft >= 0 && (
                              <>
                              <Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({paymentToRefund: obligation, refundDialogOpen: true}, () => {
                                console.log(this.state.paymentToRefund)
                              })}>
                                זיכוי
                              </Button>
                              <Button onClick={(e) => this.removeObligation(obligation._id.toString())}>
                                <DeleteIcon />
                              </Button>
                              <Button onClick={(e) => window.open('/financial-obligations/edit?id='+obligation._id.toString(), '_blank')}>
                                <EditIcon />
                              </Button>
                              </>
                            )}
                          </TableCell>
                          <TableCell align="right" style={{color: 'red'}}>₪{obligation.amount}</TableCell>
                          <TableCell align="right" style={{color: 'green'}}>{/*₪{(parseFloat(obligation.amount) - parseFloat(obligation.amountLeft))}*/}</TableCell>
                          <TableCell align="right" style={{color: 'red'}}>₪{obligation.amount}</TableCell>
                          <TableCell align="right">{'התחייבות'}</TableCell>
                          <TableCell align="right">{obligation.description}</TableCell>
                          <TableCell align="right">- {/*{this.modifyDate(obligation.createdAt)}*/}</TableCell>
                        </TableRow>
                        {obligation.payments !== undefined && (
                          <>
                          {obligation.payments.length >= 1 && (
                            <>
                              {this.sortByDate(obligation.payments).map((payment, p) => {
                                if (!payment.refunded) {
                                  total = (parseFloat(payment.amount) - parseFloat(total));
                                  totalPaid += parseFloat(payment.amount);
                                  return (
                                    <TableRow key={i+'_obligation'}>
                                      <TableCell align="right" >
                                        <Button onClick={(e) => window.open('https://wa.me?text=https://sivan-school.com/pdfs/ob_'+payment._id.toString()+'.pdf', '_blank')}>
                                          <WhatsAppIcon />
                                        </Button>
                                        <Button onClick={(e) => window.open('https://sivan-school.com/pdfs/ob_'+payment._id.toString()+'.pdf', '_blank')}>
                                          <PrintIcon />
                                        </Button>
                                      </TableCell>
                                      <TableCell align="right" style={{color: 'red'}}>₪{(obligation.amount - totalPaid )}</TableCell>
                                      <TableCell align="right" style={{color: 'green'}}>₪{payment.amount}</TableCell>
                                      <TableCell align="right" style={{color: 'red'}}>{/*₪{obligation.amount}*/}</TableCell>
                                      <TableCell align="right">{this.modifyPaymentType(payment.paymentType)}</TableCell>
                                      <TableCell align="right">{'תשלום'}</TableCell>
                                      <TableCell align="right">{this.modifyDate(payment.createdAt)}</TableCell>
                                    </TableRow>
                                  )
                                }
                              })}
                            </>
                          )}
                          </>
                        )}

                        </>
                      );
                    })}

                    {this.state.obligationPaymentsRequests && this.state.obligationPaymentsRequests[this.state.teacherChosen['teacher']['_id']] && (
                      <>
                        {this.state.obligationPaymentsRequests[this.state.teacherChosen['teacher']['_id']].map((item) => {
                          return (
                            <TableRow key={item._id.toString()+'_obligation'}>
                            <TableCell align="right" >
                              <Button onClick={(e) => window.open('https://wa.me?text=https://sivan-school.com/pdfs/'+item.invoiceLink, '_blank')}>
                                <WhatsAppIcon />
                              </Button>
                              <Button onClick={(e) => window.open('https://sivan-school.com/pdfs/'+item.invoiceLink, '_blank')}>
                                <PrintIcon />
                              </Button>
                            </TableCell>
                            <TableCell align="right" style={{color: 'red'}}>-</TableCell>
                            <TableCell align="right" style={{color: 'green'}}>₪{item.amountWithVat}</TableCell>
                            <TableCell align="right" style={{color: 'red'}}>{/*₪{obligation.amount}*/}</TableCell>
                            <TableCell align="right">{'תשלום באשראי מורה'}</TableCell>
                            <TableCell align="right">{'תשלום'}</TableCell>
                            <TableCell align="right">{this.modifyDate(item.createdAt)}</TableCell>
                          </TableRow>
                          )
                        })}
                      </>
                    )}

                    <TableRow key={'sum_obligation'}>
                      <TableCell align="right">{''}</TableCell>
                      <TableCell align="right" style={{color: 'red'}}>₪{this.state.sum_left - parseFloat(parseFloat(this.state.sumRequests))}</TableCell>
                      <TableCell align="right" style={{color: 'green'}}>₪{((parseFloat(this.state.sum) + parseFloat(this.state.sumRequests)) - parseFloat(this.state.sum_left))}</TableCell>
                      <TableCell align="right" style={{color: 'red'}}>₪{this.state.sum}</TableCell>
                      <TableCell align="right">{'סה״כ'}</TableCell>
                      <TableCell align="right">{''}</TableCell>
                      <TableCell align="right">{''}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </center>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <center>
              <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>ת. הת. אחרון</TableCell>
                      {!this.state.usePaymentLink && (
                        <TableCell align="right" style={{fontWeight: 'bold'}}>פעולות תשלום מורה</TableCell>
                      )}
                      <TableCell align="right" style={{fontWeight: 'bold'}}>יתרה</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>פירוט</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>טלפון נייד</TableCell>
                      <TableCell align="right" style={{fontWeight: 'bold'}}>מורה</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.obligationsTeachers.map((tObData, tob) => {
                      return (
                        <TableRow key={tob+'_obligation'}>
                          <TableCell align="right">{this.modifyDate(tObData.lastPaymentDate)}</TableCell>
                          {this.state.editTeachersPaymentsForLinkOn ? (
                            <TableCell align="right" style={{fontWeight: 'bold'}}><input value={this.state.teachersPayload[tObData.teacher._id]} onChange={(e) => this.changeInputValueBulkPayment(tObData.teacher._id, e.target.value)}/></TableCell>
                          ) : (
                            <>
                              {!this.state.usePaymentLink && (
                                <TableCell align="right">
                                  
                                  {this.state.signlePaymentLinkOn === tObData.teacher._id ? (
                                    <>
                                    <Button color='primary' style={{color: 'white', backgroundColor: 'red', height: 30}} onClick={() => this.setState({signlePaymentLinkAmount: 0, signlePaymentLinkOn: ""})}>ביטול</Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button color='primary' style={{color: 'white', backgroundColor: 'green', height: 30}} onClick={() => this.sendSinglePayment(tObData)}>שליחה</Button>
                                    &nbsp;
                                    <input style={{height: 30}} value={this.state.signlePaymentLinkAmount} onChange={(e) => this.setState({signlePaymentLinkAmount: e.target.value})}/>
                                    </>
                                  ) : (
                                      <Button color='primary' onClick={() => this.igniteSinglePaymentSend(tObData)}>לחצ/י לשליחת לינק בודד</Button>
                                  )}
                                </TableCell>
                              )}
                            </>
                          )}
                          {this.state.paidByRequestsByTeacherId && this.state.paidByRequestsByTeacherId[tObData.teacher._id] ? (
                            <TableCell align="right">₪{parseFloat(tObData.amountLeft)-parseFloat(this.state.paidByRequestsByTeacherId[tObData.teacher._id])}</TableCell>
                          ) : (
                            <TableCell align="right">₪{parseFloat(tObData.amountLeft)}</TableCell>
                          )}
                          <TableCell align="right">{<Button onClick={(e) => this.chooseTeacher(tObData)} color={'primary'} variant={'contained'}>+</Button>}</TableCell>
                          <TableCell align="right">{tObData.teacher.phoneNumber}</TableCell>
                          <TableCell align="right">{tObData.teacher.firstName + " " + tObData.teacher.lastName}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              </center>
            </Grid>
          )}


        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ObligationsDashboard;
