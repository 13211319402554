import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ObligationsDashboardTeacher extends Component {
  componentDidMount() {
    this.getTeachers();
    this.getData();


    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     obligations: [],
     obligationsTeachers: {},
     obligationsPayments: {},
     teachers: [],
     display: '',
     id: '',
     teacher: {
       firstName: '',
       lastName: ''
     },
     teacherObligations: []
   }
  }


  getTeachers = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({teachers: response.data.data.teachers})
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getData = async () => {
    axios.get(API + 'obligations', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({
          obligations: response.data.data.obligations,
          obligationsTeachers: response.data.data['obligations-teachers'],
          obligationsPayments: response.data.data.obligationsPayments
        }, () => {
          const url = window.location.href;
          const id = url.split('?id=')[1];

          let teacher = {};
          let teacherObligations = [];
          Object.keys(this.state.obligationsTeachers).forEach((item, i) => {
            if (item === id) {
              teacher = this.state.obligationsTeachers[item][0].teacher;

              teacherObligations = this.state.obligationsTeachers[item];
            }
          });

          this.setState({teacher: teacher, teacherObligations: teacherObligations});
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }
  sortByDate = (array) => {
    array.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return array;
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>פורטל התחייבויות המורים</h1>
              <h3>מציג למורה {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}</h3>
              <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href = '/financial-obligations/add'}>הוספת התחייבות חדשה</Button>
            </center>
          </Grid>

          <Grid item xs={12}>
            <h3><u>התחייבויות פתוחות</u></h3>


            <center>
            <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>ניהול</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>תאריך</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>נותר לתשלום</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>סכום</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>התחייבות</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>מספר טלפון</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>מורה</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.teacherObligations && this.sortByDate(this.state.teacherObligations).map((obligation, i) => {
                    return (
                      <TableRow key={i+'_obligation'}>
                        <TableCell align="right">
                          <Button onClick={(e) => window.location.href = '/financial-obligations/manage?id='+obligation._id.toString()} variant={'contained'} color={'primary'}>ניהול</Button>
                        </TableCell>
                        <TableCell align="right">{new Date(obligation.date).getDate() + '/' + parseInt(new Date(obligation.date).getMonth() + 1) + '/' + new Date(obligation.date).getFullYear()}</TableCell>
                        <TableCell align="right">{obligation.amountLeft}</TableCell>
                        <TableCell align="right">{obligation.amount}</TableCell>
                        <TableCell align="right">{obligation.description}</TableCell>
                        <TableCell align="right">{obligation.teacher.phoneNumber}</TableCell>
                        <TableCell align="right">{obligation.teacher.firstName + ' ' + obligation.teacher.lastName + ' ('+obligation.teacher.idNumber+')'}</TableCell>
                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>
            </TableContainer>
            </center>

          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ObligationsDashboardTeacher;
