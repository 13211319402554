import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import SelectSearch from 'react-select-search';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();


class ClerkMigrateStudent extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     studentId: '',
     teacherId: '',

     students: [],
     teachers: [],
     s_: [],
     t_: [],
     student: {},

     lastSeen: '',
     language: 'he'
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/all-my-students-teachers-available', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          students: response.data.data.students,
          teachers: response.data.data.teachers,
        }, () => {
          let s_ = [];
          this.state.students.forEach((item, i) => {
            s_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
          });

          let t_ = [];
          this.state.teachers.forEach((item, i) => {
            t_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
          });

          this.setState({s_: s_, t_: t_});
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת המורים שלי')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  migrateUser = () => {
    let payload = {
      sid: this.state.studentId,
      tid: this.state.teacherId,
    }

    axios.put(API + 'clerk/students-migrate', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messageStudentUpdateSuccess);
        window.location.href='/'
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בהעברת התלמיד למורה')
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.editstudent}</h2>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosestudent}</p>
              <SelectSearch
                onChange={(value) => this.setState({studentId: value})}
                options={this.state.s_}
                search={true}
                value={this.state.studentId}
                name="studentId"
                placeholder="שם התלמיד.."
              />



              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseteacher}</p>
              <SelectSearch
                onChange={(value) => this.setState({teacherId: value})}
                options={this.state.t_}
                search={true}
                value={this.state.teacherId}
                name="teacherId"
                placeholder="שם המורה.."
              />

              <Button onClick={(e) => this.migrateUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.migrateAction}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkMigrateStudent;
