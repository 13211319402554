import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ClerkViewDashboard extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],

     lastSeen: '',
     clerk: {
       firstName: '',
       lastName: ''
     },
     students: [],
     studentsApproval: [],
     payments: [],
     language: 'he',
     haveSignUpFee: false,
     haveInsideTests: false
   }
  }

  getData = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          clerk: response.data.data.clerk,
          students: response.data.data.students,
          lastSeen: response.data.lastSeen,
          payments: response.data.data.payments,
          haveSignUpFee: response.data.haveSignUpFee,
          haveInsideTests: response.data.haveInsideTests
        }, () => {
          let students = [];
          let studentsApproval = [];

          this.state.students.forEach((item, i) => {
            if (item.approved === "false" || !item.approved) studentsApproval.push(item);
            else students.push(item);
          });

          this.setState({
            students: students,
            studentsApproval: studentsApproval
          });
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  approveStudentRequest = (id) => {
    axios.put(API + 'teacher/student/approve?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  removeStudentRequest = (id) => {
    axios.delete(API + 'teacher/student?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <center><br />
          <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <CardContent>
              <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1.2em'}}>{this.state.clerk.firstName + ' ' + this.state.clerk.lastName} <AssignmentIndIcon style={{width: 32, height: 32}}/></p>
              <br />

              {isMobile ? (
                <Link to='/student/add' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher">
                    <PersonAddIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                  </IconButton>
                </Link>
              ) : (
                <Link to='/student/add' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher">
                    <PersonAddIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                  </IconButton>
                </Link>
              )}
              <br />
              {isMobile ? (
                <Link to='/get-link' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher">
                    <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                  </IconButton>
                </Link>
              ) : (
                <Link to='/get-link' style={{textDecoration: 'none'}}>
                <IconButton alt="add-teacher">
                    <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                  </IconButton>
                </Link>
              )}
              <br />
              {isMobile ? (
                <React.Fragment>
                  <hr style={{maxWidth: '90%'}}/>
                  <Link to={'/payment'} style={{textDecoration: 'none'}}>
                    <IconButton alt="add-payment">
                      <AccountBalanceIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                    </IconButton>
                  </Link>
                  <p style={{fontSize: '1em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                  <Link to='/outside-link' style={{textDecoration:'none'}}><IconButton alt="add-teacher">
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton></Link>
                </React.Fragment>
              ) : (
                <div style={{border: '1px solid #717171', maxWidth: '30%'}}>
                <Link to={'/payment'} style={{textDecoration: 'none'}}>
                <IconButton alt="add-payment">
                    <AccountBalanceIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                  </IconButton>
                </Link>
                <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                <Link to='/outside-link' style={{textDecoration:'none'}}><IconButton alt="add-teacher">
                    <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                </IconButton></Link>
                </div>
              )}
              <br /><br />

              {this.state.haveInsideTests && (
                <React.Fragment>
                <Link to={'/inside-test'} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} />{strings.setInsideTest}</Button></Link>
                <br /><br />
                <Link to={'view/clerk/inside-tests'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} />{strings.insidetestlist}</Button></Link>

                </React.Fragment>
              )}

              <br /><br />
              <Link to={'/transfer-money'} style={{textDecoration: 'none'}}><Button variant='outlined'><AccountBalanceIcon style={{color: '#404041'}} />{strings.mycashregisterandtransfermoney}</Button></Link>
              <br />
              <Link to={'/generate-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 210}}><DescriptionIcon style={{color: 'white'}} />{strings.generateinvoices}</Button></Link>
              <br />
              <Link to={'/cheques'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><DescriptionIcon style={{color: 'white'}} />{strings.writeacheque}</Button></Link>
              <br />

            <React.Fragment>
              <br /><br />
              <Link to={'/employees'} style={{textDecoration: 'none'}}>
                <Button variant='contained' color={'primary'} style={{fontSize: '1.2em'}}>{strings.manageEmployees}</Button>
              </Link>
            </React.Fragment>

            </CardContent>
          </Card>
         </center>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mystudents}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.edit}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidinsidetestfee}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidsignupfee}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.idnumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.students.reverse().map((student, i) => {
                  return (
                    <TableRow key={i+'_student'}>
                      <TableCell align="right">
                        <Link to={'/student/update?id='+student._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                      </TableCell>
                      <TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor : strings.no)}</TableCell>
                      <TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor : strings.no)}</TableCell>
                      <TableCell align="right">{student.status === 'passed_test' ? strings.passedtest : (student.status === 'active' ? strings.active : strings.inactive)}</TableCell>
                      <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                      <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                      <TableCell align="right">{student.phoneNumber}</TableCell>
                      <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                      <TableCell align="right">{student.idNumber}</TableCell>
                      <TableCell align="right">{student.teacher === undefined ? '' : (student.teacher.firstName + ' ' + student.teacher.lastName)}</TableCell>
                      <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>


        </center>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.getexternalstudentlinktitle}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.studentsApproval.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.nostudentstoapprove1}
          <br/>
          {strings.nostudentstoapprove2}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.delete}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.approve}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.studentsApproval.reverse().map((student, i) => {
                  return (
                    <TableRow key={i+'_student'}>
                      <TableCell align="right">
                        <IconButton alt="edit-user" onClick={(e) => this.removeStudentRequest(student._id)}>
                          <DeleteForeverIcon style={{color: '#404041'}}/>
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton alt="edit-user" onClick={(e) => this.approveStudentRequest(student._id)}>
                          <CheckIcon style={{color: '#404041'}}/>
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">{student.status === 'passed_test' ? strings.passedtest : (student.status === 'active' ? strings.active : strings.inactive)}</TableCell>
                      <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                      <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                      <TableCell align="right">{student.phoneNumber}</TableCell>
                      <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                      <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                      <TableCell align="right">{student.teacherName}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mypayments}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>
        
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paymentType}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidfor}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.dateOfPayment}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.payments.reverse().map((payment, i) => {
                  if (!payment.isPtor) {
                    return (
                      <TableRow key={i+'_payment'}>
                        <TableCell align="right">{this.modifyPaymentType(payment)}</TableCell>
                        <TableCell align="right">{payment.amountWithVAT}</TableCell>
                        <TableCell align="right">{(payment.description === 'insideTestFee' || payment.description === 'signupFee') ? (payment.description === 'insideTestFee' ? 'אגרת מבחן פנימי' : 'אגרת רישום') : payment.description}</TableCell>
                        <TableCell align="right">{new Date(payment.dateOfPayment).getDate() + '/' + (new Date(payment.dateOfPayment).getMonth() + 1) + '/' + new Date(payment.dateOfPayment).getFullYear()}</TableCell>
                        <TableCell align="right">{payment.studentName}</TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>


        </center>
        </Grid>

        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ClerkViewDashboard;
