import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import SelectSearch from 'react-select-search';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();
const paidBefore = ['שילם במקום אחר', 'שילם במערכת ישנה']

class ClerkAddAStudent extends Component {
  componentDidMount () {
    this.getData();
    this.getClerks();
    this.getPtors();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     firstName: '',
     lastName: '',
     idNumber: '',
     gender: 'male',
     birthday: '01-01-1995',
     email: '',
     phoneNumber: '',
     licenseType: 'A1',
     status: 'active',
     paidSignupFee: 'no',
     paidInsideTestFee: 'no',
     canSignWithBrosh: false,
     broshDate: '01-01-2020',
     broshDateDialog: false,
     broshClerk: '',
     teachers: [],
     teacherId: '',
     lastSeen: '',
     language: 'he',

     teacherById: {},

     allowedDrivingLicenseTypes: [],
     allowedDrivingLicenseTypesAge: [],
     age: 15,

     signupFeeisPtor: false,
     signupFeeptorReason: '',
     insideTestFeeisPtor: false,
     insideTestFeeptorReason: '',
     ptors: [],
     t_: [],
     clerk: {},
     haveSignUpFee: false,
     haveInsideTests: false,
     ptorGiverSignupUserId: '',
     ptorGiverInsideTestUserId: '',
     teachersPtorSignup: [],
     teachersPtorInsideTest: [],
     birthdayDialog: false,
     paidBefore_signupfee_dateDialog: false,
     paidBefore_insidetestfee_dateDialog: false,
     clerks: [],
   }
  }

  onChangeTeacherId = (e) => {
    this.setState({teacherId: e.target.value}, () => {
      if (this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.length === 1) {
        this.setState({licenseType: this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes[0]})
      }
    })
  }

  getClerks = () => {
    axios.get(API + 'clerk/clerks', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {
        this.setState({clerks: response.data.data.clerks});
    }).catch(err => {
      console.log(err);
    });
  }

  getData = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen,
          teachers: response.data.data.teachers,
          clerk: response.data.data.clerk,
          haveSignUpFee: response.data.haveSignUpFee,
          haveInsideTests: response.data.haveInsideTests,
        }, () => {
          let teachers = this.state.teachers;
          let output = [];

          let teachersPtorSignup = [];
          let teachersPtorInsideTest = [];

          teachers.forEach((item, i) => {
            if (item.type === "teacher" || item.type === "professionalteacher") output.push(item);
            if (item.canGivePtorSignup || item.isManager) teachersPtorSignup.push(item);
            if (item.canGivePtorInsideTest || item.isManager) teachersPtorInsideTest.push(item);
          });

          console.log(teachersPtorSignup);
          console.log(teachersPtorInsideTest);

          this.setState({teachers: output, teachersPtorSignup: teachersPtorSignup, teachersPtorInsideTest: teachersPtorInsideTest}, () => {
            let teacherById = {};
            this.state.teachers.map((index) => {
              teacherById[index._id.toString()] = index;
            });

            this.setState({teacherById: teacherById})

            let t_ = [];
            this.state.teachers.forEach((item, i) => {
              t_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
            });

            this.setState({t_: t_});
          });
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בהוספת התלמיד, אנא וודא שמילאת את כל שדות החובה!')
      }
    })
    .catch(error => {
      console.log(error);
      // alert(strings.errorPleaseTryAgain);
      alert('שגיאה בהוספת התלמיד, אנא וודא שמילאת את כל שדות החובה!')
    });
  }



  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10;
  }

  validate = () => {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let idNumber = this.state.idNumber;
    let birthday = this.state.birthday;
    let phoneNumber = this.state.phoneNumber;

    if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
      alert(strings.mustFillAllFields);
      return false;
    }

    if (phoneNumber.length !== 10 || !this.validatePhoneNumber(phoneNumber)) {
      alert(strings.phoneNotGood);
      return false
    }

    if (!this.validateID(idNumber)) {
      alert(strings.idNumberNotGood);
      return false;
    }

    if (this.state.teacherId === '' || this.state.teacherId === undefined || this.state.teacherId === null) return false;

    return true;
  }

  getPtors = async () => {
    axios.get(API + 'ptors', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          ptors: response.data.data.ptors,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getAge = (date) => {
    console.log(date);
        this.setState({birthday: date}, () => {
          var today = new Date();
          var birthDate = new Date(date);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--;
          }
          console.log(age);
          if (age < 100) {
            this.setState({age: age, allowedDrivingLicenseTypesAge: []}, () => {
              if (age < 15 ) {
                this.setState({allowedDrivingLicenseTypesAge: []});
              } else if (age === 15) {
                let allowedDrivingLicenseTypesAge = [];

                allowedDrivingLicenseTypesAge.push('1')
                allowedDrivingLicenseTypesAge.push('D3')
                allowedDrivingLicenseTypesAge.push('D2')

                console.log(allowedDrivingLicenseTypesAge);
                this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                  console.log('::');
                  console.log(this.state.allowedDrivingLicenseTypesAge);
                });
              } else if (age <= 16) {
                let allowedDrivingLicenseTypesAge = [];
                allowedDrivingLicenseTypesAge.push('1')
                allowedDrivingLicenseTypesAge.push('D3')
                allowedDrivingLicenseTypesAge.push('D2')
                allowedDrivingLicenseTypesAge.push('B_automate')
                allowedDrivingLicenseTypesAge.push('B_manual')
                allowedDrivingLicenseTypesAge.push('A2')
                console.log(allowedDrivingLicenseTypesAge);
                this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                  console.log('::');
                  console.log(this.state.allowedDrivingLicenseTypesAge);
                });
              } else if (age <= 17) {
                let allowedDrivingLicenseTypesAge = [];
                allowedDrivingLicenseTypesAge.push('1')
                allowedDrivingLicenseTypesAge.push('D3')
                allowedDrivingLicenseTypesAge.push('D2')
                allowedDrivingLicenseTypesAge.push('B_automate')
                allowedDrivingLicenseTypesAge.push('B_manual')
                allowedDrivingLicenseTypesAge.push('A2')
                allowedDrivingLicenseTypesAge.push('C1')
                console.log(allowedDrivingLicenseTypesAge);
                this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                  console.log('::');
                  console.log(this.state.allowedDrivingLicenseTypesAge);
                });
              } else if (age >= 18) {
                let allowedDrivingLicenseTypesAge = [];
                allowedDrivingLicenseTypesAge.push('1')
                allowedDrivingLicenseTypesAge.push('D3')
                allowedDrivingLicenseTypesAge.push('D2')
                allowedDrivingLicenseTypesAge.push('B_automate')
                allowedDrivingLicenseTypesAge.push('B_manual')
                allowedDrivingLicenseTypesAge.push('A2')
                allowedDrivingLicenseTypesAge.push('C1')
                allowedDrivingLicenseTypesAge.push('C')
                allowedDrivingLicenseTypesAge.push('C+E')
                allowedDrivingLicenseTypesAge.push('C_E')
                allowedDrivingLicenseTypesAge.push('CE')
                allowedDrivingLicenseTypesAge.push('D')
                allowedDrivingLicenseTypesAge.push('D1')
                allowedDrivingLicenseTypesAge.push('A')
                allowedDrivingLicenseTypesAge.push('A1')
                console.log(allowedDrivingLicenseTypesAge);
                this.setState({allowedDrivingLicenseTypesAge: allowedDrivingLicenseTypesAge}, () => {
                  console.log('::');
                  console.log(this.state.allowedDrivingLicenseTypesAge);
                });
              } else {
                this.setState({allowedDrivingLicenseTypesAge: []});
              }

            });
          }
        });
  }

  createUser = () => {
    if (this.state.teacherId === '') {
      alert('חובה לבחור מורה/מנהל מקצועי!');
    } else {
      if (this.state.age >= 15 && this.state.age <= 80) {
        if (this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.length === 1 && this.state.allowedDrivingLicenseTypesAge.includes(this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes[0])) {
          this.setState({licenseType: this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes[0]}, () => {
            if (this.validate()) {
              let payload = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                idNumber: this.state.idNumber,
                gender: this.state.gender,
                birthday: this.state.birthday,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                licenseType: this.state.licenseType,
                status: this.state.status,
                canSignWithBrosh: this.state.canSignWithBrosh,
                broshDate: this.state.broshDate,
                broshClerk: this.state.broshClerk,
                paidSignupFee: this.state.paidSignupFee,
                paidInsideTestFee: this.state.paidInsideTestFee,
                teacherId: this.state.teacherId,

                paidSignupFeePtor: this.state.paidSignupFee === 'ptor' ? true : false,
                paidSignupFeePtorReason: this.state.signupFeeptorReason,
                paidInsideTestPtor: this.state.paidInsideTestFee === 'ptor' ? true : false,
                paidInsideTestPtorReason: this.state.insideTestFeeptorReason,
                ptorGiverSignupUserId: this.state.ptorGiverSignupUserId,
                ptorGiverInsideTestUserId: this.state.ptorGiverInsideTestUserId,
              }

              axios.post(API + 'clerk/student', payload, {
                headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
              })
              .then(response => {
                if (response.data.status === "ok") {
                  //alert(strings.messageUserCreatedSuccess);
                  window.location.href='/';
                } else {
                  if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                    alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
                  } else {
                    // alert(strings.errorPleaseTryAgain);
                    alert('שגיאת שרת ביצירת תלמיד');
                  }
                }
              })
              .catch(error => {
                // alert(strings.errorPleaseTryAgain);
                alert('שגיאת מערכת (ת.1223)');
              });
            }
          })
        } else {
          if (this.validate()) {
            let payload = {
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              idNumber: this.state.idNumber,
              gender: this.state.gender,
              birthday: this.state.birthday,
              email: this.state.email,
              phoneNumber: this.state.phoneNumber,
              licenseType: this.state.licenseType,
              status: this.state.status,
              canSignWithBrosh: this.state.canSignWithBrosh,
              broshDate: this.state.broshDate,
              broshClerk: this.state.broshClerk,
              paidSignupFee: this.state.paidSignupFee,
              paidInsideTestFee: this.state.paidInsideTestFee,
              teacherId: this.state.teacherId,

              paidSignupFeePtor: this.state.paidSignupFee === 'ptor' ? true : false,
              paidSignupFeePtorReason: this.state.signupFeeptorReason,
              paidInsideTestPtor: this.state.paidInsideTestFee === 'ptor' ? true : false,
              paidInsideTestPtorReason: this.state.insideTestFeeptorReason,
              ptorGiverSignupUserId: this.state.ptorGiverSignupUserId,
              ptorGiverInsideTestUserId: this.state.ptorGiverInsideTestUserId,
            }

            axios.post(API + 'clerk/student', payload, {
              headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
            })
            .then(response => {
              if (response.data.status === "ok") {
                //alert(strings.messageUserCreatedSuccess);
                window.location.href='/';
              } else {
                if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                  alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
                } else {
                  // alert(strings.errorPleaseTryAgain);
                  alert('שגיאת שרת ביצירת תלמיד');
                }
              }
            })
            .catch(error => {
              // alert(strings.errorPleaseTryAgain);
              alert('שגיאת מערכת (ת.1223)');
            });
          }
        }


      } else {
        if (this.state.age <= 0) alert('התאריך שהזנתם אינו חוקי!')
        if (this.state.age <= 15 && this.state.age >= 1) alert(strings.minimalageerror)
        if (this.state.age >= 80) alert('התאריך שהזנתם אינו חוקי!')
      }
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.addnewstudent}</h2>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.firstname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.lastname}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.idnumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.idNumber}
                onChange={(e) => this.setState({idNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>{strings.email}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.phonenumber}</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.birthday}</p>
              {/*<TextField
                value={this.state.birthday}
                onChange={(e) => this.getAge(e.target.value)}
                type='date'
                format='dd/mm/yyyy'
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              />*/}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={this.state.birthday}
                selecte={this.state.birthday}
                onChange={(value) => this.getAge(value)}
                open={this.state.birthdayDialog}
                onClick={(e) => this.setState({birthdayDialog: true})}
                onClose={(e) => this.setState({birthdayDialog: false})}
                disableFuture={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{
                  width: '100%'
                }}
                locale='he'
              />
              </MuiPickersUtilsProvider>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.gender}</p>
              <Select
                value={this.state.gender}
                onChange={(e) => this.setState({gender: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={'male'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.male}</MenuItem>
                <MenuItem value={'female'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.female}</MenuItem>
                <MenuItem value={'other'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.othergender}</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseteacher}</p>
              <SelectSearch
                onChange={(value) => this.setState({teacherId: value})}
                options={this.state.t_}
                search={true}
                value={this.state.teacherId}
                name="teacherId"
                placeholder="שם המורה.."
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.licensetype}</p>
              {this.state.teacherId === '' ? (
                <p style={{textAlign: 'right', color: 'orange'}}>{strings.needtochooseteacherlicenses}</p>
              ) : (
                <React.Fragment>
                  {this.state.allowedDrivingLicenseTypesAge.length === 0 && (
                    <p style={{textAlign: 'right', color: 'red'}}>אין רשיונות זמינים לגיל התלמיד</p>
                  )}

                  {this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.length >= 2 ? (
                    <Select
                      value={this.state.licenseType}
                      onChange={(e) => this.setState({licenseType: e.target.value})}
                      style={{
                        direction: 'rtl',
                        width: '100%'
                      }}
                    >
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A1') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('A1')) && (<MenuItem value={'A1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA1}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A2') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('A2')) && (<MenuItem value={'A2'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA2}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('A')) && (<MenuItem value={'A'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_manual') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('B_manual')) && (<MenuItem value={'B_manual'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_automate') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('B_automate')) && (<MenuItem value={'B_automate'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB_}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('C')) && (<MenuItem value={'C'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C1') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('C1')) && (<MenuItem value={'C1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC1}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C+E') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('C+E')) && (<MenuItem value={'C+E'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeCA}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('D')) && (<MenuItem value={'D'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD}</MenuItem>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('D1')) && (<MenuItem value={'D1'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD1}</MenuItem>)}
                    </Select>
                  ) : (
                    <React.Fragment>
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A1') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('A1')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA1}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A2') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('A2')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA2}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('A') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('A')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeA}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_automate') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('B_manual')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('B_manual') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('B_automate')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeB_}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('C')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C1') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('C1')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeC1}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('C+E') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('C+E')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeCA}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('D')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD}</p>)}
                      {(this.state.allowedDrivingLicenseTypesAge.includes('D1') && this.state.teacherById[this.state.teacherId].allowedDrivingLicenseTypes.includes('D1')) && (<p style={{direction: 'rtl', textAlign: 'right'}}>{strings.licensetypeD1}</p>)}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.studentstatus}</p>
              <Select
                value={this.state.status}
                onChange={(e) => this.setState({status: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={'active'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.active}</MenuItem>
                <MenuItem value={'inactive'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.inactive}</MenuItem>
                <MenuItem value={'passed_test'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.passedtest}</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.didSignedWithBrosh}</p>
              <Select
                value={this.state.canSignWithBrosh}
                onChange={(e) => this.setState({canSignWithBrosh: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>{strings.yes}</MenuItem>
                <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
              </Select>

              {this.state.canSignWithBrosh && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.broshsignupdate}</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  value={this.state.broshDate}
                  selecte={this.state.broshDate}
                  onChange={(value) => this.setState({broshDate: value})}
                  open={this.state.broshDateDialog}
                  onClick={(e) => this.setState({broshDateDialog: true})}
                  onClose={(e) => this.setState({broshDateDialog: false})}
                  disableFuture={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    width: '100%'
                  }}
                  locale='he'
                />
                </MuiPickersUtilsProvider>
                {/*<TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.broshDate}
                  onChange={(e) => this.setState({broshDate: e.target.value})}
                />*/}

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.broshclerk}</p>
                {/*<TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.broshClerk}
                  onChange={(e) => this.setState({broshClerk: e.target.value})}
                />*/}
                <Select
                  value={this.state.broshClerk}
                  onChange={(e) => this.setState({broshClerk: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={this.state.self} style={{direction: 'rtl', textAlign: 'right'}}>אני</MenuItem>
                  {this.state.clerks.map((clerk) => {
                    return (
                      <MenuItem value={clerk._id} style={{direction: 'rtl', textAlign: 'right'}}>{clerk.firstName + ' ' + clerk.lastName}</MenuItem>
                    )
                  })}
                </Select>
                </React.Fragment>
              )}

              {this.state.haveSignUpFee && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paidsignupfee}</p>
                <Select
                  value={this.state.paidSignupFee}
                  onChange={(e) => this.setState({paidSignupFee: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={'no'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                  {this.state.clerk.canGivePtorSignup && (<MenuItem value={'ptor'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.ptor}</MenuItem>)}

                </Select>
                </React.Fragment>
              )}

              {(this.state.paidSignupFee === 'ptor') && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.whoGaveThePtor}</p>
                <Select
                  value={this.state.ptorGiverInsideTestUserId}
                  onChange={(e) => this.setState({ptorGiverInsideTestUserId: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  {this.state.teachersPtorSignup.map((teacher) => {
                    return (
                      <MenuItem value={teacher._id} style={{direction: 'rtl', textAlign: 'right'}}>{teacher.firstName + ' ' + teacher.lastName + ' '+teacher.idNumber+''}</MenuItem>
                    )
                  })}
                </Select>
                </React.Fragment>
              )}

              {this.state.paidSignupFee === 'ptor' && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.ptor_reason}</p>
                <Select
                  value={this.state.signupFeeptorReason}
                  onChange={(e) => this.setState({signupFeeptorReason: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  {this.state.ptors.map((ptor) => {
                    return (
                      <MenuItem value={ptor.reason} style={{direction: 'rtl', textAlign: 'right'}}>{ptor.reason}</MenuItem>
                    )
                  })}
                </Select>

                {paidBefore.includes(this.state.signupFeeptorReason) && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paidbeforedate}</p>
                  {/*<TextField
                    value={this.state.paidBefore_signupfee_date}
                    onChange={(e) => this.setState({paidBefore_signupfee_date: e.target.value})}
                    type='date'
                    format='dd/mm/yyyy'
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  />*/}
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={this.state.paidBefore_signupfee_date}
                    onChange={(value) => this.setState({paidBefore_signupfee_date: value})}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  />
                  </MuiPickersUtilsProvider>*/}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={this.state.paidBefore_signupfee_date}
                    selecte={this.state.paidBefore_signupfee_date}
                    onChange={(value) => this.setState({paidBefore_signupfee_date: value})}
                    open={this.state.paidBefore_signupfee_dateDialog}
                    onClick={(e) => this.setState({paidBefore_signupfee_dateDialog: true})}
                    onClose={(e) => this.setState({paidBefore_signupfee_dateDialog: false})}
                    disableFuture={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{
                      width: '100%'
                    }}
                    locale='he'
                  />
                  </MuiPickersUtilsProvider>

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.padibeforeinvoice}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.paidBefore_signupfee_invoice}
                    onChange={(e) => this.setState({paidBefore_signupfee_invoice: e.target.value})}
                  />
                  </React.Fragment>
                )}
                </React.Fragment>
              )}

              {this.state.haveInsideTests && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paidinsidetestfee}</p>
                <Select
                  value={this.state.paidInsideTestFee}
                  onChange={(e) => this.setState({paidInsideTestFee: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  <MenuItem value={'no'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.no}</MenuItem>
                  {this.state.clerk.canGivePtorInsideTest && (<MenuItem value={'ptor'} style={{direction: 'rtl', textAlign: 'right'}}>{strings.ptor}</MenuItem>)}
                </Select>
                </React.Fragment>
              )}

              {(this.state.paidInsideTestFee === 'ptor') && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.whoGaveThePtor}</p>
                <Select
                  value={this.state.ptorGiverInsideTestUserId}
                  onChange={(e) => this.setState({ptorGiverInsideTestUserId: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  {this.state.teachersPtorInsideTest.map((teacher) => {
                    return (
                      <MenuItem value={teacher._id} style={{direction: 'rtl', textAlign: 'right'}}>{teacher.firstName + ' ' + teacher.lastName + ' '+teacher.idNumber+''}</MenuItem>
                    )
                  })}
                </Select>
                </React.Fragment>
              )}

              {this.state.paidInsideTestFee === 'ptor' && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.ptor_reason}</p>
                <Select
                  value={this.state.insideTestFeeptorReason}
                  onChange={(e) => this.setState({insideTestFeeptorReason: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                >
                  {this.state.ptors.map((ptor) => {
                    return (
                      <MenuItem value={ptor.reason} style={{direction: 'rtl', textAlign: 'right'}}>{ptor.reason}</MenuItem>
                    )
                  })}
                </Select>

                {paidBefore.includes(this.state.insideTestFeeptorReason) && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paidbeforedate}</p>
                  {/*<TextField
                    value={this.state.paidBefore_insidetestfee_date}
                    onChange={(e) => this.setState({paidBefore_insidetestfee_date: e.target.value})}
                    type='date'
                    format='dd/mm/yyyy'
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  />*/}
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={this.state.paidBefore_insidetestfee_date}
                    onChange={(value) => this.setState({paidBefore_insidetestfee_date: value})}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  />
                  </MuiPickersUtilsProvider>*/}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={this.state.paidBefore_insidetestfee_date}
                    selecte={this.state.paidBefore_insidetestfee_date}
                    onChange={(value) => this.setState({paidBefore_insidetestfee_date: value})}
                    open={this.state.paidBefore_insidetestfee_dateDialog}
                    onClick={(e) => this.setState({paidBefore_insidetestfee_dateDialog: true})}
                    onClose={(e) => this.setState({paidBefore_insidetestfee_dateDialog: false})}
                    disableFuture={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{
                      width: '100%'
                    }}
                    locale='he'
                  />
                  </MuiPickersUtilsProvider>


                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.padibeforeinvoice}</p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.paidBefore_insidetestfee_invoice}
                    onChange={(e) => this.setState({paidBefore_insidetestfee_invoice: e.target.value})}
                  />
                  </React.Fragment>
                )}
                </React.Fragment>
              )}



              <Button onClick={(e) => this.createUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.create}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkAddAStudent;
