import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';
import Loading from '../Loading';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {
  Link
} from "react-router-dom";

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import heLocale from '@fullcalendar/core/locales/he';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import PhoneIcon from '@material-ui/icons/Phone';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class InsideTests extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',


     amountWithVAT: 0,
     type: '',
     bankNumber: '',
     branchNumber: '',
     bankAccountNumber: '',
     dateOfPayment: new Date(Date.now()),
     description: '',
     applicationType: '',
     applicationNumber: '',
     chequeNum: '',
     transactionId: '',

     lastSeen: '',
     language: 'he',

     link: '',
     whatsapp: '',
     calendar: {},
     calendar_history: {},
     calendar_history_all: {},
     view: 'all',
     pendings: [],
     history: [],
     searched: false,
     unkown: [],
     success: [],
     failed: [],
     unkown_history: [],
     success_history: [],
     failed_history: [],
     calendarOriginal: [],
     search_calendar: [],
     all_calendar: [],
     all_calendar_original: [],
   }
  }

  orderByDate = (calendar) => {
    return calendar;
  }

  getData = async () => {
    this.setState({loading: true}, () => {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        axios.get(API + 'manager/calendar?id='+this.state.schoolId, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          if (response.data.status === "ok") {
            let all_calendar = {}
            this.setState({calendar: response.data.data.calendar, calendarOriginal: response.data.data.calendar, pendings: response.data.data.pendings}, () => {
              let output = {};
              let output_history = {};
              Object.keys(this.state.calendar).forEach((item) => {
                // console.log(item);
                // console.log(new Date(Date.now()));
                // console.log(new Date(this.state.calendar[item][0].date));
                let date_compare_a = new Date(Date.now())
                let date_compare_b = new Date(this.state.calendar[item][0].date);
                date_compare_a.setHours(0,0,0,0);
                date_compare_b.setHours(0,0,0,0);

                // if (new Date(Date.now()) <= new Date(this.state.calendar[item][0].date)) {
                if (date_compare_a <= date_compare_b) {
                  output[item] = this.state.calendar[item];
                } else {
                  output_history[item] = this.state.calendar[item];
                }
                all_calendar[item] = this.state.calendar[item];
              });

              // console.log(output);
              this.setState({loading: false, calendar: output, calendarOriginal: output, calendar_history: output_history, calendar_history_all: output_history, all_calendar: all_calendar, all_calendar_original: all_calendar}, () => {
                let calendar = this.state.all_calendar;

                let success = [];
                let failed = [];
                let unkown = [];

                Object.keys(calendar).forEach((events_) => {
                  // console.log(events_);
                  calendar[events_].forEach((item) => {
                    // console.log(item);
                    if (item.status === 'success') {
                      if (success[events_] === undefined) success[events_] = [];
                      success[events_].push(item);
                    }
                    if (item.status === 'fail') {
                      if (failed[events_] === undefined) failed[events_] = [];
                      failed[events_].push(item);
                    }
                    if (item.status === 'unkown') {
                      if (unkown[events_] === undefined) unkown[events_] = [];
                      unkown[events_].push(item);
                    }
                  });
                });

                let calendar_history = this.state.calendar_history;
                let success_history = [];
                let failed_history = [];
                let unkown_history = [];

                Object.keys(calendar_history).forEach((events_) => {
                  // console.log(events_);
                  calendar_history[events_].forEach((item) => {
                    // console.log(item);
                    if (item.status === 'success') {
                      if (success_history[events_] === undefined) success_history[events_] = [];
                      success_history[events_].push(item);
                    }
                    if (item.status === 'fail') {
                      if (failed_history[events_] === undefined) failed_history[events_] = [];
                      failed_history[events_].push(item);
                    }
                    if (item.status === 'unkown') {
                      if (unkown_history[events_] === undefined) unkown_history[events_] = [];
                      unkown_history[events_].push(item);
                    }
                  });
                });

                this.setState({
                  success_history: success_history,
                  failed_history: failed_history,
                  unkown_history: unkown_history,
                  success: success,
                  failed: failed,
                  unkown: unkown,
                  loading: false
                })
              });
            });
          } else {
            // alert(strings.errorPleaseTryAgain);
            alert('שגיאה בקבלת המבחנים הפנימיים מהשרת');
            this.setState({loading: false})
          }
        })
        .catch(error => {
          console.log(error);
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת המבחנים הפנימיים מהשרת');
          this.setState({loading: false})
        });
      });
    });
  }

  generateWeek = () => {
    let today = new Date(Date.now());

  }

  handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  }

  hebDay = (day) => {
    day = new Date(day).getDay() + 1;

    if (day === 1) return 'ראשון';
    else if (day === 2) return 'שני';
    else if (day === 3) return 'שלישי';
    else if (day === 4) return 'רביעי';
    else if (day === 5) return 'חמישי';
    else if (day === 6) return 'שישי';
    else if (day === 7) return 'שבת';
    else return 'ראשון';
  }

  //set-insidetest-time
  //
  // search = (date) => {
  //   date = new Date(date);
  //   console.log(date);
  //   date = date.getFullYear() + '-' + date.getMonth() + '-' + (parseInt(date.getDate())).toString();
  //
  //   axios.get(API + 'manager/calendar-search?id='+this.state.schoolId+'&date='+date, {
  //     headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
  //   })
  //   .then(response => {
  //     console.log(response);
  //     if (response.data.status === "ok") {
  //       this.setState({calendar: response.data.data.calendar, pendings: response.data.data.pendings}, () => {
  //         let output = {};
  //         let output_history = {};
  //         Object.keys(this.state.calendar).forEach((item) => {
  //           // console.log(new Date(Date.now()));
  //           // console.log(new Date(this.state.calendar[item][0].date));
  //           // if (new Date(Date.now()) <= new Date(this.state.calendar[item][0].date)) {
  //           let date_compare_a = new Date(Date.now())
  //           let date_compare_b = new Date(this.state.calendar[item][0].date);
  //           date_compare_a.setHours(0,0,0,0);
  //           date_compare_b.setHours(0,0,0,0);
  //
  //           // if (new Date(Date.now()) <= new Date(this.state.calendar[item][0].date)) {
  //           if (date_compare_a <= date_compare_b) {
  //             output[item] = this.state.calendar[item];
  //           } else {
  //             output_history[item] = this.state.calendar[item];
  //           }
  //         });
  //         console.log(output);
  //         this.setState({calendar: output, calendar_history: output_history}, () => {
  //           let calendar = this.state.calendar;
  //
  //           let success = [];
  //           let failed = [];
  //           let unkown = [];
  //
  //           Object.keys(calendar).forEach((events_) => {
  //             calendar[events_].forEach((item) => {
  //               if (item.status === 'success') {
  //                 if (success[events_] === undefined) success[events_] = [];
  //                 success[events_].push(item);
  //               }
  //               if (item.status === 'fail') {
  //                 if (failed[events_] === undefined) failed[events_] = [];
  //                 failed[events_].push(item);
  //               }
  //               if (item.status === 'unkown') {
  //                 if (unkown[events_] === undefined) unkown[events_] = [];
  //                 unkown[events_].push(item);
  //               }
  //             });
  //           });
  //
  //           let calendar_history = this.state.calendar_history;
  //           console.log(calendar_history);
  //           let success_history = [];
  //           let failed_history = [];
  //           let unkown_history = [];
  //
  //           Object.keys(calendar_history).forEach((events_) => {
  //             // console.log(events_);
  //             calendar_history[events_].forEach((item) => {
  //               // console.log(item);
  //               if (item.status === 'success') {
  //                 if (success_history[events_] === undefined) success_history[events_] = [];
  //                 success_history[events_].push(item);
  //               }
  //               if (item.status === 'fail') {
  //                 if (failed_history[events_] === undefined) failed_history[events_] = [];
  //                 failed_history[events_].push(item);
  //               }
  //               if (item.status === 'unkown') {
  //                 if (unkown_history[events_] === undefined) unkown_history[events_] = [];
  //                 unkown_history[events_].push(item);
  //               }
  //             });
  //           });
  //
  //           this.setState({
  //             success_history: success_history,
  //             failed_history: failed_history,
  //             unkown_history: unkown_history,
  //             success: success,
  //             failed: failed,
  //             unkown: unkown,
  //           })
  //         });
  //       });
  //     } else {
  //       // alert(strings.errorPleaseTryAgain);
  //       alert('שגיאה בקבלת המבחנים הפנימיים מהשרת')
  //     }
  //   })
  //   .catch(error => {
  //     console.log(error);
  //     // alert(strings.errorPleaseTryAgain);
  //     alert('שגיאה בקבלת המבחנים הפנימיים מהשרת')
  //   });
  // }
  //

    deleteInsideTest = (id) => {
      const a = window.confirm('האם אתם בטוחים שאינכם יכולים לבצע מבחן זה?');
      if (a) {
        this.setState({loading: true}, () => {
          axios.delete(API + 'teacher/insidetest-self?id='+id, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            alert('בוצע')
            this.getData();
            this.setState({loading: false});
          })
          .catch(error => {
            alert(strings.errorPleaseTryAgain);

            this.setState({loading: false});
          });
        })

      }
    }


    search = (e) => {
      this.setState({loading: true}, () =>  {
        const value = this.state.search_query;
        console.log(value);
        if (value === '') {
          this.setState({calendar: this.state.calendarOriginal, calendar_history: this.state.calendar_history_all, all_calendar: this.state.all_calendar_original, loading: false})
        } else {
          const items = this.state.all_calendar_original;
          let output = {};
          Object.keys(items).forEach((item_, i) => {
            let items_ = items[item_];
            items_.forEach((item, i) => {
              let teacher_name = item.teacher.firstName + ' ' + item.teacher.lastName;
              let student_name = item.studentName;
              let student_id = item.studentIdNumber;
              try {
                if (
                  teacher_name.includes(value) ||
                  student_name.includes(value) ||
                  student_id.includes(value) ||
                  value.includes(teacher_name) ||
                  value.includes(student_name) ||
                  value.includes(student_id)
                ) {
                  if (output[item_] === undefined) output[item_] = [];
                  output[item_].push(item);
                }
              } catch (err) {
                // console.log(err);
              }
            });
          });
          this.setState({calendar: output, calendarOriginal: output}, () => {
            let calendar = this.state.calendar;

            let success = [];
            let failed = [];
            let unkown = [];

            Object.keys(calendar).forEach((events_) => {
              // console.log(events_);
              calendar[events_].forEach((item) => {
                // console.log(item);
                if (item.status === 'success') {
                  if (success[events_] === undefined) success[events_] = [];
                  success[events_].push(item);
                }
                if (item.status === 'fail') {
                  if (failed[events_] === undefined) failed[events_] = [];
                  failed[events_].push(item);
                }
                if (item.status === 'unkown') {
                  if (unkown[events_] === undefined) unkown[events_] = [];
                  unkown[events_].push(item);
                }
              });
            });

            let calendar_history = this.state.calendar_history;
            let success_history = [];
            let failed_history = [];
            let unkown_history = [];

            Object.keys(calendar_history).forEach((events_) => {
              // console.log(events_);
              calendar_history[events_].forEach((item) => {
                try {
                  if (
                    (item.teacher.firstName + ' ' + item.teacher.lastName).includes(value) ||
                    item.studentName.includes(value) ||
                    value.includes(item.teacher.firstName + ' ' + item.teacher.lastName) ||
                    value.includes(item.studentName)
                  ) {
                    if (item.status === 'success') {
                      if (success_history[events_] === undefined) success_history[events_] = [];
                      success_history[events_].push(item);
                    }
                    if (item.status === 'fail') {
                      if (failed_history[events_] === undefined) failed_history[events_] = [];
                      failed_history[events_].push(item);
                    }
                    if (item.status === 'unkown') {
                      if (unkown_history[events_] === undefined) unkown_history[events_] = [];
                      unkown_history[events_].push(item);
                    }
                  }
                } catch (err) {
                  // console.log(err);
                }
              });
            });


            this.setState({
              success_history: success_history,
              failed_history: failed_history,
              unkown_history: unkown_history,
              success: success,
              failed: failed,
              unkown: unkown,
              calendar: output,
              all_calendar: output,
              loading: false
            })
          });
        }
      });
    }

    pad = (n) => {return n<10 ? '0'+n : n}

    // searchByDate = (date) => {
    //   this.setState({datesearch: date}, () => {
    //   let d = new Date(date);
    //   date = this.pad(d.getDate()) +'/'+ this.pad(parseInt(d.getMonth() + 1).toString()) +'/'+ d.getFullYear()
    //   console.log('date:', date);
    //
    //     let calendar_ = {};
    //     Object.keys(this.state.all_calendar_original).map((item) => {
    //       if (date === item) {
    //         calendar_[item] = this.state.all_calendar_original[item];
    //       }
    //     });
    //     if (Object.keys(calendar_).length >= 1) {
    //       console.log(calendar_);
    //       this.setState({all_calendar: calendar_});
    //     } else {
    //       this.setState({all_calendar: this.state.all_calendar_original})
    //       // alert('אין מבחן פנימי לתאריך הנבחר, אנא נסו תאריך אחר')
    //     }
    //   });
    // }

        searchByDate = (date) => {
          let d = new Date(date);
          // date = this.pad(d.getDate()) +'/'+ this.pad(parseInt(d.getMonth() + 1).toString()) +'/'+ d.getFullYear()
          date = d.getDate() +'/'+ parseInt(d.getMonth() + 1).toString() +'/'+ d.getFullYear()
          console.log(date);
          let results = {};
          console.log(this.state.all_calendar[date]);
          if (this.state.all_calendar[date] !== undefined && this.state.all_calendar[date].length >= 1) {
            results[date] = this.state.all_calendar[date];
            this.setState({search_calendar: results, view: 'search'});
          }
        }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.myweeklyAgenda}</h2>
              </div>

              <p>{strings.searchbydates}</p>
              <Button variant={'outlined'} onClick={(e) => this.setState({view: 'all', datesearch: new Date(Date.now())}, () => this.getData())}>איפוס</Button>
              &nbsp;
              <Button color={'primary'} variant={'contained'} onClick={(e) => this.searchByDate(this.state.datesearch)}>חיפוש</Button>

              &nbsp;
              <TextField
                value={this.state.datesearch}
                onChange={(e) => this.setState({datesearch: e.target.value})}
                type='date'
                format='dd/mm/yyyy'
              />
              <br /><br />
              {this.state.view !== 'search' && (
                <>
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.setState({view: 'unkown'})} style={{color: this.state.view === 'unkown' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'unkown' ? '#3f51b5' : 'transparent'}}>לא ידוע</Button>
                  <Button onClick={(e) => this.setState({view: 'success'})} style={{color: this.state.view === 'success' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'success' ? '#3f51b5' : 'transparent'}}>עבר</Button>
                  <Button onClick={(e) => this.setState({view: 'failed'})} style={{color: this.state.view === 'failed' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'failed' ? '#3f51b5' : 'transparent'}}>נכשל</Button>
                  <Button onClick={(e) => this.setState({view: 'all'})} style={{color: this.state.view === 'all' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'all' ? '#3f51b5' : 'transparent'}}>{strings.all}</Button>
                  <Button onClick={(e) => this.setState({view: 'history'})} style={{color: this.state.view === 'history' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'history' ? '#3f51b5' : 'transparent'}}>היסטוריה</Button>
                </ButtonGroup>

              <br />
              <br />
              <h1>חיפוש חופשי</h1>

              <Button color={'secondary'} variant={'contained'} onClick={(e) => this.getData()}>איפוס</Button>
              &nbsp;
              <Button color={'primary'} variant={'contained'} onClick={(e) => this.search(e)}>חיפוש</Button>
              &nbsp;

              <TextField
                value={this.state.search_query}
                onChange={(e) => this.setState({search_query: e.target.value})}
                style={{
                  textAlign: 'right',
                  direction: 'rtl',
                  width: '50%'
                }}
                placeholder={'רשמו את שם המורה או התלמיד או את ת.ז התלמיד..'}
              />
              </>
              )}
              <br />
              <br />
                {this.state.loading ? (
                  <center>
                    <Loading />
                  </center>
                ) : (
                  <div>
                  {this.state.view === 'search' && (
                    <React.Fragment>
                    {Object.keys(this.orderByDate(this.state.search_calendar)).reverse().map((item) => {
                      return (
                        <Card>
                         <b>{item}, יום {this.hebDay(this.state.search_calendar[item][0].date)}</b>
                         <br />
                         <hr />
                         {this.state.search_calendar[item].map((ev_) => {
                           return (
                             <React.Fragment>
                             <div style={{pading: 10}}>
                               <center><b>{ev_.teacher.firstName + ' ' + ev_.teacher.lastName}</b></center>
                               <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                               <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>

                               {ev_.status === 'unkown' && (
                                 <React.Fragment>

                                 <br /><br />
                                 <Button onClick={(e) => this.deleteInsideTest(ev_._id)} variant="contained" style={{width: '50%', color: 'white', backgroundColor: 'red'}}>אני לא יכול לבצע מבחן פנימי זה</Button>

                                 </React.Fragment>
                               )}
                               <p></p>
                             </div>
                             <hr />
                             </React.Fragment>
                           )
                         })}
                        </Card>
                      )
                    })}
                    </React.Fragment>
                  )}
                  {this.state.view === 'all' && (
                    <React.Fragment>
                    {Object.keys(this.orderByDate(this.state.all_calendar)).reverse().map((item) => {
                      return (
                        <Card>
                         <b>{item}, יום {this.hebDay(this.state.all_calendar[item][0].date)}</b>
                         <br />
                         <hr />
                         {this.state.all_calendar[item].map((ev_) => {
                           return (
                             <React.Fragment>
                             <div style={{pading: 10}}>
                               <center><b>{ev_.teacher.firstName + ' ' + ev_.teacher.lastName}</b></center>
                               <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                               <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>

                               {ev_.status === 'unkown' && (
                                 <React.Fragment>

                                 <br /><br />
                                 <Button onClick={(e) => this.deleteInsideTest(ev_._id)} variant="contained" style={{width: '50%', color: 'white', backgroundColor: 'red'}}>אני לא יכול לבצע מבחן פנימי זה</Button>

                                 </React.Fragment>
                               )}
                               <p></p>
                             </div>
                             <hr />
                             </React.Fragment>
                           )
                         })}
                        </Card>
                      )
                    })}
                    </React.Fragment>
                  )}
                  {this.state.view === 'success' && (
                    <React.Fragment>
                    {Object.keys(this.orderByDate(this.state.success)).reverse().map((item) => {
                      return (
                        <Card>
                         <b>{item}, יום {this.hebDay(this.state.success[item][0].date)}</b>
                         <br />
                         <hr />
                         {this.state.success[item].map((ev_) => {
                           return (
                             <React.Fragment>
                             <div style={{pading: 10}}>
                               <center><p>{ev_.teacher.firstName + ' ' + ev_.teacher.lastName}</p></center>
                               <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                               <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                               <p></p>
                             </div>
                             <hr />
                             </React.Fragment>
                           )
                         })}
                        </Card>
                      )
                    })}
                    </React.Fragment>
                  )}
                  {this.state.view === 'failed' && (
                    <React.Fragment>
                    {Object.keys(this.orderByDate(this.state.failed)).reverse().map((item) => {
                      return (
                        <Card>
                         <b>{item}, יום {this.hebDay(this.state.failed[item][0].date)}</b>
                         <br />
                         <hr />
                         {this.state.failed[item].map((ev_) => {
                           return (
                             <React.Fragment>
                             <div style={{pading: 10}}>
                               <center><p>{ev_.teacher.firstName + ' ' + ev_.teacher.lastName}</p></center>
                               <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                               <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                               <p></p>
                             </div>
                             <hr />
                             </React.Fragment>
                           )
                         })}
                        </Card>
                      )
                    })}
                    </React.Fragment>
                  )}
                  {this.state.view === 'unkown' && (
                    <React.Fragment>
                    {Object.keys(this.orderByDate(this.state.unkown)).reverse().map((item) => {
                      return (
                        <Card>
                         <b>{item}, יום {this.hebDay(this.state.unkown[item][0].date)}</b>
                         <br />
                         <hr />
                         {this.state.unkown[item].map((ev_) => {
                           return (
                             <React.Fragment>
                             <div style={{pading: 10}}>
                               <center><p>{ev_.teacher.firstName + ' ' + ev_.teacher.lastName}</p></center>
                               <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                               <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>
                               <br /><br />
                               <Button onClick={(e) => this.deleteInsideTest(ev_._id)} variant="contained" style={{width: '50%', color: 'white', backgroundColor: 'red'}}>אני לא יכול לבצע מבחן פנימי זה</Button>

                               <p></p>
                             </div>
                             <hr />
                             </React.Fragment>
                           )
                         })}
                        </Card>
                      )
                    })}
                    </React.Fragment>
                  )}
                  {this.state.view === 'history' && (
                    <React.Fragment>
                    {Object.keys(this.orderByDate(this.state.calendar_history)).reverse().map((item) => {
                      return (
                        <Card>
                         <b>{item}, יום {this.hebDay(this.state.calendar_history[item][0].date)}</b>
                         <br />
                         <hr />
                         {this.state.calendar_history[item].map((ev_) => {
                           return (
                             <React.Fragment>
                             <div style={{pading: 10}}>
                               <center><p>{ev_.teacher.firstName + ' ' + ev_.teacher.lastName}</p></center>
                               <p style={{direction: 'rtl'}}>🚗 מבחן פנימי עם {ev_.studentName} | {ev_.status === 'unkown' ? strings.unkown : (ev_.status === 'success' ? strings.passedHappy + '😄' : strings.notPassedSad + '🙁')} | {this.pad(new Date(ev_.time).getHours()) + ':' + this.pad(new Date(ev_.time).getMinutes())}</p>
                               <Button onClick={(e) => window.location.href='/inside-test?id='+ev_._id} variant="contained" color="primary" style={{width: '50%'}}>כניסה למבחן פנימי</Button>

                               <p></p>
                             </div>
                             <hr />
                             </React.Fragment>
                           )
                         })}
                        </Card>
                      )
                    })}
                    </React.Fragment>
                  )}
                  </div>
                )}

            </center>

          </Grid>


        </Grid>
      </div>
    )
  }
}

export default InsideTests;
