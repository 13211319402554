import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import IdleTimer from 'react-idle-timer'

const strings = new LocalizedStrings(i18n);

const SITE = 'https://sivan-school.com/';
const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ClerkDashboard extends Component {
  componentDidMount() {
    this.getData();
    this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],

     lastSeen: '',
     clerk: {
       firstName: '',
       lastName: ''
     },
     teachers: [],
     students: [],
     studentsApproval: [],
     payments: [],
     language: 'he',
     messages: [],
     tickerMove: true,
     showTicker: true,

     payment_string: '',
     signToBroshInsideTest_date: new Date(Date.now()),

     haveInsideTests: false,
     haveSignUpFee: false,
     canManagerPayToEmployees: false,
     search_query: '',
     studentsOriginal: [],
     notFound: false,
     filter: 'all',
     paymentsOriginal: [],
     teacherIdSearch: 'all',

     haveStore: false,
     idleTimer: null,
     requestSMSVerification: false,
     inactivityAutoLogout: false,
     everyHourAutoLogout: false,
     passedtest_students: [],
     page: 1,
     table_load: false
   }
  }

  getMessages = async () => {
    axios.get(API + 'messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      // console.log(response);
      if (response.data.status === "ok") {
        let msgs = response.data.data.messages;
        this.setState({messages: msgs})
      } else {
        // alert(strings.errorPleaseTryAgain);

      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  signToBrosh = (id) => {
    let sure = window.confirm('האם אתם בטוחים שברצונכם לרשום תלמיד זה לברוש?');

    if (sure) {
      axios.put(API + 'clerk/student/signtobrosh?id='+id, {}, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        // console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    }
  }

  signToBroshInsideTest = (id) => {
    let sure = window.confirm('האם אתם בטוחים שאתם רוצים לעדכן בברוש שתלמיד זה עבר מבחן פנימי?');

    if (sure) {
      axios.put(API + 'clerk/student/signtobrosh-insidetest?id='+id, {
        date: this.state.signToBroshInsideTest_date
      }, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    }
  }


  getData = async () => {
    this.setState({table_load: true}, () => {
      axios.get(API + 'clerk?home=true', {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        // console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            clerk: response.data.data.clerk,
            students: response.data.data.students,
            studentsOriginal: response.data.data.students,
            lastSeen: response.data.lastSeen,
            teachers: response.data.data.teachers,
            payments: response.data.data.payments.reverse(),
            paymentsOriginal: response.data.data.payments,
            haveInsideTests: response.data.haveInsideTests,
            haveSignUpFee: response.data.haveSignUpFee,
            canManagerPayToEmployees: response.data.canManagerPayToEmployees,
            haveStore: response.data.haveStore,
            requestSMSVerification: response.data.requestSMSVerification,
            inactivityAutoLogout: response.data.inactivityAutoLogout,
            everyHourAutoLogout: response.data.everyHourAutoLogout,
          }, () => {
            let students = [];
            let passedtest_students = [];
            let studentsApproval = [];

            this.state.students.forEach((item, i) => {
              if (item.approved === "false" || !item.approved) studentsApproval.push(item);
              else  {
                if (item.passedtest) passedtest_students.push(item);
                else students.push(item);
              }
            });

            let teachers_ = [];
            this.state.teachers.forEach((item, i) => {
              if (item.type !== 'clerk' && item.type !== 'cpe') teachers_.push(item);
            });


            this.setState({
              teachers: teachers_,
              students: students,
              passedtest_students: passedtest_students,
              studentsApproval: studentsApproval,
              page: 1
            }, () => {
              this.setState({table_load: false})
              console.log('*********DONE*********')
            });
          });
        } else {
          alert('שגיאה בקבלת הפרופיל מהשרת')
          // alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאת מערכת (קוד 1772)')
      });
    })

  }

  approveStudentRequest = (id) => {
    axios.put(API + 'clerk/student/approve?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  removeStudentRequest = (id) => {
    axios.delete(API + 'clerk/student?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  copyToClipboard = (text) =>  {
    alert(strings.messageCopiedSuccess);
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }

  searchPayment = () => {
    this.setState({notFound: false});

    let payments = this.state.payments;
    let string = this.state.payment_string;
    let output = [];

    payments.forEach((payment) => {
      if (payment.studentName.includes(string)) output.push(payment);
    });
    if (output.length > 0) {
      this.setState({payments: output, page: 1})
    } else {
      this.setState({notFound: true, page: 1});
    }
  }

  searchStudent = () => {
    const q = this.state.search_query;
    if (q !== '') {
      return axios.get('https://api.sivan-school.com/v2/search-student?q='+q, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(res => {
        if (res.data.status === "ok") {
          this.setState({students: res.data.data.students, teacherIdSearch: 'all'});
        }
      });
    }
  }

  filterTeacher = (teacherId) => {
    this.setState({teacherIdSearch: teacherId}, () => {
      if (teacherId === 'all' || teacherId === '') {
        this.getData();
      } else {
        return axios.get('https://api.sivan-school.com/v2/search-student-by-teacher?id='+teacherId, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        }).then(res => {
          if (res.data.status === "ok") {
            this.setState({students: res.data.data.students});
          }
        });
      }
    });
  }


  searchStudentLive = async (string) => {
    this.setState({search_query: string, filter: 'all', page: 1}, () => {
      let teacherIdSearch = this.state.teacherIdSearch;
      if (teacherIdSearch === 'all' || teacherIdSearch === '') {
        let students = this.state.studentsOriginal;
        let output = [];
        students.forEach((item, i) => {
          if (
            item.firstName.includes(string) ||
            string.includes(item.firstName) ||
            item.lastName.includes(string) ||
            string.includes(item.lastName) ||
            item.idNumber.includes(string) ||
            string.includes(item.idNumber)
          ) {
            output.push(item);
          }
        });

          this.setState({students: output});

      } else {
        let students = this.state.studentsOriginal;
        let output = [];
        students.forEach((item, i) => {
          console.log(item);
          if (
            (item.firstName.includes(string) ||
            string.includes(item.firstName) ||
            item.lastName.includes(string) ||
            string.includes(item.lastName) ||
            item.idNumber.includes(string) ||
            string.includes(item.idNumber)) && (item.teacherId === teacherIdSearch)
          ) {
            output.push(item);
          }
        });

        // if (output.length > 0) {
          this.setState({students: output});
        // } else {
        //   this.setState({students: this.state.studentsOriginal});
        // }
      }
    });
  }


  searchPaymentLive = (string) => {
    this.setState({payment_string: string}, () => {
      let payments = this.state.paymentsOriginal;
      let output = [];

      payments.forEach((item, i) => {
        if (
          string.includes(item.studentName) ||
          item.studentName.includes(string) ||
          string.includes(item.amountWithVAT) ||
          item.amountWithVAT.toString().includes(string)
        ) {
          output.push(item);
        }
      });

      // if (output.length > 0) {
        this.setState({payments: output})
      // } else {
      //   this.setState({payments: this.state.paymentsOriginal});
      // }
    });
  }

  filter = (type = 'all') => {
    this.setState({filter: type, page: 1}, () => {
      let teacherIdSearch = this.state.teacherIdSearch;

      if (teacherIdSearch === 'all' || teacherIdSearch === '') {
        if (type === 'all') {
          let students = this.state.studentsOriginal;
          let output = [];
          this.setState({students: this.state.studentsOriginal})
        } else if (type === 'not_paid_signup') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (item.paidSignupFee === 'no' || item.paidSignupFee === '') output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'not_paid_insidetest') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (item.paidInsideTestFee === 'no' || item.paidInsideTestFee === '') output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'not_paid_nothing') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if ((item.paidSignupFee === 'no' || item.paidSignupFee === '') && (item.paidInsideTestFee === 'no' || item.paidInsideTestFee === '')) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'passed_test_search') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (item.passedexternalTest) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'active_search') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (!item.active) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'passed_internal_test') {
          let students = this.state.passedtest_students;
          this.setState({students: students});
        } else if (type === 'not_signed_in_brosh') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (!item.didSignedWithBrosh) output.push(item);
          });

          this.setState({students: output});
        } else if (type === "signed_in_brosh_and_not_payed_signup_fee") {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (!item.didSignedWithBrosh) {
              if ((item.paidSignupFee === 'yes')) output.push(item);
            }
          });

          this.setState({students: output});
        } else {
          let students = this.state.studentsOriginal;
          let output = [];
          this.setState({students: this.state.studentsOriginal})
        }
      } else {
        if (type === 'all') {
          let students = this.state.studentsOriginal;
          let output = [];

          students.forEach((item, i) => {
            if (item.teacherId === teacherIdSearch) output.push(item);
          });

          this.setState({students: output})
        } else if (type === 'not_paid_signup') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if ((item.paidSignupFee === 'no' || item.paidSignupFee === '') && (item.teacherId === teacherIdSearch)) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'not_paid_insidetest') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if ((item.paidInsideTestFee === 'no' || item.paidInsideTestFee === '') && (item.teacherId === teacherIdSearch)) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'not_paid_nothing') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (((item.paidSignupFee === 'no' || item.paidSignupFee === '') && (item.paidInsideTestFee === 'no' || item.paidInsideTestFee === '')) && (item.teacherId === teacherIdSearch)) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'passed_test_search') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (item.passedexternalTest) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'active_search') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (!item.active) output.push(item);
          });

          this.setState({students: output});
        } else if (type === 'passed_internal_test') {
          let students = this.state.passedtest_students;
          this.setState({students: students});
        } else if (type === 'not_signed_in_brosh') {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (!item.didSignedWithBrosh) output.push(item);
          });

          this.setState({students: output});
        } else if (type === "signed_in_brosh_and_not_payed_signup_fee") {
          let students = this.state.studentsOriginal;
          let output = [];
          students.forEach((item, i) => {
            if (!item.didSignedWithBrosh) {
              if ((item.paidSignupFee === 'yes')) output.push(item);
            }
          });

          this.setState({students: output});
        } else {
          let students = this.state.studentsOriginal;
          let output = [];

          students.forEach((item, i) => {
            if (item.teacherId === teacherIdSearch) output.push(item);
          });

          this.setState({students: output})
        }
      }

    });
  }

  handleOnAction = (event) => {
    // console.log('user did something', event)
  }

  handleOnActive = (event) => {
    console.log('user is active', event)
  }

  handleOnIdle = (event) => {
    cookies.remove('token___school');
    alert('לא זוהתה פעולה בדקות האחרונות, יש להתחבר מחדש')
    window.location.href='/';
  }



      changePassedExternalTest = (id, status) => {
        if (status) {
          axios.put(API + 'clerk/student-change-passedexternalTest-false?id='+id, {}, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            // console.log(response);
            if (response.data.status === "ok") {
              this.getData();
            } else {
              alert(strings.errorPleaseTryAgain);
            }
          })
          .catch(error => {
            console.log(error);
            alert(strings.errorPleaseTryAgain);
          });
        } else {
          axios.put(API + 'clerk/student-change-passedexternalTest-true?id='+id, {}, {
            headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
          })
          .then(response => {
            // console.log(response);
            if (response.data.status === "ok") {
              this.getData();
            } else {
              alert(strings.errorPleaseTryAgain);
            }
          })
          .catch(error => {
            console.log(error);
            alert(strings.errorPleaseTryAgain);
          });
        }

      }

      changeActive = (id) => {
        axios.put(API + 'clerk/student-change-active?id='+id, {}, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          // console.log(response);
          if (response.data.status === "ok") {
            this.getData();
          } else {
            alert(strings.errorPleaseTryAgain);
          }
        })
        .catch(error => {
          console.log(error);
          alert(strings.errorPleaseTryAgain);
        });
      }


            showMoreRecords = () => {
              let page = this.state.page;
              const perpage = 5;

              this.setState({page: page + 1});
            }

  getSignupFeeText = () => {
    let ft = window.localStorage.getItem('signupfee_text');
    if (ft === null || ft === undefined || ft === 'NULL' || ft === 'null') {
      return 'תשלום דמי רישום';
    }
    return ft;
  }
  render() {
    return (
      <div className="App">

      {this.state.inactivityAutoLogout && (
        <IdleTimer
          ref={ref => { this.state.idleTimer = ref }}
          timeout={1000 * 60 * 5}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
      )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <br />

          <center><br />
          <Card style={{maxWidth: '90%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <CardContent>
            <div>
            {this.state.showTicker && (
              <div class="ticker-wrap" onClick={(e) => this.setState({showTicker: false})}>
                <div class="ticker" onClick={(e) => this.setState({showTicker: false})}>
                {this.state.messages.map((index, i) => {
                  if (i === 0) {
                    return (
                      <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                        <span onClick={(e) => this.setState({showTicker: false})}> {index.message} </span>
                      </div>
                    )
                  } else {
                    return (
                      <div class="ticker__item" onClick={(e) => this.setState({showTicker: false})}>
                        <span onClick={(e) => this.setState({showTicker: false})}>  {index.message} |&nbsp;  </span>
                      </div>
                    )
                  }
                })}
                </div>
              </div>
            )}
            </div>

            {this.state.clerk.shouldReportHours === true && (
              <>
              <Link to='/report-hours' style={{textDecoration: 'none'}}>
                <Button>
                  <p style={{color: 'red', fontSize: '1.5em'}}>דיווח שעות</p>
                </Button>
              </Link><br />
              </>
            )}


            {this.state.clerk.canSeeKupotMorim && (
              <>
              <Link
              to={'/commission-table'}
                style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                    קופות עמלות מורים ותשלומים
                  </Button>
              </Link><br />
              </>
            )}


            {this.state.haveStore && (
              <React.Fragment>
                <Link
                  to={'/store/slogan'}
                  style={{textDecoration: 'none'}}>
                    <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                      ערוך את סלוגן החנות שלך
                    </Button>
                </Link>
              </React.Fragment>
            )}

              <p style={{fontWeight: 'bold', color: '#404041', fontSize: '1.2em'}}>{this.state.clerk.firstName + ' ' + this.state.clerk.lastName} <AssignmentIndIcon style={{width: 32, height: 32}}/></p>
              <br />

              {isMobile ? (
                <Link to='/student/add' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher">
                    <PersonAddIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                  </IconButton>
                </Link>
              ) : (
                <Link to='/student/add' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher">
                    <PersonAddIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.studentadd}</p>
                  </IconButton>
                </Link>
              )}
              <br />
              {isMobile ? (
                <Link to='/get-link' style={{textDecoration: 'none'}}>
                  <IconButton alt="add-teacher">
                    <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                  </IconButton>
                </Link>
              ) : (
                <Link to='/get-link' style={{textDecoration: 'none'}}>
                <IconButton alt="add-teacher">
                    <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.getexternalstudentlink}</p>
                  </IconButton>
                </Link>
              )}
              <br />
              {isMobile ? (
                <React.Fragment>
                  <hr style={{maxWidth: '90%'}}/>
                  <Link to={'/payment'} style={{textDecoration: 'none'}}>
                    <IconButton alt="add-payment">
                      <AccountBalanceIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                    </IconButton>
                  </Link>
                  <p style={{fontSize: '1em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                  <Link to='/outside-link' style={{textDecoration:'none'}}><IconButton alt="add-teacher">
                      <LinkIcon style={{color: '#404041', width: 32, height: 32}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                  </IconButton></Link>
                </React.Fragment>
              ) : (
                <div style={{border: '1px solid #717171', maxWidth: '30%'}}>
                <Link to={'/payment'} style={{textDecoration: 'none'}}>
                <IconButton alt="add-payment">
                    <AccountBalanceIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.addpaymentlink}</p>
                  </IconButton>
                </Link>
                <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>{strings.orsendlinkpayment}</p>
                <Link to='/outside-link' style={{textDecoration:'none'}}><IconButton alt="add-teacher">
                    <LinkIcon style={{color: '#404041'}}/> <p style={{color: 'black', fontSize: '0.7em'}}>&nbsp; {strings.sendpaymentlink}</p>
                </IconButton></Link>
                </div>
              )}
              <br />


              {this.state.clerk.obligationManagment === true && (
                <Link to='/financial-obligations' style={{textDecoration: 'none'}}>
                  <Button variant='outlined'>פורטל התחייבויות מורים</Button>
                </Link>
              )}

              {this.state.clerk.canSeeTeachersIncome === true && (
                <Link to='/teachers-income' style={{textDecoration: 'none'}}>
                  <Button variant='outlined'>צפה בהכנסות מורים</Button>
                </Link>
              )}

              {(this.state.clerk.canSeeInsideTests && this.state.haveInsideTests) && (
                <React.Fragment>
                <br /><br />
                <Link to={'/inside-test/add'} style={{textDecoration: 'none'}}><Button variant='outlined'><DriveEtaIcon style={{color: '#404041'}} />{strings.setInsideTest}</Button></Link>
                <br /><br />
                <Link to={'/inside-tests'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'}><DriveEtaIcon style={{color: 'white'}} />{strings.insidetestlist}</Button></Link>
                </React.Fragment>
              )}

              <br /><br />
              {this.state.clerk.canWithdrawMoney && (
                <Link to={'/transfer-money'} style={{textDecoration: 'none'}}><Button variant='outlined'><AccountBalanceIcon style={{color: '#404041'}} />{strings.mycashregisterandtransfermoney}</Button></Link>
              )}
              <br />
              <Link to={'/generate-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 210}}><DescriptionIcon style={{color: 'white'}} />{strings.generateinvoices}</Button></Link>
              <br />

              {this.state.canManagerPayToEmployees && (
                <React.Fragment>
                <Link to={'/cheques'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><DescriptionIcon style={{color: 'white'}} />{strings.writeacheque}</Button></Link>
                <br />
                </React.Fragment>
              )}


              {this.state.clerk.canSellItems && (
                <React.Fragment>
                <Link to={'/items'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><DescriptionIcon style={{color: 'white'}} />{strings.manageItems}</Button></Link>
                <br />
                </React.Fragment>
              )}

                          {this.state.clerk.canGeneratePaymentDocument && (
                            <React.Fragment>
                              <Link
                                to={'/payment-document'}
                                style={{textDecoration: 'none'}}>
                                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 310}}>
                                    הפקדת דו"ח תשלומים תקופתי
                                  </Button>
                              </Link>
                            </React.Fragment>
                          )}


              {this.state.clerk.smallcashregisterIn && (
                <React.Fragment>
                <Link to={'/small-cashregister-in'} style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}><DescriptionIcon style={{color: 'white'}} />
                    הפקדת כסף לקופה קטנה
                  </Button>
                </Link>
                <br />
                </React.Fragment>
              )}
              {this.state.clerk.smallcashregisterOut && (
                <React.Fragment>
                <Link to={'/small-cashregister'} style={{textDecoration: 'none'}}>
                  <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>
                    <DescriptionIcon style={{color: 'white'}} />
                      רישום תשלום יוצא לקופה קטנה
                    </Button>
                  </Link>
                <br />
                </React.Fragment>
              )}

              {this.state.clerk.canAddEmployees && (
                <React.Fragment>
                  <br /><br />
                  <Link to={'/employees'} style={{textDecoration: 'none'}}>
                    <Button variant='contained' color={'primary'} style={{fontSize: '1.2em'}}>{strings.manageEmployees}</Button>
                  </Link>
                </React.Fragment>
              )}

              {this.state.clerk.canTransferStudent && (
                <React.Fragment>
                <br />
                <Link to={'/migrate'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.migrateAction}</Button></Link>
                </React.Fragment>
              )}

              {this.state.clerk.canDeposit && (
                <React.Fragment>
                <br />
                <Link to={'/deposit'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.deposit}</Button></Link>
                </React.Fragment>
              )}

              {this.state.clerk.canDiscoverPayments && (
                <React.Fragment>
                <br />
                <Link to={'/payments/discover'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.discoverPayments}</Button></Link>
                </React.Fragment>
              )}

              {this.state.haveInsideTests && (
                <React.Fragment>
                <br />
                <Link to={'/inside-tests-ev'} style={{textDecoration: 'none'}}>
                    <Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.rishumBabroshDates}</Button></Link>
                </React.Fragment>
              )}

              {this.state.clerk.canSeeTeachersCashRegister && (
                <React.Fragment>
                <br />
                <Link to={'/cash-registers'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{strings.canSeeTeachersCashRegister}</Button></Link>
                </React.Fragment>
              )}

              {this.state.clerk.canSeePtorsGiven && (
                <React.Fragment>
                <br />
                <Link to={'/ptors-given'} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'} style={{ marginTop: '.5%', width: 210}}>{'מעקב פטורים'}</Button></Link>
                </React.Fragment>
              )}


              {this.state.clerk.canRefund && (
                <React.Fragment>
                <br />
                <Link
                  to={'/refunds'}
                  style={{textDecoration: 'none'}}>
                    <Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 210}}>
                      {strings.refunds}
                    </Button>
                </Link>
                <br />
                </React.Fragment>
              )}
              <br />
              <Link to={'/students-invoices'} style={{textDecoration: 'none'}}><Button variant='contained' color={'primary'} style={{ marginTop: '.5%', width: 250}}>{'חשבוניות תלמידים'}</Button></Link>
              <br />


            </CardContent>
          </Card>
         </center>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mystudents}</h2>
            <h5>שימו לב: לטובת מהירות הגלישה, מוצגים 100 התלמידים האחרונים בלבד</h5>
          </center>
        </Grid>
        <Grid item xs={12}>
        <Select
          value={this.state.teacherIdSearch}
          onChange={(e) => this.filterTeacher(e.target.value)}
          style={{
            direction: 'rtl',
            textAlign: 'right',
            width: '20%'
          }}
          placeholder={'בחר מורה..'}
        >
          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'all'}>כל המורים</MenuItem>
          {this.state.teachers.map((index) => {
            return (
              <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index._id}>{index.firstName + ' ' + index.lastName}</MenuItem>
            )
          })}
        </Select>
        <span style={{direction: 'rtl'}}> :סינון תלמידים על פי מורה</span>
          <br />
          <center>
          <h4>{'חפש על פי קבוצה'}</h4>
            <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
              <Button onClick={(e) => this.filter('passed_test_search')} style={{color: this.state.filter === 'passed_test_search' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'passed_test_search' ? '#3f51b5' : 'transparent'}}>עבר טסט</Button>
              {(this.state.haveSignUpFee && this.state.haveInsideTests) && (<Button onClick={(e) => this.filter('not_paid_nothing')} style={{color: this.state.filter === 'not_paid_nothing' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'not_paid_nothing' ? '#3f51b5' : 'transparent'}}>לא שילמו מבחן פנימי ו{this.getSignupFeeText()}</Button>)}
              {this.state.haveInsideTests && (<Button onClick={(e) => this.filter('not_paid_insidetest')} style={{color: this.state.filter === 'not_paid_insidetest' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'not_paid_insidetest' ? '#3f51b5' : 'transparent'}}>לא שילמו מבחן פנימי</Button>)}
              {this.state.haveSignUpFee && (<Button onClick={(e) => this.filter('not_paid_signup')} style={{color: this.state.filter === 'not_paid_signup' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'not_paid_signup' ? '#3f51b5' : 'transparent'}}>לא שילמו {this.getSignupFeeText()}</Button>)}
              <Button onClick={(e) => this.filter('all')} style={{color: this.state.filter === 'all' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'all' ? '#3f51b5' : 'transparent'}}>הכל</Button>
            </ButtonGroup>
            <br />
            <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
              <Button onClick={(e) => this.filter('signed_in_brosh_and_not_payed_signup_fee')} style={{color: this.state.filter === 'signed_in_brosh_and_not_payed_signup_fee' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'signed_in_brosh_and_not_payed_signup_fee' ? '#3f51b5' : 'transparent'}}>שילם {this.getSignupFeeText()} ולא רשום בברוש</Button>
              {/*              <Button onClick={(e) => this.filter('not_signed_in_brosh')} style={{color: this.state.filter === 'not_signed_in_brosh' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'not_signed_in_brosh' ? '#3f51b5' : 'transparent'}}>לא רשום בברוש</Button>
*/}
              <Button onClick={(e) => this.filter('passed_internal_test')} style={{color: this.state.filter === 'passed_internal_test' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'passed_internal_test' ? '#3f51b5' : 'transparent'}}>עבר מ.פ</Button>
              <Button onClick={(e) => this.filter('active_search')} style={{color: this.state.filter === 'active_search' ? 'white' : '#3f51b5', backgroundColor: this.state.filter === 'active_search' ? '#3f51b5' : 'transparent'}}>הצג לא פעילים</Button>
            </ButtonGroup>
          </center>

          <h4>- או -</h4>
        </Grid>
        <center>
          <h4>{strings.searchstudentbynm}</h4>

          <Button variant={'contained'} onClick={(e) => this.setState({search_query: ''}, () => {this.getData()})}>איפוס</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant={'contained'} color={'primary'} onClick={(e) => this.searchStudent()}>חיפוש</Button>
          &nbsp;&nbsp;&nbsp;
          <TextField
            value={this.state.search_query}
            onChange={(e) => this.setState({search_query: e.target.value})}
            style={{
              textAlign: 'right',
              direction: 'rtl'
            }}
            placeholder={'טקסט חופשי'}
          />
          <br />
        </center>
        <Grid item xs={12}>
        <center>
        {this.state.table_load ? (
          <>
            <center>
              <CircularProgress />
            </center>
          </>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.edit}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>עבר \ לא עבר טסט</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>פעיל \ לא פעיל</TableCell>
                  {this.state.haveInsideTests && (<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidinsidetestfee}</TableCell>)}
                  {this.state.haveSignUpFee && (<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidsignupfee}</TableCell>)}
                  {this.state.haveInsideTests && (<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.statusBroshInsideTest}</TableCell>)}
                  {this.state.haveSignUpFee && (<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.statusBrosh}</TableCell>)}
                  {/*<TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidsignupfee}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidinsidetestfee}</TableCell>*/}

                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.idnumber}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {this.state.students.map((student, i) => {
                  if (this.state.filter === 'active_search') {
                    return (
                      <TableRow key={i+'_student'}>
                        <TableCell align="right">
                          <Link to={'/student/update?id='+student._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.clerk.changeStudentPassTest ? (
                            <Button onClick={(e) => this.changePassedExternalTest(student._id, student.passedexternalTest)}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                          ) : (
                            <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.clerk.changeStudentActive ? (
                            <Button onClick={(e) => this.changeActive(student._id)}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                          ) : (
                            <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                          )}
                        </TableCell>
                        {this.state.haveInsideTests && (<TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor + ' '+student.paidSignupFeePtorReason+'' : strings.no)}</TableCell>)}
                        {this.state.haveSignUpFee && (<TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor + ' '+student.paidInsideTestPtorReason+'' : strings.no)}</TableCell>)}
                        {this.state.haveInsideTests && (
                          <TableCell align="right">
                          {
                            student.canSignWithBroshInsideTest ? (
                              student.didSignedWithBroshInsideTest ?
                               ('עודכן שעבר מבחן פנימי בברוש' + ' ('+(student.didSignedWithBroshInsideTestDate === undefined ? 'שגיאה' : (new Date(student.didSignedWithBroshInsideTestDate).getDate() + '/' + parseInt(new Date(student.didSignedWithBroshInsideTestDate).getMonth() + 1) + '/' + new Date(student.didSignedWithBroshInsideTestDate).getFullYear()))+')')
                               : (
                                 <React.Fragment>
                                 <TextField
                                  value={this.state.signToBroshInsideTest_date}
                                  onChange={(e) => this.setState({signToBroshInsideTest_date: e.target.value})}
                                  type="date"
                                  format='dd/mm/yyyy'
                                />
                                 <Button onClick={(e) => this.signToBroshInsideTest(student._id)}>לא עודכן עדיין בברוש שעבר מבחן פנימי</Button>
                                 </React.Fragment>
                               )
                            ) : (
                              'לא יכול לעדכן מבחן פנימי בברוש'
                            )
                          }
                          </TableCell>
                        )}
                        {this.state.haveSignUpFee && (
                          <TableCell align="right">
                          {
                            student.canSignWithBrosh ? (
                              student.didSignedWithBrosh ?
                               'נרשם בברוש'
                               : (<Button onClick={(e) => this.signToBrosh(student._id)}>לא נרשם עדיין בברוש</Button>)
                             )
                               : 'לא יכול להרשם בברוש'
                          }
                          </TableCell>
                        )}
                        {/*<TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor : strings.no)}</TableCell>
                        <TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor : strings.no)}</TableCell>*/}
                        <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                        <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                        <TableCell align="right">{student.phoneNumber}</TableCell>
                        <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                        <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                        <TableCell align="right">{student.idNumber}</TableCell>
                        <TableCell align="right">{student.teacher === undefined ? '' : (student.teacher.firstName + ' ' + student.teacher.lastName)}</TableCell>
                        <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                      </TableRow>
                    )
                  } else {
                    if (student.active) {
                      return (
                        <TableRow key={i+'_student'}>
                          <TableCell align="right">
                            <Link to={'/student/update?id='+student._id} style={{textDecoration: 'none'}}><IconButton alt="edit-user"><EditIcon style={{color: '#404041'}}/></IconButton></Link>
                          </TableCell>
                          <TableCell align="right">
                            {this.state.clerk.changeStudentPassTest ? (
                              <Button onClick={(e) => this.changePassedExternalTest(student._id, student.passedexternalTest)}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                            ) : (
                              <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.passedexternalTest ? 'עבר' : 'לא עבר'}</Button>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {this.state.clerk.changeStudentActive ? (
                              <Button onClick={(e) => this.changeActive(student._id)}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                            ) : (
                              <Button onClick={(e) => this.alert('אין לך הרשאה לשנות ערך זה')}>{student.active ? 'פעיל' : 'לא פעיל'}</Button>
                            )}
                          </TableCell>
                          {this.state.haveInsideTests && (<TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor + ' '+student.paidSignupFeePtorReason+'' : strings.no)}</TableCell>)}
                          {this.state.haveSignUpFee && (<TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor + ' '+student.paidInsideTestPtorReason+'' : strings.no)}</TableCell>)}
                          {this.state.haveInsideTests && (
                            <TableCell align="right">
                            {
                              student.canSignWithBroshInsideTest ? (
                                student.didSignedWithBroshInsideTest ?
                                 ('עודכן שעבר מבחן פנימי בברוש' + ' ('+(student.didSignedWithBroshInsideTestDate === undefined ? 'שגיאה' : (new Date(student.didSignedWithBroshInsideTestDate).getDate() + '/' + parseInt(new Date(student.didSignedWithBroshInsideTestDate).getMonth() + 1) + '/' + new Date(student.didSignedWithBroshInsideTestDate).getFullYear()))+')')
                                 : (
                                   <React.Fragment>
                                   <TextField
                                    value={this.state.signToBroshInsideTest_date}
                                    onChange={(e) => this.setState({signToBroshInsideTest_date: e.target.value})}
                                    type="date"
                                    format='dd/mm/yyyy'
                                  />
                                   <Button onClick={(e) => this.signToBroshInsideTest(student._id)}>לא עודכן עדיין בברוש שעבר מבחן פנימי</Button>
                                   </React.Fragment>
                                 )
                              ) : (
                                'לא יכול לעדכן מבחן פנימי בברוש'
                              )
                            }
                            </TableCell>
                          )}
                          {this.state.haveSignUpFee && (
                            <TableCell align="right">
                            {
                              student.canSignWithBrosh ? (
                                student.didSignedWithBrosh ?
                                 'נרשם בברוש'
                                 : (<Button onClick={(e) => this.signToBrosh(student._id)}>לא נרשם עדיין בברוש</Button>)
                               )
                                 : 'לא יכול להרשם בברוש'
                            }
                            </TableCell>
                          )}
                          {/*<TableCell align="right">{student.paidSignupFee === 'yes' ? strings.yes : (student.paidSignupFee === 'ptor' ? strings.ptor : strings.no)}</TableCell>
                          <TableCell align="right">{student.paidInsideTestFee === 'yes' ? strings.yes : (student.paidInsideTestFee === 'ptor' ? strings.ptor : strings.no)}</TableCell>*/}
                          <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                          <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                          <TableCell align="right">{student.phoneNumber}</TableCell>
                          <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                          <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                          <TableCell align="right">{student.idNumber}</TableCell>
                          <TableCell align="right">{student.teacher === undefined ? '' : (student.teacher.firstName + ' ' + student.teacher.lastName)}</TableCell>
                          <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                        </TableRow>
                      )
                    }
                  }

                })}

              </TableBody>
              <TableFooter>
              <TableRow>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                <div style={{textAlign: 'right'}}>
                  {(this.state.page * 5) > this.state.students.length ? (
                    <p>ראיתם הכל</p>
                  ) : (
                    <Button style={{width: 400, fontSize: '1.3em', fontWeight: 'bold', marginRight: '-13%'}} color={'primary'} onClick={(e) => this.showMoreRecords()}>לחצו להצגת רשומות נוספות (נשארו להצגה {(this.state.students.length - (this.state.page * 5))})</Button>
                  )}
                </div></TableCell>
              </TableRow>
            </TableFooter>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>

        {this.state.studentsApproval.length >= 1 && (
          <React.Fragment>
          <Grid item xs={12}>
            <br />
            <center>
              <h2>{strings.getexternalstudentlinktitle}</h2>
            </center>
          </Grid>
          <Grid item xs={12}>
          <center>
          {this.state.studentsApproval.length === 0 ? (
            <h4 style={{direction: 'rtl', color: 'grey'}}>
            {strings.nostudentstoapprove1}
            <br/>
            {strings.nostudentstoapprove2}
            </h4>
          ) : (
            <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.delete}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.approve}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.status}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.birthday}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.licensetype}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.phonenumber}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.gender}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.studentsApproval.reverse().map((student, i) => {
                    return (
                      <TableRow key={i+'_student'}>
                        <TableCell align="right">
                          <IconButton alt="edit-user" onClick={(e) => this.removeStudentRequest(student._id)}>
                            <DeleteForeverIcon style={{color: '#404041'}}/>
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton alt="edit-user" onClick={(e) => this.approveStudentRequest(student._id)}>
                            <CheckIcon style={{color: '#404041'}}/>
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">{student.status === 'passed_test' ? strings.passedtest : (student.status === 'active' ? strings.active : strings.inactive)}</TableCell>
                        <TableCell align="right">{new Date(student.birthday).getDate() + '/' + parseInt(new Date(student.birthday).getMonth() + 1) + '/' + new Date(student.birthday).getFullYear()}</TableCell>
                        <TableCell align="right">{(student.licenseType === 'B_automate' || student.licenseType === 'B_manual') ? (student.licenseType === 'B_automate' ? 'B אוטומט' : 'B הילוכים') : student.licenseType}</TableCell>
                        <TableCell align="right">{student.phoneNumber}</TableCell>
                        <TableCell align="right">{new Date(student.createdAt).getDate() + '/' + parseInt(new Date(student.createdAt).getMonth() + 1) + '/' + new Date(student.createdAt).getFullYear()}</TableCell>
                        <TableCell align="right">{student.gender === 'other' ? strings.othergender : (student.gender === 'male' ? strings.male : strings.female)}</TableCell>
                        <TableCell align="right">{student.firstName + ' ' + student.lastName + ' ('+student.idNumber+')'}</TableCell>
                        <TableCell align="right">{student.teacherName}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          </center>
          </Grid>
          </React.Fragment>
        )}


        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mypayments}</h2>
            <h4 style={{marginTop: 0, marginBottom: 0}}>ה20 האחרונות</h4>
          </center>
        </Grid>
        <Grid item xs={12}>
        <center>

          <React.Fragment>
            <Button variant={'contained'} onClick={(e) => this.setState({payment_string: ''}, () => { this.getData() })}>איפוס</Button>
            &nbsp;&nbsp;&nbsp;
            {/*<Button variant={'contained'} color={'primary'} onClick={(e) => this.searchPayment()}>חיפוש</Button>
            &nbsp;&nbsp;&nbsp;*/}
            <TextField
              value={this.state.payment_string}
              onChange={(e) => this.searchPaymentLive(e.target.value)}
              style={{
                textAlign: 'right',
                direction: 'rtl'
              }}
            />
            <br /><br />
            <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>חשבונית</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paymentType}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountWithVAT}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.paidfor}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.dateOfPayment}</TableCell>
                    <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.payments.slice(0, 20).map((payment, i) => {
                    if (!payment.isPtor) {
                      return (
                        <TableRow key={i+'_payment'}>
                          {payment.refunded ? (
                            <TableCell align="right"><Button onClick={(e) => window.open('https://sivan-school.com/pdfs/'+payment._id+'_refund.pdf', '_blank')}>צפה בחשבונית</Button></TableCell>
                          ) : (
                            <TableCell align="right"><Button onClick={(e) => window.open('https://sivan-school.com/pdfs/'+payment._id+'.pdf', '_blank')}>צפה בחשבונית</Button></TableCell>
                          )}
                          <TableCell align="right">{this.modifyPaymentType(payment)}</TableCell>
                          <TableCell align="right">{payment.amountWithVAT}</TableCell>
                          <TableCell align="right">{(payment.description === 'insideTestFee' || payment.description === 'signupFee') ? (payment.description === 'insideTestFee' ? 'אגרת מבחן פנימי' : 'אגרת רישום') : payment.description}</TableCell>
                          <TableCell align="right">{new Date(payment.dateOfPayment).getDate() + '/' + (new Date(payment.dateOfPayment).getMonth() + 1) + '/' + new Date(payment.dateOfPayment).getFullYear()}</TableCell>
                          <TableCell align="right">{payment.studentName}</TableCell>
                        </TableRow>
                      )
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>


        </center>
        </Grid>

        <Snackbar open={this.state.notFound} autoHideDuration={3000} onClose={() => this.setState({notFound: false})}>
          <Alert onClose={() => this.setState({notFound: false})} severity="warning">
          לא נמצאו רשומות חיפוש, אנא נסו בשנית
          </Alert>
        </Snackbar>

        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ClerkDashboard;
