import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SelectSearch from 'react-select-search';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class AddMessage extends Component {
  componentDidMount() {
    this.getData();
    this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     lastSeen: '',
     language: 'he',

     messages: [],
     personalMessage: false,
     specificSchoolMessage: false,

     teacherId: '',
     schoolId: '',
     s_: [],
   }
  }

  messageType = () => {
    if (this.state.personalMessage || this.state.specificSchoolMessage) {
      if (this.state.schoolId !== '' || this.state.teacherId !== '') {
        return 'specific';
      }
    }

    return 'general';
  }

  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
        }, () => {
          if (this.state.schools.length <= 1) {
            this.setState({schoolId: this.state.main._id});
          }

          let s_ = [];
          this.state.employees.forEach((item, i) => {
              s_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
          });

          this.setState({s_: s_})
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getMessages = async () => {
    axios.get(API + 'manager/messages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messages: response.data.data.messages
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  createMessage = async () => {
    let payload = {
      schoolId: this.state.schoolId,
      teacherId: this.state.teacherId,
      type: this.messageType(),
      message: this.state.message,
      read: false,
      frozen: false,
      systemMessage: false,
    };

    axios.post(API + 'manager/message', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        window.location.href='/messages'
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  handlePersonalMessage = () => {
    let value = this.state.personalMessage;
    if (value) {
      this.setState({personalMessage: false});
    } else {
      this.setState({personalMessage: true});
    }
  }

  handleSpecificSchoolMessage = () => {
    let value = this.state.specificSchoolMessage;
    if (value) {
      this.setState({specificSchoolMessage: false});
    } else {
      this.setState({specificSchoolMessage: true});
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>שידור הודעה חדשה</h2>
          </center>
        </Grid>
        <Grid item xs={12}>
          <div style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>
            <Checkbox
              value={this.state.personalMessage}
              onChange={(e) => this.handlePersonalMessage()}
              checked={this.state.personalMessage}
            /> שליחת הודעה פרטית למורה / מנהל / פקיד / רו"ח

            {this.state.personalMessage && (
              <React.Fragment>
              <br />
              <p>בחרו את הנמען מהרשימה</p>
              {/*<Select
                value={this.state.teacherId}
                onChange={(e) => this.setState({teacherId: e.target.value})}
                style={{
                  textAlign: 'right',
                  direction: 'rtl',
                  width: '90%'
                }}
              >
                {this.state.employees.map((index) => {
                  return (
                    <MenuItem value={index._id} style={{textAlign: 'right', direction: 'rtl'}}>{index.firstName + ' ' + index.lastName + ' ('+this.modifyType(index.type)+')'}</MenuItem>
                  )
                })}
              </Select>*/}
              <SelectSearch
                onChange={(value) => this.setState({teacherId: value})}
                options={this.state.s_}
                search={true}
                value={this.state.teacherId}
                name="studentId"
                placeholder="שם הנמען.."
              />

              </React.Fragment>
            )}

            <br />
            <React.Fragment>
            {this.state.schools !== undefined && (
              <React.Fragment>
              {this.state.schools.length >= 2 && (
                <React.Fragment>
                <Checkbox
                  value={this.state.specificSchoolMessage}
                  onChange={(e) => this.handleSpecificSchoolMessage()}
                  checked={this.state.specificSchoolMessage}
                /> שליחת הודעה לבית ספר ספציפי
                </React.Fragment>
              )}
              </React.Fragment>
            )}

            {this.state.specificSchoolMessage && (
              <Select
                value={this.state.schoolId}
                onChange={(e) => this.setState({schoolId: e.target.value})}
                style={{
                  textAlign: 'right',
                  direction: 'rtl',
                  width: '90%'
                }}
              >
                {this.state.schools.map((index) => {
                  return (
                    <MenuItem value={index._id} style={{textAlign: 'right', direction: 'rtl'}}>{index.name}</MenuItem>
                  )
                })}
              </Select>
            )}
            </React.Fragment>
            <br />
            <p>ההודעה</p>
            <TextField
              value={this.state.message}
              onChange={(e) => this.setState({message: e.target.value})}
              style={{
                textAlign: 'right',
                direction: 'rtl',
                width: '90%'
              }}
            />
            <br /><br />
            <Button onClick={(e) => this.createMessage()} variant={'contained'} color={'primary'}>
              שידור ההודעה
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default AddMessage;
