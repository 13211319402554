import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import DescriptionIcon from '@material-ui/icons/Description';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Loading from '../Loading';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import {
  Link
} from "react-router-dom";

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class AddCheque extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     teachers: [],
     cheques: [],
     lastSeen: '',
     language: 'he',
     teacherId: '',
     chequeNum: '',
     chequeAmount: 0,
     teacherInvoiceNumber: '',
     teacherInvoiceAmount: 0,
     isBankTransfer: false,
     bankAccountNumber: '',
     bankNumber: '',
     bankBranchNumber: '',
     loading: false
   }
  }

  getData = async () => {
    this.setState({loading: true}, () => {
      axios.get(API + 'clerk/cheque-list', {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            teachers: response.data.data.teachers,
            cheques: response.data.data.cheques,
            loading: false
          });
        } else {
          this.setState({loading: false});
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({loading: false});
        alert(strings.errorPleaseTryAgain);
      });
    });

  }

  getAmount = (amount) => {
    let money = parseFloat(amount).toFixed(2);
    if (money === null || money === undefined || isNaN(money)) return parseFloat(0.00);

    return money;
  }

  createCheque = () => {
    this.setState({loading: true}, () => {
      if ((this.state.teacherId !== '' && this.state.chequeNum !== '' && this.state.chequeAmount !== '') || (this.state.teacherId !== '' && this.state.bankNumber !== '' && this.state.bankAccountNumber !== '')) {
        let payload = {
          'id': this.state.teacherId,
          'chequeNum': this.state.chequeNum,
          'chequeAmount': this.state.chequeAmount,
          'isBankTransfer': this.state.isBankTransfer,
          'bankNumber': this.state.bankNumber,
          'bankAccountNumber': this.state.bankAccountNumber,
          'bankBranchNumber': this.state.bankBranchNumber,
          'teacherInvoiceNumber': this.state.teacherInvoiceNumber,
          'teacherInvoiceAmount': this.state.teacherInvoiceAmount,
        }

        axios.post(API + 'clerk/cheque', payload, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            //alert('הרשומה נוצרה בהצלחה');
            this.setState({loading: false});
            window.location.href='/cheques';
          } else {
            this.setState({loading: false});
            // alert(strings.errorPleaseTryAgain);
            alert('שגיאה ביצירת רשומת צק')
          }
        })
        .catch(error => {
          this.setState({loading: false});
          console.log(error);
          alert(strings.errorPleaseTryAgain);
        });
      } else {
        this.setState({loading: false});
        alert(strings.pleasefillall);
      }
    })
  }

  changeIsBankTransfer = () => {
    let isBankTransfer = this.state.isBankTransfer;

    if (isBankTransfer) {
      this.setState({isBankTransfer: false});
    } else {
      this.setState({isBankTransfer: true});
    }
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            {this.state.loading ? (
              <Loading />
            ) : (
              <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.writeacheque}</h2>


                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosenimanfromthelist}</p>
                <Select
                  value={this.state.teacherId}
                  onChange={(e) => this.setState({teacherId: e.target.value})}
                  style={{
                    direction: 'rtl',
                    width: '100%',
                    textAlign: 'right',
                  }}
                >
                  {this.state.teachers.map((teacher) => {
                    return (
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={teacher._id}>{teacher.firstName + ' ' + teacher.lastName + ' ('+teacher.idNumber+')'}</MenuItem>
                    )
                  })}
                </Select>

                <div style={{
                  direction: 'rtl',
                  width: '100%',
                  textAlign: 'right',
                }}>
                <Checkbox
                  value={this.state.isBankTransfer}
                  onChange={(e) => this.changeIsBankTransfer()}
                /> {strings.isbanktransferc}
                </div>

                {this.state.isBankTransfer ? (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                    <Select
                      value={this.state.bankNumber}
                      onChange={(e) => this.setState({bankNumber: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                    </Select>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                    <Input
                      value={this.state.bankAccountNumber}
                      onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                    <Input
                      value={this.state.bankBranchNumber}
                      onChange={(e) => this.setState({bankBranchNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chequenum}</p>
                  <Input
                    value={this.state.chequeNum}
                    onChange={(e) => this.setState({chequeNum: e.target.value})}
                    type='number'
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  />


                  </React.Fragment>
                )}

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {this.state.isBankTransfer ? strings.banktransferamount : strings.chequeamount}</p>
                <Input
                  value={this.state.chequeAmount}
                  onChange={(e) => this.setState({chequeAmount: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />

                <center><p style={{fontSize: '1.1em', fontWeight: 'bold', marginTop: '2%'}}>- {strings.notmendatorycheque} -</p></center>

                <p style={{textAlign: 'right'}}>{strings.teacherinvoicenumber}</p>
                <Input
                  value={this.state.teacherInvoiceNumber}
                  onChange={(e) => this.setState({teacherInvoiceNumber: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />

                <p style={{textAlign: 'right'}}>{strings.teacherinvoiceamount}</p>
                <Input
                  value={this.state.teacherInvoiceAmount}
                  onChange={(e) => this.setState({teacherInvoiceAmount: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />

                <br /><br />
                {(this.state.teacherInvoiceNumber !== '') ? (
                  <div>
                    {(this.state.teacherInvoiceAmount !== this.state.chequeAmount) ? (
                      <div>
                        <p>{strings.chequeamountnotequal}</p>
                        <Button variant={'contained'} color={'grey'}>{strings.create}</Button>
                      </div>
                    ) : (
                      <Button onClick={(e) => this.createCheque()} variant={'contained'} color={'primary'}>{strings.create}</Button>
                    )}
                  </div>
                ) : (
                  <Button onClick={(e) => this.createCheque()} variant={'contained'} color={'primary'}>{strings.create}</Button>
                )}
              </div>
              </center>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddCheque;
