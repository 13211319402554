import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class TeacherPtorsGiven extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     accounts: [],
     accountId: '',
     deposits: [],
     cashRegister: 0,
     depositsOriginal: [],
     depositsDay: [],
     depositsMonth: [],
     view: '',
     dateToSearch: '',
     output: [],
     outputOriginal: []
   }
  }

  getData = async () => {
    axios.get(API + 'teacher/ptors-given', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          students: response.data.data.students,
          payments: response.data.data.payments,
          output: response.data.data.output,
          outputOriginal: response.data.data.output,
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת רשימת החשבונות')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

    searchByDate = () => {
      let date = this.state.dateToSearch;
      date = new Date(date);
      date = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
      console.log(date);
      let output = [];
      this.state.depositsOriginal.map((deposit) => {
        let deposit_date_d = new Date(deposit.createdAt);

        deposit_date_d = deposit_date_d.getDate() + '/' + deposit_date_d.getMonth() + '/' + deposit_date_d.getFullYear();

        if (deposit_date_d === date) output.push(deposit);
      });
      console.log(output);
      this.setState({deposits: output});
    }

    isToday = (date) => {
      let today = new Date(Date.now());

      today = today.getDate() + '-' + today.getMonth() + '-' + today.getFullYear();
      date = new Date(date);
      date = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();

      if (today === date) {
        return true;
      } else {
        return false;
      }

      return false;
    }

    isMonth = (date) => {
      let today = new Date(Date.now());

      today = today.getMonth();
      date = new Date(date);
      date = date.getMonth();

      if (today === date) {
        return true;
      } else {
        return false;
      }

      return false;
    }

    isDateOk = (date) => {
      if (this.state.dateToSearch === '') return true;

      let today = new Date(this.state.dateToSearch);

      today = today.getDate() + '-' + today.getMonth() + '-' + today.getFullYear();
      date = new Date(date);
      date = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();

      if (today === date) {
        return true;
      } else {
        return false;
      }

      return false;
    }

    changeView = (view) => {
      this.setState({view: view});
    }

    searchStudentLive = (value) => {
      this.setState({search_query: value}, () => {
        const query = this.state.search_query;
        if (query === '') this.setState({output: this.state.outputOriginal});
        else {
          let o_ = [];
          this.state.output.forEach((item, i) => {
            if (
              item.student.firstName.includes(query) ||
              item.student.lastName.includes(query) ||
              item.student.idNumber.includes(query) ||
              item.student.phoneNumber.includes(query) ||
              query.includes(item.student.firstName) ||
              query.includes(item.student.lastName) ||
              query.includes(item.student.idNumber) ||
              query.includes(item.student.phoneNumber)
            ) o_.push(item);
          });
          this.setState({output: o_});
        }
      });

    }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>טבלת מעקב פטורים</h2>


                <center>
                  <h4>{strings.searchstudentbynm}</h4>

                  <Button variant={'contained'} onClick={(e) => this.setState({search_query: ''}, () => {this.getData()})}>איפוס</Button>
                  &nbsp;&nbsp;&nbsp;
                  <TextField
                    value={this.state.search_query}
                    onChange={(e) => this.searchStudentLive(e.target.value)}
                    style={{
                      textAlign: 'right',
                      direction: 'rtl'
                    }}
                  />
                  <br />
                  <br />
                </center>

                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                  <Button onClick={(e) => this.changeView('other')} style={{color: this.state.view === 'other' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'other' ? '#3f51b5' : 'transparent'}}>אחר</Button>
                  <Button onClick={(e) => this.changeView('month')} style={{color: this.state.view === 'month' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'month' ? '#3f51b5' : 'transparent'}}>החודש</Button>
                  <Button onClick={(e) => this.changeView('day')} style={{color: this.state.view === 'day' ? 'white' : '#3f51b5', backgroundColor: this.state.view === 'day' ? '#3f51b5' : 'transparent'}}>היום</Button>
                  <Button onClick={(e) => this.changeView('')} style={{color: this.state.view === '' ? 'white' : '#3f51b5', backgroundColor: this.state.view === '' ? '#3f51b5' : 'transparent'}}>הכל</Button>
                </ButtonGroup>

                {this.state.view === 'other' && (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> תאריך לחיפוש</p>
                  <TextField
                    value={this.state.dateToSearch}
                    onChange={(e) => this.setState({dateToSearch: e.target.value})}
                    type='date'
                    format='dd/mm/yyyy'
                    style={{
                      direction: 'rtl',
                      width: '50%'
                    }}
                  />
                  <br /><br />
                  </React.Fragment>
                )}

                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>תלמיד</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>פטור ניתן לרשומת</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סיבת הפטור</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>סוג פטור</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>מזין הפטור</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>מאשר הפטור</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.output.reverse().map((record, i) => {
                        let date = new Date(record.record.createdAt);

                        if (this.state.view === '') {
                          return (
                            <TableRow key={i+'_employee'}>
                              <TableCell align="right">
                                {date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}
                              </TableCell>
                              <TableCell align="right" style={{direction: 'rtl'}}>{(record.student.firstName + ' ' + record.student.lastName + ' ('+ record.student.idNumber +')' )}</TableCell>
                              <TableCell align="right" style={{direction: 'rtl'}}>{(record.type === 'student' ? 'תלמיד' : 'רשומת תשלום')}</TableCell>
                              <TableCell align="right" style={{direction: 'rtl'}}>{record.record.ptorReason}</TableCell>
                              <TableCell align="right" style={{direction: 'rtl'}}>{record.record.description === 'insideTestFee' ? 'מבחן פנימי' : (window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text'))}</TableCell>
                              <TableCell align="right">{(record.issuer === undefined) ? '-' : record.issuer.firstName + ' ' + record.issuer.lastName + ' ('+record.issuer.idNumber+')'}</TableCell>
                              <TableCell align="right">{(record.gaveBy === undefined) ? '-' : record.gaveBy.firstName + ' ' + record.gaveBy.lastName + ' ('+record.gaveBy.idNumber+')'}</TableCell>
                            </TableRow>
                          )
                        } else {
                          if (this.state.view === 'day' && this.isToday(date)) {
                            return (
                              <TableRow key={i+'_employee'}>
                                <TableCell align="right">
                                  {date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}
                                </TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{(record.student.firstName + ' ' + record.student.lastName + ' ('+ record.student.idNumber +')' )}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{(record.type === 'student' ? 'תלמיד' : 'רשומת תשלום')}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{record.record.ptorReason}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{record.record.description === 'insideTestFee' ? 'מבחן פנימי' : (window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text'))}</TableCell>
                                <TableCell align="right">{(record.issuer === undefined) ? '-' : record.issuer.firstName + ' ' + record.issuer.lastName + ' ('+record.issuer.idNumber+')'}</TableCell>
                                <TableCell align="right">{(record.gaveBy === undefined) ? '-' : record.gaveBy.firstName + ' ' + record.gaveBy.lastName + ' ('+record.gaveBy.idNumber+')'}</TableCell>
                              </TableRow>
                            );
                          }
                          if (this.state.view === 'month' && this.isMonth(date)) {
                            return (
                              <TableRow key={i+'_employee'}>
                                <TableCell align="right">
                                  {date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}
                                </TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{(record.student.firstName + ' ' + record.student.lastName + ' ('+ record.student.idNumber +')' )}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{(record.type === 'student' ? 'תלמיד' : 'רשומת תשלום')}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{record.record.ptorReason}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{record.record.description === 'insideTestFee' ? 'מבחן פנימי' : (window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text'))}</TableCell>
                                <TableCell align="right">{(record.issuer === undefined) ? '-' : record.issuer.firstName + ' ' + record.issuer.lastName + ' ('+record.issuer.idNumber+')'}</TableCell>
                                <TableCell align="right">{(record.gaveBy === undefined) ? '-' : record.gaveBy.firstName + ' ' + record.gaveBy.lastName + ' ('+record.gaveBy.idNumber+')'}</TableCell>
                              </TableRow>
                            );
                          }
                          if (this.state.view === 'other' && this.isDateOk(date)) {
                            return (
                              <TableRow key={i+'_employee'}>
                                <TableCell align="right">
                                  {date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}
                                </TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{(record.student.firstName + ' ' + record.student.lastName + ' ('+ record.student.idNumber +')' )}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{(record.type === 'student' ? 'תלמיד' : 'רשומת תשלום')}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{record.record.ptorReason}</TableCell>
                                <TableCell align="right" style={{direction: 'rtl'}}>{record.record.description === 'insideTestFee' ? 'מבחן פנימי' : (window.localStorage.getItem('signupfee_text') === undefined || window.localStorage.getItem('signupfee_text') === null ? 'תשלום דמי רישום' : window.localStorage.getItem('signupfee_text'))}</TableCell>
                                <TableCell align="right">{(record.issuer === undefined) ? '-' : record.issuer.firstName + ' ' + record.issuer.lastName + ' ('+record.issuer.idNumber+')'}</TableCell>
                                <TableCell align="right">{(record.gaveBy === undefined) ? '-' : record.gaveBy.firstName + ' ' + record.gaveBy.lastName + ' ('+record.gaveBy.idNumber+')'}</TableCell>
                              </TableRow>
                            );
                          }
                        }
                      })}

                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default TeacherPtorsGiven;
