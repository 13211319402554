import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ClerkRefunds extends Component {
  componentDidMount() {
    this.getData();
    this.getRefunds();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     lastSeen: '',

     language: 'he',

     type: '',
     schoolId: '',

     refundables: [],
     refunds: [],
     refundables_original: [],
     refunds_original: [],
     string: ''
   }
  }


  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'clerk', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getRefunds = async () => {
    axios.get(API + 'clerk/refundables', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      let refunds = [];
      refunds = response.data.data.refunds;
      refunds.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      if (response.data.status === "ok") {
        this.setState({
          refundables: response.data.data.refundables,
          refunds: refunds,
          refundables_original: response.data.data.refundables,
          refunds_original: response.data.data.refunds,
        }, () => {
          let refundables = [];

          this.state.refundables.forEach((item, i) => {
            if (!item.isPtor) {
              refundables.push(item);
            }
          });

          this.setState({refundables: refundables});
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  search = (string) => {
    console.log(string);
    let records = this.state.refundables_original;
    let output = [];
    records.forEach((item, i) => {
      console.log(item);
      let fullname = item.student.firstName + ' ' + item.student.lastName;
      if (fullname.includes(string) || item.student.idNumber.includes(string)) {
        console.log('found');
        output.push(item);
      }
    });

    this.setState({refundables: output});
  }


      // searchLive = (string) => {
      //   this.setState({string: string}, () => {
      //     let records = this.state.refundables_original;
      //     let output = [];
      //     records.forEach((item, i) => {
      //       console.log(item);
      //       let fullname = item.student.firstName + ' ' + item.student.lastName;
      //       if (fullname.includes(string) || item.student.idNumber.includes(string)) {
      //         console.log('found');
      //         output.push(item);
      //       }
      //     });

      //     if (output.length > 0) {
      //       this.setState({refundables: output});
      //     }
      //   });
      // }
      searchLive = (string) => {
        this.setState({string: string});
        let records = this.state.refundables_original;
      
        // Use filter for more concise and efficient looping
        let output = records.filter(item => {
          let fullname = `${item.student.firstName} ${item.student.lastName}`;
          return fullname.includes(string) || item.student.idNumber.includes(string);
        });
      
        // Update the state with the filtered results
        this.setState({ refundables: output });
      };

  restart = () => {
    let original = this.state.refundables_original;
    this.setState({refundables: original, string: ''});
  }

    modifyPaymentType = (payment) => {
      if (payment.isPtor) {
        return 'פטור; ' + payment.ptorReason;
      } else {
        let paymentType = payment.type;

        if (paymentType === "bankTransfer") return strings.bankTransfer;
        else if (paymentType === "cash") return strings.cash;
        else if (paymentType === "creditcard") return strings.creditcard;
        else if (paymentType === "cheque") return strings.cheque;
        else if (paymentType === "application") return strings.application;
        else if (paymentType === "tranzila") return strings.tranzila;
        else if (paymentType === "barter") return strings.barter;
        else if (paymentType === "ptor") return strings.ptor;
        else return strings.bankTransfer;

        return strings.bankTransfer;
      }
    }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.refunds}</h2>
          </center>
          <br />
          <center>
            <h4>{strings.refundables}</h4>
          </center>
          <br /><br />
          
          <Button color={'primary'} variant={'contained'} onClick={(e) => this.restart()}>חיפוש</Button>
          
          &nbsp;&nbsp;
          {/*<Button color={'primary'} variant={'contained'} onClick={(e) => this.search(this.state.string)}>חיפוש</Button>
          &nbsp;&nbsp;&nbsp;&nbsp;*/}
          <TextField
            value={this.state.string}
            onChange={(e) => this.searchLive(e.target.value)}
          />
          <br />
        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.refundables.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.emptyrefundablesstring}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'זיכוי'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'סכום כולל מע"מ'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'תיאור'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.studentname}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.refundables.reverse().map((refundable, i) => {
                  return (
                    <TableRow key={i+'_employee'}>
                      <TableCell align="right"><Link to={'/refund?id='+refundable._id} style={{textDecoration: 'none'}}><Button>זיכוי</Button></Link></TableCell>
                      <TableCell align="right">{new Date(refundable.createdAt).getDate() + '/' + (new Date(refundable.createdAt).getMonth() + 1) +'/' + new Date(refundable.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{this.modifyPaymentType(refundable)}</TableCell>
                      <TableCell align="right">₪{parseFloat(refundable.amountWithVAT).toFixed(2)}</TableCell>
                      <TableCell align="right">{(refundable.description === 'signupFee' || refundable.description === 'insideTestFee') ? 'דמי מבחן פנימי' : refundable.description}</TableCell>
                      <TableCell align="right">{refundable.student.firstName + ' ' + refundable.student.lastName + ' ('+refundable.student.idNumber+')'}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>

        <Grid item xs={12}>
          <br />
          <center>
            <h4>{strings.refunds}</h4>
          </center>
          <br /><br />

        </Grid>
        <Grid item xs={12}>
        <center>
        {this.state.refunds.length === 0 ? (
          <h4 style={{direction: 'rtl', color: 'grey'}}>
          {strings.emptyrefundablesstring}
          </h4>
        ) : (
          <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'קבלה'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.type}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{'סכום כולל מע"מ'}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.refunds.reverse().map((refundable, i) => {
                  return (
                    <TableRow key={i+'_employee'}>
                      <TableCell align="right"><a href={'https://sivan-school.com/pdfs/'+refundable.invoice} target="_blank"><Button>צפייה</Button></a></TableCell>
                      <TableCell align="right">{new Date(refundable.createdAt).getDate() + '/' + (new Date(refundable.createdAt).getMonth() + 1) +'/' + new Date(refundable.createdAt).getFullYear()}</TableCell>
                      <TableCell align="right">{this.modifyPaymentType(refundable)}</TableCell>
                      <TableCell align="right">₪{parseFloat(refundable.amount).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        </center>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ClerkRefunds;
