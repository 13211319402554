import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class AddSupplier extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     suppliers: [],

     lastSeen: '',

     language: 'he',

     type: '',

     name: '',
     phonenumber: '',
     category: '',
     schoolId: '',
     fee: 100,
     canElseSee: false
   }
  }


  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  createSuppleir = () => {
    if (this.state.category === '' || this.state.name === '') {
      alert('חובה למלא קטגוריה ושם לספק לפני יצירתו');
    } else {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        // console.log({
        //   name: this.state.name,
        //   phoneNumber: this.state.phoneNumber,
        //   category: this.state.category,
        //   schoolId: this.state.schoolId
        // });
        axios.post(API + 'manager/supplier', {
          name: this.state.name,
          phoneNumber: this.state.phoneNumber,
          category: this.state.category,
          schoolId: this.state.schoolId,
          fee: this.state.fee,
          canElseSee: this.state.canElseSee
        }, {
          headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
        }).then(response => {
          if (response.data.status === "ok") {
            //alert('נוצר בהצלחה');
            window.location.href='/school/suppliers?id='+this.state.schoolId
          }
        }).catch(err => {
          console.log(err);
          alert('שגיאה ביצירת הספק');
        });
      })
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.mysupplierslist}</h2>
          </center>
        </Grid>
        <Grid item xs={12}>

          <p style={{textAlign: 'right', marginRight: '5%'}}><span style={{color: 'red'}}>*</span> {strings.suppliername}</p>
          <TextField
            style={{
              direction: 'rtl',
              width: '90%'
            }}
            value={this.state.name}
            onChange={(e) => this.setState({name: e.target.value})}
            required
          />

          <p style={{textAlign: 'right', marginRight: '5%'}}><span style={{color: 'red'}}>*</span> {strings.supplierphone}</p>
          <TextField
            style={{
              direction: 'rtl',
              width: '90%'
            }}
            value={this.state.phoneNumber}
            onChange={(e) => this.setState({phoneNumber: e.target.value})}
            required
          />

          <p style={{textAlign: 'right', marginRight: '5%'}}><span style={{color: 'red'}}>*</span> {strings.category}</p>
          <TextField
            style={{
              direction: 'rtl',
              width: '90%'
            }}
            value={this.state.category}
            onChange={(e) => this.setState({category: e.target.value})}
            required
          />

          <p style={{textAlign: 'right', marginRight: '5%'}}><span style={{color: 'red'}}>*</span> {strings.supplierfee}</p>
          <TextField
            style={{
              direction: 'rtl',
              width: '90%'
            }}
            value={this.state.fee}
            type='number'
            onChange={(e) => this.setState({fee: e.target.value})}
            required
          />

          <p style={{textAlign: 'right', marginRight: '5%'}}><span style={{color: 'red'}}>*</span> {strings.suppliercanelsesee}</p>
          <Select
            value={this.state.canElseSee}
            onChange={(e) => this.setState({canElseSee: e.target.value})}
            style={{
              direction: 'rtl',
              width: '90%',
              textAlign: 'right'
            }}
          >
            <MenuItem style={{
              direction: 'rtl',
              width: '90%',
              textAlign: 'right'
            }} value={true}>{strings.yes}</MenuItem>
            <MenuItem style={{
              direction: 'rtl',
              width: '90%',
              textAlign: 'right'
            }} value={false}>{strings.no}</MenuItem>
          </Select>

          <Button onClick={(e) => this.createSuppleir()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
            {strings.create}
          </Button>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default AddSupplier;
