import React, { Component } from 'react';
import '../../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class EmployeeExpenses extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     table: {},
     employee: {
       name: ''
     },
     lineToEdit: null,
     add: false,
     month: new Date(Date.now()).getMonth() + 1,
     year: new Date(Date.now()).getFullYear(),
     cost: '',
     name: '',
     isFixedCost: true,
     dailyOrMonthly: false,
     rowsNum: 0,
     rows: [],
     header: [],
     editActive: false,
     editItem: {},
     editPrice: 0,
     editName: ''
   }
  }


  getData = async () => {
    const url = window.location.href;
    const id = url.split('?id=')[1];
    axios.get(API + 'manager/report/expenses?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          table: response.data.table,
          header: response.data.header,
          employee: response.data.employee
        }, () => {
          const table = response.data.table;
          let rows = [];
          Object.keys(table).forEach((item, i) => {
            let value = table[item];
            if (value.length > this.state.rowsNum) this.setState({rowsNum: value.length});
            for (let xr = 0; xr < value.length; xr++){
              if (!rows.includes(value[xr].name)) rows.push(value[xr].name);
            }
          });
          this.setState({rows: rows})
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  addRow = (key) => {
    const url = window.location.href;
    const id = url.split('?id=')[1];
    axios.post(API + 'manager/report/expense?id='+id, {
      userId: id,
      name: this.state.name,
      month: this.state.month,
      year: this.state.year,
      cost: this.state.cost,
      isFixedCost: this.state.isFixedCost,
      dailyOrMonthly: this.state.dailyOrMonthly ? 'daily' : 'monthly'
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
        this.setState({lineToEdit: null, add: false, name: '', month: new Date(Date.now()).getMonth() + 1, year: new Date(Date.now()).getFullYear(), isFixedCost: true, dailyOrMonthly: false});
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  setEdit = (item) => {

    console.log(item);
    this.setState({editActive: true, editItem: item, editPrice: item.cost, editName: item.name})
  }
  closeEdit = (item) => {
    this.setState({editActive: false, editItem: {}, editPrice: 0, editName: ''})
  }

  editSave = () => {
    axios.put(API +'manager/edit-monthly-expenses-item', {
      id: this.state.editItem._id,
      cost: this.state.editPrice,
      name: this.state.editName
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(res => {
      this.getData();
      this.closeEdit();
    }).catch(err => {

    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>

          <Grid item xs={12}>
            <center>
              <h1>ההוצאות של {this.state.employee.name}</h1>
              <Button variant={'contained'} color={'secondary'} onClick={(e) => this.setState({add: true})}>הוספת רשומה חדשה</Button>
              <br />
              <br />
              <Button variant={'contained'} color={'primary'} onClick={(e) => window.location.href='/reports/employees'}>חזרה למסך כל המשתמשים</Button>
              <br />

              {this.state.editActive && (
                <div style={{border: '1px solid black', maxWidth: '50%', padding: '1%', marginTop: '1%'}}>
                  <h3>עריכת רשומה</h3>
                  <p style={{textDecoration: 'underline'}}>מחיר</p>
                  <TextField value={this.state.editPrice} onChange={(e) => this.setState({editPrice: e.target.value})} />
                  <br />
                  <p style={{textDecoration: 'underline'}}>שם (כותרת)</p>
                  <TextField value={this.state.editName} onChange={(e) => this.setState({editName: e.target.value})} />
                  <br />
                  <br />

                  <Button onClick={(e) => this.closeEdit()} color={'secondary'} variant={'contained'}>סגור</Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button onClick={(e) => this.editSave()} color={'primary'} variant={'contained'}>שמירה</Button>
                </div>
              )}

              {this.state.add && (
                <div style={{border: '1px solid black', maxWidth: '50%', padding: '1%', marginTop: '1%'}}>
                  <h3>הוספת רשומה חדשה</h3>
                  <p style={{textDecoration: 'underline'}}>תאריך</p>
                  <Select value={this.state.month} onChange={(e) => this.setState({month: e.target.value})}>
                    <MenuItem value={1}>01</MenuItem>
                    <MenuItem value={2}>02</MenuItem>
                    <MenuItem value={3}>03</MenuItem>
                    <MenuItem value={4}>04</MenuItem>
                    <MenuItem value={5}>05</MenuItem>
                    <MenuItem value={6}>06</MenuItem>
                    <MenuItem value={7}>07</MenuItem>
                    <MenuItem value={8}>08</MenuItem>
                    <MenuItem value={9}>09</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                  <span style={{fontWeight: 'bold'}}>&nbsp;/&nbsp;</span>
                  <Select value={this.state.year} onChange={(e) => this.setState({year: e.target.value})}>
                  {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear()) + i).map((year) => {
                    return (
                      <MenuItem style={{ textAlign: 'right', direction: 'rtl' }} value={year}>{year.toString()}</MenuItem>
                    )
                  })}
                  </Select>
                  <p style={{textDecoration: 'underline'}}>שם (תיאור)</p>
                  <TextField placeholder={'נסיעות יומי'} value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} style={{width: '30%'}}/>
                  <p style={{textDecoration: 'underline'}}>עלות בש"ח</p>
                  <TextField placeholder={'2500'} value={this.state.cost} onChange={(e) => this.setState({cost: e.target.value})} style={{width: '10%'}}/>
                  <br />
                  <Checkbox checked={this.state.isFixedCost} onChange={(e) => this.setState({isFixedCost: this.state.isFixedCost ? false : true})} /> האם הוצאה קבועה? (אם לא - חד פעמית)
                  <br />
                  <Checkbox checked={this.state.dailyOrMonthly} onChange={(e) => this.setState({dailyOrMonthly: this.state.dailyOrMonthly ? false : true})} /> האם הוצאה יומית? (אם לא - הוצאה חודשית)
                  <br />
                  <Button variant={'contained'} color={'secondary'} onClick={(e) => this.addRow()}>הוספה</Button>
                </div>
              )}
            </center>
          </Grid>

          <Grid item xs={12}>
            <center>
            {Object.keys(this.state.table).map((key, i) => {
              let value = this.state.table[key];
              let missingRows = (this.state.rowsNum + 1) - value.length  < 0 ? 0 : (this.state.rowsNum + 1) - value.length;
              return (
                <>
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {value.map((item) => {
                          return (
                            <TableCell align="right" style={{fontWeight: 'bold'}}>{item.name === "" ? 'לא צוין' : item.name}</TableCell>
                          );
                        })}
                        <TableCell align="right" style={{fontWeight: 'bold'}}>חודש / שנה</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {value.map((item, x) => {
                          console.log(item);
                          return (
                            <TableCell align="right" style={{direction: 'rtl'}}>{item.cost} ש״ח <Button onClick={(e) => this.setEdit(item)} color={'primary'} variant={'outlined'}>עריכה</Button></TableCell>
                          );
                        })}
                        <TableCell align="right">{key}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </TableContainer>
                  <br />
                </>
              );
            })}

            {/*<TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {this.state.header.reverse().map((item) => {
                      return (
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{item}</TableCell>
                      );
                    })}
                    <TableCell align="right" style={{fontWeight: 'bold'}}>חודש / שנה</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(this.state.table).map((key, i) => {
                    let value = this.state.table[key];
                    let missingRows = (this.state.rowsNum + 1) - value.length  < 0 ? 0 : (this.state.rowsNum + 1) - value.length;
                    return (
                      <>
                      <TableRow>
                      {value.map((item, x) => {
                        return (
                          <TableCell align="right">{item.cost}</TableCell>
                        );
                      })}
                      <TableCell align="right">{key}</TableCell>


                      </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              </TableContainer>*/}
            </center>
          </Grid>

        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default EmployeeExpenses;
