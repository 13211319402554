import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};


class UserPaymentsDocument extends Component {
  componentDidMount() {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],

     lastSeen: '',
     language: 'he',

     fromDate: new Date(Date.now()),
     toDate: new Date(Date.now()),

     teachersIds: [],
     link: '',
     fromDateDialog: false,
     toDateDialog: false,
   }
  }


  getData = async () => {
    axios.get(API + 'user/payments-document', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          employees: response.data.data.employees,
          lastSeen: response.data.lastSeen,
        }, () => {
          let teachersIds = [];
          this.state.employees.forEach((item, i) => {
            teachersIds.push(item._id.toString());
          });

          this.setState({teachersIds: teachersIds});
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  generate = async () => {
    console.log({
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      teachersIds: this.state.teachersIds
    });
    axios.post(API + 'user/payments-document', {
      fromDate: new Date(this.state.fromDate),
      toDate: new Date(this.state.toDate),
      teachersIds: this.state.teachersIds
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          link: response.data.link,
        }, () => {
          window.scroll(0,0);
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  addRemoveTeacher = (id) => {
    if (this.state.teachersIds.includes(id.toString())) {
      let t = this.state.teachersIds;
      t.remove(id.toString());
      this.setState({teachersIds: t})
    } else {
      let t = this.state.teachersIds;
      t.push(id.toString());
      this.setState({teachersIds: t})
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header type={'logged'} lastSeen={this.state.lastSeen}/>
        </Grid>
        <Grid item xs={12}>
          <br />
          <center>
            <h2>{strings.paymentsdocument}</h2>
          </center>

          {this.state.link !== '' && (
            <>
              <IconButton onClick={(e) => window.open(this.state.link, '_blank')}>
                <GetAppIcon style={{width: 32, height: 32 }} />
                <a href={this.state.link} target="_blank">{'הורד מסמך'}</a>
              </IconButton>
              <p>{strings.orH}</p>
              <Button onClick={(e) => window.open('https://wa.me/?text='+this.state.link, '_blank')} style={{backgroundColor: '#25D366'}}>
                <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{'שלחו את המסמך בווטצאפ'}</a>
              </Button>
              <br />
              <Button onClick={(e) => window.open('mailto:'+this.state.email+'?subject=מסמך תשלום תקופתי&body=היי, מצרף כאן מסמך תשלום תקופתי '+this.state.link, '_blank')} color={'primary'} variant={'contained'}>
                <MailOutlineIcon style={{width: 32, height: 32, color: 'white'}}/>
                &nbsp;
                <a href={'mailto:'+this.state.email+'subject=מסמך תשלום תקופתי&body=היי, מצרף כאן מסמך תשלום תקופת '+this.state.link} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלח את המסמך באימייל בלחיצת כפתור</a>
              </Button>
              <br /><br />
              <Button onClick={(e) => window.open('https://mail.google.com/mail/?view=cm&fs=1&to='+this.state.email+'&su=מסמך תשלום תקופתי&body=היי, מצרף כאן את המסמך התשלום התקופתי '+this.state.link, '_blank')} style={{backgroundColor: '#D44638'}} variant={'contained'}>
                <MailOutlineIcon style={{width: 32, height: 32, color: 'white'}}/>
                &nbsp;
                <a href={'https://mail.google.com/mail/?view=cm&fs=1&to='+this.state.email+'&su=מסמך תשלום תקופתי&body=היי, מצרף כאן את המסמך התשלום התקופתי '+this.state.link} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>שלח את המסמך בג'ימייל בלחיצת כפתור</a>
              </Button>
              <br />
            </>
          )}

            <React.Fragment>
            <Button variant={'contained'} color={'secondary'} onClick={(e) => this.generate()}>הפק</Button>

            <p style={{textAlign: 'center'}}><span style={{color: 'red'}}>*</span> תאריך לחיפוש</p>
            <p>מתאריך</p>
            {/*<TextField
              value={this.state.fromDate}
              onChange={(e) => this.setState({fromDate: e.target.value})}
              type='date'
              format='dd/mm/yyyy'
              style={{
                direction: 'rtl',
                width: '20%'
              }}
            />*/}
            <center>
            <div style={{maxWidth: '30%'}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={this.state.fromDate}
                selecte={this.state.fromDate}
                onChange={(value) => this.setState({fromDate: value})}
                open={this.state.fromDateDialog}
                onClick={(e) => this.setState({fromDateDialog: true})}
                onClose={(e) => this.setState({fromDateDialog: false})}
                disableFuture={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{
                  width: '100%'
                }}
                locale='he'
              />
              </MuiPickersUtilsProvider>
            </div>
            </center>
            <p>עד תאריך</p>
            {/*<TextField
              value={this.state.toDate}
              onChange={(e) => this.setState({toDate: e.target.value})}
              type='date'
              format='dd/mm/yyyy'
              style={{
                direction: 'rtl',
                width: '20%'
              }}
            />*/}
            <center>
            <div style={{maxWidth: '30%'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={this.state.toDate}
              selecte={this.state.toDate}
              onChange={(value) => this.setState({toDate: value})}
              open={this.state.toDateDialog}
              onClick={(e) => this.setState({toDateDialog: true})}
              onClose={(e) => this.setState({toDateDialog: false})}
              disableFuture={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{
                width: '100%'
              }}
              locale='he'
            />
            </MuiPickersUtilsProvider>
            </div>
            </center>
            <br />
            <br />


            </React.Fragment>
        </Grid>
        <Grid item xs={12}>
        <center>
        <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{'הוסף/הסר'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teachername}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.employees.map((teacher, i) => {
                let checked = this.state.teachersIds.includes(teacher._id);
                return (
                  <TableRow key={i+'_student'}>
                      <TableCell align="right">
                        <Checkbox
                          value={checked}
                          checked={checked}
                          selected={checked}
                          onClick={(e) => this.addRemoveTeacher(teacher._id)}
                        />
                      </TableCell>
                      <TableCell align="right">{teacher.firstName + ' ' + teacher.lastName}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </center>
        </Grid>
        <Grid item xs={12}>
        <center>

        <Button variant={'contained'} color={'secondary'} onClick={(e) => this.generate()}>הפק</Button>

        <br /><br />
        </center>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
        </Grid>
      </div>
    )
  }
}

export default UserPaymentsDocument;
