import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import DateFnsUtils from '@date-io/date-fns';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  isMobile
} from "react-device-detect";


import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  WhatsappIcon as WhatsAppIcon2,
} from "react-share";

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { Link } from 'react-router-dom';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class MessagesBoard extends Component {
  componentDidMount () {
    this.getMessages();
    this.me();
    this.getCategories();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     messages: [],
     messagesOriginal: [],
     lastSeen: '',
     language: 'he',
     me: '',
     categories: [],
     children_categories:{},
     child_category: '',
     category: '',
     chosenCategory: '',
     chosenCategoryChild: '',
     categoriesChildren: [],
     allCategories: [],
     search_query: '',
     addButtonFontColor: '',
     messageBackgroundColor: 'white',
     messageFontColor: 'black',
     categoriesTranslations: {},
     children_orders: []
   }
  }

  me = async () => {
    axios.get(API + 'me', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          me: response.data.me
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  // getCategories = async () => {
  //   axios.get(API + 's-categories', {
  //     headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
  //   })
  //   .then(response => {
  //     console.log(response);
  //     if (response.data.status === "ok") {
  //       this.setState({
  //         categories: response.data.data.categories,
  //         allCategories: response.data.data.categories
  //       }, () => {
  //         let parent = [];
  //         let children = {};
  //
  //         this.state.categories.map((category) => {
  //           if (category.parentCategory === '' || category.parentCategory === null || category.parentCategory === undefined) parent.push(category);
  //           else {
  //             if (children[category.parentCategory] === undefined) children[category.parentCategory] = [];
  //             children[category.parentCategory].push(category);
  //           }
  //         });
  //         this.setState({categories: parent, children_categories: children});
  //
  //       });
  //     } else {
  //       // alert(strings.errorPleaseTryAgain);
  //     }
  //   })
  //   .catch(error => {
  //     console.log(error);
  //     alert(strings.errorPleaseTryAgain);
  //   });
  // }
  getCategories = async () => {
    axios.get(API + 's-categories', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          allCategories: response.data.data.categories,
          children_orders: response.data.data.children
        }, () => {
          let parent = [];
          let children = {};
          let categories = response.data.data.categories;
          let order = response.data.data.order;
          let categoriesTranslations = {};

          categories.map((category) => {
            categoriesTranslations[category.name] = {
              'he': category.name,
              'en': category.nameEnglish,
              'ar': category.nameArabic,
              'ru': category.nameRussian
            }

            if (category.parentCategory === '' || category.parentCategory === null || category.parentCategory === undefined) parent.push(category);
            else {
              if (children[category.parentCategory] === undefined) children[category.parentCategory] = [];
              children[category.parentCategory].push(category);
            }
          });
          this.setState({children_categories: children, categoriesTranslations: categoriesTranslations}, () => {
            this.parser(parent, order);
          });

        });
      } else {
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  parser = (categories, str) => {
  str = str.split(',');
  let output = [];
  str.forEach((item, i) => {
    // console.log(item);
    categories.forEach((category) => {
      // console.log(category);
      if (item === category.name.trim()) {
        output.push(category);
      }
    });
  });

  this.setState({categories: output});
}

  getMessages = async () => {
    axios.get(API + 'sivanboardmessages', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      // console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messages: response.data.data.sivanboardmessages,
          messagesOriginal: response.data.data.sivanboardmessages,
          addButtonColor: response.data.data.addButtonColor,
          addButtonFontColor: response.data.data.addButtonFontColor,
          messageBackgroundColor: response.data.data.messageBackgroundColor,
          messageFontColor: response.data.data.messageFontColor,
          soldColor: response.data.data.soldColor,
          soldBackgroundColor: response.data.data.soldBackgroundColor,
        }, () => {
          let a_ = [];
          let b_ = [];

          this.state.messages.forEach((item, i) => {
            if (item.sold) b_.push(item);
            else a_.push(item);
          });
          let c = b_.concat(a_);

          c.sort(function(a, b) {
              return a.order - b.order;
          });

          this.setState({messages: c});
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  setSold = async (id) => {
    axios.put(API + 'sivanboardmessage/sold?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      // console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
      } else {
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  splitSold = (array) => {
    let a = [];
    let b = [];

    array.forEach((item, i) => {
      if (item.sold) b.push(item);
      else a.push(item);
    });

    a = a.reverse();

    let c = a.concat(b);

    return c;
  }

  chooseCategory = (category, index) => {
    this.setState({chosenCategory: category}, () => {
      let messages = this.state.messagesOriginal;
      let output = [];

      messages.forEach((item, i) => {
        console.log(item);
        if (item.category === category) {
          output.push(item);
        }
      });

      let messages_ = this.splitSold(output);

      let a = [];
      let b = [];

      messages_.forEach((item, i) => {
        if (item.sold) b.push(item);
        else a.push(item);
      });

      let c = a.concat(b);

      this.setState({
        messages: c,
        categoriesChildren: this.state.children_categories[category],
        showCostPrice: index.showCostPrice,
        showPriceWanted: index.showPriceWanted,
        showDiscount: index.showDiscount,
        isBuy: index.isBuy
      }, () => {
        this.state.children_orders.map((index) => {
          if (index.key === category) {
            console.log(this.state.children_categories[category], index.value);
            this.sub_parser(this.state.children_categories[category], index.value);
          }
        });
      });
    })
  }


  chooseChildCategory = (category, index) => {
      let messages = this.state.messagesOriginal;
      let output = [];

      messages.forEach((item, i) => {
        if (item.category === this.state.chosenCategory && item.childCategory === category) {
          output.push(item);
        }
      });

      this.setState({
        messages: this.splitSold(output),
        showCostPrice: index.showCostPrice,
        showPriceWanted: index.showPriceWanted,
        showDiscount: index.showDiscount,
        isBuy: index.isBuy
      });
  }

  search = () => {
    let messages = this.state.messagesOriginal;

    let output = [];

    messages.forEach((item, i) => {
      if (item.freetext.includes(this.state.search_query)) output.push(item);
    });

    this.setState({messages: output});
  }

  getCategoryColors = (category, isParent) => {
    let allCategories = this.state.allCategories;
    let output = {};

    allCategories.forEach((item, i) => {
      if (item.parentCategory === '' || item.parentCategory === null || item.parentCategory === undefined) {
        output[item.name] = item;
      }
    });

    if (output[category] === undefined) return { color: '', backgroundColor: '' }
    return output[category];
  }

  getCategoryColorsChild = (category, isParent) => {
    let allCategories = this.state.allCategories;
    let output = {};

    allCategories.forEach((item, i) => {
      if (item.parentCategory !== '' && item.parentCategory !== null && item.parentCategory !== undefined) {
        output[item.name] = item;
      }
    });

    console.log(output[category]);
    if (output[category] === undefined) return { color: '', backgroundColor: '' }
    return output[category];
  }

  discountOrMore = (message) => {
    let d = parseFloat((((message.price) - (message.priceWanted)) / (message.price)) * 100).toFixed(2);
    console.log(d);
    if (d > 0) {
      return <span>הנחה באחוזים: {d}%</span>;
    } else {
      return <span>מעל המחיר ב{d * -1}%</span>;
    }
  }

  nameTranslation = (item) => {
    let lang = this.state.language;

    if (lang === 'he' || lang === 'hebrew') return item.name;
    else if (lang === 'en' || lang === 'english') {
      if (item.nameEnglish === undefined || item.nameEnglish === null || item.nameEnglish === '') {
        return item.name;
      } else return item.nameEnglish;
    }
    else if (lang === 'ar' || lang === 'arabic') {
      if (item.nameArabic === undefined || item.nameArabic === null || item.nameArabic === '') {
        return item.name;
      } else return item.nameArabic;
    }
    else if (lang === 'ru' || lang === 'russian') {
      if (item.nameRussian === undefined || item.nameRussian === null || item.nameRussian === '') {
        return item.name;
      } else return item.nameRussian;
    } else {
      return item.name;
    }
  }


      nameTranslationCategory = (category, type = 'parent') => {
        let lang = this.state.language;

        if (this.state.categoriesTranslations[category] === undefined) {
          return category;
        } else {
          if (lang === 'he' || lang === 'hebrew') return this.state.categoriesTranslations[category].he;
          else if (lang === 'en' || lang === 'english') {
            if (this.state.categoriesTranslations[category].en === undefined || this.state.categoriesTranslations[category].en === null || this.state.categoriesTranslations[category].en === '') {
              return this.state.categoriesTranslations[category].he;
            } else return this.state.categoriesTranslations[category].en;
          }
          else if (lang === 'ar' || lang === 'arabic') {
            if (this.state.categoriesTranslations[category].ar === undefined || this.state.categoriesTranslations[category].ar === null || this.state.categoriesTranslations[category].ar === '') {
              return this.state.categoriesTranslations[category].he;
            } else return this.state.categoriesTranslations[category].ar;
          } else if (lang === 'ru' || lang === 'russian') {
            if (this.state.categoriesTranslations[category].ru === undefined || this.state.categoriesTranslations[category].ru === null || this.state.categoriesTranslations[category].ru === '') {
              return this.state.categoriesTranslations[category].he;
            } else return this.state.categoriesTranslations[category].ru;
          }
          else {
            return this.state.categoriesTranslations[category].he;
          }
        }

      }

      soldTranslation = () => {
        let lang = this.state.language;

        if (this.state.isBuy) {
          if (lang === 'he' || lang === 'hebrew') return 'נקנה';
          else if (lang === 'en' || lang === 'english') {
            return 'Bought';
          }
          else if (lang === 'ar' || lang === 'arabic') {
            return 'اشترى';
          }
          else if (lang === 'ru' || lang === 'russian') {
            return 'Купил';
          } else {
            return 'נקנה';
          }
        } else {
          if (lang === 'he' || lang === 'hebrew') return 'נמכר';
          else if (lang === 'en' || lang === 'english') {
            return 'SOLD';
          }
          else if (lang === 'ar' || lang === 'arabic') {
            return 'تم البيع';
          }
          else if (lang === 'ru' || lang === 'russian') {
            return 'Продан';
          } else {
            return 'נמכר';
          }
        }
      }

      sub_parser = (parent, str) => {
        let categories = parent;
        str = str.split(',');
        let output = [];
        str.forEach((item, i) => {
          categories.forEach((category) => {
            // console.log(category);
            if (item === category.name.trim()) {
              output.push(category);
            }
          });
        });

        this.setState({categoriesChildren: output});
      }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.mymessagessivan}</h2>
                <div style={{textAlign: 'right'}}>
                  <Link to='/contact-us' style={{textDecoration: 'none', color: 'black'}}><Button variant={'outlined'}>
                    <MailOutlineIcon width={32} height={32}/> יצירת קשר
                  </Button></Link>
                </div>

                <Button onClick={(e) => this.search()} color={'primary'} variant={'contained'}>חפש</Button>
                &nbsp;&nbsp;&nbsp;
                <Input
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  value={this.state.search_query}
                  onChange={(e) => this.setState({search_query: e.target.value})}
                  style={{
                    direction: 'rtl'
                  }}
                />

                <br /><br />
                {(this.state.addButtonColor === null || this.state.addButtonColor === '' || this.state.addButtonColor === undefined)? (
                  <Link to='/board/add' style={{textDecoration: 'none'}}><Button variant="outlined">
                   <AddShoppingCartIcon style={{color: this.state.addButtonFontColor}} /> <span style={{color: this.state.addButtonFontColor}}>הוספת מודעה</span>
                  </Button></Link>
                ) : (
                  <Link to='/board/add' style={{textDecoration: 'none'}}><Button variant="outlined" style={{backgroundColor: this.state.addButtonColor, color: 'white', border: '1px slid ' + this.state.addButtonColor}}>
                   <AddShoppingCartIcon style={{color: this.state.addButtonFontColor}} /> <span style={{color: this.state.addButtonFontColor}}>הוספת מודעה</span>
                  </Button></Link>
                )}
              </div>
            </center>
          </Grid>

          <Grid item xs={12}>
          <center><div style={{textAlign: 'center'}}>
            {this.state.categories.map((index) => {
              return (
                <React.Fragment>
                  {(index.color === '' || index.color === null || index.color === undefined) ? (
                    <Button onClick={(e) => this.chooseCategory(index.name, index)} variant={'contained'} color={index.name === this.state.chooseCategory ? 'primary' : 'secondary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', color: index.fontColor}}>{this.nameTranslation(index)}</Button>
                  ) : (
                    <Button onClick={(e) => this.chooseCategory(index.name, index)} variant={'contained'}  style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', backgroundColor: index.color, color: 'white', color: index.fontColor}}>{this.nameTranslation(index)}</Button>
                  )}
                </React.Fragment>
              )
            })}
          </div></center>

          {(this.state.categoriesChildren !== '' && this.state.categoriesChildren !== null && this.state.categoriesChildren !== undefined) && (
            <React.Fragment>
            <br /><br />
            <center><div style={{textAlign: 'center'}}>
              {this.state.categoriesChildren.map((index) => {
                return (
                  <React.Fragment>
                    {(index.color === '' || index.color === null || index.color === undefined) ? (
                      <Button onClick={(e) => this.chooseChildCategory(index.name, index)} variant={'contained'} color={index.name === this.state.chooseCategory ? 'primary' : 'secondary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', color: index.fontColor === undefined ? 'white' : index.fontColor}}>{this.nameTranslation(index)}</Button>
                    ) : (
                      <Button onClick={(e) => this.chooseChildCategory(index.name, index)} variant={'contained'}  style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', backgroundColor: index.color, color: index.fontColor === undefined ? 'white' : index.fontColor}}>{this.nameTranslation(index)}</Button>
                    )}
                  </React.Fragment>
                )
              })}
            </div></center>
            </React.Fragment>
          )}
          </Grid>

          {this.state.messages.map((message, i) => {
            if (message.sold) {
              return (
                <Grid item xs={12}>
                  <Card style={{textAlign: 'right', direction: 'rtl', padding: '2%', backgroundColor: this.state.messageBackgroundColor === undefined ? 'white' : this.state.messageBackgroundColor, color: this.state.messageFontColor === undefined ? 'white' : this.state.messageFontColor}}>
                    {isMobile ? (
                      <div style={{marginRight: '5%', maxWidth: '100%', backgroundColor: this.state.messageBackgroundColor === undefined ? 'white' : this.state.messageBackgroundColor}}>
                        <center>
                        <Chip color="secondary" label={this.soldTranslation()} style={{fontSize: '1.4em', color: this.state.soldColor, backgroundColor: this.state.soldBackgroundColor}}/>
                        {(message.category !== null && message.category !== undefined && message.category !== '') && (<Chip color={this.getCategoryColors(message.category).color} variant={'outlined'} label={this.nameTranslationCategory(message.category)} style={{fontSize: '1.4em', color: this.getCategoryColors(message.category).fontColor, backgroundColor: this.getCategoryColors(message.category).color}}/>)}
                        {(message.childCategory !== null && message.childCategory !== undefined && message.childCategory !== '') && (<Chip color={this.getCategoryColorsChild(message.childCategory).color} variant={'outlined'} label={this.nameTranslationCategory(message.childCategory)} style={{fontSize: '1.4em', color: this.getCategoryColorsChild(message.childCategory).fontColor, backgroundColor: this.getCategoryColorsChild(message.childCategory).color}}/>)}
                        <br /><br />
                        <img
                          src={'https://sivan-school.com/images/'+message.picture}
                          style={{
                            width: '90%',
                            height: '100%',
                            maxHeight: '100%',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            objectFit: 'contain',
                          }}
                        />
                        </center>
                        <div>
                          <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{message.freetext}</p>
                          {this.state.showCostPrice && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {message.price} ש"ח</p>)}
                          {this.state.showPriceWanted && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {message.priceWanted === undefined ? 0 : message.priceWanted} ש"ח</p>)}
                          {this.state.showDiscount && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.discountOrMore(message)}</p>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold', textDecoration: 'underline', direction: 'rtl'}}>שיתוף באמצעות:</p>
                          <FacebookShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookIcon size={36} round={true} />
                          </FacebookShareButton>
                          <FacebookMessengerShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookMessengerIcon size={36} round={true} />
                          </FacebookMessengerShareButton>
                          <WhatsappShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <WhatsAppIcon2 size={36} round={true} />
                          </WhatsappShareButton>
                          <TelegramShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <TelegramIcon size={36} round={true} />
                          </TelegramShareButton>
                          <EmailShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <EmailIcon size={36} round={true} />
                          </EmailShareButton><br/><br/>

                          {this.state.me !== message.userId && (
                            <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://api.sivan-school.com/v1/contact-us-requested-proxy?id='+message._id, '_blank')}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                            &nbsp;&nbsp;
                            <WhatsAppIcon style={{color: 'white'}}/>
                            </Button>
                          )}

                        </div>
                      </div>
                    ) : (
                      <div style={{marginRight: '5%', maxWidth: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: this.state.messageBackgroundColor === undefined ? 'white' : this.state.messageBackgroundColor}}>
                        <div style={{backgroundColor: this.state.messageBackgroundColor === undefined ? 'white' : this.state.messageBackgroundColor}}>
                          <Chip color="secondary" label={this.soldTranslation()} style={{fontSize: '1.4em', color: this.state.soldColor, backgroundColor: this.state.soldBackgroundColor}}/>
                          {(message.category !== null && message.category !== undefined && message.category !== '') && (<Chip color={this.getCategoryColors(message.category).color} variant={'outlined'} label={this.nameTranslationCategory(message.category)} style={{fontSize: '1.4em', color: this.getCategoryColors(message.category).fontColor, backgroundColor: this.getCategoryColors(message.category).color}}/>)}
                          {(message.childCategory !== null && message.childCategory !== undefined && message.childCategory !== '') && (<Chip color={this.getCategoryColorsChild(message.childCategory).color} variant={'outlined'} label={this.nameTranslationCategory(message.childCategory)} style={{fontSize: '1.4em', color: this.getCategoryColorsChild(message.childCategory).fontColor, backgroundColor: this.getCategoryColorsChild(message.childCategory).color}}/>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{message.freetext}</p>
                          {this.state.showCostPrice && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {message.price} ש"ח</p>)}
                          {this.state.showPriceWanted && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {message.priceWanted === undefined ? 0 : message.priceWanted} ש"ח</p>)}
                          {this.state.showDiscount && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.discountOrMore(message)}</p>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold', textDecoration: 'underline', direction: 'rtl'}}>שיתוף באמצעות:</p>
                          <FacebookShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookIcon size={36} round={true} />
                          </FacebookShareButton>
                          <FacebookMessengerShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookMessengerIcon size={36} round={true} />
                          </FacebookMessengerShareButton>
                          <WhatsappShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <WhatsAppIcon2 size={36} round={true} />
                          </WhatsappShareButton>
                          <TelegramShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <TelegramIcon size={36} round={true} />
                          </TelegramShareButton>
                          <EmailShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <EmailIcon size={36} round={true} />
                          </EmailShareButton><br/><br/>

                          {this.state.me !== message.userId && (
                            <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://api.sivan-school.com/v1/contact-us-requested-proxy?id='+message._id, '_blank')}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                            &nbsp;&nbsp;
                            <WhatsAppIcon style={{color: 'white'}}/>
                            </Button>
                          )}

                        </div>
                        <img
                        src={'https://sivan-school.com/images/'+message.picture}
                          style={{
                            width: '100%',
                            maxWidth: 300,
                            height: 'auto',
                            maxHeight: '100%',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                          objectFit: 'contain'}}
                        />
                      </div>
                    )}
                  </Card>
                </Grid>
              )
            } else {
              return (
                <Grid item xs={12}>
                  <Card style={{textAlign: 'right', direction: 'rtl', padding: '2%', backgroundColor: this.state.messageBackgroundColor === undefined ? 'white' : this.state.messageBackgroundColor, color: this.state.messageFontColor === undefined ? 'white' : this.state.messageFontColor}}>
                    {isMobile ? (
                      <div style={{marginRight: '5%', maxWidth: '100%'}}>
                        <center>
                        <img
                          src={'https://sivan-school.com/images/'+message.picture}
                          style={{
                            width: '100%',
                          height: 'auto',
                          maxHeight: 300,
                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        objectFit: 'contain'}}
                        />
                        </center>
                        <div>
                          {(message.category !== null && message.category !== undefined && message.category !== '') && (<Chip color={this.getCategoryColors(message.category).color} variant={'outlined'} label={this.nameTranslationCategory(message.category)} style={{fontSize: '1.4em', color: this.getCategoryColors(message.category).fontColor, backgroundColor: this.getCategoryColors(message.category).color}}/>)}
                          {(message.childCategory !== null && message.childCategory !== undefined && message.childCategory !== '') && (<Chip color={this.getCategoryColorsChild(message.childCategory).color} variant={'outlined'} label={this.nameTranslationCategory(message.childCategory)} style={{fontSize: '1.4em', color: this.getCategoryColorsChild(message.childCategory).fontColor, backgroundColor: this.getCategoryColorsChild(message.childCategory).color}}/>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{message.freetext}</p>
                          {this.state.showCostPrice && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {message.price} ש"ח</p>)}
                          {this.state.showPriceWanted && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {message.priceWanted === undefined ? 0 : message.priceWanted} ש"ח</p>)}
                          {this.state.showDiscount && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.discountOrMore(message)}</p>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold', textDecoration: 'underline', direction: 'rtl'}}>שיתוף באמצעות:</p>
                          <FacebookShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookIcon size={36} round={true} />
                          </FacebookShareButton>
                          <FacebookMessengerShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookMessengerIcon size={36} round={true} />
                          </FacebookMessengerShareButton>
                          <WhatsappShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <WhatsAppIcon2 size={36} round={true} />
                          </WhatsappShareButton>
                          <TelegramShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <TelegramIcon size={36} round={true} />
                          </TelegramShareButton>
                          <EmailShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <EmailIcon size={36} round={true} />
                          </EmailShareButton><br/><br/>

                          {message.isLastMinute && (
                            <p style={{fontSize: '1.2em', fontWeight: 'bold', color: 'orange'}}>מוצר זה בעל תוקף מימוש של עד {new Date(message.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(message.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(message.lastMinuteMaxDate).getFullYear()}</p>
                          )}

                          {this.state.me !== message.userId && (
                            <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://api.sivan-school.com/v1/contact-us-requested-proxy?id='+message._id, '_blank')}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                            &nbsp;&nbsp;
                            <WhatsAppIcon style={{color: 'white'}}/>
                            </Button>
                          )}



                          {this.state.me === message.userId && (
                            <React.Fragment>
                              <br />
                              <br /><Button variant={'contained'} style={{backgroundColor: 'red'}} onClick={(e) => this.setSold(message._id)}>
                                <span style={{fontSize: '1.2em', color: 'white'}}>סמן מודעה זו כנמכרה</span>

                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Link to={'/board/edit?id='+message._id} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>
                                <span style={{fontSize: '1.2em', color: 'white'}}>ערוך מודעה</span>
                              </Button></Link>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div style={{marginRight: '5%', maxWidth: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                          {(message.category !== null && message.category !== undefined && message.category !== '') && (<Chip color={this.getCategoryColors(message.category).color} variant={'outlined'} label={this.nameTranslationCategory(message.category)} style={{fontSize: '1.4em', color: this.getCategoryColors(message.category).fontColor, backgroundColor: this.getCategoryColors(message.category).color}}/>)}
                          {(message.childCategory !== null && message.childCategory !== undefined && message.childCategory !== '') && (<Chip color={this.getCategoryColorsChild(message.childCategory).color} variant={'outlined'} label={this.nameTranslationCategory(message.childCategory)} style={{fontSize: '1.4em', color: this.getCategoryColorsChild(message.childCategory).fontColor, backgroundColor: this.getCategoryColorsChild(message.childCategory).color}}/>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{message.freetext}</p>
                          {this.state.showCostPrice && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {message.price} ש"ח</p>)}
                          {this.state.showPriceWanted && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {message.priceWanted === undefined ? 0 : message.priceWanted} ש"ח</p>)}
                          {this.state.showDiscount && (<p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.discountOrMore(message)}</p>)}

                          <p style={{fontSize: '1.2em', fontWeight: 'bold', textDecoration: 'underline', direction: 'rtl'}}>שיתוף באמצעות:</p>
                          <FacebookShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookIcon size={36} round={true} />
                          </FacebookShareButton>
                          <FacebookMessengerShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <FacebookMessengerIcon size={36} round={true} />
                          </FacebookMessengerShareButton>
                          <WhatsappShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <WhatsAppIcon2 size={36} round={true} />
                          </WhatsappShareButton>
                          <TelegramShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <TelegramIcon size={36} round={true} />
                          </TelegramShareButton>
                          <EmailShareButton
                            url={'https://sivan-school.com/board/page?id='+message._id}
                            quote={message.freetext + ' | ' + message.priceWanted + ' ש"ח'}
                            hashtag="#sivanschool"
                          >
                             <EmailIcon size={36} round={true} />
                          </EmailShareButton><br/><br/>

                          {message.isLastMinute && (
                            <p style={{fontSize: '1.2em', fontWeight: 'bold', color: 'orange'}}>מוצר זה בעל תוקף מימוש של עד {new Date(message.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(message.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(message.lastMinuteMaxDate).getFullYear()}</p>
                          )}

                          {this.state.me !== message.userId && (
                            <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://api.sivan-school.com/v1/contact-us-requested-proxy?id='+message._id, '_blank')}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                            &nbsp;&nbsp;
                            <WhatsAppIcon style={{color: 'white'}}/>
                            </Button>
                          )}

                          {this.state.me === message.userId && (
                            <React.Fragment>
                              <br />
                              <br /><Button variant={'contained'} style={{backgroundColor: 'red'}} onClick={(e) => this.setSold(message._id)}>
                                <span style={{fontSize: '1.2em', color: 'white'}}>סמן מודעה זו כנמכרה</span>

                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Link to={'/board/edit?id='+message._id} style={{textDecoration: 'none'}}>
                              <Button variant={'contained'} color={'primary'}>
                                <span style={{fontSize: '1.2em', color: 'white'}}>ערוך מודעה</span>
                              </Button></Link>
                            </React.Fragment>
                          )}
                        </div>
                        <img
                          src={'https://sivan-school.com/images/'+message.picture}
                          style={{
                            width: '100%',
                            maxWidth: 300,
                            maxHeight: '100%',
                            height: 'auto', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',objectFit: 'contain'}}
                        />
                      </div>
                    )}
                  </Card>
                </Grid>
              )
            }
          })}
          </Grid>
      </div>
    )
  }
}

export default MessagesBoard;
