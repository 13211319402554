import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import {
  Link
} from "react-router-dom";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SetInsideTestTime extends Component {
  componentDidMount () {
    this.getData();
    this.getProfile();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',
     schoolId: '',
     professionalId: '',
     testDate: new Date(Date.now()),
     testTime: new Date(Date.now()),
     insidetestId: '',
     lastSeen: '',
     language: 'he',

     link: '',
     whatsapp: '',
     professionals: [],
     withoutDates: false,
     displayPermissionsNumberTesting: false,
     insidetest: {},
     student: {}
   }
  }

  setWithoutDates = () => {
    if (this.state.withoutDates) {
      this.setState({
        withoutDates: false
      });
    } else {
      this.setState({
        withoutDates: true
      });
    }
  }

  getProfile = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          displayPermissionsNumberTesting: response.data.displayPermissionsNumberTesting,
        });
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }


  getData = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({insidetestId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'teacher/get-inside-test?id='+this.state.insidetestId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            insidetest: response.data.data.inside_test,
            student: response.data.data.student,
            lastSeen: response.data.lastSeen,
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת המבחן פנימי שלי');
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  setTest = () => {
    let payload = {
      id: this.state.insidetestId,
      testDate: this.state.testDate,
      testTime: this.state.testTime,
    }

    axios.put(API + 'teacher/set-insidetest-time?id='+this.state.insidetestId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messageTestCreatedSuccess);
        window.location.href='/calendar/tests';
      } else {
        if (response.data.errorCode === 111 || response.data.errorCode === '111') {
          alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בעדכון המבחן פנימי שלי')
        }
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeAmount = (amount) => {
    if (amount > 0) {
      this.setState({amountWithVAT: amount})
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
            {this.state.displayPermissionsNumberTesting && (
              <p>הרשאות 10\9\7</p>
            )}

              <h2 style={{textAlign: 'right'}}>{strings.setInsideTest}</h2>

              <h4 style={{textAlign: 'right'}}>התלמיד: {this.state.student.firstName + ' ' + this.state.student.lastName}</h4>
              <h4 style={{textAlign: 'right'}}>ת.ז: {this.state.student.idNumber}</h4>
              <h4 style={{textAlign: 'right'}}>מספר טלפון: {this.state.student.phoneNumber}</h4>

              <React.Fragment>
              <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateoftest}</p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={strings.opencalendarhere}
                    format="dd/MM/yyyy"
                    value={this.state.testDate}
                    onChange={(date) => this.setState({testDate: date})}
                    style={{
                      width: '100%',
                      textAlign: 'right',
                      direction: 'rtl'
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateoftesttime}</p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">

                  <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      ampm={false}
                      label={strings.opencalendarheretime}
                      value={this.state.testTime}
                      onChange={(date) => this.setState({testTime: date})}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      style={{
                        width: '100%',
                        textAlign: 'right',
                        direction: 'rtl'
                      }}
                    />
                </Grid>
              </MuiPickersUtilsProvider>
              </React.Fragment>
              <br /><br />
              <Button onClick={(e) => this.setTest()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.create}
              </Button>
            </div>
            </center>

          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SetInsideTestTime;
