import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import SelectSearch from 'react-select-search';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ClerkItems extends Component {
  componentDidMount () {
    this.getData();
    this.getPtors();
    this.getItems();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     students: [],
     studentId: '',
     schoolId: '',


     type: '',
     bankNumber: '',
     branchNumber: '',
     bankAccountNumber: '',
     dateOfPayment: new Date(Date.now()),
     description: '',
     applicationType: '',
     applicationNumber: '',
     chequeNum: '',
     transactionId: '',

     lastSeen: '',
     language: 'he',

     link: '',
     whatsapp: '',

     canSignup: true,
     canInsideTest: true,

     ccno: '',
     expdate: '',
     cvv: '',
     expdatemonth: '',
     expdateyear: '',

     isPtor: false,
     ptorReason: '',
     createItemB: false,
     ptors: [],

     items: [],

     edit: false,
     edit_description: '',
     edit_makat: '',
     edit_price: 0,
     edit_id: '',

     isCasualClient: false,
     casualClientName: '',
     casualClientPhoneNumber: '',

     s_: [],
     note:'',
     numberOfItems: 1
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/student-school-all', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          students: response.data.data.students,
          lastSeen: response.data.lastSeen,
        }, () => {
          let students = [];
          let studentsApproval = [];

          this.state.students.forEach((item, i) => {
            if (item.approved === "false" || !item.approved) studentsApproval.push(item);
            else students.push(item);
          });

          this.setState({
            students: students,
            studentsApproval: studentsApproval
          }, () => {
            let s_ = [];
            this.state.students.forEach((item, i) => {
              s_.push({name: item.firstName + ' ' + item.lastName, value: item._id},)
            });

            this.setState({s_: s_});
          });
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בקבלת רשימת תלמידים')
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getPtors = () => {
    axios.get(API + 'ptors', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {
      this.setState({ptors: response.data.data.ptors});
    }).catch(err => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getItems = () => {
    axios.get(API + 'clerk/items', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {
      this.setState({items: response.data.data.items});
    }).catch(err => {
      alert('שגיאה בהשגת הפריטים');
    });
  }

  removeItem = (id) => {
    axios.delete(API + 'clerk/item?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    }).then(response => {
      this.getItems();
    }).catch(err => {
      alert('שגיאה בהשגת הפריטים');
    });
  }

  createPayment = () => {
    let payload = {
      type: this.state.type,
      bankNumber: this.state.bankNumber,
      branchNumber: this.state.branchNumber,
      bankAccountNumber: this.state.bankAccountNumber,
      dateOfPayment: this.state.dateOfPayment,
      description: this.state.description,
      applicationType: this.state.applicationType,
      applicationNumber: this.state.applicationNumber,
      chequeNum: this.state.chequeNum,
      transactionId: this.state.transactionId,
      expdate: this.state.expdatemonth +''+ this.state.expdateyear,
      ccno: this.state.ccno,
      cvv: this.state.cvv,
      isPtor: this.state.isPtor,
      ptorReason: this.state.ptorReason,
      isCasualClient: this.state.isCasualClient,
      casualClientName: this.state.casualClientName,
      casualClientPhoneNumber: this.state.casualClientPhoneNumber,
      note: this.state.note,
      numberOfItems: this.state.numberOfItems
    }

    axios.post(API + 'clerk/student/payment-item?id='+this.state.studentId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messagePaymentCreatedSuccess);
        this.setState({
          link: response.data.data.link,
          whatsapp: response.data.data.whatsapp,

          isCasualClient: false,

          casualClientName: '',
          casualClientPhoneNumber: '',
          note: '',
          numberOfItems: 1
        })
        if (this.state.isPtor) {
          this.setState({
            link: '',
            whatsapp: '',
            type: '',
            bankNumber: '',
            branchNumber: '',
            bankAccountNumber: '',
            dateOfPayment: new Date(Date.now()),
            description: '',
            applicationType: '',
            applicationNumber: '',
            chequeNum: '',
            transactionId: '',
            ccno: '',
            expdate: '',
            cvv: '',
            expdatemonth: '',
            expdateyear: '',
            isPtor: false,
            ptorReason: '',
            note: '',
            numberOfItems: 1
          })
         }
      } else {
        if (response.data.errorCode === 111 || response.data.errorCode === '111') {
          alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
        } else if (response.data.errorCode === 1111 || response.data.errorCode === '1111' || response.data.errorCode === 1112 || response.data.errorCode === '1112') {
          alert('שגיאה בחיוב הכרטיס, אנא נסו בשנית');
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  createItem = () => {
    let payload = {
      description: this.state.item_description,
      makat: this.state.item_makat,
      price: this.state.item_price,
    }
    axios.post(API + 'clerk/item', payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getItems();
        this.setState({item_description: '', item_makat: 0, item_price: 0})
      } else {
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  setStudent = (studentId) => {
    this.setState({studentId: studentId}, () => {
      axios.get(API + 'clerk/student/payment-info?id='+this.state.studentId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        console.log(response);
        this.setState({
          canSignup: response.data.data.can_signup,
          canInsideTest: response.data.data.can_inside_test,
        });
      }).catch(err => {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה קבלת המידע')
      });
    })
  }


    handlePtor = () => {
      let isPtor = this.state.isPtor;

      if (isPtor) {
        this.setState({isPtor: false});
      } else {
        this.setState({isPtor: true});
      }
    }

    editItem = (id) => {
      axios.get(API + 'manager/item?id='+id, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        if (response.data.status === 'ok') {
          this.setState({
            edit: true,
            edit_description: response.data.data.item.description,
            edit_makat: response.data.data.item.makat,
            edit_price: response.data.data.item.price,
            edit_id: id
          });
        }
      }).catch(err => {
        alert('שגיאה קבלת המידע')
      });

    }

    editItemAction = () => {
      axios.put(API + 'clerk/item?id='+this.state.edit_id, {
        description: this.state.edit_description,
        makat: this.state.edit_makat,
        price: this.state.edit_price
      }, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      }).then(response => {
        if (response.data.status === 'ok') {
          this.setState({
            edit: false,
          });
          this.getItems();
        }
      }).catch(err => {
        alert('שגיאה קבלת המידע')
      });

    }

    handleCasualClient = () => {
      let isCasualClient = this.state.isCasualClient;

      if (isCasualClient) {
        this.setState({isCasualClient: false})
      } else {
        this.setState({isCasualClient: true});
      }
    }
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.manageItems}</h2>

                <div>
                {this.state.edit && (
                  <React.Fragment>
                    <Button onClick={(e) => this.setState({edit: false, edit_description: '', edit_makat: '', edit_price: ''})} variant={'contained'} color={'primary'} style={{textAlign:'right'}}>הסתר יצירת פריט</Button>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.itemname}</p>
                    <Input
                      value={this.state.edit_description}
                      onChange={(e) => this.setState({edit_description: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}></span> {strings.makat}</p>
                    <Input
                      value={this.state.edit_makat}
                      onChange={(e) => this.setState({edit_makat: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.price}</p>
                    <Input
                      value={this.state.edit_price}
                      onChange={(e) => this.setState({edit_price: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <br /><br /><br />
                  </React.Fragment>
                )}



                <br /><br />
                <table style={{borderCollapse: 'collapse'}}>
                  <tr style={{border: '1px solid black', padding: 8}}>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.makat}</th>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.price}</th>
                    <th style={{border: '1px solid black', padding: 8}}>{strings.itemname}</th>
                  </tr>

                  {this.state.items.map((index) => {
                    return (
                      <tr style={{border: '1px solid black', padding: 8}}>
                        <th style={{border: '1px solid black', padding: 8}}>{index.makat}</th>
                        <th style={{border: '1px solid black', padding: 8}}>₪{parseFloat((parseFloat(index.price) * parseFloat(this.state.numberOfItems || 1))).toFixed()}</th>
                        <th style={{border: '1px solid black', padding: 8}}>{index.description}</th>
                      </tr>
                    );
                  })}
                </table>
                </div>
              </div>
            </center>

            {this.state.link === '' ? (

              <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.sellitem}</h2>

                <div style={{textAlign: 'right', direction: 'rtl'}}>
                  <Checkbox
                    value={this.state.isCasualClient}
                    checked={this.state.isCasualClient}
                    check={this.state.isCasualClient}
                    onChange={(e) => this.handleCasualClient()}
                  /> האם לקוח מזדמן?
                </div>

                {this.state.isCasualClient ? (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.clientName}</p>
                  <TextField
                    value={this.state.casualClientName}
                    onChange={(e) => this.setState({casualClientName: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%',
                      textAlign: 'right',
                    }}
                  />

                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.clientPhoneNumber}</p>
                  <TextField
                    value={this.state.casualClientPhoneNumber}
                    onChange={(e) => this.setState({casualClientPhoneNumber: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%',
                      textAlign: 'right',
                    }}
                  />

                  </React.Fragment>
                ) : (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosestudent}</p>
                  <SelectSearch
                    onChange={(value) => this.setState({studentId: value})}
                    options={this.state.s_}
                    search={true}
                    value={this.state.studentId}
                    name="studentId"
                    placeholder="שם התלמיד.."
                  />

                  </React.Fragment>
                )}


                <p style={{textAlign: 'right', marginBottom: -15}}><span style={{color: 'red'}}>*</span> {strings.choosedateofpayment}</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label={strings.opencalendarhere}
                      format="dd/MM/yyyy"
                      value={this.state.dateOfPayment}
                      onChange={(date) => this.setState({dateOfPayment: date})}
                      style={{
                        width: '100%',
                        textAlign: 'right',
                        direction: 'rtl'
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.payfor}</p>
                <Select
                  value={this.state.description}
                  onChange={(e) => this.setState({description: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                >
                  {this.state.items.map((item) => {
                    return (
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={item._id}>{item.description}  ({item.price} ש״ח)</MenuItem>
                    );
                  })}
                </Select>

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> מספר פריטים</p>
                <Input
                  value={this.state.numberOfItems}
                  onChange={(e) => this.setState({numberOfItems: e.target.value})}
                  type='number'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />

                <div style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '100%'
                }}>
                  <Checkbox
                    onChange={(e) => this.handlePtor()}
                    selected={this.state.isPtor}
                    value={this.state.isPtor}
                  /> {strings.ptorfrompayment}
                </div>

                {!this.state.isPtor ? (
                  <React.Fragment>
                  <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.paywith}</p>
                  <Select
                    value={this.state.type}
                    onChange={(e) => this.setState({type: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '100%'
                    }}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cash'}>{strings.cash}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cheque'}>{strings.cheque}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bankTransfer'}>{strings.bankTransfer}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'application'}>{strings.application}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'tranzila'}>{strings.tranzila}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'barter'}>{strings.barter}</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'ptor'}>{strings.ptor}</MenuItem>
                  </Select>

                  {this.state.type === 'tranzila' && (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.creditcardnumber}</p>
                      <Input
                        value={this.state.ccno}
                        onChange={(e) => this.setState({ccno: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.cvvnumber}</p>
                      <Input
                        value={this.state.cvv}
                        onChange={(e) => this.setState({cvv: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.expdatenumber}</p>
                      <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.month}</p>
                      <Select
                        value={this.state.expdatemonth}
                        onChange={(e) => this.setState({expdatemonth: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>01</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'02'}>02</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'03'}>03</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>04</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'05'}>05</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'06'}>06</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>07</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>08</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>09</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>10</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>11</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>12</MenuItem>
                      </Select>
                      <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.year}</p>
                      <Select
                        value={this.state.expdateyear}
                        onChange={(e) => this.setState({expdateyear: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear() % 100) + i).map((index) => {
                          return (
                            <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.toString()}>{index.toString()}</MenuItem>
                          )
                        })}
                      </Select>
                    </React.Fragment>
                  )}


                  {this.state.type === "application" && (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseapplication}</p>
                      <Select
                        value={this.state.applicationType}
                        onChange={(e) => this.setState({applicationType: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bit'}>{strings.bit}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'paybox'}>{strings.paybox}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'pepper'}>{strings.pepper}</MenuItem>
                      </Select>
                    </React.Fragment>
                  )}

                  {(this.state.type === 'bankTransfer' || this.state.type === 'cheque') && (
                    <React.Fragment>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                      <Select
                        value={this.state.bankNumber}
                        onChange={(e) => this.setState({bankNumber: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                      </Select>

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                      <Input
                        value={this.state.branchNumber}
                        onChange={(e) => this.setState({branchNumber: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                      <Input
                        value={this.state.bankAccountNumber}
                        onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />
                    </React.Fragment>
                  )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.ptor_reason}</p>
                    <Select
                      value={this.state.ptorReason}
                      onChange={(e) => this.setState({ptorReason: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      {this.state.ptors.map((index) => {
                        return (
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.reason}>{index.reason}</MenuItem>
                        );
                      })}
                    </Select>
                  </React.Fragment>
                )}

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> הערה (יצורף לחשבונית)</p>
                <TextField
                  value={this.state.note}
                  onChange={(e) => this.setState({note: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                />

                <Button onClick={(e) => this.createPayment()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                  {strings.create}
                </Button>
              </div>
              </center>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <center>
                  <br />
                  <Button onClick={(e) => this.setState({
                    link: '',
                    whatsapp: '',
                    type: '',
                    bankNumber: '',
                    branchNumber: '',
                    bankAccountNumber: '',
                    dateOfPayment: new Date(Date.now()),
                    description: '',
                    applicationType: '',
                    applicationNumber: '',
                    chequeNum: '',
                    transactionId: '',
                    ccno: '',
                    expdate: '',
                    cvv: '',
                    expdatemonth: '',
                    expdateyear: '',
                    isPtor: false,
                    ptorReason: '',
                  })}>{strings.backtohomepage}</Button>

                    <p style={{fontSize: '1.5em'}}>{strings.invoicereceiptandpaymentcreatedsuccess}</p>

                    <IconButton onClick={(e) => window.open(this.state.link, '_blank')}>
                      <GetAppIcon style={{width: 32, height: 32 }} />
                      <a href={this.state.link} target="_blank">{strings.todownloadreceipt}</a>
                    </IconButton>
                    <p>{strings.orH}</p>
                    <Button style={{backgroundColor: '#25D366'}}>
                      <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                      <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceipt}</a>
                    </Button>
                    <br />

                  </center>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerkItems;
