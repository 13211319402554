import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import GetAppIcon from '@material-ui/icons/GetApp';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ClerkRefund extends Component {
  componentDidMount() {
    this.getData();
    this.getRefund();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'}, () => {
        strings.setLanguage(this.state.language);
      });
    } else {
      this.setState({language: 'ar'}, () => {
        strings.setLanguage(this.state.language);
      });
    }
  }


  constructor(props) {
   super(props);
   this.state = {
     lastSeen: '',
     language: 'he',

     refund: {},
     payment: {},
     student: {},
     refundId: '',
     whatsapp: '',
     link: '',
     refunded: false
   }
  }

  getData = async () => {
    axios.get(API + 'teacher', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          lastSeen: response.data.lastSeen,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  getRefund = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({refundId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'teacher/to-refund?id='+this.state.refundId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            payment: response.data.data.payment,
            student: response.data.data.student,
          }, () => {
            if (this.state.payment.refunded) {
              alert('החיוב כבר זוכה');
              window.location.href='/refunds';
            }
          })
        } else {
          alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  modifyPaymentType = (payment) => {
    if (payment.isPtor) {
      return 'פטור; ' + payment.ptorReason;
    } else {
      let paymentType = payment.type;

      if (paymentType === "bankTransfer") return strings.bankTransfer;
      else if (paymentType === "cash") return strings.cash;
      else if (paymentType === "creditcard") return strings.creditcard;
      else if (paymentType === "cheque") return strings.cheque;
      else if (paymentType === "application") return strings.application;
      else if (paymentType === "tranzila") return strings.tranzila;
      else if (paymentType === "barter") return strings.barter;
      else if (paymentType === "ptor") return strings.ptor;
      else return strings.bankTransfer;

      return strings.bankTransfer;
    }
  }

  createRefund = () => {
    let payload = {
      type: this.state.type,
      bankNumber: this.state.bankNumber,
      branchNumber: this.state.branchNumber,
      bankAccountNumber: this.state.bankAccountNumber,
      dateOfPayment: this.state.dateOfPayment,
      applicationType: this.state.applicationType,
      applicationNumber: this.state.applicationNumber,
      chequeNum: this.state.chequeNum,
      expdate: this.state.expdatemonth +''+ this.state.expdateyear,
      ccno: this.state.ccno,
      cvv: this.state.cvv,
    }

    axios.post(API + 'clerk/refund?id='+this.state.refundId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messagePaymentCreatedSuccess);
        this.setState({
          link: response.data.data.link,
          whatsapp: response.data.data.whatsapp,
          refunded: true
        });
      } else {
        if (response.data.errorCode === 111 || response.data.errorCode === '111') {
          alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
        } else if (response.data.errorCode === 1111 || response.data.errorCode === '1111' || response.data.errorCode === 1112 || response.data.errorCode === '1112') {
          alert('שגיאה בזיכוי הכרטיס, אנא נסו בשנית');
        }else {
          alert(strings.errorPleaseTryAgain);
        }
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginRight: '5%', marginTop: '2%'}}>
            <h2 style={{textAlign: 'right'}}>זיכוי חדש</h2>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginRight: '5%', marginTop: '2%', textAlign: 'right', direction: 'rtl'}}>
            <p>זיכוי עבור {this.state.student.firstName + ' ' + this.state.student.lastName}, מספר ת.ז {this.state.student.idNumber}</p>
            <p>שם הפריט: {(this.state.payment.description === 'insideTestFee' || this.state.payment.description === 'signupFee') ? 'דמי מבחן פנימי' : this.state.payment.description}, סכום כולל מע"מ {this.state.payment.amountWithVAT} ש"ח</p>
            <p>שולם באמצעות {this.modifyPaymentType(this.state.payment)}, בתאריך {new Date(this.state.payment.createdAt).getDate() + '/' + parseInt(new Date(this.state.payment.createdAt).getMonth() + 1) + '/' + new Date(this.state.payment.createdAt).getFullYear()}</p>
            <p style={{fontWeight: 'bold'}}>יצירת קשר עם התלמיד:</p>
            <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+this.state.student.phoneNumber, '_blank')}>
              <span style={{fontSize: '1em', color: 'white'}}>לשליחת הודעה בווטצאפ</span>
              &nbsp;&nbsp;
              <WhatsAppIcon style={{color: 'white'}}/>
            </Button>
            &nbsp;&nbsp;
            <Button variant={'contained'} color={'primary'} onClick={(e) => window.open('tel:+972'+this.state.student.phoneNumber, '_blank')}>
              <span style={{fontSize: '1em', color: 'white'}}>להתקשרות</span>
              &nbsp;&nbsp;
              <PhoneIcon style={{color: 'white'}}/>
            </Button>

            {(this.state.payment.amountWithVAT === 0 || this.state.payment.amountWithVAT === undefined || this.state.payment.amountWithVAT === null || isNaN(this.state.payment.amountWithVAT)) ? (
              <p style={{fontSize: '1em', color: 'red'}}>אין אפשרות לזכות 0 ש"ח</p>
            ) : (
              <React.Fragment>
              {(!this.state.refunded && this.state.payment.amountWithVAT >= 1) ? (
                <React.Fragment>
                <h2>יצירת זיכוי</h2>
                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> זיכוי באמצעות</p>
                <Select
                  value={this.state.type}
                  onChange={(e) => this.setState({type: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '100%'
                  }}
                >
                  <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cash'}>{strings.cash}</MenuItem>
                  <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'creditcard'}>{strings.creditcard}</MenuItem>
                  <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'cheque'}>{strings.cheque}</MenuItem>
                  <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bankTransfer'}>{strings.bankTransfer}</MenuItem>
                  <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'application'}>{strings.application}</MenuItem>
                  {this.state.payment.type === 'tranzila' && (<MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'tranzila'}>{strings.tranzila}</MenuItem>)}
                </Select>



                {this.state.type === 'tranzila' && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.creditcardnumber}</p>
                    <Input
                      value={this.state.ccno}
                      onChange={(e) => this.setState({ccno: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.cvvnumber}</p>
                    <Input
                      value={this.state.cvv}
                      onChange={(e) => this.setState({cvv: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.expdatenumber}</p>
                    <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.month}</p>
                    <Select
                      value={this.state.expdatemonth}
                      onChange={(e) => this.setState({expdatemonth: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>01</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'02'}>02</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'03'}>03</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>04</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'05'}>05</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'06'}>06</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>07</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>08</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>09</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>10</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>11</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>12</MenuItem>
                    </Select>
                    <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.year}</p>
                    <Select
                      value={this.state.expdateyear}
                      onChange={(e) => this.setState({expdateyear: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                     {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear() % 100) + i).map((index) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.toString()}>{index.toString()}</MenuItem>
                      )
                    })}
                    </Select>
                  </React.Fragment>
                )}


                {this.state.type === "application" && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.chooseapplication}</p>
                    <Select
                      value={this.state.applicationType}
                      onChange={(e) => this.setState({applicationType: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'bit'}>{strings.bit}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'paybox'}>{strings.paybox}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'pepper'}>{strings.pepper}</MenuItem>
                    </Select>
                  </React.Fragment>
                )}

                {(this.state.type === 'bankTransfer' || this.state.type === 'cheque') && (
                  <React.Fragment>
                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.choosebank}</p>
                    <Select
                      value={this.state.bankNumber}
                      onChange={(e) => this.setState({bankNumber: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'99'}>{strings.bankIsrael}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>{strings.bankHapoalim}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>{strings.discontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>{strings.bankLeumi}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'20'}>{strings.tfahotBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'31'}>{strings.internationalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'14'}>{strings.otzarHayalBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'17'}>{strings.markantilDiscontBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>{strings.doarBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'13'}>{strings.igudBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'46'}>{strings.masadBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'52'}>{strings.poaliAgudatBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>{strings.yahavBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'26'}>{strings.yovankBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>{strings.pituhaTayasiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>{strings.sapanutBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'54'}>{strings.yerushalayimBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'34'}>{strings.arabiIsraelBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>{strings.unitedEuroBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'19'}>{strings.haklautBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'22'}>{strings.citiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'48'}>{strings.kupatHaovedAlumiBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.otzarHashiltonBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'23'}>{strings.daskiaBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.hasbcBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'68'}>{strings.arabLandBank}</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'39'}>{strings.bankOfIndiaBank}</MenuItem>
                    </Select>

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.branchNumber}</p>
                    <Input
                      value={this.state.branchNumber}
                      onChange={(e) => this.setState({branchNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />

                    <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.bankAccountNumber}</p>
                    <Input
                      value={this.state.bankAccountNumber}
                      onChange={(e) => this.setState({bankAccountNumber: e.target.value})}
                      type='number'
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '100%'
                      }}
                    />
                  </React.Fragment>
                )}
                <br /><br />
                <Button variant={'contained'} color={'primary'} onClick={(e) => this.createRefund()}>יצירת זיכוי</Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <center>
                    <a href='/'>{strings.backtohomepage}</a>

                      <p style={{fontSize: '1.5em'}}>{strings.invoicereceiptandpaymentcreatedsuccess}</p>

                      <IconButton onClick={(e) => window.open(this.state.link, '_blank')}>
                        <GetAppIcon style={{width: 32, height: 32 }} />
                        <a href={this.state.link} target="_blank">{strings.todownloadreceipt}</a>
                      </IconButton>
                      <p>{strings.orH}</p>
                      <Button onClick={(e) => window.open(this.state.whatsapp, '_blank')} style={{backgroundColor: '#25D366'}}>
                        <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}} />
                        <a href={this.state.whatsapp} target="_blank" style={{fontSize: '1em', textDecoration: 'none', color: 'white'}}>{strings.sendwhatsappreceipt}</a>
                      </Button>
                      <br />

                    </center>
                  </Grid>
                </React.Fragment>
              )}
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default ClerkRefund;
