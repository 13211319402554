import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// Kupot Morim
import KupotMorim from './pages/KupotMorim';

// Teachers Income
import TeachersIncome from './pages/TeachersIncome';


// Manager - Teacher (Hybrid)
import TeacherViewDashboard from './pages/manager/TeacherViewDashboard';

// Manager - Clerk (Hybrid)
import ClerkViewDashboard from './pages/manager/ClerkViewDashboard';

// Manager

//  ** HOURS REPORTS **
import ReportsEmployees from './pages/manager/hoursreports/ReportsEmployees';
import WeeklyCalendar from './pages/manager/hoursreports/WeeklyCalendar';
import EmployeeReport from './pages/manager/hoursreports/EmployeeReport';
import EmployeeExpenses from './pages/manager/hoursreports/EmployeeExpenses';
//  ** HOURS REPORTS **

//  ** OBLIGATIONS **
import ObligationsDashboard from './pages/manager/obligations/ObligationsDashboard';
import ObligationsDashboardTeacher from './pages/manager/obligations/ObligationsDashboardTeacher';
import ManageObligation from './pages/manager/obligations/ManageObligation';
import AddObligation from './pages/manager/obligations/AddObligation';
import ObligationsPermission from './pages/manager/obligations/ObligationsPermission';
import AddObligationPayment from './pages/manager/obligations/AddObligationPayment';
import EditObligation from './pages/manager/obligations/EditObligation';
//  ** OBLIGATIONS **

import ManagerDashboard from './pages/manager/Dashboard';
import AddEmployee from './pages/manager/AddEmployee';
import EditEmployee from './pages/manager/EditEmployee';
import EditSchool from './pages/manager/EditSchool';
import ManagerAddStudent from './pages/manager/AddStudent';
import ManagerEditStudent from './pages/manager/EditStudent';
import Students from './pages/manager/Students';
import MigrateStudent from './pages/manager/MigrateStudent';
import ManagerAddPayment from './pages/manager/AddPayment';
import SpecialMessage from './pages/manager/SpecialMessage';
import PaymentMessage from './pages/manager/PaymentMessage';
import AddInsideTest from './pages/manager/AddInsideTest';
import Settings from './pages/manager/Settings';
import ManagerSendOutSidePaymentLink from './pages/manager/SendOutSidePaymentLink';
import ManagerPullMoney from './pages/manager/PullMoney';
import ManagerGenerateInvoice from './pages/manager/GenerateInvoice';
import InsideTests from './pages/manager/InsideTests';
import ManagerCheques from './pages/manager/Cheques';
import ManagerAddCheque from './pages/manager/AddCheque';
import ManagerEditCheque from './pages/manager/EditCheque';
import Suppliers from './pages/manager/Suppliers';
import AddSupplier from './pages/manager/AddSupplier';
import EditSupplier from './pages/manager/EditSupplier';
import Items from './pages/manager/Items';
import SpecialMessageSignupFee from './pages/manager/SpecialMessageSignupFee';
import SpecialMessageInsideTestFee from './pages/manager/SpecialMessageInsideTestFee';
import MoneyTransferSettings from './pages/manager/MoneyTransferSettings';
import Messages from './pages/manager/Messages';
import AddMessage from './pages/manager/AddMessage';
import Refunds from './pages/manager/Refunds';
import Refund from './pages/manager/Refund';
import DepositAccounts from './pages/manager/DepositAccounts';
import Deposit from './pages/manager/Deposit';
import DiscoverPayments from './pages/manager/DiscoverPayments';
import CashRegisters from './pages/manager/CashRegisters';
import PtorsGiven from './pages/manager/PtorsGiven';
import PaymentsDocument from './pages/manager/PaymentsDocument';
import PermissionsInsidetestSMS from './pages/manager/PermissionsInsidetestSMS';
import PermissionsSmallCashregister from './pages/manager/PermissionsSmallCashregister';
import PermissionsStudentsActive from './pages/manager/PermissionsStudentsActive';
import PermissionsStudentsPassedTest from './pages/manager/PermissionsStudentsPassedTest';
import StudentsInvoices from './pages/manager/StudentsInvoices';
import InsideTestsStatistics from './pages/manager/InsideTestsStatistics';

// Teacher
import AddStudent from './pages/teacher/AddStudent';
import EditStudent from './pages/teacher/EditStudent';
import TeacherDashboard from './pages/teacher/Dashboard';
import TeacherAddPayment from './pages/teacher/AddPayment';
import MyInsideTests from './pages/teacher/MyInsideTests';
import InsideTest from './pages/teacher/InsideTest';
import TeacherSettings from './pages/teacher/Settings';
import GizbarPullMoney from './pages/teacher/PullMoney'; // Gizbar
import TeacherPullMoney from './pages/teacher/PullMoneyTeacher';
import SetInsideTestTime from './pages/teacher/SetInsideTestTime';
import TeacherGenerateInvoice from './pages/teacher/GenerateInvoice';
import AddInsideTestStudent from './pages/teacher/AddInsideTestStudent';
import TeacherInsideTests from './pages/teacher/TeacherInsideTests';
import TeacherItems from './pages/teacher/TeacherItems';
import TeacherMigrateStudent from './pages/teacher/TeacherMigrateStudent';
import SetInsideTestSelfProfessional from './pages/teacher/SetInsideTestSelfProfessional';
import TeacherDepositAccounts from './pages/teacher/TeacherDepositAccounts';
import TeacherDeposit from './pages/teacher/TeacherDeposit';
import TeacherDiscoverPayments from './pages/teacher/TeacherDiscoverPayments';
import TeacherCashRegisters from './pages/teacher/TeacherCashRegisters';
import TeacherPtorsGiven from './pages/teacher/TeacherPtorsGiven';
import GizbarRefunds from './pages/teacher/GizbarRefunds';
import GizbarRefund from './pages/teacher/GizbarRefund';
import SMSSetInsideTest from './pages/teacher/SMSSetInsideTest';
import TeacherStudentsInvoices from './pages/teacher/TeacherStudentsInvoices';

// Student
import SelfSignup from './pages/students/SelfSignup';
import SelfPayment from './pages/students/SelfPayment';

// Clerk

//  ** OBLIGATIONS **
import ClerkObligationsDashboard from './pages/clerk/obligations/ObligationsDashboard';
import ClerkObligationsDashboardTeacher from './pages/clerk/obligations/ObligationsDashboardTeacher';
import ClerkManageObligation from './pages/clerk/obligations/ManageObligation';
import ClerkAddObligation from './pages/clerk/obligations/AddObligation';
import ClerkAddObligationPayment from './pages/clerk/obligations/AddObligationPayment';
//  ** OBLIGATIONS **

import ClerkDashboard from './pages/clerk/Dashboard';
import ClerkAddStudent from './pages/clerk/AddStudent';
import ClerkEditStudent from './pages/clerk/EditStudent';
import GetLink from './pages/clerk/GetLink';
import AddPayment from './pages/clerk/AddPayment';
import ClerkSettings from './pages/clerk/Settings';
import ClerkAddInsideTest from './pages/clerk/AddInsideTest';
import ClerkSendOutSidePaymentLink from './pages/clerk/SendOutSidePaymentLink';
import ClerkPullMoney from './pages/clerk/PullMoney';
import ClerkAddEmployee from './pages/clerk/AddEmployee';
import ClerkEditEmployee from './pages/clerk/EditEmployee';
import EmployeesList from './pages/clerk/Employees';
import ClerkGenerateInvoice from './pages/clerk/GenerateInvoice';
import Cheques from './pages/clerk/Cheques';
import AddCheque from './pages/clerk/AddCheque';
import EditCheque from './pages/clerk/EditCheque';
import ClerkInsideTests from './pages/clerk/ClerkInsideTests';
import ClerkItems from './pages/clerk/ClerkItems';
import ClerkMigrateStudent from './pages/clerk/ClerkMigrateStudent';
import ClerkRefunds from './pages/clerk/Refunds';
import ClerkRefund from './pages/clerk/Refund';
import InsideTestsEasyView from './pages/clerk/InsideTestsEasyView';
import ClerkDepositAccounts from './pages/clerk/ClerkDepositAccounts';
import ClerkDeposit from './pages/clerk/ClerkDeposit';
import ClerkDiscoverPayments from './pages/clerk/ClerkDiscoverPayments';
import ClerkCashRegisters from './pages/clerk/ClerkCashRegisters';
import ClerkInsideTest from './pages/clerk/ClerkInsideTest';
import ClerkPtorsGiven from './pages/clerk/ClerkPtorsGiven';
import ClerkStudentsInvoices from './pages/clerk/ClerkStudentsInvoices';

//  ** HOURS REPORTS **
import ReportHoursClerk from './pages/clerk/hoursreport/ReportHoursClerk';
//  ** HOURS REPORTS **

// CPE
import CPEDashboard from './pages/cpe/Dashboard';
import CPEPayments from './pages/cpe/Payments';
import CPETeachersPayments from './pages/cpe/TeachersPayments';
import CPEDeposits from './pages/cpe/CPEDeposits';

// Global
import Login from './pages/Login';
import Loading from './pages/Loading';
import MessagesBoard from './pages/MessagesBoard';
import SivanBoard from './pages/SivanBoard';
import NewSivanBoardMessage from './pages/NewSivanBoardMessage';
import EditSivanBoardMessage from './pages/EditSivanBoardMessage';
import SivanBoardPage from './pages/SivanBoardPage';
import SivanBoardGuest from './pages/SivanBoardGuest';
import SivanBoardPageGuest from './pages/SivanBoardPageGuest';
import NewSivanBoardMessageGuest from './pages/NewSivanBoardMessageGuest';
import ContactUsBoard from './pages/ContactUsBoard';
import EditSlogan from './pages/EditSlogan';
import SmallCashRegister from './pages/SmallCashRegister';
import SmallCashRegisterIn from './pages/SmallCashRegisterIn';
import SmallCashRegisterPull from './pages/SmallCashRegisterPull';
import SmallCashRegisterPush from './pages/SmallCashRegisterPush';
import UserPaymentsDocument from './pages/UserPaymentsDocument';

import Cookies from 'universal-cookie';
import axios from 'axios';
import TeacherSelfPayment from './pages/TeacherSelfPayment';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class App extends Component {
  componentDidMount() {
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isConnected: false,
     type: '',
     loading: false,
     isManager: false
   }
  }

  saveSignupFeeText = (text) => {
    const currect_signupfeetext = window.localStorage.getItem('signupfee_text');
    console.log('currect_signupfeetext:', currect_signupfeetext);

    let signupfeetext_exists = currect_signupfeetext === null ? false : true;
    if (signupfeetext_exists) {
      if (text != currect_signupfeetext) window.localStorage.setItem('signupfee_text', text);
    } else {
      window.localStorage.setItem('signupfee_text', text);
    }
  }


  validate = () => {
    this.setState({loading: true});
    axios.get(API + 'validate', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({isConnected: true, type: response.data.data.type, isManager: response.data.data.isManager, loading: false}, () => {
          this.saveSignupFeeText(response.data.data.signupFeeText)
        });
      }
    }).catch(err => {
      this.setState({isConnected: false, loading: false});
    });
  }

  cpe = () => {
    return (
      <Switch>

<Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
        <Route exact path='/store/slogan'>
          <EditSlogan />
        </Route>
        <Route exact path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route exact path='/deposits'>
          <CPEDeposits />
        </Route>
        <Route exact path='/teachers-payments'>
          <CPETeachersPayments />
        </Route>
        <Route exact path='/payments'>
          <CPEPayments />
        </Route>
        <Route path='/'>
          <CPEDashboard />
        </Route>
        <Route path='/messages'>
          <MessagesBoard />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route exact path='/board'>
          <SivanBoard />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
      </Switch>
    );
  }

  loneManager = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
      <Route exact path='/teachers-income'>
        <TeachersIncome />
      </Route>
      <Route exact path='/commission-table'>
        <KupotMorim />
      </Route>
      <Route exact path='/financial-obligations/edit'>
        <EditObligation />
      </Route>
      <Route exact path='/financial-obligations/payment'>
        <AddObligationPayment />
      </Route>
      <Route exact path='/financial-obligations/manage-teacher'>
        <ObligationsDashboardTeacher />
      </Route>
        <Route exact path='/financial-obligations/permissions'>
          <ObligationsPermission />
        </Route>
        <Route exact path='/financial-obligations/manage'>
          <ManageObligation />
        </Route>
        <Route exact path='/financial-obligations/add'>
          <AddObligation />
        </Route>
        <Route exact path='/financial-obligations'>
          <ObligationsDashboard />
        </Route>
        <Route exact path='/reports/expenses'>
          <EmployeeExpenses />
        </Route>
        <Route exact path='/reports/report'>
          <EmployeeReport />
        </Route>
        <Route exact path='/reports/weekly-calendar'>
          <WeeklyCalendar />
        </Route>
        <Route exact path='/reports/employees'>
          <ReportsEmployees />
        </Route>
        <Route exact path='/inside-tests-statistics'>
          <InsideTestsStatistics />
        </Route>
        <Route exact path='/students-invoices'>
          <StudentsInvoices />
        </Route>
        <Route exact path='/permissions-active'>
          <PermissionsStudentsActive />
        </Route>
        <Route exact path='/permissions-passedtest'>
          <PermissionsStudentsPassedTest />
        </Route>
        <Route exact path='/small-cashregister-pull'>
          <SmallCashRegisterPull />
        </Route>
        <Route exact path='/small-cashregister-push'>
          <SmallCashRegisterPush />
        </Route>
        <Route exact path='/small-cashregister-permissions'>
          <PermissionsSmallCashregister />
        </Route>
        <Route exact path='/small-cashregister'>
          <SmallCashRegister />
        </Route>
        <Route exact path='/small-cashregister-in'>
          <SmallCashRegisterIn />
        </Route>
        <Route exact path='/permissions-insidetest-sms'>
          <PermissionsInsidetestSMS />
        </Route>
        <Route exact path='/payment-document'>
          <PaymentsDocument />
        </Route>
        <Route exact path='/store/slogan'>
          <EditSlogan />
        </Route>
        <Route exact path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route exact path='/ptors-given'>
          <PtorsGiven />
        </Route>
        <Route exact path='/cash-registers'>
          <CashRegisters />
        </Route>
        <Route exact path='/payments/discover'>
          <DiscoverPayments />
        </Route>
        <Route exact path='/deposit'>
          <Deposit />
        </Route>
        <Route exact path='/deposit/accounts'>
          <DepositAccounts />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route path='/refunds'>
          <Refunds />
        </Route>
        <Route path='/refund'>
          <Refund />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board'>
          <SivanBoard />
        </Route>
        <Route exact path="/message/add">
          <AddMessage />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/settings-money-transfer">
          <MoneyTransferSettings />
        </Route>
        <Route exact path="/school/post-signup-message">
          <SpecialMessageSignupFee />
        </Route>
        <Route exact path="/school/post-insidetest-message">
          <SpecialMessageInsideTestFee />
        </Route>
        <Route exact path="/manager/items">
          <Items />
        </Route>
        <Route exact path="/school/supplier/edit">
          <EditSupplier />
        </Route>
        <Route exact path="/school/supplier/add">
          <AddSupplier />
        </Route>
        <Route exact path="/school/suppliers">
          <Suppliers />
        </Route>
        <Route exact path="/cheque/edit">
          <ManagerEditCheque />
        </Route>
        <Route exact path="/cheque/add">
          <ManagerAddCheque />
        </Route>
        <Route exact path="/cheques">
          <ManagerCheques />
        </Route>
        <Route exact path="/school/inside-tests">
          <InsideTests />
        </Route>
        <Route exact path="/generate-invoices">
          <ManagerGenerateInvoice />
        </Route>
        <Route exact path="/outside-link">
          <ManagerSendOutSidePaymentLink />
        </Route>
        <Route exact path="/school/inside-test">
          <AddInsideTest />
        </Route>
        <Route exact path="/transfer-money">
          <ManagerPullMoney />
        </Route>
        <Route path="/payment">
          <ManagerAddPayment />
        </Route>
        <Route path="/students">
          <Students />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route exact path="/cheque/add">
          <ManagerAddCheque />
        </Route>
        <Route path="/student/add">
          <ManagerAddStudent />
        </Route>
        <Route path="/student/update">
          <ManagerEditStudent />
        </Route>
        <Route path="/student/migrate">
          <MigrateStudent />
        </Route>
        <Route exact path="/employee/add">
          <AddEmployee />
        </Route>
        <Route exact path="/employee/update">
          <EditEmployee />
        </Route>
        <Route exact path="/school/special-message">
          <SpecialMessage />
        </Route>
        <Route exact path="/school/payment-message">
          <PaymentMessage />
        </Route>
        <Route exact path="/school/update">
          <EditSchool />
        </Route>
        <Route path="/">
          <ManagerDashboard />
        </Route>
      </Switch>
    );
  }

  teacherManager = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
      <Route exact path='/teachers-income'>
        <TeachersIncome />
      </Route>
      <Route exact path='/commission-table'>
        <KupotMorim />
      </Route>
      <Route exact path='/financial-obligations/manage-teacher'>
        <ObligationsDashboardTeacher />
      </Route>
      <Route exact path='/financial-obligations/edit'>
        <EditObligation />
      </Route>
      <Route exact path='/financial-obligations/payment'>
        <AddObligationPayment />
      </Route>
        <Route exact path='/financial-obligations/permissions'>
          <ObligationsPermission />
        </Route>
        <Route exact path='/financial-obligations/manage'>
          <ManageObligation />
        </Route>
        <Route exact path='/financial-obligations/add'>
          <AddObligation />
        </Route>
        <Route exact path='/financial-obligations'>
          <ObligationsDashboard />
        </Route>
        <Route exact path='/reports/expenses'>
          <EmployeeExpenses />
        </Route>
        <Route exact path='/reports/report'>
          <EmployeeReport />
        </Route>
        <Route exact path='/reports/weekly-calendar'>
          <WeeklyCalendar />
        </Route>
        <Route exact path='/reports/employees'>
          <ReportsEmployees />
        </Route>
        <Route exact path='/inside-tests-statistics'>
          <InsideTestsStatistics />
        </Route>
        <Route exact path='/students-invoices'>
          <StudentsInvoices />
        </Route>
        <Route exact path='/permissions-active'>
          <PermissionsStudentsActive />
        </Route>
        <Route exact path='/permissions-passedtest'>
          <PermissionsStudentsPassedTest />
        </Route>
        <Route exact path='/small-cashregister-permissions'>
          <PermissionsSmallCashregister />
        </Route>
        <Route exact path='/small-cashregister-pull'>
          <SmallCashRegisterPull />
        </Route>
        <Route exact path='/small-cashregister-push'>
          <SmallCashRegisterPush />
        </Route>
        <Route exact path='/small-cashregister'>
          <SmallCashRegister />
        </Route>
        <Route exact path='/small-cashregister-in'>
          <SmallCashRegisterIn />
        </Route>
        <Route exact path='/teacher/set-insidetest-teacher'>
          <SMSSetInsideTest />
        </Route>
        <Route exact path='/permissions-insidetest-sms'>
          <PermissionsInsidetestSMS />
        </Route>
      <Route exact path="/cheque/add">
        <ManagerAddCheque />
      </Route>
        <Route exact path='/payment-document'>
          <PaymentsDocument />
        </Route>
        <Route exact path='/store/slogan'>
          <EditSlogan />
        </Route>
        <Route exact path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route exact path='/ptors-given'>
          <PtorsGiven />
        </Route>
        <Route exact path='/cash-registers'>
          <CashRegisters />
        </Route>
        <Route exact path='/payments/discover'>
          <DiscoverPayments />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route path='/refunds'>
          <Refunds />
        </Route>
        <Route path='/refund'>
          <Refund />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board'>
          <SivanBoard />
        </Route>
        {/* teacher */}
        <Route exact path='/deposit'>
          <Deposit />
        </Route>
        <Route exact path='/deposit/accounts'>
          <DepositAccounts />
        </Route>
        <Route exact path="/view/teacher/payment">
          <TeacherAddPayment />
        </Route>
        <Route exact path="/view/teacher">
          <TeacherViewDashboard />
        </Route>
        <Route exact path="/calendar/tests">
          <MyInsideTests />
        </Route>
        <Route exact path="/inside-test">
          <InsideTest />
        </Route>
        <Route exact path="/inside-test-time">
          <SetInsideTestTime />
        </Route>
        <Route exact path="/calendar/students">
          <TeacherInsideTests />
        </Route>
        {/* teacher */}
        <Route exact path="/items">
          <TeacherItems />
        </Route>
        <Route exact path="/message/add">
          <AddMessage />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/settings-money-transfer">
          <MoneyTransferSettings />
        </Route>
        <Route exact path="/school/post-signup-message">
          <SpecialMessageSignupFee />
        </Route>
        <Route exact path="/school/post-insidetest-message">
          <SpecialMessageInsideTestFee />
        </Route>
        <Route exact path="/manager/items">
          <Items />
        </Route>
        <Route exact path="/calendar/students/set-test">
          <AddInsideTestStudent />
        </Route>
        <Route exact path="/school/supplier/add">
          <AddSupplier />
        </Route>
        <Route exact path="/school/suppliers">
          <Suppliers />
        </Route>
        <Route exact path="/school/supplier/edit">
          <EditSupplier />
        </Route>
        <Route exact path="/cheque/edit">
          <ManagerEditCheque />
        </Route>
        <Route exact path="/cheque/add">
          <ManagerAddCheque />
        </Route>
        <Route exact path="/cheques">
          <ManagerCheques />
        </Route>
        <Route exact path="/school/inside-tests">
          <InsideTests />
        </Route>
        <Route exact path="/generate-invoices">
          <ManagerGenerateInvoice />
        </Route>
        <Route exact path="/outside-link">
          <ManagerSendOutSidePaymentLink />
        </Route>
        <Route exact path="/school/inside-test">
          <AddInsideTest />
        </Route>
        <Route exact path="/transfer-money">
          <ManagerPullMoney />
        </Route>
        <Route path="/payment">
          <ManagerAddPayment />
        </Route>
        <Route path="/students">
          <Students />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/student/add">
          <ManagerAddStudent />
        </Route>
        <Route path="/student/update">
          <ManagerEditStudent />
        </Route>
        <Route path="/student/migrate">
          <MigrateStudent />
        </Route>
        <Route exact path="/employee/add">
          <AddEmployee />
        </Route>
        <Route exact path="/employee/update">
          <EditEmployee />
        </Route>
        <Route exact path="/school/special-message">
          <SpecialMessage />
        </Route>
        <Route exact path="/school/payment-message">
          <PaymentMessage />
        </Route>
        <Route exact path="/school/update">
          <EditSchool />
        </Route>
        <Route path="/">
          <ManagerDashboard />
        </Route>
      </Switch>
    )
  }

  clerkManager = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
      <Route exact path='/teachers-income'>
        <TeachersIncome />
      </Route>
      <Route exact path='/commission-table'>
        <KupotMorim />
      </Route>
      <Route exact path='/financial-obligations/manage-teacher'>
        <ObligationsDashboardTeacher />
      </Route>
      <Route exact path='/financial-obligations/edit'>
        <EditObligation />
      </Route>
      <Route exact path='/financial-obligations/payment'>
        <AddObligationPayment />
      </Route>
      <Route exact path='/financial-obligations/permissions'>
        <ObligationsPermission />
      </Route>
      <Route exact path='/financial-obligations/manage'>
        <ManageObligation />
      </Route>
      <Route exact path='/financial-obligations/add'>
        <AddObligation />
      </Route>
      <Route exact path='/financial-obligations'>
        <ObligationsDashboard />
      </Route>
      <Route exact path='/reports/expenses'>
        <EmployeeExpenses />
      </Route>
      <Route exact path='/reports/report'>
        <EmployeeReport />
      </Route>
      <Route exact path='/reports/weekly-calendar'>
        <WeeklyCalendar />
      </Route>
      <Route exact path='/reports/employees'>
        <ReportsEmployees />
      </Route>
      <Route exact path='/inside-tests-statistics'>
        <InsideTestsStatistics />
      </Route>
      <Route exact path='/students-invoices'>
        <StudentsInvoices />
      </Route>
      <Route exact path='/permissions-active'>
        <PermissionsStudentsActive />
      </Route>
      <Route exact path='/permissions-passedtest'>
        <PermissionsStudentsPassedTest />
      </Route>
      <Route exact path='/small-cashregister-permissions'>
        <PermissionsSmallCashregister />
      </Route>
      <Route exact path='/small-cashregister'>
        <SmallCashRegister />
      </Route>
      <Route exact path='/small-cashregister-in'>
        <SmallCashRegisterIn />
      </Route>
      <Route exact path='/permissions-insidetest-sms'>
        <PermissionsInsidetestSMS />
      </Route>
        <Route exact path='/payment-document'>
          <PaymentsDocument />
        </Route>
        <Route exact path='/store/slogan'>
          <EditSlogan />
        </Route>
        <Route exact path='/contact-us'>
          <ContactUsBoard />
        </Route>
        {/* Clerk */}
        <Route exact path="/view/clerk">
          <ClerkViewDashboard />
        </Route>
        <Route exact path="/inside-test">
          <InsideTest />
        </Route>
        <Route exact path="/view/clerk/inside-tests">
          <ClerkInsideTests />
        </Route>
        {/* Clerk */}
        <Route exact path='/ptors-given'>
          <PtorsGiven />
        </Route>
        <Route exact path='/cash-registers'>
          <CashRegisters />
        </Route>
        <Route exact path='/payments/discover'>
          <DiscoverPayments />
        </Route>
        <Route exact path='/deposit'>
          <Deposit />
        </Route>
        <Route exact path='/deposit/accounts'>
          <DepositAccounts />
        </Route>
        <Route exact path='/inside-tests-ev'>
          <InsideTestsEasyView />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route path='/refunds'>
          <Refunds />
        </Route>
        <Route path='/refund'>
          <Refund />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board'>
          <SivanBoard />
        </Route>
        <Route exact path="/items">
          <ClerkItems />
        </Route>
        <Route exact path="/message/add">
          <AddMessage />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/settings-money-transfer">
          <MoneyTransferSettings />
        </Route>
        <Route exact path="/school/post-signup-message">
          <SpecialMessageSignupFee />
        </Route>
        <Route exact path="/school/post-insidetest-message">
          <SpecialMessageInsideTestFee />
        </Route>
        <Route exact path="/manager/items">
          <Items />
        </Route>
        <Route exact path="/school/supplier/add">
          <AddSupplier />
        </Route>
        <Route exact path="/school/supplier/edit">
          <EditSupplier />
        </Route>
        <Route exact path="/school/suppliers">
          <Suppliers />
        </Route>
        <Route exact path="/cheque/edit">
          <ManagerEditCheque />
        </Route>
        <Route exact path="/cheque/add">
          <ManagerAddCheque />
        </Route>
        <Route exact path="/cheques">
          <ManagerCheques />
        </Route>
        <Route exact path="/school/inside-tests">
          <InsideTests />
        </Route>
        <Route exact path="/generate-invoices">
          <ManagerGenerateInvoice />
        </Route>
        <Route exact path="/outside-link">
          <ManagerSendOutSidePaymentLink />
        </Route>
        <Route exact path="/school/inside-test">
          <AddInsideTest />
        </Route>
        <Route exact path="/transfer-money">
          <ManagerPullMoney />
        </Route>
        <Route path="/payment">
          <ManagerAddPayment />
        </Route>
        <Route path="/students">
          <Students />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/student/add">
          <ManagerAddStudent />
        </Route>
        <Route path="/student/update">
          <ManagerEditStudent />
        </Route>
        <Route path="/student/migrate">
          <MigrateStudent />
        </Route>
        <Route exact path="/employee/add">
          <AddEmployee />
        </Route>
        <Route exact path="/employee/update">
          <EditEmployee />
        </Route>
        <Route exact path="/school/special-message">
          <SpecialMessage />
        </Route>
        <Route exact path="/school/payment-message">
          <PaymentMessage />
        </Route>
        <Route exact path="/school/update">
          <EditSchool />
        </Route>
        <Route path="/">
          <ManagerDashboard />
        </Route>
      </Switch>
    );
  }

  teacher = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
      <Route exact path='/teachers-income'>
        <TeachersIncome />
      </Route>
      <Route exact path='/commission-table'>
        <KupotMorim />
      </Route>
      <Route exact path='/payment-document'>
        <UserPaymentsDocument />
      </Route>
      <Route exact path='/students-invoices'>
        <TeacherStudentsInvoices />
      </Route>
      <Route exact path='/small-cashregister'>
        <SmallCashRegister />
      </Route>
      <Route exact path='/small-cashregister-in'>
        <SmallCashRegisterIn />
      </Route>
        <Route exact path='/teacher/set-insidetest-teacher'>
          <SMSSetInsideTest />
        </Route>
        <Route exact path='/store/slogan'>
          <EditSlogan />
        </Route>
        <Route exact path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route path='/refunds'>
          <GizbarRefunds />
        </Route>
        <Route path='/refund'>
          <GizbarRefund />
        </Route>
        <Route exact path='/ptors-given'>
          <TeacherPtorsGiven />
        </Route>
        <Route exact path='/cash-registers'>
          <TeacherCashRegisters />
        </Route>
        <Route exact path='/payments/discover'>
          <TeacherDiscoverPayments />
        </Route>
        <Route exact path='/deposit'>
          <TeacherDeposit />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route path='/calendar/student/set-test-self'>
          <SetInsideTestSelfProfessional />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board'>
          <SivanBoard />
        </Route>
        <Route exact path="/migrate">
          <TeacherMigrateStudent />
        </Route>
        <Route exact path="/items">
          <TeacherItems />
        </Route>
        <Route path='/messages'>
          <MessagesBoard />
        </Route>
        <Route exact path="/calendar/students">
          <TeacherInsideTests />
        </Route>
        <Route exact path="/calendar/students/set-test">
          <AddInsideTestStudent />
        </Route>
        <Route exact path="/generate-invoices">
          <TeacherGenerateInvoice />
        </Route>
        <Route path="/transfer-money-teacher">
          <TeacherPullMoney />
        </Route>
        <Route path="/transfer-money">
          <GizbarPullMoney />
        </Route>
        <Route exact path="/calendar/tests">
          <MyInsideTests />
        </Route>
        <Route exact path="/inside-test">
          <InsideTest />
        </Route>
        <Route exact path="/inside-test-time">
          <SetInsideTestTime />
        </Route>
        <Route path="/settings">
          <TeacherSettings />
        </Route>
        <Route path="/payment">
          <TeacherAddPayment />
        </Route>
        <Route path="/student/add">
          <AddStudent />
        </Route>
        <Route path="/student/update">
          <EditStudent />
        </Route>
        <Route path="/">
          <TeacherDashboard />
        </Route>
      </Switch>
    );
  }

  clerk = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
      <Route exact path='/teachers-income'>
        <TeachersIncome />
      </Route>
      <Route exact path='/commission-table'>
        <KupotMorim />
      </Route>
      <Route exact path='/financial-obligations/manage'>
        <ClerkManageObligation />
      </Route>
      <Route exact path='/financial-obligations/add'>
        <ClerkAddObligation />
      </Route>
      <Route exact path='/financial-obligations/edit'>
        <EditObligation />
      </Route>
      <Route exact path='/financial-obligations/payment'>
        <ClerkAddObligationPayment />
      </Route>
      <Route exact path='/financial-obligations/manage-teacher'>
        <ClerkObligationsDashboardTeacher />
      </Route>
      <Route exact path='/financial-obligations'>
        <ClerkObligationsDashboard />
      </Route>
      <Route exact path='/payment-document'>
        <UserPaymentsDocument />
      </Route>
      <Route exact path='/report-hours'>
        <ReportHoursClerk />
      </Route>
      <Route exact path='/students-invoices'>
        <ClerkStudentsInvoices />
      </Route>
      <Route exact path='/small-cashregister'>
        <SmallCashRegister />
      </Route>
      <Route exact path='/small-cashregister-in'>
        <SmallCashRegisterIn />
      </Route>
          <Route exact path='/store/slogan'>
            <EditSlogan />
          </Route>
          <Route exact path='/contact-us'>
            <ContactUsBoard />
          </Route>
          <Route exact path='/ptors-given'>
            <ClerkPtorsGiven />
          </Route>
          <Route exact path='/cash-registers'>
            <ClerkCashRegisters />
          </Route>
          <Route exact path='/payments/discover'>
            <ClerkDiscoverPayments />
          </Route>
          <Route exact path='/deposit'>
            <ClerkDeposit />
          </Route>
          <Route exact path='/inside-tests-ev'>
            <InsideTestsEasyView />
          </Route>
          <Route exact path='/board/page'>
            <SivanBoardPage />
          </Route>
          <Route exact path='/refunds'>
            <ClerkRefunds />
          </Route>
          <Route path='/refund'>
            <ClerkRefund />
          </Route>
          <Route path='/board/edit'>
            <EditSivanBoardMessage />
          </Route>
          <Route path='/board/add'>
            <NewSivanBoardMessage />
          </Route>
          <Route path='/board'>
            <SivanBoard />
          </Route>
          <Route exact path="/migrate">
            <ClerkMigrateStudent />
          </Route>
          <Route exact path="/items">
            <ClerkItems />
          </Route>
          <Route exact path="/inside-tests">
            <ClerkInsideTests />
          </Route>
          <Route path='/messages'>
            <MessagesBoard />
          </Route>
          <Route exact path="/cheque/edit">
            <EditCheque />
          </Route>
          <Route exact path="/cheque/add">
            <AddCheque />
          </Route>
          <Route exact path="/cheques">
            <Cheques />
          </Route>
          <Route exact path="/generate-invoices">
            <ClerkGenerateInvoice />
          </Route>
          <Route path="/transfer-money">
            <ClerkPullMoney />
          </Route>
          <Route exact path="/outside-link">
            <ClerkSendOutSidePaymentLink />
          </Route>
          <Route exact path="/inside-test">
            <ClerkInsideTest />
          </Route>
          <Route exact path="/inside-test/add">
            <ClerkAddInsideTest />
          </Route>
          <Route exact path="/employee/add">
            <ClerkAddEmployee />
          </Route>
          <Route exact path="/employee/edit">
            <ClerkEditEmployee />
          </Route>
          <Route exact path="/employees">
            <EmployeesList />
          </Route>
          <Route path="/payment">
            <AddPayment />
          </Route>
          <Route path="/get-link">
            <GetLink />
          </Route>
          <Route path="/settings">
            <ClerkSettings />
          </Route>
          <Route path="/student/add">
            <ClerkAddStudent />
          </Route>
          <Route path="/student/update">
            <ClerkEditStudent />
          </Route>
          <Route path="/">
            <ClerkDashboard />
          </Route>
      </Switch>
    );
  }

  guest = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board'>
          <SivanBoard />
        </Route>
        <Route path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route path="/">
          <SivanBoard />
        </Route>
      </Switch>
    );
  }

  notconnected = () => {
    return (
      <Switch>
        <Route path="/teacher-payments-page">
          <TeacherSelfPayment />
        </Route>
        <Route exact path="/board/add">
          <NewSivanBoardMessageGuest />
        </Route>
        <Route exact path="/board/page">
          <SivanBoardPageGuest />
        </Route>
        <Route exact path="/board">
          <SivanBoardGuest />
        </Route>
        <Route exact path="/payment">
          <SelfPayment />
        </Route>
        <Route exact path="/signup">
          <SelfSignup />
        </Route>
        <Route exact path="/contact-us">
          <ContactUsBoard />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    )
  }


  render() {
    return (
      <Router>
        {this.state.loading ? (
          <div>
            <Switch>
              <Route path="/">
                <Loading />
              </Route>
            </Switch>
          </div>
        ) : (
          <div>
            {this.state.isConnected ? (
              <div>
                {this.state.type === '' ? (
                  <div>
                    {this.state.isManager && (
                      <div>{this.loneManager()}</div>
                    )}
                  </div>
                ) : (
                  <div>
                    {(this.state.type === 'teacher' || this.state.type === 'professional' || this.state.type === 'professionalteacher') ? (
                      <div>
                        {this.state.isManager ? (
                          <div>{this.teacherManager()}</div>
                        ) : (
                          <div>{this.teacher()}</div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {this.state.type === 'clerk' ? (
                          <div>
                            {this.state.isManager ? (
                              <div>{this.clerkManager()}</div>
                            ) : (
                              <div>{this.clerk()}</div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {this.state.type === 'cpe' ? (
                              <div>{this.cpe()}</div>
                            ) : (
                              <div>
                                {this.state.type === 'guest' ? (
                                  <div>{this.guest()}</div>
                                ) : (
                                  <div>{this.notconnected()}</div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>{this.notconnected()}</div>
            )}
          </div>
        )}
      </Router>
    )
  }


}

export default App;
