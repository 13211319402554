import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import axios from 'axios';

import {
  Link
} from "react-router-dom";

import {
  isMobile
} from "react-device-detect";

import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class PermissionsSmallCashregister extends Component {
  componentDidMount() {
    this.getData();
    // this.getMessages();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }

  }


  constructor(props) {
   super(props);
   this.state = {
     main: [],
     schools: [],
     employees: [],
     employeesOriginal: [],
     lastSeen: '',
     language: 'he',
     type: '',
     messages: [],
     tickerMove: true,
     showTicker: true,
     string: '',
     haveInsideTests: false,
     canManagerPayToEmployees: false,
     haveSignUpFee: false,
     notFound: false,
     haveStore: false,
     teachers: [],
     professionalteacher: [],
     edit: false,
     teacher: {}
   }
  }

  modifyType = (type) => {
    if (type === "teacher") {
      return strings.teacher
    } else if (type === "clerk") {
      return strings.clerk
    } else if (type === "professionalteacher") {
      return strings.professionalteacher
    } else if (type === "cpe") {
      return strings.cpe
    } else {
      return strings.teacher;
    }

    return strings.teacher;
  }

  getData = async () => {
    axios.get(API + 'manager', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          main: response.data.data.schools['main'],
          schools: response.data.data.schools['children'],
          employees: response.data.data.employees,
          employeesOriginal: response.data.data.employees,
          lastSeen: response.data.lastSeen,
          type: response.data.type,
          haveSignUpFee: response.data.haveSignUpFee,
          haveInsideTests: response.data.haveInsideTests,
          canManagerPayToEmployees: response.data.canManagerPayToEmployees,
          haveStore: response.data.haveStore,
        })
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeIn = async (id) => {
    axios.put(API + 'manager/smallcashregister-in-permission?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeOut = async (id) => {
    axios.put(API + 'manager/smallcashregister-out-permission?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeView = async (id) => {
    axios.put(API + 'manager/smallcashregister-view-permission?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changePrint = async (id) => {
    axios.put(API + 'manager/smallcashregister-print-permission?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeEdit = async (id) => {
    axios.put(API + 'manager/smallcashregister-edit-permission?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  changeRemove = async (id) => {
    axios.put(API + 'manager/smallcashregister-remove-permission?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (

      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginRight: '5%', marginTop: '2%'}}>
            <h2 style={{textAlign: 'right'}}>הרשאות קופה קטנה</h2>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginRight: '5%', marginTop: '2%', textAlign: 'right', direction: 'rtl'}}>


          <TableContainer component={Paper} style={{maxWidth: '85%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>שם העובד</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>ת.ז</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>הרשאת רישום תשלום ספק</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>הרשאת הפקדת כסף</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>צפייה בקופה</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>הפקת מסמך הוצאות</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>עריכת מסמך (באותו היום)</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>מחיקת מסמך (באותו היום)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.employees.reverse().map((employee, i) => {
                  return (
                    <TableRow key={i+'_tb'}>
                      <TableCell align="right">{employee.firstName + ' ' + employee.lastName}</TableCell>
                      <TableCell align="right">{employee.idNumber}</TableCell>

                      <TableCell align="right">
                        <Checkbox
                          checked={employee.smallcashregisterOut}
                          onChange={(e) => this.changeOut(employee._id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={employee.smallcashregisterIn}
                          onChange={(e) => this.changeIn(employee._id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={employee.smallcashregisterView}
                          onChange={(e) => this.changeView(employee._id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={employee.smallcashregisterPrint}
                          onChange={(e) => this.changePrint(employee._id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={employee.smallcashregisterEdit}
                          onChange={(e) => this.changeEdit(employee._id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={employee.smallcashregisterRemove}
                          onChange={(e) => this.changeRemove(employee._id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>


          </div>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2%'}}></Grid>
      </div>
    )
  }
}

export default PermissionsSmallCashregister;
