import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';
import {Helmet} from "react-helmet";


import {
  isMobile
} from "react-device-detect";
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const strings = new LocalizedStrings(i18n);


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SelfPayment extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     firstName: '',
     lastName: '',
     idNumber: '',
     gender: 'male',
     birthday: '01-02-1995',
     email: '',
     phoneNumber: '',
     licenseType: 'A1',

     teacher: '',
     teacherName: '',
     message: '',
     paymentMessage: '',
    signupfeeMessage: '',
    insidetestfeeMessage: '',
     saved: false,
     language: 'he',
     payFor: '',
     canSignup: true,
     canInsideTest: true,

     allowedDrivingLicenseTypes: [],
     ccno: '',
     expdate: '',
     cvv: '',
     expdatemonth: '',
     expdateyear: '',

     schoolName: '',

     prices: {
       'signupPrice': 0,
       'insideTestPrice': 0
     },

     studentName: '',
     havePtor: false,
     birthdayDialog: false,
     schoolId: '',
     canPayNow: false,
     haveInsideTests: true,
     haveSignUpFee: true,
     idNumberOwner: '',
     loading: false
   }
  }

  getData = async () => {
    // if (cookies.get('saved') === true || cookies.get('saved') === "true") {
    //   this.setState({saved: true});
    // } else {
        let name = 'id';
        let url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {

          axios.get(API +'student/payment-message?id='+this.state.schoolId+'&tid='+this.state.schoolId).then(response => {
            // console.log(API +'student/payment-message?id='+this.state.teacherId);
            // console.log(response);
            this.setState({
              message: response.data.data.message,
              paymentMessage: response.data.data.paymentMessage,
              signupfeeMessage: response.data.data.signupfeeMessage,
              insidetestfeeMessage: response.data.data.insidetestfeeMessage,
              schoolName: response.data.data.school,
              haveInsideTests: response.data.data.haveInsideTests,
              haveSignUpFee: response.data.data.haveSignUpFee,
            }, () => {
              let name = 'pf';
              let url = window.location.href;
              name = name.replace(/[\[\]]/g, '\\$&');
              var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                  results = regex.exec(url);
              if (!results) return null;
              if (!results[2]) return '';
              // this.setState({payFor: decodeURIComponent(results[2].replace(/\+/g, ' ')).toLowerCase()}, () => {
              //   if (this.state.payFor !== 'signupFee' && this.state.payFor !== 'itf') {
              //     this.setState({payFor: 'signupFee'});
              //   } else {
              //     if (this.state.payFor === 'itf') this.setState({payFor: 'insideTestFee'})
              //   }
              // })
            });
          }).catch(err => {
            console.log(err);
          });
        });
    // }
  }

  // checkIdNumber = () => {
  //   axios.get(API + 'student/idnumber?id='+)
  // }

  checkIdNumber = (idNumber) => {
    if (this.validateID(idNumber)) {
      this.setState({idNumber: idNumber})

      axios.get(API + 'student/idnumber?id='+this.state.schoolId+'&idn='+idNumber).then(response => {
        console.log(response);

        this.setState({
          paidFee: response.data.paidFee,
          studentExists: response.data.studentExists,
          studentId: response.data.studentId,
          canSignup: response.data.can_signup,
          canInsideTest: response.data.can_inside_test,
          studentName: response.data.student,
          havePtor: response.data.havePtor,
          prices: response.data.prices,
          teacherName: response.data.teacher,
          allowedDrivingLicenseTypes: response.data.allowedDrivingLicenseTypes,
          canPayNow: response.data.canPayNow,
          signupFeeText: response.data.signupFeeText
        });

        if (response.data.turn === 'signupFee' || response.data.turn === 'signupfee') {
          if (this.state.haveSignUpFee) this.setState({payFor: 'signupFee'});
          else this.setState({payFor: 'no_need'})
        }
        if (response.data.turn === 'insideTestFee' || response.data.turn === 'insidetestFee') {
          if (this.state.haveInsideTests) this.setState({payFor: 'insideTestFee'});
          else this.setState({payFor: 'no_need'})
        }
        if (response.data.turn === 'no_need') {
          this.setState({payFor: 'no_need'})
        }
      }).catch(err => {
        this.setState({
          paidFee: false,
          studentExists: false,
          studentId: '',
          canSignup: true,
          canInsideTest: true,
          payFor: ''
        });
      });
    } else {
      this.setState({
        idNumber: idNumber,
        paidFee: false,
        studentExists: false,
        studentId: '',
        payFor: '',
        canPayNow: false
      });
    }
  }


  validateID = (str) => {
      // var R_ELEGAL_INPUT = -1;
      // var R_NOT_VALID = -2;
      // var R_VALID = 1;
      var R_ELEGAL_INPUT = false;
      var R_NOT_VALID = false;
      var R_VALID = true;
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5))
        return R_ELEGAL_INPUT;
      if (isNaN(IDnum))
        return R_ELEGAL_INPUT;

      // The number is too short - add leading 0000
      if (IDnum.length < 9)
      {
        while(IDnum.length < 9)
        {
           IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i=0; i < 9; i++)
      {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i%2)+1;
        if (incNum > 9)
           incNum -= 9;
        mone += incNum;
      }
      if (mone%10 == 0)
        return R_VALID;
      else
        return R_NOT_VALID;
  }

  validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d/g).length===10;
  }

  validate = () => {
    // if (this.state.studentExists) {
    //   let firstName = this.state.firstName;
    //   let lastName = this.state.lastName;
    //   let idNumber = this.state.idNumber;
    //   let birthday = this.state.birthday;
    //   let phoneNumber = this.state.phoneNumber;
    //
    //   if (firstName === '' || lastName === '' || idNumber === '' || birthday === '' || phoneNumber === ''){
    //     alert(strings.mustFillAllFields);
    //     return false;
    //   }
    //
    //   if (phoneNumber.length !== 10 || !this.validatePhoneNumber(phoneNumber)) {
    //     alert(strings.phoneNotGood);
    //     return false
    //   }
    //
    //   if (!this.validateID(idNumber)) {
    //     alert(strings.idNumberNotGood);
    //     return false;
    //   }
    //
    //   return true;
    // }

    return true;
  }

  createUser = () => {
    if (!this.state.loading) {
      this.setState({loading: true}, () => {
        if (this.validate()) {
          let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            idNumber: this.state.idNumber,
            gender: this.state.gender,
            birthday: this.state.birthday,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            licenseType: this.state.licenseType,
            type: 'tranzila',
            signupStudent: false,
            schoolId: this.state.schoolId,
            teacherId: this.state.teacherId,
            studentId: this.state.studentId,
            expdate: this.state.expdatemonth +''+ this.state.expdateyear,
            ccno: this.state.ccno,
            cvv: this.state.cvv,
            description: this.state.payFor,
            idNumberOwner: this.state.idNumberOwner
          }
          console.log(payload);

          if (this.state.allowedDrivingLicenseTypes.length <= 1) {
            payload['licenseType'] = this.state.allowedDrivingLicenseTypes[0];
          }

          // console.log(payload);
          axios.post(API + 'student/payment', payload)
          .then(response => {
            if (response.data.status === "ok") {
              this.setState({loading: false});
              // alert(strings.messsageThankYouContactShortly);
              // cookies.set('saved', true, { path: '/' });
              this.setState({saved: true})
              window.open(response.data.data.link, '_blank');
              // window.location.reload();
            } else {
              this.setState({loading: false});
              if (response.data.errorCode === 111 || response.data.errorCode === '111') {
                alert(strings.messageStudentAlreadyExists + ' ' + response.data.teacherName);
              } else if (response.data.errorCode === 1111 || response.data.errorCode === '1111' || response.data.errorCode === 1112 || response.data.errorCode === '1112') {
                alert('שגיאה בחיוב הכרטיס, אנא בדקו את הנתונים ונסו בשנית');
              }else {
                alert('תלמיד/ה יקר/ה, בוצע שגיאה כללית. אנא נסו לעשות ריפרש לעמוד ולנסות בשנית, אם הבעיה חוזרת על עצמה, אנא פנו למורה שלכם');
              }
            }
          })
          .catch(error => {
            this.setState({loading: false});
            alert('תלמיד/ה יקר/ה, בוצע שגיאה כללית. אנא נסו לעשות ריפרש לעמוד ולנסות בשנית, אם הבעיה חוזרת על עצמה, אנא פנו למורה שלכם');
          });
        } else {
          this.setState({loading: false});
          alert('שגיאה, אנו וודאו שהת.ז תקין');
        }
      });
    } else {
      alert('טוען, אנא המתינו');
    }
  }

  // getSignupFeeText = () => {
  //   return this.state.signupFeeText === undefined || this.state.signupFeeText === null ? 'תשלום דמי רישום' : this.state.signupFeeText;
  // }
  getSignupFeeText = () => {
    let ft = window.localStorage.getItem('signupfee_text');
    if (ft === null || ft === undefined || ft === 'NULL' || ft === 'null') {
      return 'תשלום דמי רישום';
    }
    return ft;
  }

  render() {
    return (
      <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>לינק לתשלום בית ספר {this.state.schoolName}</title>
            <meta name="description" content="אנא הרשם בעזרת לינק זה" />
        </Helmet>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'not_logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            {this.state.saved ? (
              <div>
              {(this.state.message !== '' && this.state.message !== undefined && this.state.message !== null) ? (
                <h4 style={{textAlign: 'center', color: 'green', direction: 'rtl'}}>
                  {this.state.message}
                </h4>
              ) : (
                <React.Fragment>
                  <h2 style={{textAlign: 'center', color: 'green', direction: 'rtl'}}>{this.state.paymentMessage}</h2>
                  {(this.state.payFor !== 'no_need' && this.state.payFor !== '') && (
                    <React.Fragment>
                    {this.state.payFor === 'signupFee' ? (
                      <h3 style={{textAlign: 'center', color: 'green', direction: 'rtl'}}>{this.state.signupfeeMessage}</h3>
                    ) : (
                      <h3 style={{textAlign: 'center', color: 'green', direction: 'rtl'}}>{this.state.insidetestfeeMessage}</h3>
                    )}
                    </React.Fragment>
                  )}

                </React.Fragment>
              )}

              <br />
              <div style={{border: '1px solid black', width: '50%'}}>
                <div>
                  <p style={{tetDecoration: 'rtl'}}>אנו מזמינים אתכם להכנס לאתר המכירות - קניות של קמע</p>
                  <p style={{tetDecoration: 'rtl'}}><a href='https://sivan-school.com/board' target='_blank'>לכניסה לחצו כאן</a></p>
                </div>
              </div>
              </div>
            ) : (
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>
                {this.state.studentName === '' ? strings.selfsigndearstudent : (this.state.studentName + ', אנא מלא/י את הפרטים ולחצ/י על כפתור האישור')}
                </h2>
                <h4 style={{textAlign: 'right', color: '#717171', direction: 'rtl'}}>
                {strings.selfsignattheend}
                </h4>
                {(this.state.message !== '' && this.state.message !== undefined && this.state.message !== null) && (
                  <h4 style={{textAlign: 'right', direction: 'rtl'}}>
                    {this.state.message}
                  </h4>
                )}

                {(this.state.teacherName !== '' && this.state.schoolName !== '') && (
                  <h4 style={{textAlign: 'right', direction: 'rtl'}}>{'מורה: ' + this.state.teacherName + ', ' + this.state.schoolName}</h4>
                )}

                {this.state.canPayNow ? (
                  <React.Fragment>
                  {(this.state.payFor !== 'no_need' && this.state.payFor !== '') && (
                    <React.Fragment>
                    {(this.state.payFor === 'signupFee' && this.state.haveSignUpFee) ? (
                      <h4 style={{textAlign: 'right', direction: 'rtl'}}>סה"כ לתשלום: ₪{this.state.prices.signupPrice}, {this.state.payFor === 'signupFee' ? this.getSignupFeeText() : 'תשלום מבחן פנימי'}</h4>
                    ) : (
                      <>
                        {(this.state.haveInsideTests) && (
                          <h4 style={{textAlign: 'right', direction: 'rtl'}}>סה"כ לתשלום: ₪{this.state.prices.insideTestPrice}, {this.state.payFor === 'signupFee' ? this.getSignupFeeText() : 'תשלום מבחן פנימי'}</h4>
                        )}
                      </>
                    )}
                    </React.Fragment>
                  )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p style={{fontWeight: 'bold'}}>אנא הקלד את מספר הת.ז שלך (התלמיד) על מנת לבצע תשלום</p>
                  </React.Fragment>
                )}


                {this.state.havePtor ? (
                  <React.Fragment>
                    <h4>אין צורך בתשלום. אנא צרו קשר עם המורה שלכם</h4>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                  <p style={{textAlign: 'right', direction: 'rtl'}}>{strings.idnumberstudent} <span style={{color: 'red'}}>*</span></p>
                  <TextField
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                    value={this.state.idNumber}
                    onChange={(e) => this.checkIdNumber(e.target.value)}
                  />

                  {this.state.canPayNow && (
                    <React.Fragment>

                    {this.state.payFor !== 'no_need' && (
                      <React.Fragment>
                      <p style={{textAlign: 'right', direction: 'rtl'}}>{strings.idnumbercardowner} <span style={{color: 'red'}}>*</span></p>
                      <TextField
                        style={{
                          direction: 'rtl',
                          width: '100%'
                        }}
                        value={this.state.idNumberOwner}
                        onChange={(e) => this.setState({idNumberOwner: e.target.value})}
                      />

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.creditcardnumber}</p>
                      <Input
                        value={this.state.ccno}
                        onChange={(e) => this.setState({ccno: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />

                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.expdatenumber}</p>
                      <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.month}</p>
                      <Select
                        value={this.state.expdatemonth}
                        onChange={(e) => this.setState({expdatemonth: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'01'}>01</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'02'}>02</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'03'}>03</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'04'}>04</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'05'}>05</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'06'}>06</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'07'}>07</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'08'}>08</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'09'}>09</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'10'}>10</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'11'}>11</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={'12'}>12</MenuItem>
                      </Select>
                      <p style={{textAlign: 'right', fontSize: '0.7em'}}><span style={{color: 'red'}}>*</span> {strings.year}</p>
                      <Select
                        value={this.state.expdateyear}
                        onChange={(e) => this.setState({expdateyear: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      >
                        {Array.from({ length: 11 }, (_, i) => (new Date().getFullYear() % 100) + i).map((index) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}}  value={index.toString()}>{index.toString()}</MenuItem>
                      )
                    })}
                      </Select>
                      <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> {strings.cvvnumber}</p>
                      <Input
                        value={this.state.cvv}
                        onChange={(e) => this.setState({cvv: e.target.value})}
                        type='number'
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '100%'
                        }}
                      />

                      <br /><br />
                      </React.Fragment>
                    )}




                    {(
                      ((this.state.payFor === 'signupFee' && this.state.prices.signupPrice === 0) || this.state.payFor === 'no_need') ||
                      ((this.state.payFor === 'insideTestFee' && this.state.prices.insideTestPrice === 0) || this.state.payFor === 'no_need')
                    ) ? (
                      <React.Fragment>
                        <p>אין צורך בתשלום</p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                      {(!this.state.canSignup && this.state.payFor === 'signupFee') || (!this.state.canInsideTest && this.state.payFor === 'insideTestFee') ? (
                        <p>{strings.dearstudentcantpay}</p>
                      ) : (
                        <React.Fragment>
                        {isMobile ? (
                          <Button onClick={(e) => this.createUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.4em', width: '90%'}}>
                            {strings.approve}
                          </Button>
                        ) : (
                          <Button onClick={(e) => this.createUser()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                            {strings.approve}
                          </Button>
                        )}
                        </React.Fragment>
                      )}
                      </React.Fragment>
                    )}


                    </React.Fragment>
                    )}
                    </React.Fragment>
                  )}



              </div>
            )}
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SelfPayment;
