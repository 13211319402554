import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Cookies from 'universal-cookie';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class PaymentMessage extends Component {
  componentDidMount () {
    this.getSchool();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
      message: '',
      schoolId: '',
      lastSeen: '',
      language: 'he'
   }
  }

  handleChange = () => {
    let isClerk = this.state.isClerk ? false : true;
    let type = this.state.type;
    if (isClerk) type = 'clerk';
    else type = 'teacher';

    this.setState({isClerk: isClerk, type: type});
  }

  getSchool = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'manager/school/payment-message?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            message: response.data.data.message,
            lastSeen: response.data.lastSeen
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
          alert('שגיאה בקבלת נתוני בית הספר')
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  updateSchool = () => {
    let payload = {
      specialMessage: this.state.message
    }

    axios.put(API + 'manager/school/payment-message?id='+this.state.schoolId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        //alert(strings.messageSpecialMessageUpdatedSuccess);
        window.location.href='/';
      } else {
        // alert(strings.errorPleaseTryAgain);
        alert('שגיאה בעדכון בית הספר')
      }
    })
    .catch(error => {
      alert(strings.errorPleaseTryAgain);
    });

  }

  freeze = () => {
    let frozen = this.state.frozen ? false : true;

    this.setState({frozen: frozen});
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right', marginBottom: -15}}>{strings.editPaymentMessage}</h2>
              <h4 style={{textAlign: 'right', color: '#717171'}}>{strings.editPaymentMessage2}</h4>
              <br />
              <p style={{textAlign: 'right'}}>{strings.paymentMessage}</p>
              <Input
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='text'
                value={this.state.message}
                onChange={(e) => this.setState({message: e.target.value})}
              />

              <Button onClick={(e) => this.updateSchool()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                {strings.update}
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default PaymentMessage;
