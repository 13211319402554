import React, { Component } from 'react';
import '../../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import DescriptionIcon from '@material-ui/icons/Description';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import axios from 'axios';
import { i18n } from '../../components/i18n';
import LocalizedStrings from 'react-localization';
import {
  Link
} from "react-router-dom";

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Cheques extends Component {
  componentDidMount () {
    this.getData();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     teachers: [],
     cheques: [],
     user: {},
     cashRegister: '',
     userId: '',
     lastSeen: '',
     language: 'he',
     money: 0,
     payments: [],
     old_payments: [],
     invoiceReady: false,
     link: '',
     whatsapp: '',
     fromDate: new Date(Date.now()),
     toDate: new Date(Date.now()),
   }
  }

  getData = async () => {
    axios.get(API + 'clerk/cheque-list', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers: response.data.data.teachers,
          cheques: response.data.data.cheques,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getDataFiltered = async () => {
    let fromDate = new Date(this.state.fromDate).toString();
    let toDate = new Date(this.state.toDate).toString();

    axios.get(API + 'clerk/cheque-list-filter?fromDate='+fromDate+'&toDate='+toDate, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers: response.data.data.teachers,
          cheques: response.data.data.cheques,
        });
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getAmount = (amount) => {
    let money = parseFloat(amount).toFixed(2);
    if (money === null || money === undefined || isNaN(money)) return parseFloat(0.00);

    return money;
  }

  generateInvoice = (id, whatsapp) => {
    this.setState({invoiceReady: false, link: '', whatsapp: ''});
    axios.post(API + 'clerk/generate-invoice', {
      id: id
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        window.scrollTo(0, 0);
        let whatsapp_ = 'https://wa.me/972'+whatsapp+'?text='+encodeURI('החשבונית שלך מוכנה וזמינה בקישור: \n' + response.data.data.link);
        this.setState({invoiceReady: true, link: response.data.data.link, whatsapp: whatsapp_});
      } else {
        alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>{strings.writeacheque}</h2>

              <div style={{textAlign: 'right'}}>
                <Link to='/cheque/add' style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>+ {strings.newcheque}</Button></Link>
              </div>
              <br /><br />
              <center>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '7%'}}>
                  <div style={{marginRight: '1%', marginLeft: '1%', marginTop: '2%'}}>
                    <Button variant={'contained'} color={'primary'} style={{width: 100, height: 50}} onClick={(e) => this.getDataFiltered()}>חפש</Button>
                  </div>

                  <div style={{marginRight: '1%', marginLeft: '1%'}}>
                    <p>עד תאריך</p>
                    <TextField
                      value={this.state.toDate}
                      onChange={(e) => this.setState({toDate: e.target.value})}
                      type='date'
                      format='dd/mm/yyyy'
                    />
                  </div>

                  <div style={{marginRight: '1%', marginLeft: '1%'}}>
                    <p>מתאריך</p>
                    <TextField
                      value={this.state.fromDate}
                      onChange={(e) => this.setState({fromDate: e.target.value})}
                      type='date'
                      format='dd/mm/yyyy'
                    />
                  </div>
                </div>
                <br />
                <Button variant={'contained'} onClick={(e) => this.getData()}>איפוס</Button>
              </center>
              <br /><br />
              <center>
              {this.state.cheques.length === 0 ? (
                <h4 style={{direction: 'rtl', color: 'grey'}}>
                {strings.nocheques1}
                <PersonAddIcon style={{width: 32, height: 32}}/><br/>
                 {strings.nocheques2}
                </h4>
              ) : (
                <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}}>
                  <Table aria-label="simple table">
                    <TableHead>

                      <TableRow>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.invoicecreated}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.amountOnCheque}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teacherInvoiceNumber}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.teacherInvoiceAmount}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.employeename}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.createdat}</TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold'}}>{strings.attachinvoice}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cheques.reverse().map((cheque, i) => {
                        let createdAtStringify = new Date(cheque.createdAt);
                        createdAtStringify = createdAtStringify.getDate() + '/' + (createdAtStringify.getMonth() + 1) + '/' + createdAtStringify.getFullYear();

                        return (
                          <TableRow key={i+'_payment'}>
                            <TableCell align="right">{cheque.invoiceDelivered ? 'כן' : 'לא'}</TableCell>
                            <TableCell align="right">₪{this.getAmount(cheque.chequeAmount)}</TableCell>
                            <TableCell align="right">{cheque.teacherInvoiceNumber === '' ? '-' : cheque.teacherInvoiceNumber}</TableCell>
                            <TableCell align="right">{cheque.teacherInvoiceAmount === '' ? '-' : '₪' + this.getAmount(cheque.teacherInvoiceAmount)}</TableCell>
                            <TableCell align="right">{cheque.teacher.firstName + ' ' + cheque.teacher.lastName}</TableCell>
                            <TableCell align="right">{createdAtStringify}</TableCell>
                            <TableCell align="right">
                              <Link to={'/cheque/edit?id='+cheque._id} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>{strings.attachinvoice}</Button></Link>
                            </TableCell>
                          </TableRow>
                        )
                      })}

                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              </center>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Cheques;
